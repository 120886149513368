import React, { useEffect } from "react";
import NavBar from "../../components/navBar/navBar";
import { Content } from "antd/es/layout/layout";
import { AiOutlineClose } from "react-icons/ai";
import BreadcrumbComp from "../../components/Breadcrumb/Breadcrumb";
import "./categories.css";
import { Dropdown, Skeleton, Drawer, Col, Row, Pagination } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { appContext } from "../../Context/appContext";
import { Footer } from "../../components/Footer/Footer";
import CarouselProducts from "../../components/Carousel/carouselProducts";
import DividerComp from "../../components/Divider/divider";
import FilterComp from "../../components/Filter/FilterComp";
import MobileNavBar from "../../components/mobileNavBar/mobileNavBar";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import {
  // isCategory,
  // getCategoryByCatName,
  checkIfFilterNotEmpty,
  chunk,
  handleNavigate,
} from "../../lib/lib";
import { getVariantsByCategoryApi, getCategoryByNameApi } from "../../Apis";
import ProductCard from "../../components/productItem";
import ProductSkeleton from "../../components/Skeleton/ProductSkeleton";
import ImageComponent from "../../components/Image";

function Categories() {
  const [width, setWidth] = useState(window.innerWidth);
  const [CurrentPage, setCurrentPage] = useState(1);
  const [PageSize] = useState(8);
  const [pagesAdsData, setPagesAdsData] = useState([]);
  const [pagesNonAdsData, setPagesNonAdsData] = useState([]);
  const [NonAds, setNonAds] = useState([]);
  const [Ads, setAds] = useState([]);
  const [sortName, setSortName] = useState("name asc");
  const [CategoryVariants, setCategoryVariants] = useState([]);
  const [Category, setCategory] = useState({});
  const [CategoryLoading, setCategoryLoading] = useState(false);
  const {
    onClose,
    open,
    filters,
    topRatedProducts,
    // categories,
    setSort,
    getTopRatedProducts,
    loadingTopRatedProducts,
    //AllCategories,
    setCategoryId,
    //categoryId,
    //searchQuery,
    FilteredProducts,
    _FilterValues,
    // loadingFilters,
    getFilters,
    LoadingFilteredProducts,
    getFilteredProductsRelatedToCategory,
    handleFavoret,
    loadingFavorite,
    loading,
    itemId,
    AllProducts,
    favoritList,
  } = useContext(appContext);
  const [results, setResults] = useState(0);
  const { categoryName, subCategoryName } = useParams();
  //const pathCategoryName = getPath(categoryName);
  //console.log("categories ", categories);

  // const isSubCategoryPath = isCategory(
  //   window.location.pathname.split("/"),
  //   AllCategories
  // )?.isSubCategory;
  // const SubCategoryData =
  //   isCategory(window.location.pathname.split("/"), AllCategories)?.data || {};
  // let catId = isSubCategoryPath
  //   ? SubCategoryData?._id
  //   : getCategoryByCatName(categoryName, AllCategories)?._id;
  // let subCategoryProducts = SubCategoryData?.products?.filter((product) =>
  //   [...FilteredProducts?.map((ele) => ele?._id)].includes(product?._id)
  // );
  // let products = isSubCategoryPath
  //   ? SubCategoryData?.products?.filter((ele) => ele?.isActive)
  //   : getCategoryByCatName(categoryName, AllCategories)?.products?.filter(
  //       (ele) => ele?.isActive
  //     );
  const items = [
    {
      label: (
        <span>
          product Name <AiOutlineArrowDown />
        </span>
      ),
      key: "name-asc",
    },
    {
      label: (
        <span>
          product Name <AiOutlineArrowUp />
        </span>
      ),
      key: "name-desc",
    },
    {
      label: (
        <span>
          product Price <AiOutlineArrowDown />
        </span>
      ),
      key: "price-asc",
    },
    {
      label: (
        <span>
          product Price <AiOutlineArrowUp />
        </span>
      ),
      key: "price-desc",
    },
  ];
  const categoryFilterPathLocalStorage =
    localStorage.getItem("categoryFilterPath") || undefined;
  let isNamePath =
    window.location.pathname.split("/")[1] === categoryFilterPathLocalStorage;
  //console.log("isNamePath ", isNamePath);
  const categoryFilterLocalStorage =
    localStorage.getItem("categoryFilter") || undefined;

  //#region useEffect
  useEffect(() => {
    return () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };
  }, [CurrentPage]);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  useEffect(() => {
    if (subCategoryName) {
      getCategoryByName(subCategoryName?.trim()?.replace(/-/, " "));
    } else if (categoryName) {
      getCategoryByName(categoryName?.trim()?.replace(/-/, " "));
    }
  }, [categoryName, subCategoryName]);
  useEffect(() => {
    if (Category?._id) {
      getTopRatedProducts(Category?._id);
      getFilters(Category?._id);
      getVariantsByCategory(Category?._id);
      setCategoryId(Category?._id);
      //storage filter
      if (isNamePath) {
        let storageFilter = categoryFilterLocalStorage
          ? JSON.parse(categoryFilterLocalStorage)
          : {};
        let _FilterValues = { ...storageFilter };
        _FilterValues["brand"] = storageFilter["brandId"] || "";
        _FilterValues["tag"] = storageFilter["tagId"] || "";
        if ("brandId" in _FilterValues) delete _FilterValues["brandId"];
        if ("tagId" in _FilterValues) delete _FilterValues["tagId"];
        if ("prices" in _FilterValues) delete _FilterValues["prices"];
        getFilteredProductsRelatedToCategory(Category?._id, _FilterValues);
      } else {
        getFilteredProductsRelatedToCategory(Category?._id, {});
      }
    }
  }, [Category?._id]);
  // useEffect(() => {
  //   if (Category?._id !== undefined && searchQuery !== undefined) {

  //   }
  // }, [Category?._id]);
  useEffect(() => {
    //console.log("categories ", categories);
    let categoryProducts = Category?.products?.filter(
      (ele) => ele?.category === Category?._id
    );
    let FilteredProductsIds = [...FilteredProducts?.map((ele) => ele?._id)];
    if (Category?._id) {
      let arr = [];
      categoryProducts?.map((data) => {
        if (checkIfFilterNotEmpty(_FilterValues)) {
          if (FilteredProductsIds.includes(data?._id)) {
            arr.push(data);
          }
        } else {
          arr.push(data);
        }
      });
      let Ads = arr?.filter((ele) => ele?.isPromoted === true);
      let Non_Ads = arr?.filter((ele) => !ele?.isPromoted);
      setAds(Ads);
      setNonAds(Non_Ads);
      setPagesAdsData(chunk(Ads, PageSize));
      setPagesNonAdsData(chunk(Non_Ads, PageSize));

      //setCatProducts(arr);
      //setPagesData(chunk(arr, PageSize));
      setResults(arr?.length);
    }
  }, [Category?._id, FilteredProducts, PageSize]);
  //#endregion useEffect
  //#region  functions
  const getCategoryByName = async (name) => {
    try {
      setCategoryLoading(true);
      const response = await getCategoryByNameApi(name);
      if (response.data) {
        setCategory(response.data?.data || {});
      }
      setCategoryLoading(false);
    } catch (error) {
      setCategoryLoading(false);
    }
  };
  const getVariantsByCategory = async (id) => {
    try {
      const response = await getVariantsByCategoryApi(id);
      if (response.data) {
        //  console.log(response.data?.data);
        setCategoryVariants(response.data?.data?.variants || []);
      }
    } catch (error) {}
  };
  const handleSort = (value) => {
    setSort(value?.key);
    setSortName(value?.key?.trim().replace("-", " "));
  };
  //#endregion functions
  const renderProductCart = ({ product, onClickHeartIcon }) => {
    let _product = AllProducts?.find((ele) => ele?._id === product?._id);
    return (
      <>
        <ProductCard
          product={_product}
          favoritList={favoritList}
          handleFavoret={onClickHeartIcon}
          handleNavigate={handleNavigate}
          itemId={itemId}
          loading={loading}
          loadingFavorite={loadingFavorite}
        />
      </>
    );
  };
  const renderPaginator = (
    <Pagination
      current={CurrentPage}
      pageSize={PageSize}
      onChange={(page) => {
        console.log(page);
        setCurrentPage(page);
      }}
      // total={catProducts?.length}
      total={Ads?.length > NonAds?.length ? Ads?.length : NonAds?.length}
    />
  );
  let cardFilterSpan = width < 1000 ? 8 : 7;

  const renderProductsPages = (label, pagesData, data) => {
    return (
      <>
        <Row gutter={[10, 10]}>
          {data?.length > 0 && pagesData[CurrentPage - 1]?.length > 0 && (
            <Col span={24}>
              <h2 style={{ color: "#ec2025" }}>{label}</h2>
            </Col>
          )}
          {CategoryLoading || LoadingFilteredProducts ? (
            <Row gutter={[10, 10]}>
              {Array(4)
                .fill(null)
                .map((_, i) => (
                  <Col span={width < 1000 ? 12 : 6} flex={1} key={i}>
                    <ProductSkeleton />
                  </Col>
                ))}
            </Row>
          ) : (
            pagesData[CurrentPage - 1]?.map((product) => (
              <Col span={width < 1000 ? 12 : 6}>
                {renderProductCart({
                  product,
                  onClickHeartIcon: () => handleFavoret(product?._id),
                })}
              </Col>
            ))
          )}
        </Row>
      </>
    );
  };
  return (
    <>
      {width > 767 ? (
        <>
          <NavBar />
          <Content
            style={{
              //  width: width > 1024 ? "1300px" : width <= 1024 ? "92%" : "70%",
              width: `calc(${width}px - ${width < 1200 ? 5 : 22}%)`,
              margin: "auto",
            }}
          >
            <Row gutter={[25, 25]}>
              {Category?.image && (
                <Col span={24}>
                  <ImageComponent
                    image={Category?.image}
                    style={{
                      width: "100%",
                      height: width > 767 ? "400px" : "130px",
                    }}
                  />
                </Col>
              )}
              <Col span={24}>
                <BreadcrumbComp />
              </Col>
              <Col span={cardFilterSpan}>
                <FilterComp
                  CategoryVariants={CategoryVariants}
                  title={"Categories"}
                  data={filters?.categories}
                  defaultKeys={CategoryVariants?.map((val) => val?.key)}
                  CategoryLoading={CategoryLoading}
                />
              </Col>
              <Col span={24 - cardFilterSpan}>
                <div className="categoriesList">
                  <div className="categoriesListTitle">
                    <Row
                      justify="space-between"
                      align="middle"
                      style={{ width: "100%" }}
                    >
                      <Col span={8}>
                        <div className="catTitle1">
                          <span style={{ textTransform: "capitalize" }}>
                            {/* {isSubCategoryPath
                              ? SubCategoryData?.englishName
                                  ?.trim()
                                  .replace("-", " ")
                              : categoryName?.trim().replace("-", " ")} */}
                            {Category?.englishName}
                          </span>
                        </div>
                      </Col>
                      <Col span={4} offset={12}>
                        <div className="catTitle2">
                          <span className="storeByTitle">Sort By</span>
                        </div>
                      </Col>
                      <Col span={4}>
                        <div className="catTitle1">
                          {CategoryLoading || LoadingFilteredProducts ? (
                            <Skeleton.Button
                              active
                              block
                              size={20}
                              shape={"round"}
                            />
                          ) : (
                            <p>
                              {/* {isSubCategoryPath
                                ? subCategoryProducts?.length
                                : results}{" "} */}
                              {results} Results
                            </p>
                          )}
                        </div>
                      </Col>
                      <Col span={4} offset={16}>
                        <div className="catTitle2">
                          <Dropdown
                            menu={{
                              items,
                              style: { marginTop: 20 },
                              onClick: handleSort,
                            }}
                            trigger={["click"]}
                          >
                            <p className="dropDownCat">
                              {sortName}{" "}
                              <DownOutlined
                                color="#231F20"
                                style={{ cursor: "pointer" }}
                              />
                            </p>
                          </Dropdown>
                        </div>
                      </Col>
                      <Col span={24} style={{ marginTop: "20px" }}>
                        {/** Ads */}
                        {renderProductsPages("Featured Ads", pagesAdsData, Ads)}
                        <br />
                        {/** Other */}
                        {renderProductsPages("Other", pagesNonAdsData, NonAds)}
                        <br />
                        {renderPaginator}
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>

              <Col span={24}>
                <div>
                  <DividerComp
                    title={"Top Rated Products"}
                    route={"Top Rated Products"}
                    id={Category?._id}
                  />
                </div>
                <div style={{ height: "300px" }}>
                  <CarouselProducts
                    products={topRatedProducts}
                    loading={loadingTopRatedProducts}
                    cols={6}
                  />
                </div>
              </Col>
            </Row>
          </Content>
          <Footer />
        </>
      ) : (
        <>
          <MobileNavBar />
          <Content style={{ width: "92%", margin: "auto" }}>
            <Row gutter={[5, 5]}>
              {Category?.image && (
                <Col span={24}>
                  <ImageComponent
                    image={Category?.image}
                    style={{
                      width: "100%",
                      height: width > 767 ? "400px" : "130px",
                    }}
                  />
                </Col>
              )}{" "}
            </Row>
            <div className="categoriesComp">
              <div
                style={{ width: "100%", marginBottom: "220px" }}
                className="categoriesList subCat"
              >
                {/** Ads */}
                {renderProductsPages("Featured Ads", pagesAdsData, Ads)}
                <br />
                {/** Other */}
                {renderProductsPages("Other", pagesNonAdsData, NonAds)}
                <br />
                {renderPaginator}
              </div>
            </div>
          </Content>
          <Footer />
          {/*********filters drawer******** */}
          <Drawer
            title={
              <span style={{ fontSize: "24px", fontWeight: "bold" }}></span>
            }
            placement="bottom"
            closable={false}
            height={"95%"}
            style={{
              padding: "0",
              borderTopLeftRadius: 15,
              borderTopRightRadius: 15,
              boxSizing: "border-box",
            }}
            extra={
              <AiOutlineClose
                style={{ fontSize: 25, cursor: "pointer" }}
                onClick={onClose}
              />
            }
            onClose={onClose}
            open={open}
          >
            <div
              className="paymentForm"
              style={{ width: "100%", margin: "15px" }}
            >
              <FilterComp
                CategoryVariants={CategoryVariants}
                title={"Categories"}
                data={filters?.categories}
                defaultKeys={CategoryVariants?.map((val) => val?.key)}
                onClose={onClose}
                CategoryLoading={CategoryLoading}
              />
            </div>
          </Drawer>
        </>
      )}
    </>
  );
}

export default Categories;
