import { Skeleton } from "antd";
import React from "react";

function ProductSkeleton() {
	return (
		<div className="productCard">
			<Skeleton.Image
				style={{ width: "100%", height: 186, borderRadius: 15 }}
				active
			/>
			{/* <div className='productImage'>
        <img src="/Asset 11.svg" alt="" />
        </div> */}
			<div className="productTitle">
				<p className="productName">
					<Skeleton.Button
						size={15}
						block={false}
						style={{ width: 70 }}
						active
					/>
				</p>

				<p className="productPrice">
					<Skeleton.Button
						size={15}
						block={false}
						style={{ width: 40 }}
						active
					/>
				</p>
			</div>
			<div className="productDescription">
				<p className="desc">
					<Skeleton.Input size={15} active />
				</p>
			</div>
			<div className="productRate">
				<div className="starIcon">
					<Skeleton.Button
						shape="square"
						size={15}
						style={{ width: 100 }}
						active
					/>
					<Skeleton.Button shape="square" size={15} active />
					{/* <Skeleton.Button shape='square' size={15} active/> */}

					{/* <p className='rate'><Skeleton.Button shape='circle' size={15} active/></p> */}
				</div>
				{/* <p className='rate'><Skeleton.Input size={15} active/></p> */}
			</div>
			<div className="productAddToCart">
				<Skeleton.Button block active />
				{/* <Button className='productBtn' icon={<PiShoppingCartSimpleThin className='cartIcon'/>} >Add to Cart</Button> */}
				<div className="heartbutton">
					<Skeleton.Button active />
					{/* <Button className='productBtn' icon={<BsHeart className="heartIcon"/>} ></Button> */}
				</div>
			</div>
		</div>
	);
}

export default ProductSkeleton;
