import React, { useEffect, useState } from "react";
import { CiStar } from "react-icons/ci";
import { addRateApi } from "../../Apis";
import { message } from "antd";
import { FaStar } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
const StarRating = ({ totalStars, initialRating, productId, size }) => {
  const [rating, setRating] = useState(initialRating);
  const [width, setWidth] = useState(window.innerWidth);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  const handleStarClick = async (clickedRating) => {
    if (!token) {
      message.warning("Please Login first");
      navigate("/login");
      return;
    }
    try {
      let obj = {
        rate: clickedRating,
      };
      const response = await addRateApi(productId, obj);
      setRating(clickedRating);
      // console.log(clickedRating);
      // console.log(response);
    } catch (error) {
      message.error(error.response.data.message);
    }
  };

  return (
    <div
      className="starIcon"
      style={{ justifyContent: width < 768 ? "start" : "space-between" }}
    >
      {[...Array(totalStars)].map((_, index) => {
        const starValue = index + 1;
        return !(starValue <= rating) ? (
          <CiStar
            size={size ? size : 15}
            key={starValue}
            onClick={() => handleStarClick(starValue)}
          />
        ) : (
          <FaStar
            color={"#ec2025"}
            size={size ? size : 15}
            key={starValue}
            onClick={() => handleStarClick(starValue)}
          />
        );
      })}
    </div>
  );
};

export default StarRating;
