import React, { useContext, useEffect, useState } from "react";
import "./carouselProducts.css";
import { appContext } from "../../Context/appContext";
import ProductSkeleton from "../Skeleton/ProductSkeleton";
import ProductCard from "../productItem";
import { chunk, handleNavigate } from "../../lib/lib";
import { Col, Row } from "antd";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

function CarouselProductsMobile({
  products,
  row = 1,
  loading: propsLoading,
  cols,
}) {
  const [width, setWidth] = useState(window.innerWidth);
  //const navigate = useNavigate();

  const {
    loadingProducts,
    favoritList,
    setFavoritList,
    handleFavoret,
    loadingFavorite,
    loading,
    itemId,
    userDetails,
  } = useContext(appContext);
  useEffect(() => {
    products?.forEach((product) => {
      if (product.favorite) {
        setFavoritList((prev) => [...prev, product._id]);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  const renderResponsiveCarousel = (products = [], style) => {
    let chunkProducts = [];
    if (products % 2 !== 0) {
      let p = [
        ...products,
        ...[...products].slice(0, 2 - (products.length % 2)),
      ];
      chunkProducts = chunk(p, 2);
    } else {
      chunkProducts = chunk(products, 2);
    }
    //console.log(chunkProducts);
    return (
      <div style={{ width: "100%", height: "100%" }}>
        <>
          <Carousel // react-responsive-carousel
            autoPlay
            infiniteLoop
            showArrows={false}
            showThumbs={false}
            emulateTouch={true}
            showIndicators={false}
            swipeScrollTolerance={20}
            preventMovementUntilSwipeScrollTolerance={true}
            interval={5000}
            onChange={(e) => {
              //console.log("onclick ", e);
            }}
            onClickItem={(e) => {
              console.log("onclick item ", e);
            }}
            onClickThumb={(e) => {
              // console.log("onClickThumb ", e);
            }}
          >
            {loadingProducts || propsLoading ? (
              <Row gutter={[10, 10]}>
                <Col span={12}>
                  <div className="container">
                    <ProductSkeleton />
                  </div>
                </Col>
                <Col span={12}>
                  <div className="container">
                    <ProductSkeleton />
                  </div>
                </Col>
              </Row>
            ) : (
              chunkProducts?.map((products, i) => (
                <Row
                  key={i}
                  cols={24}
                  gutter={5}
                  style={{ marginLeft: "5px", marginRight: "5px" }}
                >
                  {products?.map((product, j) => (
                    <Col key={j} span={12}>
                      <ProductCard
                        product={product}
                        favoritList={favoritList}
                        handleFavoret={handleFavoret}
                        handleNavigate={handleNavigate}
                        itemId={itemId}
                        loading={loading}
                        loadingFavorite={loadingFavorite}
                        userDetails={userDetails}
                        // style={{ width: "200px" }}
                      />
                    </Col>
                  ))}
                </Row>
              ))
            )}
          </Carousel>
        </>
      </div>
    );
  };

  const responsive = [
    {
      breakpoint: 5000,
      cols: 6,
      gap: 20,
      // loop: false,
    },
    {
      breakpoint: 1500,
      cols: 6,
      gap: 20,
      // loop: false,
    },
    {
      breakpoint: 1400,
      cols: 5,
      gap: 20,
      // loop: false,
    },
    {
      breakpoint: 1250,
      cols: 4,
      gap: 20,
      // loop: false,
    },
    {
      breakpoint: 1050,
      cols: 3,
      gap: 20,
      // loop: false,
    },
    {
      breakpoint: 840,
      cols: 2,
      rows: 2,
      gap: 10,
      // loop: false,
      hideArrow: false,
    },
    {
      breakpoint: 460,
      cols: 2,
      rows: 2,
      gap: 10,
      // loop: false,
      hideArrow: false,
    },
    {
      breakpoint: 300,
      cols: 2,
      rows: 2,
      gap: 10,
      // loop: false,
      hideArrow: false,
    },
  ];

  return (
    <div
      className="mobile-carousel-products"
      style={{ marginTop: "5px", marginBottom: "5px" }}
    >
      {renderResponsiveCarousel(products)}
      {/* <Carousel
        mobileBreakpoint={767}
        //responsiveLayout={responsive}
        // marginWidth={40}
        responsiveLayout={[
          {
            breakpoint: 50,
            rows: 1,
            cols: 2,
            gap: 15,
            loop: true,
            autoplay: 1000,
          },
          {
            breakpoint: 100,
            rows: 1,
            cols: 2,
            gap: 15,
            loop: true,
            autoplay: 1000,
          },
        ]}
        dotColorActive="#231F20"
        rows={row}
        cols={6}
        autoPlay={1000}
      >
        {loadingProducts || propsLoading
          ? Array(6)
              .fill(null)
              .map((_, ind) => (
                <Carousel.Item key={ind}>
                  <div className="container">
                    <ProductSkeleton />
                  </div>
                </Carousel.Item>
              ))
          : products?.map((product, i) => (
              <Carousel.Item id={i} key={i}>
                <ProductCard
                  product={product}
                  favoritList={favoritList}
                  handleFavoret={handleFavoret}
                  handleNavigate={handleNavigate}
                  itemId={itemId}
                  loading={loading}
                  loadingFavorite={loadingFavorite}
                  userDetails={userDetails}
                  // style={{ width: "200px" }}
                />
              </Carousel.Item>
            ))}
      </Carousel> */}
    </div>
  );
}

export default CarouselProductsMobile;
