import React, { useContext, useEffect, useState } from "react";
import NavBar from "../../components/navBar/navBar";
import { Footer } from "../../components/Footer/Footer";
import BreadcrumbComp from "../../components/Breadcrumb/Breadcrumb";
import { Content } from "antd/es/layout/layout";
import { appContext } from "../../Context/appContext";
import { Divider } from "antd";
import { useLocation, useParams } from "react-router-dom";
import CarouselCategoriesHome from "../../components/Carousel/carouselCategoriesHome";
import { getCategoryByCatName, getPath } from "../../lib/lib";

function HomeCategories() {
  const {
    categoryProducts,
    getHomeCategoryProducts,
    setCategoryId,
    AllCategories,
  } = useContext(appContext);
  const [width, setWidth] = useState(window.innerWidth);
  const { categoryName } = useParams();
  const pathCategoryName = getPath(categoryName);
  const location = useLocation();
  const data = location?.state?.route;
  console.log("data ", data);
  let catId = getCategoryByCatName(categoryName, AllCategories)?._id;
  // console.log("catId", catId);
  //#region useEffect
  useEffect(() => {
    if (data && catId) {
      // console.log(data);
      getHomeCategoryProducts(catId, data);
    } else {
      if (data) getHomeCategoryProducts("", data);
    }
  }, [data]);
  useEffect(() => {
    // console.log(catId);
    if (catId) {
      setCategoryId(catId);
    } else {
      setCategoryId("");
    }
  }, [catId]);
  useEffect(() => {
    return () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };
  }, [categoryName]);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  //#endregion useEffect
  return (
    <>
      <NavBar />

      <Content
        style={{
          width: width > 768 ? "80%" : "100%",
          margin: "auto",
          marginBottom: 100,
        }}
      >
        <div className="homeCategory">
          {/* {width > 768 && <BreadcrumbComp />} */}
          <Content
            style={{
              padding: width > 550 && "0 10px",
              margin: "30px 0",
            }}
          >
            <div style={{ margin: width > 768 ? "40px 0" : "60px 12px 30px" }}>
              {width > 768 && <BreadcrumbComp />}
              <Divider
                type="horizontal"
                orientation="left"
                orientationMargin={width > 768 ? 0 : 0}
                style={{ borderColor: "#EC2025" }}
              >
                {pathCategoryName?.trim()?.replaceAll("-", " ")}
              </Divider>
            </div>

            <CarouselCategoriesHome products={categoryProducts} row={2} />
          </Content>
        </div>
      </Content>
      <Footer />
    </>
  );
}

export default HomeCategories;
