import React, { useContext, useEffect } from "react";
import "./carouselProducts.css";
import "react-multi-carousel/lib/styles.css";
import { appContext } from "../../Context/appContext";

import CarouselProductsMobile from "./carouselProductsMobile";
import CarouselProductsDesktop from "./CarouselProductsDesktop";
function CarouselProducts({ products, loading, cols }) {
  const { setFavoritList } = useContext(appContext);

  useEffect(() => {
    products?.forEach((product) => {
      if (product.favorite) {
        setFavoritList((prev) => [...prev, product._id]);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  return (
    <div>
      <span className="hidden md:block">
        <CarouselProductsDesktop
          products={products}
          loading={loading}
          cols={cols}
        />
      </span>
      <span className="block md:hidden">
        <CarouselProductsMobile
          products={products}
          loading={loading}
          cols={cols}
        />
      </span>
    </div>
  );
}

export default CarouselProducts;
