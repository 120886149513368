import React from "react";
import { Col, Modal, Row, Spin } from "antd";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import ButtonsFooter from "../ButtonsFooter";

function Confirm({
  open,
  title,
  message,
  loading,
  confirmLabel = "Submit",
  confirmFunc,
  cancelFunc,
  hideMessage = false,
  children = <></>,
}) {
  const antIcon = (
    <Loading3QuartersOutlined
      style={{
        fontSize: 15,
        color: "#fff",
      }}
      spin
    />
  );
  return (
    <>
      <Modal
        title=""
        open={open}
        onOk={confirmFunc}
        onCancel={cancelFunc}
        closable={false}
        footer={null}
        maskClosable={true}
        okText={confirmLabel}
        cancelText={"Cancel"}
        className="add-address-modal"
        bodyStyle={{
          padding: 0,
          border: "none",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            padding: "1em",
            backgroundColor: "rgba(236, 32, 37, 1)",
            borderRadius: "8px",
            color: "white",
            fontWeight: "bold",
            width: "100%",
            boxSizing: "border-box",
            fontSize: "18px",
          }}
        >
          <p>{title}</p>
        </div>
        {!hideMessage && (
          <div className="modalContentImei">
            <p style={{ padding: "3em", fontWeight: "700" }}>{message}</p>
          </div>
        )}
        {children}
        <Row
          style={{
            padding: "1em",
            width: "100%",
          }}
        >
          <Col xs={24} md={24} lg={24}>
            {ButtonsFooter({
              submitLabel: loading ? (
                <Spin indicator={antIcon} />
              ) : (
                ` ${confirmLabel}`
              ),
              onCancel: cancelFunc,
              onSubmit: confirmFunc,
            })}
          </Col>
        </Row>
      </Modal>
    </>
  );
}

export default Confirm;
