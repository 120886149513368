/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState } from "react";
import "./product.css";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import "pure-react-carousel/dist/react-carousel.es.css";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import {
  Button,
  Col,
  Divider,
  Drawer,
  Input,
  Modal,
  Row,
  Skeleton,
  message,
  Image,
} from "antd";
import NavBar from "../../components/navBar/navBar";
import { Content } from "antd/es/layout/layout";
import BreadcrumbComp from "../../components/Breadcrumb/Breadcrumb";
import { Footer } from "../../components/Footer/Footer";
import { AiOutlineClose } from "react-icons/ai";
import CarouselProducts from "../../components/Carousel/carouselProducts";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import PhoneInput, { isValidPhoneNumber } from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { appContext } from "../../Context/appContext";
import { useLocation, useNavigate } from "react-router-dom";
import { Url, requestQuantityApi, getVariantsByCategoryApi } from "../../Apis";
import StarRating from "../../components/StarRateComp/StarRateComp";
import { BsHeart, BsHeartFill } from "react-icons/bs";
import {
  getPath,
  //isCategory,
  isMatchedDetails,
  getArrayOfValues,
  getMaxQuantity,
  addCommas,
  getImgVidUrl,
} from "../../lib/lib";
import Variant from "./Variant";
import _ from "lodash";
import moment from "moment";
import ButtonsFooter from "../../components/ButtonsFooter";
import { FaVideo } from "react-icons/fa";
//import { PlusSquareFilled } from "@ant-design/icons";
import Gallery from "../../components/Carousel/gallery";

function Product() {
  const [openModal, setOpenModal] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [imageList, setImageList] = useState([]);
  const [openCarousel, setOpenCarousel] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [SelectVideo, setSelectVideo] = useState("");
  const [requestQuantityLoading, setRequestQuantityLoading] = useState(false);
  const [loadingImage, setloadingImage] = useState(true);
  const [productInfo, setProductInfo] = useState("Overview");
  const [open, setOpen] = useState(false);
  const [CategoryVariants, setCategoryVariants] = useState([]);
  const [selectedVarientsVals, setSelectedVarientsVals] = useState({});
  const [detailsValues, setdetailsValues] = useState([]);
  const [SelectedDetailsPrice, setSelectedDetailsPrice] = useState({});
  const navigate = useNavigate();
  const {
    topRatedProducts,
    oneProduct,
    setOneProduct,
    oneProductLoading,
    //getProductByName,
    getProductByUrl,
    setOneProductLoading,
    cartList,
    priceDetails,
    addToCart,
    loading,
    itemId,
    token,
    favoritProductList,
    getTopRatedProducts,
    loadingTopRatedProducts,
    setProductColor,
    setProductSize,
    shippingPrice,
  } = useContext(appContext);
  const location = useLocation();
  const [isExpire, setIsExpire] = useState(true);
  const [ProductDetails, setProductDetails] = useState([]);
  // let isExpire = moment(Date.now()).isAfter(
  //   oneProduct?.discountEndDate || "1970-01-01"
  // );

  //#region useEffect
  useEffect(() => {
    // getProductByName(location.pathname.split("/").at(-1).split("-").join(""));
    getProductByUrl(location.pathname.split("/").at(-1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
  useEffect(() => {
    // delete empty values from details
    let productDetails = [];
    oneProduct?.details?.forEach((d) => {
      let obj = {};
      for (const key in d) {
        if (d[key] !== null && d[key] !== undefined && d[key] !== "") {
          obj = { ...obj, [key]: d[key] };
        }
      }
      productDetails = [...productDetails, obj];
    });
    setProductDetails(productDetails);
  }, [oneProduct, CategoryVariants]);
  useEffect(() => {
    let detailsValues = [];
    let keys = CategoryVariants?.map((ele) => ele?.key);
    ProductDetails?.forEach((d) => {
      detailsValues.push(getArrayOfValues(d, keys));
    });
    setdetailsValues(detailsValues);
  }, [ProductDetails, CategoryVariants]);
  useEffect(() => {
    getSelectedDetails();
  }, [selectedVarientsVals, oneProduct]);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  useEffect(() => {
    checkProductInCart();
    // console.log(firstSize);
    setloadingImage(true);
    let imagesList = [];
    if (oneProduct) {
      setSelectVideo("");
      setIsExpire(
        moment(Date.now()).isAfter(oneProduct?.discountEndDate || "1970-01-01")
      );
      getVariantsByCategory(oneProduct?.category?._id);
      getTopRatedProducts(oneProduct?.category?._id);
      imagesList = [
        oneProduct?.featuredImage,
        ...(oneProduct?.productImage || [])?.filter(
          (url) => url !== oneProduct?.featuredImage
        ),
      ];
      setImageList(imagesList);
      setSelectedImage(oneProduct?.featuredImage);
      setloadingImage(false);
    }
    return () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oneProduct]);

  const Billing = yup.object().shape({
    fullName: yup
      .string("Invalid Full Name!")
      .required("Full Name is required!"),
    email: yup
      .string("Invalid Email!")
      .email("Invalid Email!")
      .required("Email is required!"),
    phoneNumber: yup
      .string()
      .required("Phone number is required")
      .min(10, "phone number  should be at least 10 numbers!")
      .max(16, "phone number  should be at most 16 numbers!"),
    // address: yup.string("Invalid Address!").required("Address is required!"),
    message: yup.string("Invalid message!").required("message is required!"),
    quantity: yup.number("Invalid quantity!").required("quantity is required!"),
  });
  //reset sizesArr when navigate to other products
  //#endregion useEffect
  //#region functions
  const showDrawer = () => {
    if (!token) {
      message.warning("please login first or create new account");
      navigate("/login");
      return;
    }
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
    resetData();
  };
  const checkProductInCart = () => {
    const productInCart = cartList?.filter(
      (data) => data?.product?._id === oneProduct?._id
    )[0];
    if (productInCart) {
      setProductColor(productInCart?.color);
      setProductSize(productInCart?.size);
    } else {
      setProductColor("");
      setProductSize("");
    }
  };
  const getVariantsByCategory = async (id) => {
    try {
      const response = await getVariantsByCategoryApi(id);
      if (response.data) {
        // console.log(response.data?.data);
        setCategoryVariants(response.data?.data?.variants || []);
      }
    } catch (error) {}
  };
  const {
    // register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm({
    resolver: yupResolver(Billing),
  });
  const onsubmit = async (data) => {
    const newData = {
      ...(data ? data : {}),
      product: oneProduct?._id,
      productName: oneProduct?.englishName,
      productUrl: window.location.pathname,
    };
    try {
      setRequestQuantityLoading(true);
      const response = await requestQuantityApi(newData);
      if (response.data) {
        message.success(response.data.messageEn);
        setRequestQuantityLoading(false);
        resetData();
      }
    } catch (error) {
      message.error(error.response.data.messageEn);
      setRequestQuantityLoading(false);
    }
  };
  const handleOpen = () => {
    if (!token) {
      message.warning("please login first or create new account");
      navigate("/login");
      return;
    }
    setOpenModal(true);
  };
  const resetData = () => {
    setValue("phoneNumber", "");
    setValue("email", "");
    setValue("fullName", "");
    setValue("message", "");
    setValue("quantity", "");
    setOpenModal(false);
  };
  const handleClose = () => {
    resetData();
  };
  const handleCloseCarousel = () => {
    setOpenCarousel(false);
  };
  // let spesfications = [
  //   `Size: ${firstSize}`,
  //   "QTY Applied",
  //   `Color: ${oneProduct?.color}`,
  //   "Same Day Delivery",
  // ];
  const checkAreAllDetailsFilled = () => {
    let values = {};
    Object.keys(selectedVarientsVals).forEach((key) => {
      if (selectedVarientsVals[key])
        values = { ...values, [key]: selectedVarientsVals[key] };
    });
    let details = [...(ProductDetails || [])]?.filter((ele) =>
      isMatchedDetails(values, ele)
    );
    let isAllDetailsFilled = [];
    let emptyDetails = [];
    [selectedVarientsVals]?.forEach((detail) => {
      CategoryVariants?.forEach((ele) => {
        if (!detail[ele?.key]) {
          emptyDetails = [...emptyDetails, ele?.key];
          isAllDetailsFilled = [...isAllDetailsFilled, false];
          //message.warning(`please choose ${ele?.key}`);
        } else {
          isAllDetailsFilled = [...isAllDetailsFilled, true];
        }
      });
    });
    return {
      isAllDetailsFilled: !isAllDetailsFilled.includes(false),
      details: details,
      emptyDetails: emptyDetails,
    };
  };
  const getSelectedDetails = () => {
    let keys = CategoryVariants?.map((ele) => ele?.key);
    let _v = getArrayOfValues(selectedVarientsVals, keys);
    const data = checkAreAllDetailsFilled();
    let isEqualToValues = [];
    detailsValues?.forEach((ele) => {
      isEqualToValues = [...isEqualToValues, _.isEqual(ele, _v)];
    });
    console.log("data ", data);
    if (detailsValues.length === 0) {
      setSelectedDetailsPrice({
        price: isExpire ? oneProduct?.price : oneProduct?.afterDiscountPrice,
      });
    } else if (isEqualToValues.includes(true) || data?.isAllDetailsFilled) {
      setSelectedDetailsPrice({
        price: isExpire
          ? data?.details[0]?.price
          : oneProduct?.afterDiscountPrice,
      });
    } else {
      setSelectedDetailsPrice({
        price: isExpire ? oneProduct?.price : oneProduct?.afterDiscountPrice,
      });
    }

    return {
      _isEqualToValues: isEqualToValues.includes(true),
      data: data,
    };
  };
  const handelAddToCart = () => {
    const { _isEqualToValues, data } = getSelectedDetails();
    //console.log("data ", data);
    let _cartList = [...(cartList || [])];
    let Alldetails = ProductDetails || [];
    let orderDetails =
      _cartList?.find((order) => order?.product?._id === oneProduct?._id)
        ?.details || {};

    let keys = [...CategoryVariants?.map((ele) => ele?.key), "price"];
    let productCartList = _cartList?.filter(
      (ele) =>
        ele?.product?._id === oneProduct?._id &&
        _.isEqual(
          getArrayOfValues(ele?.details, keys),
          getArrayOfValues(
            data?.details?.length > 0 ? data?.details[0] : {},
            keys
          )
        )
    );
    let quantity = productCartList[0]?.quantity || 0;

    let isAllAdded = true;
    if (Alldetails?.length === 0) {
      if (quantity === oneProduct?.quantity) {
        isAllAdded = true;
        message.warning(`Item out of stock`);
      } else {
        isAllAdded = false;
      }
    }
    if (detailsValues.length === 0) {
      if (ProductDetails?.length === 0) {
        if (isAllAdded) {
          message.warning(`Item out of stock`);
        } else {
          addToCart(
            {
              Price: isExpire
                ? oneProduct?.price
                : oneProduct?.afterDiscountPrice,
              plusQuantity: quantity,
            },
            oneProduct?._id,
            shippingPrice
          );
        }
      } else {
        if (getMaxQuantity(Alldetails, orderDetails) === quantity) {
          message.warning(`Item out of stock`);
        } else {
          addToCart(
            {
              Price: isExpire
                ? oneProduct?.price
                : oneProduct?.afterDiscountPrice,
              plusQuantity: quantity,
            },
            oneProduct?._id,
            shippingPrice
          );
        }
      }
    } else if (_isEqualToValues) {
      if (ProductDetails?.length === 0) {
        if (isAllAdded) {
          message.warning(`Item out of stock`);
        } else {
          addToCart(
            {
              Price: isExpire
                ? oneProduct?.price
                : oneProduct?.afterDiscountPrice,
              plusQuantity: quantity,
            },
            oneProduct?._id,
            shippingPrice
          );
        }
      } else {
        if (getMaxQuantity(Alldetails, orderDetails) === quantity) {
          message.warning(`Item out of stock`);
        } else {
          addToCart(
            {
              ...(data?.details?.length > 0 ? data?.details[0] : {}),
              plusQuantity: quantity,
            },
            oneProduct?._id,
            shippingPrice
          );
        }
      }
    } else if (data?.isAllDetailsFilled) {
      if (ProductDetails?.length === 0) {
        if (isAllAdded) {
          message.warning(`Item out of stock`);
        } else {
          addToCart(
            {
              Price: isExpire
                ? oneProduct?.price
                : oneProduct?.afterDiscountPrice,
              plusQuantity: quantity,
            },
            oneProduct?._id,
            shippingPrice
          );
        }
      } else {
        if (getMaxQuantity(Alldetails, orderDetails) === quantity) {
          message.warning(`Item out of stock`);
        } else {
          addToCart(
            {
              ...(data?.details?.length > 0 ? data?.details[0] : {}),
              plusQuantity: quantity,
            },
            oneProduct?._id,
            shippingPrice
          );
        }
      }
    } else {
      message.warning(`please choose ${data?.emptyDetails?.join(" ,")}`);
    }
  };
  //#endregion functions
  console.log("oneProduct ", oneProduct);
  console.log("SelectedDetailsPrice ", SelectedDetailsPrice);
  return (
    <>
      <NavBar />
      <Content>
        {width > 767 ? (
          <Content
            className="capitalize-text"
            style={{
              // width: width > 767 ? "71%" : "85%",
              // width: `calc(${width}px - 15%)`,
              width: `calc(${width}px - ${width < 1200 ? 20 : 30}%)`,
              margin: "auto",
            }}
          >
            <div className="product">
              <div className="productGalary">
                <Row gutter={[24, 24]} justify={"center"}>
                  <Col xs={24} sm={24} md={24} lg={13}>
                    <div className="productCarousel">
                      {oneProductLoading || loadingImage ? (
                        <>
                          <div style={{ width: "65%" }} className="imgSkelton">
                            <Skeleton.Image
                              active
                              style={{
                                width: "100%",
                                height: "100%",
                                borderRadius: 15,
                              }}
                            />
                          </div>
                          <div className="imageSelected">
                            <div className="oneImage">
                              <Skeleton.Image active />
                            </div>
                            <div className="oneImage">
                              <Skeleton.Image active />
                            </div>
                            <div
                              className="oneImage"
                              style={{ position: "relative" }}
                            >
                              <Skeleton.Image active />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="imageCard" style={{ flex: "1" }}>
                            {SelectVideo ? (
                              <video
                                height={"100%"}
                                width={"100%"}
                                src={getImgVidUrl(oneProduct?.video)}
                                controls
                                autoPlay
                              />
                            ) : (
                              <>
                                <Gallery
                                  images={imageList}
                                  selectedImage={selectedImage}
                                  setSelectedImage={setSelectedImage}
                                />
                              </>
                            )}
                          </div>
                          <div className="imageSelected">
                            {oneProduct?.video && (
                              <div
                                className="oneImage"
                                style={{ position: "relative" }}
                              >
                                <video
                                  pause
                                  src={getImgVidUrl(oneProduct?.video)}
                                  onClick={() => {
                                    setSelectedImage("");
                                    setSelectVideo(oneProduct?.video);
                                  }}
                                />
                                <FaVideo
                                  size={25}
                                  style={{
                                    position: "absolute",
                                    bottom: 5,
                                    right: 10,
                                    color: "#ec2025",
                                  }}
                                />
                              </div>
                            )}
                            {imageList?.map((img, i) => (
                              <div
                                key={i}
                                className="oneImage"
                                style={{
                                  backgroundImage: `url(${getImgVidUrl(img)})`,
                                  opacity: selectedImage !== img && "0.5",
                                  position: "relative",
                                }}
                                onClick={() => {
                                  setSelectedImage(img);
                                  setSelectVideo("");
                                }}
                              >
                                {/* {imageList?.length > 3 && i === 2 && (
                                  <PlusSquareFilled
                                    onClick={() => setVisible(true)}
                                    style={{
                                      position: "absolute",
                                      bottom: 5,
                                      right: 10,
                                      color: "#ec2025",
                                      fontSize: "24px",
                                      backgroundColor: "#fff",
                                    }}
                                  />
                                )} */}
                              </div>
                            ))}
                            {((imageList?.length === 2 && !oneProduct?.video) ||
                              (imageList?.length === 1 &&
                                oneProduct?.video)) && (
                              <div
                                className="oneImage"
                                style={{ visibility: "hidden" }}
                              />
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </Col>
                  <Col span={2} />
                  <Col xs={24} sm={24} md={24} lg={9}>
                    <BreadcrumbComp />
                    <div className="productData" style={{ width: "100%" }}>
                      <div className="productDataDetails">
                        <span className="productName">
                          {oneProductLoading ? (
                            <Skeleton.Button size={20} block active />
                          ) : (
                            oneProduct?.englishName
                          )}
                        </span>

                        <span className="productrate">
                          {oneProductLoading ? (
                            <Skeleton.Button size={20} block active />
                          ) : (
                            <>
                              <div className="star">
                                <StarRating
                                  totalStars={5}
                                  initialRating={oneProduct?.avgRate}
                                  productId={oneProduct?._id}
                                />
                              </div>
                              <span className="rate">
                                {oneProduct?.ratesCount} Rate
                              </span>
                            </>
                          )}
                        </span>
                      </div>
                      <div className="productPrice">
                        <span className="title1">
                          {oneProductLoading ? (
                            <Skeleton.Button size={20} block active />
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "baseline",
                              }}
                            >
                              <p style={{ marginRight: "5px" }}>
                                ${addCommas(SelectedDetailsPrice?.price)}
                              </p>
                              {!isExpire && (
                                <small className="price_2">
                                  ${addCommas(oneProduct?.price)}
                                </small>
                              )}
                            </div>
                          )}
                        </span>
                      </div>
                      {/** variants */}
                      <Variant
                        CategoryVariants={CategoryVariants}
                        product={oneProduct}
                        setSelectedVarientsVals={setSelectedVarientsVals}
                        width={width}
                        dropdownWidth={"380px"}
                      />
                      <div className="productDelivery">
                        <span
                          style={{
                            display: "flex",
                            gap: 5,
                            alignItems: "center",
                          }}
                        >
                          <span className="title1">Delivery Fees:</span>
                          <span className="title2">
                            ${addCommas(priceDetails?.delivery)}
                          </span>
                        </span>
                      </div>
                      <div className="productRequest">
                        <span className="title1">Request Bulk Quantities:</span>
                        <div
                          className="shoppingBtn"
                          style={{
                            marginTop: "1em",
                            width: "100%",
                            display: "flex",
                            //justifyContent: "flex-end",
                          }}
                        >
                          <Button
                            style={{
                              padding: "20px auto",
                              height: "50px",
                              // width: "380px",
                              width: "100%",
                            }}
                            // className="vendorBtn"
                            className="white-btn hover-bg-red"
                            onClick={() => handleOpen()}
                          >
                            Request Quantities
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>

              <Divider className="categoriesDivider" />

              <div className="totalPriceSection">
                <Row gutter={[24, 24]} align={"top"} justify={"center"}>
                  <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                    <div className="totalPrice">
                      <span style={{ fontSize: "16px" }}>Total Price:</span>
                      <span>
                        $
                        {oneProductLoading ? (
                          <Skeleton.Button size={20} block active />
                        ) : (
                          addCommas(SelectedDetailsPrice?.price)
                        )}
                      </span>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={11}>
                    <Row>
                      <Col xs={24} md={24} lg={7}>
                        <div className="specificationDiv">
                          <span
                            style={{ fontSize: "16px", whiteSpace: "nowrap" }}
                          >
                            Your Specifications:
                          </span>
                          <span>
                            {oneProductLoading ? (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 10,
                                  flexWrap: "wrap",
                                }}
                              >
                                {[1, 2, 3].map((_) => (
                                  <Skeleton.Button
                                    shape={"round"}
                                    size={25}
                                    style={{ width: 120 }}
                                    block
                                    active
                                  />
                                ))}
                              </div>
                            ) : (
                              <>
                                {Object.keys(selectedVarientsVals).map(
                                  (key) => {
                                    if (selectedVarientsVals[key])
                                      return (
                                        <span className="specifications">
                                          {key}: {selectedVarientsVals[key]}
                                        </span>
                                      );
                                  }
                                )}
                              </>
                            )}
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={9}>
                    <div className="addToCart product-add-to-cart">
                      <Button
                        loading={loading && itemId === oneProduct?._id}
                        style={{
                          padding: "20px auto",
                          height: "50px",
                          // width: "380px",
                          width: "100%",
                        }}
                        className="vendorBtn red-btn hover-bg-w"
                        onClick={handelAddToCart}
                      >
                        Add to Cart
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
              <Divider className="categoriesDivider" />
              <div className="productDescripe">
                <Row gutter={[24, 24]} justify={"center"}>
                  <Col xs={24} sm={24} md={24} lg={17} xl={17}>
                    <Row gutter={[24, 24]}>
                      <Col xs={24} sm={24} md={24}>
                        <span>Product Details:</span>
                        <Row gutter={[24, 24]} style={{ marginTop: 20 }}>
                          {/* {[
                          { label: "Status:", value: oneProduct?.status },
                          {
                            label: "Brand:",
                            value: oneProduct?.brand?.englishName,
                          },
                          { label: "", value: "" },
                          { label: "", value: "" },
                        ]} */}
                          <Col xs={24} sm={24} md={12}>
                            <div className="details">
                              <span>
                                <div className="genderDetails">
                                  <span className="title">
                                    Status:
                                    <span className="supTitle">
                                      {oneProductLoading ? (
                                        <Skeleton.Button
                                          size={20}
                                          block
                                          active
                                        />
                                      ) : (
                                        oneProduct?.status
                                      )}
                                    </span>
                                  </span>
                                  <span className="title">
                                    Date:
                                    <span className="supTitle">
                                      {oneProductLoading ? (
                                        <Skeleton.Button
                                          size={20}
                                          block
                                          active
                                        />
                                      ) : (
                                        oneProduct?.updatedAt?.split("T")[0]
                                      )}
                                    </span>
                                  </span>
                                </div>
                              </span>
                            </div>
                          </Col>
                          <Col xs={24} sm={24} md={12}>
                            <div className="details">
                              <div className="genderDetails">
                                <span className="title">
                                  Brand:
                                  <span className="supTitle">
                                    {oneProduct?.brand?.englishName}
                                  </span>
                                </span>
                                <span className="title">
                                  Tag :
                                  <span className="supTitle">
                                    {" "}
                                    <span className="supTitle">
                                      {oneProduct?.tag?.englishName}
                                    </span>
                                  </span>
                                </span>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Col>

                      <Col xs={24} sm={24} md={24}>
                        <Row gutter={[24, 24]}>
                          <Col xs={24} sm={24} md={24}>
                            <div className="descriptionText">
                              <span>Description:</span>
                              <span style={{ whiteSpace: "pre-line" }}>
                                {oneProductLoading ? (
                                  <Skeleton.Button size={20} block active />
                                ) : (
                                  oneProduct?.englishDescription
                                )}
                              </span>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={7} xl={7}></Col>
                </Row>
              </div>
              <div className="divider" style={{ padding: 0 }}>
                <p className="left">Top Rated Products</p>
                <div className="line"></div>
                <p
                  className="right"
                  onClick={() =>
                    navigate(`/${getPath("Top Rated Products")}`, {
                      state: {
                        route: "Top Rated Products",
                        catHome: true,
                      },
                    })
                  }
                >
                  {" "}
                  View All
                </p>
              </div>

              <CarouselProducts
                row={1}
                cols={6}
                products={topRatedProducts}
                loading={loadingTopRatedProducts}
              />
              <div></div>
            </div>
          </Content>
        ) : (
          <Content
            className="capitalize-text"
            style={{
              // width: width > 767 ? "71%" : "85%",
              width: `calc(${width}px - 15%)`,
              margin: "80px auto",
            }}
          >
            <div className="productMobile" style={{ marginBottom: 110 }}>
              <div
                className="productMpbileData"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "5px",
                }}
              >
                <h3 style={{ color: "#ec2025" }}>
                  {oneProductLoading ? (
                    <Skeleton.Button size={20} block active />
                  ) : (
                    oneProduct?.englishName
                  )}
                </h3>
                <div className="pricetitle">
                  {oneProductLoading ? (
                    <Skeleton.Button size={20} block active />
                  ) : (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {favoritProductList?.includes(oneProduct?._id) ? (
                        <BsHeartFill size={16} color="#ec2025" />
                      ) : (
                        <BsHeart size={16} />
                      )}{" "}
                      &nbsp;&nbsp;
                      <div
                        style={{
                          display: "flex",
                          alignItems: "baseline",
                        }}
                      >
                        <p
                          style={{
                            marginRight: "5px",
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}
                        >
                          ${SelectedDetailsPrice?.price}
                        </p>
                        {!isExpire && (
                          <small className="price_2">
                            ${addCommas(oneProduct?.price)}
                          </small>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <span className="productrate">
                {oneProductLoading ? (
                  <Skeleton.Button size={20} block active />
                ) : (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div className="star">
                      <StarRating
                        totalStars={5}
                        initialRating={oneProduct?.avgRate}
                        productId={oneProduct?._id}
                      />
                    </div>
                    <span className="rate">{oneProduct?.ratesCount} Rate</span>
                  </div>
                )}
              </span>
              <div
                style={{ display: "flex", marginTop: "5px" }}
                className="my-profile-btns-mobile"
              >
                <Button
                  className="infoBtn"
                  style={{
                    fontSize: "13px",
                    marginRight: "10px",
                    backgroundColor:
                      productInfo === "Overview"
                        ? "rgba(236, 32, 37, 1)"
                        : "transparent",
                    color: productInfo === "Overview" ? "white" : "black",
                  }}
                  onClick={() => setProductInfo("Overview")}
                >
                  Overview
                </Button>
                <Button
                  className="infoBtn"
                  style={{
                    fontSize: "13px",
                    marginLeft: "10px",
                    backgroundColor:
                      productInfo === "Details"
                        ? "rgba(236, 32, 37, 1)"
                        : "transparent",
                    color: productInfo === "Details" ? "white" : "black",
                  }}
                  onClick={() => setProductInfo("Details")}
                >
                  Details
                </Button>
              </div>
              {productInfo === "Overview" ? (
                <div className="productOverView">
                  <div className="productMobileCarousel">
                    {oneProductLoading ? (
                      [1, 2, 3].map((_) => (
                        <>
                          <Skeleton.Image
                            active
                            style={{
                              width: "220px",
                              height: "220px",
                              borderRadius: 15,
                            }}
                          />
                        </>
                      ))
                    ) : (
                      <>
                        {oneProduct?.video && (
                          <div className="prodImg">
                            <video
                              controls
                              src={getImgVidUrl(oneProduct?.video)}
                              width={"100%"}
                              height={"100%"}
                              style={{
                                border: "1px solid #fff",
                                borderRadius: "7px",
                              }}
                            />
                          </div>
                        )}
                        <Image.PreviewGroup
                          preview={{ onChange: (current, prev) => "" }}
                        >
                          {imageList?.map((img, i) => (
                            <div key={i} className="prodImg">
                              <Image
                                loading="lazy"
                                width={"100%"}
                                className="img"
                                src={getImgVidUrl(img)}
                              />
                            </div>
                          ))}
                        </Image.PreviewGroup>
                      </>
                    )}
                  </div>
                  {/** xxxxxxxxxxxxx */}
                  {/** variant */}
                  <Variant
                    CategoryVariants={CategoryVariants}
                    product={oneProduct}
                    setSelectedVarientsVals={setSelectedVarientsVals}
                    width={width}
                  />
                </div>
              ) : null}
              {productInfo === "Details" && (
                <Row
                  cols={24}
                  gutter={10}
                  style={{
                    marginBottom: "10px",
                    marginTop: "10px",
                    whiteSpace: "pre-line",
                  }}
                >
                  {[
                    {
                      label: "Description:",
                      value: oneProduct?.englishDescription,
                    },
                    { label: "Brand:", value: oneProduct?.brand?.englishName },
                    { label: "Tag:", value: oneProduct?.tag?.englishName },
                    { label: "Status:", value: oneProduct?.status },
                    {
                      label: "Date:",
                      value: oneProduct?.updatedAt?.split("T")[0],
                    },
                    {
                      label: "Delivery:",
                      value: addCommas(priceDetails?.delivery) + " $",
                    },
                  ].map((ele, i) => (
                    <Col key={i} span={24} style={{ marginBottom: "10px" }}>
                      <Row cols={24} gutter={10}>
                        <Col span={8}>
                          <h4>{ele?.label}</h4>
                        </Col>
                        <Col span={16}>
                          {oneProductLoading ? (
                            <Skeleton.Button size={20} block active />
                          ) : (
                            ele?.value
                          )}
                        </Col>
                      </Row>
                    </Col>
                  ))}
                </Row>
              )}
              <div
                className="addToCart product-add-to-cart"
                style={{ marginBottom: "10px", marginTop: "10px" }}
              >
                <Button
                  loading={loading && itemId === oneProduct?._id}
                  style={{
                    padding: "20px auto",
                    height: "40px",
                    width: "100%",
                    fontSize: "13px",
                  }}
                  className="vendorBtn red-btn hover-bg-w"
                  onClick={handelAddToCart}
                >
                  Add to Cart
                </Button>
              </div>
              {productInfo === "Overview" ? (
                <div
                  className="shoppingBtn"
                  style={{ width: "100%", marginBottom: 20 }}
                >
                  <Button
                    style={{
                      padding: "20px auto",
                      height: "40px",
                      width: "100%",
                      fontWeight: 700,
                      fontSize: "13px",
                    }}
                    // className="vendorBtn hover-bg-w"
                    className="white-btn hover-bg-red"
                    onClick={() => showDrawer()}
                  >
                    Request QTY
                  </Button>
                </div>
              ) : null}
              <div className="divider" style={{ padding: 0 }}>
                <p className="left">Top Rated Products</p>
                <div className="line"></div>
                <p
                  className="right"
                  onClick={() =>
                    navigate(`/${getPath("Top Rated Products")}`, {
                      state: {
                        route: "Top Rated Products",
                        catHome: true,
                      },
                    })
                  }
                >
                  {" "}
                  View All
                </p>
              </div>
              <CarouselProducts
                row={1}
                products={topRatedProducts}
                loading={loadingTopRatedProducts}
              />
            </div>
          </Content>
        )}
      </Content>
      <Footer />
      {/* <Image.PreviewGroup
        items={imageList?.map((imgURL) => Url + imgURL)}
        preview={{
          visible,
          onVisibleChange: (value) => {
            setVisible(value);
          },
        }}
      ></Image.PreviewGroup> */}
      <Drawer
        title={
          <span style={{ fontSize: "24px", fontWeight: "bold" }}>
            Billing Details:{" "}
          </span>
        }
        placement="bottom"
        closable={false}
        height={"95%"}
        style={{
          padding: "0",
          borderTopLeftRadius: 15,
          borderTopRightRadius: 15,
          boxSizing: "border-box",
        }}
        extra={
          <AiOutlineClose
            style={{ fontSize: 25, cursor: "pointer" }}
            onClick={onClose}
          />
        }
        onClose={onClose}
        open={open}
      >
        <div className="paymentForm" style={{ width: "100%" }}>
          <form
            onSubmit={handleSubmit(onsubmit)}
            style={{ marginTop: "2em", padding: "0 10px 20px" }}
          >
            <Row
              style={{ display: "flex", justifyContent: "space-between" }}
              gutter={[10, 10]}
            >
              <Col xs={24} md={12} lg={12}>
                <p className="becomeVendorLable">Full Name*</p>
                <Controller
                  name="fullName"
                  control={control}
                  render={({ field }) => (
                    <Input
                      type="text"
                      {...field}
                      className="becomeVendorInput"
                      placeholder=" full Name"
                    />
                  )}
                />
                <p style={{ color: "red" }}>{errors.fullName?.message}</p>
              </Col>
              <Col xs={24} md={12} lg={12}>
                <p className="becomeVendorLable">Email*</p>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <Input
                      type="email"
                      {...field}
                      className="becomeVendorInput"
                      placeholder=" Email"
                    />
                  )}
                />
                <p style={{ color: "red" }}>{errors.email?.message}</p>
              </Col>
              <Col xs={24} md={12} lg={12}>
                <p className="becomeVendorLable">Phone Number*</p>
                <Controller
                  name="phoneNumber"
                  control={control}
                  rules={{
                    validate: (value) => isValidPhoneNumber(value),
                  }}
                  render={({ field: { onChange, value } }) => (
                    <PhoneInput
                      onChange={onChange}
                      value={value}
                      id="phoneNumber"
                      containerStyle={{
                        margin: "10px auto",
                      }}
                      enableSearch
                      enableClickOutside
                      enableTerritories
                      country={"lb"}
                      inputClass="profileInput"
                      inputStyle={{
                        width: "100%",
                        height: "40px",
                        position: "relative",
                        margin: "0px",
                        boxSizing: "border-box",
                        border: "1px solid #ccc",
                        outline: "none",
                        borderRadius: "7px",
                        backgroundColor: "#EBEBEB",
                        boxShadow: "0 0 4px lightgray",
                      }}
                    />
                  )}
                />
                <p style={{ color: "red" }}>{errors.phoneNumber?.message}</p>
              </Col>
              <Col xs={24} md={12} lg={12}>
                <p className="becomeVendorLable">Product Quantity*</p>
                <Controller
                  name="quantity"
                  control={control}
                  render={({ field }) => (
                    <Input
                      type="number"
                      {...field}
                      className="becomeVendorInput"
                      placeholder="quantity"
                    />
                  )}
                />
                <p style={{ color: "red" }}>{errors.quantity?.message}</p>
              </Col>
              <Col xs={24} md={24} lg={24}>
                <p className="becomeVendorLable">Your Message*</p>
                <Controller
                  name="message"
                  control={control}
                  render={({ field }) => (
                    <Input.TextArea
                      rows={3}
                      style={{ height: "112px" }}
                      type="text"
                      {...field}
                      className="becomeVendorInput"
                      placeholder="Enter your Message Here"
                    />
                  )}
                />
                <p style={{ color: "red" }}>{errors.message?.message}</p>
              </Col>
              <Col xs={24} md={24} lg={24}>
                {ButtonsFooter({
                  submitLabel: "Request QTY",
                  onCancel: onClose,
                  onSubmit: () => {},
                })}
              </Col>
            </Row>
          </form>
        </div>
      </Drawer>
      <Modal
        closeIcon={null}
        footer={null}
        open={openModal}
        onCancel={handleClose}
        onOk={handleSubmit}
        bodyStyle={{ background: "#fff" }}
        centered
        width={width > 1000 ? 842 : "90%"}
        style={{ width: "100%" }}
      >
        <div className="paymentForm" style={{ width: "100%" }}>
          <span style={{ fontSize: "24px", fontWeight: "bold" }}>
            Billing Details:{" "}
          </span>
          <form onSubmit={handleSubmit(onsubmit)} style={{ marginTop: "2em" }}>
            <Row
              style={{ display: "flex", justifyContent: "space-between" }}
              gutter={[24, 24]}
            >
              <Col xs={24} md={12} lg={12}>
                <p className="becomeVendorLable">Full Name*</p>
                <Controller
                  name="fullName"
                  control={control}
                  render={({ field }) => (
                    <Input
                      type="text"
                      {...field}
                      className="becomeVendorInput"
                      placeholder=" full Name"
                    />
                  )}
                />
                <p style={{ color: "red" }}>{errors.fullName?.message}</p>
              </Col>
              <Col xs={24} md={12} lg={12}>
                <p className="becomeVendorLable">Email*</p>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <Input
                      type="email"
                      {...field}
                      className="becomeVendorInput"
                      placeholder=" Email"
                    />
                  )}
                />
                <p style={{ color: "red" }}>{errors.email?.message}</p>
              </Col>
              <Col xs={24} md={12} lg={12}>
                <p className="becomeVendorLable">Phone Number*</p>
                <Controller
                  name="phoneNumber"
                  control={control}
                  rules={{
                    validate: (value) => isValidPhoneNumber(value),
                  }}
                  render={({ field: { onChange, value } }) => (
                    <PhoneInput
                      onChange={onChange}
                      value={value}
                      id="phoneNumber"
                      containerStyle={{
                        margin: "10px auto",
                      }}
                      enableSearch
                      enableClickOutside
                      enableTerritories
                      country={"lb"}
                      inputClass="profileInput"
                      inputStyle={{
                        width: "100%",
                        height: "58px",
                        position: "relative",
                        boxSizing: "border-box",
                        border: "1px solid #ccc",
                        outline: "none",
                        borderRadius: "7px",
                        backgroundColor: "#EBEBEB",
                        boxShadow: "0 0 4px lightgray",
                      }}
                    />
                  )}
                />
                <p style={{ color: "red" }}>{errors.phoneNumber?.message}</p>
              </Col>
              <Col xs={24} md={12} lg={12}>
                <p className="becomeVendorLable">Product Quantity*</p>
                <Controller
                  name="quantity"
                  control={control}
                  render={({ field }) => (
                    <Input
                      type="number"
                      {...field}
                      className="becomeVendorInput"
                      placeholder="quantity"
                    />
                  )}
                />
                <p style={{ color: "red" }}>{errors.quantity?.message}</p>
              </Col>
              <Col xs={24} md={24} lg={24}>
                <p className="becomeVendorLable">Your Message*</p>
                <Controller
                  name="message"
                  control={control}
                  render={({ field }) => (
                    <Input.TextArea
                      rows={3}
                      style={{ height: "112px" }}
                      type="text"
                      {...field}
                      className="becomeVendorInput"
                      placeholder="Enter your Message Here"
                    />
                  )}
                />
                <p style={{ color: "red" }}>{errors.message?.message}</p>
              </Col>

              <Col xs={24} md={24} lg={24}>
                {ButtonsFooter({
                  submitLabel: "Request QTY",
                  onCancel: handleClose,
                  onSubmit: () => {},
                })}
              </Col>
            </Row>
          </form>
        </div>
      </Modal>
      <Modal
        width={"100%"}
        centered
        className="carouselModal"
        style={{ width: "100%", background: "transparent" }}
        onCancel={handleCloseCarousel}
        open={openCarousel}
        footer={null}
        closable
        maskStyle={{ backgroundColor: "#00000057" }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <AliceCarousel
            mouseTracking
            items={imageList?.map((img) => (
              <div
                style={{
                  width: "60vw",
                  height: "60vh",
                  backgroundImage: `url(${getImgVidUrl(img)})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "auto 100%",
                  backgroundPosition: "center",
                  margin: "auto",
                }}
              ></div>
            ))}
          />
        </div>
      </Modal>
    </>
  );
}

export default Product;
