import React from "react";
import "./Card.css";
import { Col, Row, Tag, Skeleton } from "antd";
import { MdSpeed } from "react-icons/md";
import { useNavigate } from "react-router-dom";
//import { Url } from "../../Apis";
import { addCommas, getImgVidUrl, getPath } from "../../lib/lib";

function Card({ data, LoadingAutomotive, style = {} }) {
  const navigate = useNavigate();
  // console.log("data ", data);
  const renderValue = (value) => {
    return (
      <>
        {LoadingAutomotive ? (
          <Skeleton.Button active block size={20} shape={"round"} />
        ) : (
          value
        )}
      </>
    );
  };
  const handelNavigate = (propertyUrl) => {
    navigate(`/automotive/${getPath(propertyUrl)}`);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <>
      <Row className="automotive-card-container" style={style}>
        <Col span={24}>
          <div className="item-header">
            <div
              className="tags-wrap capitalize-text"
              onClick={() => handelNavigate(data?.url)}
            >
              {data?.isPromoted && (
                <Tag
                  color="#ec2025"
                  style={{ marginRight: "5px", marginBottom: "5px" }}
                >
                  Featured
                </Tag>
              )}
            </div>
            {/* <!-- item-tools --> */}
            <div
              className="listing-image-wrap"
              onClick={() => handelNavigate(data?.url)}
            >
              <img
                width="592"
                height="444"
                src={
                  getImgVidUrl(data?.featuredImage) || "/img_placeholder.png"
                }
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = "/img_placeholder.png";
                }}
                className="img-fluid wp-post-image"
                alt=""
                decoding="async"
                sizes="(max-width: 592px) 100vw, 592px"
              />
            </div>
          </div>
        </Col>
        <Col span={24}>
          <Row className="item-body" gutter={[5, 5]}>
            <Col span={24} className="flex-center-between">
              <h2
                className="item-title capitalize-text"
                onClick={() => handelNavigate(data?.url)}
              >
                {renderValue(data?.englishName || "")}
              </h2>
              <h2 className="item-title capitalize-text">
                $ {renderValue(addCommas(data?.price || ""))}
              </h2>
            </Col>
            <Col span={24}>
              <address className="item-address capitalize-text">
                {renderValue(data?.brand?.brandEnglishName || "")} &nbsp;
                {data?.subBrand || renderValue(data?.subBrand || "")} &nbsp;
                {data?.year || renderValue(data?.year || "")} &nbsp;
              </address>
            </Col>
            <Col span={24}>
              <h4 style={{ display: "flex", alignItems: "center" }}>
                <MdSpeed size={20} /> &nbsp;{data?.kilometers} km
              </h4>
            </Col>
            <Col span={24} className="flex-center-between">
              <p>{data?.location || ""}</p>
              {data?.featuredBusiness?.image?.length > 0 && (
                <img
                  src={
                    getImgVidUrl(data?.featuredBusiness?.image[0]) ||
                    "/img_placeholder.png"
                  }
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = "/img_placeholder.png";
                  }}
                  style={{
                    width: "100px",
                    height: "40px",
                    objectFit: "cover",
                    borderRadius: "5px",
                  }}
                  alt=""
                  decoding="async"
                />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default Card;
