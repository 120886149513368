import React, { useContext, useEffect } from "react";
import "./Breadcrumb.css";
import { Link, useLocation, useParams } from "react-router-dom";
import { Breadcrumb } from "antd";
import { AiOutlineRight } from "react-icons/ai";
import { appContext } from "../../Context/appContext";
import { getPath } from "../../lib/lib";

function BreadcrumbComp() {
  const { AllCategories } = useContext(appContext);
  const { categoryName, subCategoryName, productName, propertyUrl, offerName } =
    useParams();
  const breadcrumbNameMap = {
    "/my-profile": "My Profile",
    "/my-profile/basic-info": "Basic Info",
    "/my-profile/my-addresses": "My Addresses",
    "/my-profile/payment-info": "Payment Info",
    "/my-profile/sales": "Sales",
    "/my-profile/orders": "Orders",
    "/my-profile/my-products": "My Products",
    "/my-profile/order": "Order",
    "/my-profile/my-orders": "My Orders",
    "/my-profile/my-order": "My Order",
    "/my-profile/my-products": "My Products",
    "/my-profile/edit-product": "Edit Product",
    "/my-profile/add-product": "Add Product",
    "/my-wishList": "WishList",
    "/become-a-vendor": "Become A Vendor",
    "/goBack": "goBack",
    [`/${getPath(categoryName)}`]: getPath(categoryName),
    [`/${getPath(categoryName)}/${getPath(subCategoryName)}`]:
      getPath(subCategoryName),
    [`/${getPath(categoryName)}/${getPath(subCategoryName)}/${getPath(
      productName
    )}`]: getPath(productName),
    [`/${getPath(categoryName)}/${getPath(productName)}`]: getPath(productName),
    [`/goBack/${getPath(categoryName)}`]: `${getPath(categoryName)}`,
    "/offer": "Offer",
    [`/offer/${getPath(offerName)}`]: `${getPath(offerName)}`,
    "/my-cart": "Cart",
    "/my-cart/checkout": "Checkout",
    "/real-estate": "Real Estate",
    [`/real-estate/${getPath(propertyUrl)}`]: `${getPath(propertyUrl)}`,
    "/automotive": "Automotive",
    [`/automotive/${getPath(propertyUrl)}`]: `${getPath(propertyUrl)}`,
    // '/apps/1/detail': 'Detail',
    // '/apps/2/detail': 'Detail',
  };

  let catId = AllCategories?.filter(
    (data) => getPath(data?.englishName) === getPath(categoryName)
  )[0]?._id;
  useEffect(() => {
    localStorage.setItem("prevRoute", window.location.pathname);
  }, []);
  const location = useLocation();
  const pathSnippets = location.pathname.split("/").filter((i) => i);
  const subCat = location?.state?.subCat ? location?.state?.subCat : "";
  const catHome = location?.state?.catHome ? location?.state?.catHome : "";
  // // console.log(pathSnippets);
  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
    //console.log(url);
    // console.log(breadcrumbNameMap[url]);
    return {
      key: url,
      title: (
        <Link
          to={url}
          className="linkClass"
          state={{ id: catId, subCat, catHome }}
        >
          <span
            style={{ color: index == pathSnippets.length - 1 && "#EC2025" }}
          >
            {breadcrumbNameMap[url]}
          </span>
        </Link>
      ),
    };
  });
  const breadcrumbItems = [
    {
      title: (
        <Link to="/" className="linkClass">
          <span>Home</span>
        </Link>
      ),
      key: "home",
    },
  ].concat(extraBreadcrumbItems);
  return (
    <>
      <Breadcrumb
        // style={{ margin: "1em 0" }}
        separator={
          <span className="linkClass">
            <AiOutlineRight
              color="#EC2025"
              style={{ fontSize: 17, fontWeight: 500 }}
            />
          </span>
        }
        items={breadcrumbItems}
      />
    </>
  );
}

export default BreadcrumbComp;
