import React from "react";

function ImageComponent({ image = "", style = {} }) {
  return (
    <img
      src={image || "/img_placeholder.png"}
      style={{
        objectFit: "cover",
        borderRadius: "10px",
        ...style,
      }}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null; // prevents looping
        currentTarget.src = "/img_placeholder.png";
      }}
      alt=""
      decoding="async"
    />
  );
}

export default ImageComponent;
