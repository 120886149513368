import React, { useEffect, useState } from "react";
import "./SignUp.css";
import { Footer } from "../../components/Footer/Footer";
import NavBar from "../../components/navBar/navBar";
import { Button, Input, message, Row, Col } from "antd";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { HiOutlineMail, HiOutlineUser } from "react-icons/hi";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import { RiLockPasswordLine } from "react-icons/ri";
import PhoneInput, { isValidPhoneNumber } from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
//import {} from "react-ro"
import { reSendOtpApi, registerApi, sendOtpApi } from "../../Apis";
function SignUp() {
  const [show, setShow] = useState(false);
  const [showCode, setShowCode] = useState(false);
  const [showConfirmCode, setShowConfirmCode] = useState(false);
  const [email, setEmail] = useState("");
  const [RoleName, setRoleName] = useState("vendor");

  //  const [code,setCode] = useState("")
  const navigate = useNavigate();

  const signupSchema = yup.object().shape({
    fullName: yup
      .string("Invalid Full Name!")
      .required("Full Name is required!"),
    email: yup
      .string("Invalid Email!")
      .email("Invalid Email!")
      .required("Email is required!"),
    mobileNumber: yup
      .string()
      .required("Phone number is required")
      .min(10, "phone number  should be at least 10 numbers!")
      .max(16, "phone number  should be at most 16 numbers!"),
    password: yup
      .string("Invalid password!")
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
        "Password length must be start with capital letter , 3 characters long , has a number and special character at the end"
      )
      .required("Password is required")
      .min(6, "Password is too short - should be 6 chars minimum"),
    confirmPassword: yup
      .string()
      .required("Confirm Password is required")
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });
  const becomeVendorSchema = yup.object().shape({
    fullName: yup
      .string("Invalid Full Name!")
      .required("Full Name is required!"),
    email: yup
      .string("Invalid Email!")
      .email("Invalid Email!")
      .required("Email is required !"),
    mobileNumber: yup
      .string()
      .required("Phone number is required !")
      .min(10, "phone number  should be at least 10 numbers!")
      .max(16, "phone number  should be at most 16 numbers!"),
    company: yup
      .string("Invalid Company Name !")
      .required("Company Name is required !"),
    password: yup
      .string("Invalid password!")
      .required("Password is required")
      .min(6, "Password is too short - should be 6 chars minimum"),
    confirmPassword: yup
      .string()
      .required("Confirm Password is required !")
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });
  const confirmCodeSchema = yup.object().shape({
    code: yup.string().required("Code is required!"),
  });
  const {
    register: register1,
    handleSubmit: handleSubmit1,
    formState: { errors: errors1 },
    control: control1,
    setValue: resetCode,
  } = useForm({
    resolver: yupResolver(confirmCodeSchema),
  });
  const {
    register: register,
    handleSubmit: handleSubmit,
    formState: { errors: errors },
    control,
    setValue: resetSignUp,
  } = useForm({
    resolver: yupResolver(
      RoleName === "user" ? signupSchema : becomeVendorSchema
    ),
  });
  // console.log(errors);

  const resetSignUpForm = () => {
    resetSignUp("fullName", "");
    resetSignUp("email", "");
    resetSignUp("mobileNumber", "");
    resetSignUp("password", "");
    resetSignUp("confirmPassword", "");
    resetSignUp("company", undefined);
  };
  const resetCodeForm = () => {
    resetCode("code", "");
  };
  const onsubmit = async (data) => {
    // console.log(data);
    const { fullName, mobileNumber, email, password } = data;
    setEmail(email);
    let obj = {
      fullName,
      mobileNumber,
      email,
      password,
      roleName: RoleName,
      company: data?.company ? data?.company : undefined,
    };
    try {
      const response = await registerApi(obj);
      // console.log(response);
      message.success(response.data.messageEn);
      setShowConfirmCode(true);
    } catch (error) {
      if (error.response) {
        message.error(error.response.data.messageEn);
      } else {
        message.error(error.message);
      }
    }
  };
  const handleResendCode = async () => {
    try {
      const response = await reSendOtpApi({ email });
      // console.log(response);
      message.success(response.data.messageEn);
    } catch (error) {
      if (error.response) {
        message.error(error.response.data.messageEn);
      } else {
        message.error(error.message);
      }
    }
  };
  const onsubmit1 = async (data) => {
    // console.log(data);
    const { code } = data;
    let obj = { code, email, roleName: "user" };
    try {
      const response = await sendOtpApi(obj);
      // console.log(response);
      message.success(response.data.messageEn);
      resetCodeForm();
      setShowConfirmCode(false);
      resetSignUpForm();
      // console.log(response.data.data);
      localStorage.setItem("userData", JSON.stringify(response.data.data));
      localStorage.setItem("token", response.data.data.token);
      navigate(localStorage.getItem("prevRoute"));
      window.location.reload();
    } catch (error) {
      if (error.response) {
        message.error(error.response.data.messageEn);
      } else {
        message.error(error.message);
      }
    }
  };

  useEffect(() => {
    return () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };
  }, []);
  return (
    <>
      <NavBar />
      <div className="signupContainer">
        {!showConfirmCode && (
          <>
            <div className="signupTitle">
              <h1 className="title1">Sign Up</h1>
            </div>

            <div className="signupForm">
              <Row gutter={[10, 10]}>
                <Col span={12}>
                  <Button
                    className={RoleName === "user" ? `red-btn` : `white-btn`}
                    style={{ width: "100%", height: "50px" }}
                    onClick={() => {
                      setRoleName("user");
                    }}
                  >
                    User
                  </Button>
                </Col>
                <Col span={12}>
                  <Button
                    className={RoleName === "vendor" ? `red-btn` : `white-btn`}
                    style={{ width: "100%", height: "50px" }}
                    onClick={() => {
                      setRoleName("vendor");
                    }}
                  >
                    Vendor
                  </Button>
                </Col>
              </Row>
              <form onSubmit={handleSubmit(onsubmit)}>
                <p className="signupLable">Full Name :</p>
                <Controller
                  name="fullName"
                  control={control}
                  render={({ field }) => (
                    <Input
                      prefix={
                        <HiOutlineUser
                          style={{ fontSize: "25px", color: "#c9c9c9" }}
                        />
                      }
                      {...field}
                      className="signupInput"
                      placeholder=" ex: John Michael Doe"
                    />
                  )}
                />
                <p style={{ color: "red" }}>{errors.fullName?.message}</p>
                <p className="signupLable">Email :</p>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="ex: Johndoe@gmail.com"
                      prefix={
                        <HiOutlineMail
                          style={{ fontSize: "25px", color: "#c9c9c9" }}
                        />
                      }
                      className="signupInput"
                      type="email"
                      {...field}
                    />
                  )}
                />
                <p style={{ color: "red" }}>{errors.email?.message}</p>
                <p className="signupLable">Phone Number :</p>
                <Controller
                  name="mobileNumber"
                  control={control}
                  rules={{
                    validate: (value) => isValidPhoneNumber(value),
                  }}
                  render={({ field: { onChange, value } }) => (
                    <PhoneInput
                      onChange={onChange}
                      value={value}
                      id="mobileNumber"
                      containerStyle={{
                        margin: "1em auto",
                      }}
                      enableSearch
                      enableClickOutside
                      enableTerritories
                      country={"lb"}
                      inputClass="signupInput"
                      inputStyle={{
                        width: "100%",
                        margin: "10px 0",
                        boxSizing: "border-box",
                        border: "1px solid #ccc",
                        outline: "none",
                        borderRadius: "7px",
                        backgroundColor: "#EBEBEB",
                        boxShadow: "0 0 4px lightgray",
                      }}
                    />
                  )}
                />
                <p style={{ color: "red" }}>{errors.mobileNumber?.message}</p>
                {RoleName === "vendor" && (
                  <>
                    <p className="becomeVendorLable">Company/Entity Name :</p>
                    <Controller
                      name="company"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          className="becomeVendorInput signupInput"
                          placeholder=" Enter Name Here."
                        />
                      )}
                    />
                    <p style={{ color: "red" }}>{errors.company?.message}</p>
                  </>
                )}
                <small className="note">at Least 8 Characters</small>
                <p className="signupLable">Password :</p>
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <Input
                      prefix={
                        <RiLockPasswordLine
                          style={{ fontSize: "25px", color: "#c9c9c9" }}
                        />
                      }
                      suffix={
                        !show ? (
                          <AiOutlineEye
                            style={{ fontSize: "25px", color: "#000" }}
                            onClick={() => setShow(true)}
                          />
                        ) : (
                          <AiOutlineEyeInvisible
                            style={{ fontSize: "25px", color: "#000" }}
                            onClick={() => setShow(false)}
                          />
                        )
                      }
                      className="signupInput"
                      type={!show ? "password" : "text"}
                      {...field}
                    />
                  )}
                />
                <p style={{ color: "red" }}>{errors.password?.message}</p>
                <p className="signupLable">Confirm Password :</p>
                <Controller
                  name="confirmPassword"
                  control={control}
                  render={({ field }) => (
                    <Input
                      prefix={
                        <RiLockPasswordLine
                          style={{ fontSize: "25px", color: "#c9c9c9" }}
                        />
                      }
                      suffix={
                        !show ? (
                          <AiOutlineEye
                            style={{ fontSize: "25px", color: "#000" }}
                            onClick={() => setShow(true)}
                          />
                        ) : (
                          <AiOutlineEyeInvisible
                            style={{ fontSize: "25px", color: "#000" }}
                            onClick={() => setShow(false)}
                          />
                        )
                      }
                      className="signupInput"
                      type={!show ? "password" : "text"}
                      {...field}
                    />
                  )}
                />
                <p style={{ color: "red" }}>
                  {errors.confirmPassword?.message}
                </p>

                <small className="privecy">
                  By Signing Up You Agree with Our Terms and Conditions.
                </small>
                <Button
                  htmlType="submit"
                  className="signupBtn red-btn hover-bg-w"
                  style={{ width: "100%" }}
                >
                  Sign Up
                </Button>
              </form>
              <p className="hint1">You Already have an Account ?</p>
              <p className="hint2" onClick={() => navigate("/login")}>
                Log In Now
              </p>
            </div>
          </>
        )}
        {showConfirmCode && (
          <div className="signupForm">
            <form onSubmit={handleSubmit1(onsubmit1)}>
              <span className="resetPasswordLable">Confirmation Code :</span>
              <Controller
                name="code"
                control={control1}
                render={({ field }) => (
                  <Input
                    prefix={
                      <RiLockPasswordLine
                        style={{ fontSize: "25px", color: "#c9c9c9" }}
                      />
                    }
                    suffix={
                      !show ? (
                        <AiOutlineEye
                          style={{ fontSize: "25px", color: "#000" }}
                          onClick={() => setShowCode(true)}
                        />
                      ) : (
                        <AiOutlineEyeInvisible
                          style={{ fontSize: "25px", color: "#000" }}
                          onClick={() => setShow(false)}
                        />
                      )
                    }
                    className="resetPasswordInput"
                    type={!showCode ? "password" : "text"}
                    {...field}
                  />
                )}
              />
              <p style={{ color: "red" }}>{errors1.code?.message}</p>
              <small className="note">Enter the Code Sent to your Email</small>
              <Button htmlType="submit" className="resetPasswordBtn">
                Enter Code
              </Button>
              <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                <button
                  type="button"
                  className="resetPasswordAuthBtn"
                  onClick={() => handleResendCode()}
                >
                  Resend Code
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
}

export default SignUp;
