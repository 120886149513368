import React, { useEffect, useState } from "react";
import "./resetPassword.css";
import { Button, Input, message } from "antd";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { HiOutlineMail } from "react-icons/hi";
import { RiLockPasswordLine } from "react-icons/ri";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import {
  forgotPasswordApi,
  reSendOtpApi,
  setNewPasswordApi,
  verifyForgotPassApi,
} from "../../Apis";
import NavBar from "../../components/navBar/navBar";
import { Footer } from "../../components/Footer/Footer";
function ResetPassword() {
  const [show, setShow] = useState(false);
  const [showForgotPass, setShowForgotPass] = useState(true);
  const [showConfirmCode, setShowConfirmCode] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);
  const navigate = useNavigate();
  const [width, setWidth] = useState(window.innerWidth);
  const [email, setEmail] = useState("");

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  const fogotPasswordSchema = yup.object().shape({
    email: yup
      .string("Invalid Email!")
      .email("Invalid Email!")
      .required("Email is required!"),
  });
  const confirmCodeSchema = yup.object().shape({
    code: yup.string().required("Code is required!"),
  });
  const newPasswordSchema = yup.object().shape({
    newPassword: yup
      .string("Invalid password!")
      // .matches(
      //   /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
      //   "Password length must be start with capital letter , 3 characters long , has a number and special character at the end"
      // )
      .required("Password is required"),
  });

  const {
    register: register,
    handleSubmit: handleSubmit,
    formState: { errors: errors },
    control,
  } = useForm({
    resolver: yupResolver(fogotPasswordSchema),
  });

  const {
    register: register1,
    handleSubmit: handleSubmit1,
    formState: { errors: errors1 },
    control: control1,
  } = useForm({
    resolver: yupResolver(confirmCodeSchema),
  });

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    control: control2,
  } = useForm({
    resolver: yupResolver(newPasswordSchema),
  });

  // // console.log(errors);

  const onsubmit = async (data) => {
    // console.log("userDetails", userDetails);
    console.log(data);
    const { email } = data;
    try {
      setEmail(email);
      const response = await forgotPasswordApi(data);
      message.success(response.data.messageEn);
      setShowForgotPass(false);
      setShowConfirmCode(true);
    } catch (error) {
      if (error.response) {
        message.error(error.response.data.messageEn);
      } else {
        message.error(error.message);
      }
    }
  };
  const onsubmit1 = async (data) => {
    // console.log(data);
    const { code } = data;
    try {
      const response = await verifyForgotPassApi({ email, code });
      message.success(response.data.messageEn);
      setShowForgotPass(false);
      setShowConfirmCode(false);
      setShowNewPass(true);
    } catch (error) {
      if (error.response) {
        message.error(error.response.data.messageEn);
      } else {
        message.error(error.message);
      }
    }
  };
  const onsubmit2 = async (data) => {
    // console.log(data);
    const { newPassword } = data;
    try {
      const response = await setNewPasswordApi({ email, newPassword });
      message.success(response.data.messageEn);
      navigate("/login");
      setShowForgotPass(true);
      setShowConfirmCode(false);
      setShowNewPass(false);
    } catch (error) {
      if (error.response) {
        message.error(error.response.data.messageEn);
      } else {
        message.error(error.message);
      }
    }
  };
  const handleResendCode = async () => {
    try {
      const response = await reSendOtpApi({ email });
      // console.log(response);
      message.success(response.data.messageEn);
    } catch (error) {
      if (error.response) {
        message.error(error.response.data.messageEn);
      } else {
        message.error(error.message);
      }
    }
  };
  useEffect(() => {
    return () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };
  }, []);
  return (
    <>
      <NavBar />
      <div
        className="resetPasswordContainer"
        style={{
          marginTop: showNewPass
            ? "20vh"
            : showForgotPass && width > 400 && "14vh",
        }}
      >
        {/* <div className="resetPasswordLogo">
             <img src="/Logo Icon.svg" alt="logo" />
             <div className="logoTitle">
                <h1 className="logoTitle1" >Beirut Mart</h1>
                <span className="logoTitle2" >It is all about your needs</span>
             </div>
           </div> */}

        <div className="resetPasswordTitle">
          <h1 className="title1">
            {showForgotPass
              ? "Forgot Password"
              : showConfirmCode
              ? "Confirmation Code"
              : "New Password"}
          </h1>
          {/* <span className="title2">
            Lorem ipsum dolor sit amet consectetur.
          </span> */}
        </div>
        <div className="resetPasswordForm">
          {showForgotPass && (
            <form onSubmit={handleSubmit(onsubmit)}>
              <span className="resetPasswordLable">Email :</span>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <Input
                    placeholder="ex: Johndoe@gmail.com"
                    prefix={
                      <HiOutlineMail
                        style={{ fontSize: "25px", color: "#c9c9c9" }}
                      />
                    }
                    className="resetPasswordInput"
                    type="email"
                    {...field}
                  />
                )}
              />

              <p style={{ color: "red" }}>{errors.email?.message}</p>
              <small className="note">Code will be Sent to this Email</small>

              <Button
                htmlType="submit"
                className="resetPasswordBtn red-btn hover-bg-w"
              >
                Send Code
              </Button>
            </form>
          )}
          {showConfirmCode && (
            <form onSubmit={handleSubmit1(onsubmit1)}>
              <span className="resetPasswordLable">Confirmation Code :</span>
              <Controller
                name="code"
                control={control1}
                render={({ field }) => (
                  <Input
                    prefix={
                      <RiLockPasswordLine
                        style={{ fontSize: "25px", color: "#c9c9c9" }}
                      />
                    }
                    suffix={
                      !show ? (
                        <AiOutlineEye
                          style={{ fontSize: "25px", color: "#000" }}
                          onClick={() => setShow(true)}
                        />
                      ) : (
                        <AiOutlineEyeInvisible
                          style={{ fontSize: "25px", color: "#000" }}
                          onClick={() => setShow(false)}
                        />
                      )
                    }
                    className="resetPasswordInput"
                    type={!show ? "password" : "text"}
                    {...field}
                  />
                )}
              />

              <p style={{ color: "red" }}>{errors1.code?.message}</p>
              <small className="note">Enter the Code Sent to your Email</small>

              <Button htmlType="submit" className="resetPasswordBtn">
                Enter Code
              </Button>
              <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                <button
                  type="button"
                  className="resetPasswordAuthBtn"
                  onClick={() => handleResendCode()}
                >
                  Resend Code
                </button>
              </div>
            </form>
          )}
          {showNewPass && (
            <form onSubmit={handleSubmit2(onsubmit2)}>
              <span className="resetPasswordLable">New Password :</span>
              <Controller
                name="newPassword"
                control={control2}
                render={({ field }) => (
                  <Input
                    prefix={
                      <RiLockPasswordLine
                        style={{ fontSize: "25px", color: "#c9c9c9" }}
                      />
                    }
                    suffix={
                      !show ? (
                        <AiOutlineEye
                          style={{ fontSize: "25px", color: "#000" }}
                          onClick={() => setShow(true)}
                        />
                      ) : (
                        <AiOutlineEyeInvisible
                          style={{ fontSize: "25px", color: "#000" }}
                          onClick={() => setShow(false)}
                        />
                      )
                    }
                    className="resetPasswordInput"
                    type={!show ? "password" : "text"}
                    {...field}
                  />
                )}
              />
              <p style={{ color: "red" }}>{errors2.newPassword?.message}</p>
              <small className="note">at Least 8 Characters</small>

              <Button htmlType="submit" className="resetPasswordBtn">
                Enter New Password
              </Button>
            </form>
          )}
          {!showNewPass && (
            <>
              <p className="hint1">You Don’t have an Account ?</p>
              <p className="hint2" onClick={() => navigate("/signup")}>
                Sign up Now
              </p>
              <p
                className="hint2"
                onClick={() => navigate("/become-a-vendor")}
                style={{ margin: "10px 0px" }}
              >
                Sign Up As a Vendor
              </p>
            </>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ResetPassword;
