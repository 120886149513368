import React from "react";
import "./Card.css";
import { Col, Row, Tag, Skeleton } from "antd";
import { LuBath } from "react-icons/lu";
import { TfiRulerAlt2 } from "react-icons/tfi";
import { IoBedOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
//import ReadMoreReact from "read-more-react";
import { Url } from "../../Apis";
import { addCommas, getImgVidUrl, getPath } from "../../lib/lib";

function Card({ data, LoadingPropertyListing, style = {} }) {
  const navigate = useNavigate();
  // console.log("data ", data);
  const renderValue = (value) => {
    return (
      <>
        {LoadingPropertyListing ? (
          <Skeleton.Button active block size={20} shape={"round"} />
        ) : (
          value
        )}
      </>
    );
  };
  const handelNavigate = (propertyUrl) => {
    navigate(`/real-estate/${getPath(propertyUrl)}`);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <>
      <Row className="card-container" style={style}>
        <Col span={24}>
          <div className="item-header">
            {data?.isPromoted && (
              <Tag
                color="#ec2025"
                className="featured-tag"
                onClick={() => handelNavigate(data?.url)}
              >
                Featured
              </Tag>
            )}
            <div
              className="tags-wrap capitalize-text"
              onClick={() => handelNavigate(data?.url)}
            >
              {data?.status?.map((val, i) => (
                <Tag
                  key={i}
                  color="rgba(0, 0, 0, 0.65)"
                  style={{ marginRight: "5px", marginBottom: "5px" }}
                >
                  {val?.englishName}
                </Tag>
              ))}
            </div>
            <div className="item-price-wrap">
              <span
                className="item-price"
                onClick={() => handelNavigate(data?.url)}
              >
                $ {renderValue(addCommas(data?.price || ""))}
              </span>
            </div>
            {/* <!-- item-tools --> */}
            <div
              className="listing-image-wrap"
              onClick={() => handelNavigate(data?.url)}
            >
              {/* {LoadingPropertyListing ? (
                <Skeleton.Image active />
              ) : ( */}
              <img
                width="592"
                height="444"
                src={
                  getImgVidUrl(data?.featuredImage) || "/img_placeholder.png"
                }
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = "/img_placeholder.png";
                }}
                className="img-fluid wp-post-image"
                alt=""
                decoding="async"
                sizes="(max-width: 592px) 100vw, 592px"
              />
              {/* )} */}
              {/* <div class="overlay" /> */}
            </div>
          </div>
        </Col>
        <Col span={24}>
          <Row className="item-body">
            <Col span={24}>
              <h2
                className="item-title capitalize-text"
                onClick={() => handelNavigate(data?.url)}
              >
                {renderValue(data?.englishName || "")}
              </h2>
            </Col>
            <Col span={24}>
              <address className="item-address capitalize-text">
                {renderValue(data?.address || "")}
              </address>
            </Col>
            <Col span={24}>
              <ul className="item-amenities item-amenities-with-icons">
                <li>
                  <div>
                    {renderValue(data?.numberOfBedRooms || "")}
                    <IoBedOutline size={25} className="item-icon" />
                  </div>
                  <p>Bedrooms</p>
                </li>
                <li>
                  <div>
                    {renderValue(data?.numberOfBathRooms || "")}{" "}
                    <LuBath size={25} className="item-icon" />
                  </div>
                  <p>Bathroom</p>
                </li>
                <li>
                  <div>
                    {renderValue(data?.size || "")}{" "}
                    <TfiRulerAlt2 size={25} className="item-icon" />
                  </div>
                  <p className="hz-figure area_postfix unset-text-transform">
                    m²
                  </p>
                </li>
              </ul>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default Card;
