import Carousel from "better-react-carousel";
import "react-multi-carousel/lib/styles.css";
import { chunk } from "../../../lib/lib";
import Card from "../Card";
import { Col, Row } from "antd";
import "../index.css";

function RealEstateCarousal({
  LoadingPropertyListing,
  AllPropertyListing,
  number,
  span,
}) {
  console.log("number ", number);
  console.log("AllPropertyListing ", AllPropertyListing);
  console.log("AllPropertyListing?.length ", AllPropertyListing?.length);
  return (
    <div>
      <Carousel
        mobileBreakpoint={500}
        marginWidth={40}
        responsiveLayout={[
          {
            breakpoint: 5000,
            rows: 1,
            cols: 1, //7,
            gap: 10,
            loop: true,
            autoPlay: 2000,
          },
        ]}
        autoPlay={2000}
        dotColorActive="#231F20"
        rows={1}
        cols={1}
        loop
      >
        {chunk(AllPropertyListing || [], number)?.map((arr, i) => {
          console.log("arr ", arr);
          return (
            <Carousel.Item key={i}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {arr?.map((val, i) => (
                  <Card
                    key={i}
                    data={val}
                    LoadingPropertyListing={LoadingPropertyListing}
                    style={{ marginRight: i < number - 1 ? "10px" : "0px" }}
                  />
                ))}
                {AllPropertyListing.length < number &&
                  Array(number - AllPropertyListing.length)
                    .fill(null)
                    .map((_, i) => (
                      <div style={{ flexGrow: 1, width: "100%" }} key={i}></div>
                    ))}
                {AllPropertyListing.length > number &&
                  arr.length < number &&
                  Array(number - arr.length)
                    .fill(null)
                    .map((_, i) => (
                      <div style={{ flexGrow: 1, width: "100%" }} key={i}></div>
                    ))}
              </div>

              {/* <Row cols={25} gutter={[10, 10]}>
                {arr?.map((val, i) => (
                  <Col
                    span={span}
                    // flex={1}
                    key={i}
                  >
                    <Card
                      data={val}
                      LoadingPropertyListing={LoadingPropertyListing}
                    />
                  </Col>
                ))}
              </Row> */}
            </Carousel.Item>
          );
        })}
      </Carousel>
    </div>
  );
}

export default RealEstateCarousal;
