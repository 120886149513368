// import logo from "./logo.svg";
import "./App.css";
import { Route, Routes, useLocation } from "react-router-dom";
import Login from "./Pages/Login/Login";
import SignUp from "./Pages/SignUp/SignUp";
import ResetPassword from "./Pages/resetPassword/resetPassword";
import { useState, useContext, useEffect } from "react";
import Splash from "./components/Splash/splash";
import { Landing } from "./Pages/Landing/Landing";
import { Footer } from "./components/Footer/Footer";
import MyProfile from "./Pages/myProfile";
import ResetNewPassword from "./Pages/resetPassword/resetNewPassword.";
import MyWishList from "./Pages/myWishList/myWishList";
import MyCart from "./Pages/myCart/myCart";
import MySingleOrder from "./Pages/mySingleOrder/mySingleOrder"; // to delete
import Categories from "./Pages/categories/categories";
import Checkout from "./Pages/checkout/Checkout";
import Product from "./Pages/product/product";
import AddProduct from "./Pages/addProduct/AddProduct";
import Become_a_vendor from "./Pages/Become_a_vendor";

import HomeCategories from "./Pages/homeCategories/homeCategories";
import { FaWhatsapp } from "react-icons/fa";
import { appContext } from "./Context/appContext";
import {
  isCategory,
  removeRealEstateFilterStorage,
  removeCategoryFilterStorage,
  removeAutomotiveFilterStorage,
} from "./lib/lib";
import RealEstate from "./Pages/RealEstate";
import Property from "./Pages/RealEstate/Property";
import OfferProducts from "./Pages/OfferProducts";
import Automotive from "./Pages/Automotives";
import AutomotiveProperty from "./Pages/Automotives/Property";

function App() {
  const { AllCategories } = useContext(appContext);
  const isSubCategoryPath = isCategory(
    window.location.pathname.split("/"),
    AllCategories
  )?.isSubCategory;
  const [splash, setSplash] = useState(true);
  const location = useLocation();
  const catHome = location?.state?.catHome;
  // console.log("state ", location?.state);
  const categoryFilterPath = localStorage.getItem("categoryFilterPath");
  setTimeout(() => {
    setSplash(false);
  }, 3000);
  useEffect(() => {
    let name = window.location.pathname.split("/")[1];
    if (name !== "real-estate") {
      removeRealEstateFilterStorage();
    }
    if (name !== "automotive") {
      removeAutomotiveFilterStorage();
    }
    if (categoryFilterPath !== name || !categoryFilterPath) {
      removeCategoryFilterStorage();
    }
  }, [window.location.pathname]);

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    function handleGoBack() {
      console.log("window.history ", window.history);
      console.log("Browser back or forward button was pressed");
      //alert("You message");
    }
    window.addEventListener("popstate", handleGoBack);
    return () => window.removeEventListener("popstate", handleGoBack);
  }, []);
  return (
    <>
      {splash && <Splash />}
      <div className={splash && "hidden"}>
        <a
          href={"https://api.whatsapp.com/send?phone=96178948090"}
          target="_blank"
          rel="noreferrer"
          style={{ zIndex: 999 }}
          className="z-50 fixed bottom-[64px] md:bottom-[24px] right-[24px] w-[60px] h-[60px] rounded-full bg-[#25D366] flex items-center justify-center"
        >
          <FaWhatsapp className="text-white text-3xl" />
        </a>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/goBack" element={<Landing />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/become-a-vendor" element={<Become_a_vendor />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/my-profile" element={<MyProfile />} />
          <Route path="/my-profile/*" element={<MyProfile />} />

          <Route path="/my-wishList" element={<MyWishList />} />
          <Route path="/my-cart" element={<MyCart />} />
          <Route path="/my-cart/checkout" element={<Checkout />} />
          <Route path="/my-profile/add-product" element={<AddProduct />} />
          <Route path="/my-profile/edit-product" element={<AddProduct />} />
          <Route path="/my-profile/my-order" element={<MySingleOrder />} />
          <Route
            path="/my-profile/reset-new-password"
            element={<ResetNewPassword />}
          />
          <Route path="/real-estate" element={<RealEstate />} />
          <Route path="/real-estate/:propertyUrl" element={<Property />} />
          <Route path="/automotive" element={<Automotive />} />
          <Route
            path="/automotive/:propertyUrl"
            element={<AutomotiveProperty />}
          />
          <Route path="/offer/:offerName" element={<OfferProducts />} />
          {!catHome && <Route path="/:categoryName" element={<Categories />} />}
          {catHome && (
            <Route path="/:categoryName" element={<HomeCategories />} />
          )}
          {isSubCategoryPath && (
            <Route
              path="/:categoryName/:subCategoryName"
              element={<Categories />}
            />
          )}
          {!isSubCategoryPath && (
            <Route path="/:categoryName/:productName" element={<Product />} />
          )}

          <Route
            path="/:categoryName/:subCategoryName/:productName"
            element={<Product />}
          />

          <Route path="/footer" element={<Footer />} />
        </Routes>
      </div>
    </>
  );
}

export default App;

// eslint-disable-next-line no-lone-blocks
{
  /* <Route path='/my-profile/my-order' element={<MySingleOrder />}/> */
}
