export function getPath(name) {
  return name?.trim()?.replaceAll(/\s+/g, "-");
}
export const getImgVidUrl = (url) => {
  let src = `${url}`.includes("https")
    ? url
    : `https://api.beirutmart.com${url}`;
  return src;
};

export function getCategoryByCatName(categoryName, categoriesList) {
  let _categoryName = categoryName?.split("-").join(" ");
  let _categoriesList = [];
  categoriesList?.forEach((ele) => {
    if (ele?.subCategory?.length > 0) {
      _categoriesList = [..._categoriesList, { ...ele }, ...ele?.subCategory];
    } else {
      _categoriesList = [..._categoriesList, { ...ele }];
    }
  });
  return _categoriesList?.find(
    (ele) => ele?.englishName?.toLowerCase() === _categoryName?.toLowerCase()
  );
}
export function isCategory(namesList, categoriesList) {
  let _categoriesList = [];
  categoriesList.forEach((ele) => {
    if (ele?.subCategory?.length > 0) {
      _categoriesList = [..._categoriesList, ...ele?.subCategory];
    }
  });
  let list = [..._categoriesList]?.map((cat) => getPath(cat?.englishName));
  let isSubCategory = list.includes(namesList[namesList.length - 1]);
  let data = isSubCategory
    ? _categoriesList?.find(
        (ele) => getPath(ele?.englishName) === namesList[namesList.length - 1]
      )
    : {};
  return {
    isSubCategory: isSubCategory,
    data: data,
  };
}
export const handleNavigate = (product, navigate) => {
  let mainCategory = product?.mainCategory?.englishName || undefined;
  let Category = product?.category?.englishName;
  //let productName = getPath(product?.englishName);
  let URL = product?.URL;
  //console.log("product ", product);

  if (mainCategory) {
    window.localStorage.setItem("categoryFilterPath", getPath(mainCategory));
    navigate(`/${getPath(mainCategory)}/${getPath(Category)}/${URL}`);
  } else {
    window.localStorage.setItem("categoryFilterPath", getPath(Category));
    navigate(`/${getPath(Category)}/${URL}`);
  }
};
export const getStatusColor = (status) => {
  if (status === "PROCESSING") {
    return "#DBC81E";
  } else if (status === "CANCELLED") {
    return "#dc3545";
  } else if (status === "REJECTED") {
    return "#dc3545";
  } else if (status === "ACCEPTED") {
    return "#0180B7";
  } else if (status === "PENDING") {
    return "#ACAAB6";
  } else if (status === "NOTAVAILABLE") {
    return "#dc3545";
  } else {
    return "rgba(3, 204, 48, 1)";
  }
};
export const checkIfFilterNotEmpty = (_FilterValues) => {
  let vals = [];
  Object.keys(_FilterValues)?.forEach((key) => {
    vals = [...vals, _FilterValues[key]];
  });
  let isFilterNotEmpty = vals?.find((ele) => ele !== undefined);
  return isFilterNotEmpty ? true : false;
};
export const isMatchedDetails = (values, detailsObj) => {
  let boolArr = [];
  Object.keys(values).map((key) => {
    if (values[key] === detailsObj[key]) {
      boolArr = [...boolArr, true];
    } else {
      boolArr = [...boolArr, false];
    }
  });
  return !boolArr.includes(false);
};
export const sortArray = (array) => {
  return array.sort(function (a, b) {
    return b - a;
  });
};
export const sortArrayOfObjects = (array, name) => {
  return array.sort((p1, p2) =>
    p1[name] < p2[name] ? -1 : p1[name] > p2[name] ? 1 : 0
  );
};
export const sortArrayAsc = (array) => {
  return [...(array || [])]?.sort(function (a, b) {
    return a - b;
  });
};
export const getArrayOfValues = (obj, keys) => {
  let _d = [];
  keys?.forEach((k) => {
    _d = [..._d, obj[k]];
  });
  let ArrValues = sortArray(_d)?.filter(
    (ele) => ele !== undefined && ele !== null
  );
  return ArrValues;
};
export const chunk = (arr, n) =>
  arr?.length ? [arr.slice(0, n), ...chunk(arr.slice(n), n)] : [];

export const getMaxQuantity = (Alldetails, orderDetails) => {
  let isDetailsMatched = [];
  Alldetails?.forEach((ele) => {
    let arr = [];
    if (orderDetails)
      Object.keys(orderDetails).forEach((key) => {
        if (orderDetails[key] === ele[key]) arr = [...arr, true];
        else arr = [...arr, false];
      });
    isDetailsMatched = [...isDetailsMatched, arr];
  });
  let indx = isDetailsMatched.findIndex((ele) => !ele.includes(false));
  // console.log("Alldetails[indx] ", SelectedOrder);
  let maxQuantity = Alldetails[indx]?.quantity || 0;
  return maxQuantity;
};
export const removeAutomotiveFilterStorage = () => {
  localStorage.removeItem("automotiveFilter");
};
export const removeRealEstateFilterStorage = () => {
  localStorage.removeItem("realEstateFilter");
};
export const removeCategoryFilterStorage = () => {
  localStorage.removeItem("categoryFilterPath");
  localStorage.removeItem("categoryFilter");
};
export const deleteItemFromList = (index, items) => {
  items.splice(index, 1);
  //console.log("items ", items);
  return items;
};

export const addCommas = (number) => {
  return `${number || ""}`?.replace(/(.)(?=(.{3})+$)/g, "$1,");
};
export const uniqueArray = (arr, field) => {
  let newArr = arr?.filter(
    (obj, index, self) =>
      index === self.findIndex((t) => t[field] === obj[field])
  );
  return newArr;
};
export const filterCategories = (array) => {
  return array?.filter(
    (val) =>
      val?.isActive === true &&
      !val?.isRealEstate &&
      !val?.isAutomotive &&
      !val?.isDeleted
  );
};
