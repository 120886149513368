import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  message,
  Input,
  InputNumber,
  Row,
  Col,
  Button,
  Popconfirm,
  Modal,
} from "antd";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import {
  deleteAddressApi,
  getAddressApi,
  addAddressApi,
  editAddressApi,
  mapKey,
} from "../../Apis";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { BiEditAlt } from "react-icons/bi";
import { PiTrashSimpleLight } from "react-icons/pi";
import {
  StandaloneSearchBox,
  LoadScript,
  GoogleMap,
  Marker,
} from "@react-google-maps/api";
import { setDefaults, geocode, RequestType } from "react-geocode";
import ButtonsFooter from "../../components/ButtonsFooter";

function Address({ width, Address, setAddressesTab }) {
  const mapRef = useRef(null);
  const inputRef = useRef();
  const [map, setMap] = useState(null);
  const containerStyle = {
    width: "100%",
    height: "300px",
  };
  let center = {
    lat: 33.894,
    lng: 35.502,
  };
  const [addresses, setAddresses] = useState([]);
  const [isAddAddressOpen, setIsAddAddressOpen] = useState(false);
  const [SelectedAddress, setSelectedAddress] = useState({
    formatted_address: "",
    full_address: "",
    lat: center?.lat,
    lng: center?.lng,
    city: "",
    district: "",
    _id: "",
  });
  const addressSchema = yup.object().shape({
    city: yup
      .string("invalid value")
      .min(3, "Must be string and at least 3 letters")
      .matches(/^[a-zA-Z\s]+$/, "Only letters are allowed")
      .required("Required"),
    district: yup
      .string()
      .min(4, "Must be string and at least 4 letters")
      .matches(/^[a-zA-Z\s]+$/, "Only letters are allowed")
      .required("district is required !"),
    fullAddress: yup
      .string()
      .min(3, "Must be string and at least 3 letters")
      .required("full address is required !"),
  });
  const {
    // register: register1,
    handleSubmit: handleSubmit1,
    formState: { errors: errors1 },
    // setError,
    control: control1,
    reset: reset1,
    // setValue: setAddressValue,
  } = useForm({
    resolver: yupResolver(addressSchema),
  });
  useEffect(() => {
    setDefaults({
      key: mapKey, // Your API key here.
      language: "en", // Default language for responses.
      region: "es",
    });
  }, []);
  useEffect(() => {
    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };
    if (navigator.geolocation) {
      //setStatus("Locating...");
      navigator.geolocation.getCurrentPosition(
        (position) => {
          //setStatus(null);
          console.log("position ", position);
          setSelectedAddress((prev) => ({
            ...prev,
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          }));
        },
        (error) => {
          //setStatus("Unable to retrieve your location");
          console.error(error);
        },
        options
      );
    } else {
      //setStatus("Geolocation is not supported by your browser");
    }
  }, [isAddAddressOpen]);
  useEffect(() => {
    setAddresses(Address);
  }, []);

  const getAddress = async () => {
    try {
      let response = await getAddressApi();
      setAddresses([...response?.data?.data]);
      setAddressesTab([...response?.data?.data]);
    } catch (error) {
      // console.log(error.message);
    }
  };
  const confirmDelete = (id) => {
    deleteAddressApi(id)
      .then((data) => {
        message.success(data.data.messageEn);
      })
      .catch((error) => {
        message.error(
          error.response.data.messageEn ||
            "Something went wrong please refresh and try again"
        );
      })
      .finally(() => {
        getAddress();
      });
  };
  const addAddressSubmit = async () => {
    let data = {
      city: SelectedAddress?.city,
      district: SelectedAddress?.district,
      fullAddress: SelectedAddress?.full_address,
      lat: `${SelectedAddress?.lat}`,
      long: `${SelectedAddress?.lng}`,
    };
    try {
      let response = await addAddressApi(data);
      message.success(response.data.messageEn);
      handleAddAddressCancel();
      getAddress();
    } catch (error) {
      message.error(error?.response.data?.messageEn);
    }
    // console.log("addAddress", data);
  };
  const handleEditAddressOk = async () => {
    let data = {
      city: SelectedAddress?.city,
      district: SelectedAddress?.district,
      fullAddress: SelectedAddress?.full_address,
      lat: `${SelectedAddress?.lat}`,
      long: `${SelectedAddress?.lng}`,
    };
    try {
      let response = await editAddressApi(SelectedAddress?._id, data);
      getAddress();
      resetSelectedAddress();
      message.success(response.data.messageEn);
    } catch (e) {
      message.error(
        e.response.data.messageEn ||
          "Something went wrong please refresh and try again"
      );
    }
  };
  const handleAddAddressCancel = () => {
    setIsAddAddressOpen(false);
    resetSelectedAddress();
  };
  const resetSelectedAddress = () => {
    setSelectedAddress({
      formatted_address: "",
      lat: center?.lat,
      lng: center?.lng,
      city: "",
      district: "",
      _id: "",
    });
  };
  //#region map
  const onLoad = useCallback(function callback(map) {
    // const bounds = new window.google.maps.LatLngBounds({
    //   lat: parseFloat(SelectedAddress.lat),
    //   lng: parseFloat(SelectedAddress.lng),
    // });
    // const bounds = new window.google.maps.LatLngBounds({
    //   north: SelectedAddress.lat + 1,
    //   south: SelectedAddress.lat - 1,
    //   east: SelectedAddress.lng + 1,
    //   west: SelectedAddress.lng - 1,
    //   lat: parseFloat(SelectedAddress.lat),
    //   lng: parseFloat(SelectedAddress.lng),
    // });
    // map.fitBounds(bounds);
    setMap(map);
    mapRef.current = map;
  }, []);

  const handlePlaceChanged = () => {
    const Places = inputRef.current?.getPlaces() || [];
    console.log("Places", Places);
    // if (Places?.length > 0) {
    setSelectedAddress((prev) => ({
      ...prev,
      formatted_address: Places[0]?.formatted_address || "",
      lat: Places[0]?.geometry?.location?.lat() || center.lat,
      lng: Places[0]?.geometry?.location?.lng() || center.lng,
    }));
    map?.panTo({
      lat: Places[0]?.geometry?.location?.lat() || center.lat,
      lng: Places[0]?.geometry?.location?.lng() || center.lng,
    });

    //console.log(inputRef.current?.getPlaces());
  };
  function getLocationAddress({ updateAddress }) {
    geocode(RequestType.LATLNG, `${SelectedAddress.lat},${SelectedAddress.lng}`)
      .then(({ results }) => {
        //console.log("results ", results);
        const address = results[0].formatted_address;
        console.log("address ", address);
        const { city, state, country } = results[0].address_components.reduce(
          (acc, component) => {
            if (component.types.includes("locality"))
              acc.city = component.long_name;
            else if (component.types.includes("administrative_area_level_1"))
              acc.state = component.long_name;
            else if (component.types.includes("country"))
              acc.country = component.long_name;
            return acc;
          },
          {}
        );
        setSelectedAddress((prev) => ({
          ...prev,
          formatted_address: updateAddress ? address : prev.formatted_address,
          city: city,
          // district: country,
        }));
      })
      .catch(console.error);
  }
  const renderMap = (
    <>
      <LoadScript googleMapsApiKey={mapKey} libraries={["places"]}>
        <StandaloneSearchBox
          onLoad={(ref) => (inputRef.current = ref)}
          onPlacesChanged={handlePlaceChanged}
        >
          <Row gutter={[10, 10]} style={{ marginBottom: "10px" }}>
            <Col span={width > 767 ? 6 : 12}>
              <Input
                id="city"
                contentEditable={false}
                aria-describedby="city-help"
                placeholder="City"
                name="city"
                className="profileInput"
                style={{ width: "100%" }}
                value={SelectedAddress?.city}
                onChange={(e) => {
                  setSelectedAddress((prev) => ({
                    ...prev,
                    city: e.target.value,
                    formatted_address: e.target.value,
                  }));
                  //getLocationAddress();
                }}
              />
            </Col>
            <Col span={width > 767 ? 6 : 12}>
              <Input
                id="district"
                contentEditable={false}
                aria-describedby="district-help"
                placeholder="District"
                name="district"
                className="profileInput"
                style={{ width: "100%" }}
                value={SelectedAddress?.district}
                onChange={(e) => {
                  setSelectedAddress((prev) => ({
                    ...prev,
                    district: e.target.value,
                  }));
                }}
              />
            </Col>
            <Col span={width > 767 ? 12 : 24}>
              <Input
                prefix={
                  <HiOutlineLocationMarker
                    style={{ fontSize: "25px", color: "#c9c9c9" }}
                  />
                }
                className="profileInput"
                placeholder="Full Address"
                size="small"
                style={{ width: "100%" }}
                value={SelectedAddress.full_address}
                onChange={(e) => {
                  setSelectedAddress((prev) => ({
                    ...prev,
                    full_address: e.target.value,
                  }));
                }}
              />
            </Col>
          </Row>
        </StandaloneSearchBox>
        <GoogleMap
          mapContainerStyle={containerStyle}
          onLoad={onLoad}
          zoom={10}
          center={{
            lat: parseFloat(SelectedAddress?.lat),
            lng: parseFloat(SelectedAddress?.lng),
          }}
          onClick={(e) => {
            setSelectedAddress((prev) => ({
              ...prev,
              lat: e.latLng.lat(),
              lng: e.latLng.lng(),
            }));
          }}
          //onUnmount={onUnmount}
        >
          <Marker
            draggable={true}
            onDrag={(e) => {
              setSelectedAddress((prev) => ({
                ...prev,
                lat: e.latLng.lat(),
                lng: e.latLng.lng(),
              }));
              getLocationAddress({ updateAddress: true });
            }}
            position={{
              lat: +(SelectedAddress?.lat || center?.lat),
              lng: +(SelectedAddress?.lng || center?.lng),
            }}
          />
          <></>
        </GoogleMap>
      </LoadScript>
    </>
  );
  //#endregion map
  return (
    <>
      <Row cols={24}>
        {addresses?.length === 0 && (
          <h4 style={{ marginTop: "5px" }}>No address yet.</h4>
        )}
        {addresses.map((address, i) => {
          return (
            <Col key={i} span={24} style={{ marginBottom: "20px" }}>
              <div className="profileInputComp">
                <p className="profileLable">Address {i + 1}:</p>
                {SelectedAddress?._id === address?._id ? (
                  <>
                    {renderMap}
                    <ButtonsFooter
                      submitLabel="Edit"
                      onCancel={resetSelectedAddress}
                      onSubmit={handleEditAddressOk}
                    />
                  </>
                ) : (
                  <Controller
                    name={`address-${i}`}
                    control={control1}
                    render={({ field }) => (
                      <Input
                        readOnly
                        prefix={
                          <HiOutlineLocationMarker
                            style={{ fontSize: "25px", color: "#c9c9c9" }}
                          />
                        }
                        value={`City: ${address.city}, ${
                          address?.district
                            ? `District: ${address?.district}`
                            : ""
                        }, FullAddress: ${address.fullAddress}`}
                        className="profileInput"
                        placeholder="your Address"
                        suffix={
                          <>
                            <BiEditAlt
                              style={{
                                fontSize: 25,
                                cursor: "pointer",
                                color: "#ACAAB6",
                              }}
                              onClick={() => {
                                setSelectedAddress({
                                  formatted_address: address?.fullAddress,
                                  full_address: address?.fullAddress,
                                  lat: address?.lat,
                                  lng: address?.long,
                                  city: address?.city,
                                  district: address?.district,
                                  _id: address?._id,
                                });
                              }}
                            />

                            <Popconfirm
                              title="Delete this location"
                              description="Are you sure to delete this location?"
                              onConfirm={() => confirmDelete(address._id)}
                              // onCancel={cancel}
                              okText="Yes"
                              cancelText="No"
                            >
                              <PiTrashSimpleLight
                                style={{
                                  fontSize: 25,
                                  cursor: "pointer",
                                  color: "#ec2025",
                                }}
                                // onClick={() => editAddress(address)}
                              />
                            </Popconfirm>
                          </>
                        }
                      />
                    )}
                  />
                )}
                {/* <p style={{ color: "red" }}>{errors.fullName?.message}</p> */}
              </div>
            </Col>
          );
        })}
        <Col span={24} style={{ marginTop: "auto" }}>
          <Button
            className="infoBtn white-btn hover-bg-red btn-height"
            style={{ width: "100%" }}
            onClick={() => {
              reset1({
                city: "",
                district: "",
                fullAddress: "",
              });
              setIsAddAddressOpen(true);
            }}
          >
            Add a New Address
          </Button>
        </Col>
      </Row>

      {/*******add address Modal***** */}
      <Modal
        width={width > 767 ? "50vw" : "90vw"}
        bodyStyle={{
          padding: 0,
          border: "none",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        closable={false}
        open={isAddAddressOpen}
        onCancel={handleAddAddressCancel}
        footer={null}
      >
        <div
          style={{
            padding: "1em",
            backgroundColor: "rgba(236, 32, 37, 1)",
            borderRadius: "8px",
            color: "white",
            fontWeight: "bold",
            width: "100%",
            boxSizing: "border-box",
            fontSize: "18px",
          }}
        >
          Add New Address
        </div>
        <div
          className="paymentForm"
          style={{
            padding: "1em",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Row>
            <Col span={24}>{renderMap}</Col>
            <Col span={24}>
              <ButtonsFooter
                submitLabel="Add"
                onCancel={handleAddAddressCancel}
                onSubmit={addAddressSubmit}
              />
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
}

export default Address;
