import React, { useState, useEffect, useContext } from "react";
import { Row, Col, ColorPicker, Button, Skeleton } from "antd";
import { getImgVidUrl, getPath, getStatusColor } from "../../lib/lib";
import { useNavigate } from "react-router-dom";
import { appContext } from "../../Context/appContext";
import { Url } from "../../Apis";
import StarRating from "../../components/StarRateComp/StarRateComp";

function Orders({ width, setTabInfo }) {
  const {
    allOrders,
    oneOrder,
    setOneOrder,
    oneOrderLoading,
    getOneOrder,
    userDetails,
    priceDetails,
    AllCategories,
    shippingPrice,
  } = useContext(appContext);
  const [SelectedOrder, setSelectedOrder] = useState({});
  //console.log("SelectedOrder ", SelectedOrder);
  let pathName = window.location.pathname;
  const navigate = useNavigate();

  //#region useEffect
  useEffect(() => {
    if (pathName !== "/my-profile/order") {
      setSelectedOrder({});
      setOneOrder({});
    }
  }, [pathName]);
  useEffect(() => {
    if (SelectedOrder?._id) {
      getOneOrder(SelectedOrder?._id);
    }
  }, [SelectedOrder?._id]);
  useEffect(() => {
    if (oneOrder?._id) {
      setTabInfo((prev) => ({
        ...prev,
        infoLabel: "Order " + oneOrder?.orderNumber + " Info:",
      }));
    } else {
      setTabInfo((prev) => ({
        ...prev,
        infoLabel: "My Orders Info:",
      }));
    }
  }, [oneOrder]);

  useEffect(() => {
    return () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };
  }, []);
  //#endregion useEffect

  const onClickMoreInfo = (order) => {
    setSelectedOrder(order);
    navigate("/my-profile/order");
  };
  const loadingOrderSkelton = (
    <>
      <Row cols={24} gutter={[10, 10]}>
        <Col span={12} style={{ borderRight: "1px solid #EC2025" }}>
          <Row cols={24} gutter={[10, 10]}>
            {[1, 2]?.map((i) => {
              return (
                <Col
                  key={i}
                  span={24}
                  style={{
                    borderBottom: "1px solid #EC2025",
                    marginTop: "10px",
                    padding: "0px 30px",
                  }}
                >
                  <Row cols={24} gutter={[10, 10]}>
                    <Col span={24}>
                      <Row cols={24} gutter={[10, 10]}>
                        <Col span={6}>
                          <Skeleton.Image active />
                        </Col>
                        <Col span={12}>
                          <Skeleton active />
                        </Col>
                        <Col span={6}>
                          <small
                            style={{
                              fontStyle: "italic",
                              color: "#EC2025",
                              fontWeight: "bold",
                            }}
                          >
                            Product Info
                          </small>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <small>Your Specifications:</small>
                    </Col>
                    <Col span={24}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Skeleton active />
                      </div>
                    </Col>
                  </Row>
                </Col>
              );
            })}
          </Row>
        </Col>
        <Col span={12}>Installment Info:</Col>
      </Row>
    </>
  );
  const renderOrderBigView = () => {
    return (
      <>
        {oneOrderLoading ? (
          loadingOrderSkelton
        ) : (
          <Row cols={24} gutter={[10, 10]}>
            <Col span={12} style={{ borderRight: "1px solid #EC2025" }}>
              <Row cols={24} gutter={[10, 10]}>
                {oneOrder?.orderDetails?.map((order, i) => {
                  let detail = order?.product?.details?.find(
                    (ele) => Number(ele?.price) === Number(order?.price)
                  );
                  let category =
                    AllCategories?.find(
                      (ele) => ele?._id === order?.product?.category
                    )?.englishName || "";
                  let url = `${category ? "/" + getPath(category) : ""}/${
                    order?.product?.URL
                  }`;
                  console.log("order ", order);
                  return (
                    <Col
                      key={i}
                      span={24}
                      style={{
                        borderBottom: "1px solid #EC2025",
                        marginTop: "10px",
                        padding: "0px 0px 0px 35px",
                      }}
                    >
                      <Row cols={24} gutter={[10, 10]}>
                        <Col span={24}>
                          <Row cols={24} gutter={[10, 10]}>
                            <Col span={6}>
                              <img
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  borderRadius: "10px",
                                  cursor: "pointer",
                                }}
                                src={getImgVidUrl(
                                  order?.product?.featuredImage ||
                                    order?.product?.productImage[0]
                                )}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null; // prevents looping
                                  currentTarget.src = "/img_placeholder.png";
                                }}
                                onClick={() => navigate(url)}
                              />
                            </Col>
                            <Col span={12}>
                              <Row cols={24} gutter={[10, 10]}>
                                <Col span={24}>
                                  <small>Product {i + 1}</small>
                                </Col>
                                <Col span={24}>
                                  <h2
                                    onClick={() => navigate(url)}
                                    style={{
                                      color: "#EC2025",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {order?.product?.englishName}
                                  </h2>
                                </Col>
                                <Col span={24}>
                                  <Row gutter={[5, 5]}>
                                    <Col span={12}>
                                      <StarRating
                                        totalStars={5}
                                        initialRating={order?.product?.avgRate}
                                        productId={order?.product?._id}
                                        size={width > 768 ? 20 : 15}
                                      />
                                    </Col>
                                    <Col span={12}>
                                      <small>
                                        {order?.product?.avgRate}{" "}
                                        {`(${order?.product?.ratesCount})`}
                                      </small>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Col>
                            <Col span={6}>
                              <small
                                style={{
                                  fontStyle: "italic",
                                  color: "#EC2025",
                                  fontWeight: "bold",
                                }}
                              >
                                Product Info
                              </small>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row gutter={[10, 10]}>
                            <Col span={14}></Col>
                            <Col span={5}>
                              <small>Product Price:</small>
                            </Col>
                            <Col span={5}>
                              <small style={{ fontWeight: "bold" }}>
                                ${order?.price}
                              </small>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <small>Your Specifications:</small>
                        </Col>
                        <Col span={24}>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {Object.keys(detail || {})?.map((key, i) => {
                              if (key !== "quantity" && key !== "price")
                                if (key?.toLowerCase() === "color") {
                                  return (
                                    <span
                                      className="specifications"
                                      key={i}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        width: "65px",
                                      }}
                                    >
                                      {key}:{" "}
                                      <ColorPicker
                                        style={{ marginLeft: "3px" }}
                                        size="small"
                                        defaultValue={"#" + detail[key]}
                                      />
                                    </span>
                                  );
                                } else {
                                  return (
                                    <span className="specifications" key={i}>
                                      {key}: {detail[key]}
                                    </span>
                                  );
                                }
                            })}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  );
                })}
              </Row>
            </Col>
            <Col span={12}>Installment Info:</Col>
          </Row>
        )}
      </>
    );
  };
  const renderOrderSmallView = () => {
    return (
      <>
        {oneOrderLoading ? (
          <>
            <Row gutter={[15, 15]}>
              {[1, 2].map((ele) => (
                <Col span={24}>
                  <Row key={ele} gutter={[15, 15]}>
                    <Col span={6}>
                      <Skeleton.Image active />
                    </Col>
                    <Col span={18}>
                      <Skeleton active />
                    </Col>
                  </Row>
                </Col>
              ))}
            </Row>
          </>
        ) : (
          <>
            <Row gutter={[5, 5]}>
              <Col span={24}>
                <h3 style={{ color: "#EC2025" }}>
                  ID {SelectedOrder?.orderNumber}
                </h3>
              </Col>
              <Col span={24}>
                <small>{SelectedOrder?.chosenDate}</small>
              </Col>
            </Row>
            <Row cols={24}>
              {oneOrder?.orderDetails?.map((order, i) => {
                let detail = order?.product?.details?.find(
                  (ele) => Number(ele?.price) === Number(order?.price)
                );
                let category =
                  AllCategories?.find(
                    (ele) => ele?._id === order?.product?.category
                  )?.englishName || "";
                let url = `${category ? "/" + getPath(category) : ""}/${
                  order?.product?.URL
                }`;
                return (
                  <Col
                    key={i}
                    span={24}
                    style={{
                      borderBottom: "1px solid #ACAAB6",
                      background: "#EBEBEB",
                    }}
                  >
                    <Row
                      cols={24}
                      gutter={[10, 10]}
                      style={{ padding: "10px 0px" }}
                    >
                      <Col span={6}>
                        <img
                          style={{
                            height: "80px",
                            width: "100%",
                            borderRadius: "10px",
                            cursor: "pointer",
                          }}
                          src={getImgVidUrl(
                            order?.product?.featuredImage ||
                              order?.product?.productImage[0]
                          )}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = "/img_placeholder.png";
                          }}
                          onClick={() => navigate(url)}
                        />
                      </Col>
                      <Col span={12}>
                        <Row cols={24} gutter={[10, 10]}>
                          <Col span={24}>
                            <h2
                              onClick={() => navigate(url)}
                              style={{
                                color: "#EC2025",
                                cursor: "pointer",
                              }}
                            >
                              {order?.product?.englishName}
                            </h2>
                          </Col>
                          <Col span={24}>
                            <small>Your Specifications:</small>
                          </Col>
                          <Col span={24}>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {Object.keys(detail || {})?.map((key, i) => {
                                if (key !== "quantity" && key !== "price")
                                  if (key?.toLowerCase() === "color") {
                                    return (
                                      <span
                                        className="specifications"
                                        key={i}
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          width: "65px",
                                        }}
                                      >
                                        {key}:{" "}
                                        <ColorPicker
                                          style={{ marginLeft: "3px" }}
                                          size="small"
                                          defaultValue={"#" + detail[key]}
                                        />
                                      </span>
                                    );
                                  } else {
                                    return (
                                      <span className="specifications" key={i}>
                                        {key}: {detail[key]}
                                      </span>
                                    );
                                  }
                              })}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={6}>
                        <h3>${order?.price}</h3>
                      </Col>
                    </Row>
                  </Col>
                );
              })}
            </Row>
            <Row gutter={[10, 10]}>
              <Col span={24}>Installment Info:</Col>
            </Row>
          </>
        )}
      </>
    );
  };
  return (
    <>
      <>
        {width > 767 ? (
          <div style={{ width: "99%" }}>
            {!SelectedOrder?._id ? (
              <Row cols={24} style={{ margin: "0px 30px" }}>
                <Col span={24}>
                  <Row
                    cols={24}
                    style={{
                      borderBottom: "1px solid #EC2025",
                      padding: "10px",
                    }}
                  >
                    {[
                      { label: "ID", span: 3 },
                      { label: "Date", span: 4 },
                      { label: "Status", span: 4 },
                      { label: "Price", span: 4 },
                      { label: "Payment Method", span: 6 },
                      { label: "More Info", span: 3 },
                    ]?.map((val, i) => (
                      <Col key={i} span={val?.span}>
                        <label
                          style={{
                            color: "#ACAAB6",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          {val?.label}
                        </label>
                      </Col>
                    ))}
                  </Row>
                </Col>
                <Col span={24}>
                  {allOrders?.length === 0 && (
                    <h4 style={{ marginTop: "5px" }}>No orders yet.</h4>
                  )}
                  {allOrders?.map((order, i) => (
                    <Row
                      key={i}
                      cols={24}
                      style={{
                        borderBottom: "1px solid #EC2025",
                        padding: "10px",
                      }}
                    >
                      {[
                        { value: order?.orderNumber, span: 3 },
                        { value: order.chosenDate, span: 4 },
                        {
                          value: order.status,
                          span: 4,
                          style: { color: getStatusColor(order?.status) },
                        },
                        { value: order.salePrice + " $", span: 4 },
                        { value: order?.paymentMethod, span: 6 },
                        {
                          value: "More Info",
                          span: 3,
                          style: { color: "#EC2025", cursor: "pointer" },
                          onClick: () => onClickMoreInfo(order),
                        },
                      ]?.map((item, j) => (
                        <Col key={j} span={item?.span}>
                          <label
                            style={{
                              fontSize: "14px",
                              fontWeight: "bold",
                              ...(item?.style || {}),
                            }}
                            onClick={item?.onClick ? item?.onClick : () => {}}
                          >
                            {item?.value}
                          </label>
                        </Col>
                      ))}
                    </Row>
                  ))}
                </Col>
              </Row>
            ) : (
              <>{renderOrderBigView()}</>
            )}
          </div>
        ) : (
          <>
            {!SelectedOrder?._id ? (
              <Row cols={24}>
                <Col span={24}>
                  {allOrders?.length === 0 && (
                    <h4 style={{ marginTop: "5px" }}>No orders yet.</h4>
                  )}
                  {allOrders?.map((order, i) => (
                    <Row
                      key={i}
                      cols={24}
                      style={{
                        borderBottom: "1px solid #ACAAB6",
                        padding: "10px",
                      }}
                    >
                      <Col span={8}>
                        <h3
                          onClick={() => onClickMoreInfo(order)}
                          style={{ color: "#EC2025", cursor: "pointer" }}
                        >
                          ID &nbsp; {order?.orderNumber}
                        </h3>
                        <small>{order.chosenDate}</small>
                      </Col>
                      <Col span={12}>
                        {order?.orderDetails?.map((data, i) => {
                          //console.log("data ", data);
                          let category =
                            AllCategories?.find(
                              (ele) => ele?._id === data?.product?.category
                            )?.englishName || "";
                          let url = `${
                            category ? "/" + getPath(category) : ""
                          }/${getPath(data?.product?.englishName)}`;
                          return (
                            <span
                              key={i}
                              style={{ cursor: "pointer" }}
                              onClick={() => navigate(url)}
                            >
                              {data?.product?.englishName}

                              {i !== order?.orderDetails?.length - 1 ? (
                                <> {" , "} </>
                              ) : null}
                            </span>
                          );
                        })}
                      </Col>
                      <Col span={4}>
                        <h3>$ &nbsp; {order.salePrice}</h3>
                        <small style={{ color: getStatusColor(order?.status) }}>
                          {order.status}
                        </small>
                      </Col>
                    </Row>
                  ))}
                </Col>
              </Row>
            ) : (
              <>{renderOrderSmallView()}</>
            )}
          </>
        )}
      </>
    </>
  );
}

export default Orders;
