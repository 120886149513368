import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import Axios from "axios";
import { AppContextProvider } from "./Context/appContext";

//const token = `Bearer ${localStorage.getItem("token")}`;
Axios.defaults.baseURL = "https://api.beirutmart.com";
//Axios.defaults.baseURL = (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") ? 'http://localhost:8000' : 'https://api-new.utrac.store'
Axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem(
  "token"
)}`;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <AppContextProvider>
      <App />
    </AppContextProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(// console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
