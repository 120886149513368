import React, { useContext, useEffect, useState } from "react";
import "./mobileNavBar.css";
import "../../Pages/Landing/Landing.css";
import { appContext } from "../../Context/appContext";
import { GrAppsRounded } from "react-icons/gr";
import { Skeleton, message, Button, Row, Col, Divider, Empty } from "antd";
import { Url, searchApi, searchProductsApi } from "../../Apis";
import { useNavigate, useParams } from "react-router-dom";
import { CiSearch } from "react-icons/ci";
import {
  Loading3QuartersOutlined,
  LogoutOutlined,
  LoginOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { SideNav } from "react-swiper-sidenav";
import { Link } from "react-router-dom";
import {
  getImgVidUrl,
  getPath,
  handleNavigate,
  isCategory,
  filterCategories,
} from "../../lib/lib";
import { BsEye } from "react-icons/bs";
import ImageComponent from "../Image";

function MobileNavBar() {
  const {
    fullName,
    imgUrl,
    loadingProfile,
    categories,
    userDetails,
    AllCategories = [],
    showDrawer,
    setCategoryId,
    setOneProduct,
    root,
    AllPropertyListing,
    AllAutomotive,
  } = useContext(appContext);
  //console.log(imgUrl);
  const url = window.location.pathname;
  const catName = url?.split("/")[1].trim()?.replaceAll("-", /\s+/g);
  console.log("url ", url);
  const category = categories?.filter((cat) => cat?.englishName === catName);
  // eslint-disable-next-line no-unused-vars
  const [subCategories, setSubCategories] = useState([]);
  const [toggleWidth, setToggleWidth] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [products, setProducts] = useState([]);
  const [searchData, setSearchData] = useState({});
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchBy, setSearchBy] = useState("");
  const [searchText, setSearchText] = useState("");
  const [loadingProduct, setloadingProduct] = useState(false);
  const [openSearch, setopenSearch] = useState(false);

  // eslint-disable-next-line no-unused-vars

  const token = localStorage.getItem("token");
  const { categoryName, subCategoryName, productName } = useParams();
  root.style.setProperty("--noscroll", openSearch ? "hidden" : "");
  const navigate = useNavigate();
  const SubCategoryData =
    isCategory(window.location.pathname.split("/"), AllCategories)?.data || {};
  const [width, setWidth] = useState(window.innerWidth);
  const [NavItems, setNavItems] = useState({});

  function handelItems(category, lasturi) {
    let items = {};
    //items["name"] =  category?.englishName;
    items["name"] = category?.icon ? (
      <div style={{ display: "flex", alignItems: "center" }}>
        <ImageComponent
          image={category?.icon}
          style={{ width: "20px", height: "20px", marginRight: "3px" }}
        />
        {category?.englishName}
      </div>
    ) : (
      category?.englishName
    );
    let subCategories = filterCategories(category?.subCategory || []);
    items["isSubCategoryEmpty"] = subCategories?.length === 0;
    items["uri"] = lasturi
      ? lasturi
      : "" +
        `/${category?.uri ? category?.uri + "/" : ""}` +
        getPath(category?.englishName);
    items["childrenItems"] = [];
    if (subCategories?.length === 0) {
      const path = items["uri"];
      items["childrenItems"].push({
        itemProps: {
          to: path,
          label: category?.englishName,
          iconUrl: category?.icon,
          catId: category?._id,
        },
      });
    } else {
      subCategories?.forEach((subcat) => {
        const path = items["uri"] + "/" + getPath(subcat?.englishName);
        if (filterCategories(subcat?.subCategory || [])?.length === 0) {
          items["childrenItems"].push({
            itemProps: {
              to: path,
              label: subcat?.englishName,
              catId: subcat?._id,
            },
          });
        } else {
          items["childrenItems"].push({
            uri: path,
            name: subcat?.englishName,
            iconUrl: subcat?.icon,
            childrenItems: filterCategories(subcat?.subCategory || [])?.map(
              (val) => handelItems(val, path + `/${getPath(val?.englishName)}`)
            ),
          });
          // console.log("cc ", items);
        }
      });
    }
    if (items?.isSubCategoryEmpty) items = items?.childrenItems[0];
    return items;
  }
  useEffect(() => {
    let realEstateCatIcon =
      AllCategories?.find((ele) => ele?.isRealEstate)?.icon || undefined;
    let AutomotiveCatIcon =
      AllCategories?.find((ele) => ele?.isAutomotive)?.icon || undefined;
    let _allCategories = filterCategories([...AllCategories]);
    setNavItems({
      name: "Categories",
      childrenItems: [
        AllPropertyListing?.filter((val) => val?.isActive)?.length > 0
          ? {
              itemProps: {
                to: "/real-estate",
                label: "Real Estate",
                iconUrl: realEstateCatIcon || "",
              },
            }
          : undefined,
        AllAutomotive?.filter((val) => val?.isActive)?.length > 0
          ? {
              itemProps: {
                to: "/automotive",
                label: "Automotive",
                iconUrl: AutomotiveCatIcon || "",
              },
            }
          : undefined,
        ..._allCategories
          ?.filter(
            (val) =>
              filterCategories(val?.subCategory || [])?.length !== 0 ||
              val?.products?.length !== 0
          )
          ?.filter(
            (ele) =>
              !isCategory([getPath(ele?.englishName)], _allCategories)
                ?.isSubCategory
          )
          ?.map((val) => handelItems(val)),
      ].filter((ele) => ele !== undefined),
    });
  }, [AllCategories, AllPropertyListing, AllAutomotive]);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  const productHover = async (id, type, name) => {
    // console.log(id);
    if (type === "cat") {
      try {
        setSearchBy(name);
        setloadingProduct(true);
        const response = await searchProductsApi(searchText, `cat=${id}`);
        if (response) {
          setProducts(response?.data?.data);
          setloadingProduct(false);
        }
      } catch (error) {
        setloadingProduct(false);
        message.error(error.response?.data?.messageEn);
      }
    } else if (type === "brand") {
      try {
        setSearchBy(name);
        setloadingProduct(true);
        const response = await searchProductsApi(searchText, `brand=${id}`);
        if (response) {
          setProducts(response?.data?.data);
          setloadingProduct(false);
        }
      } catch (error) {
        setloadingProduct(false);
        message.error(error.response?.data?.messageEn);
      }
    } else {
      try {
        setSearchBy(name);
        setloadingProduct(true);
        const response = await searchProductsApi(searchText, `tag=${id}`);
        if (response) {
          setProducts(response?.data?.data);
          setloadingProduct(false);
        }
      } catch (error) {
        setloadingProduct(false);
        message.error(error.response?.data?.messageEn);
      }
    }
  };
  const handleSearch = async (search) => {
    setopenSearch(true);
    setloadingProduct(true);
    setSearchLoading(true);
    console.log(search);
    setSearchText(search);
    if (search?.length > 1) {
      try {
        const response = await searchApi(search);
        if (response.data) {
          setSearchData(response.data?.data);
          setSearchBy(response.data?.data?.category[0]?.englishName);
          try {
            setloadingProduct(true);
            if (response.data?.data?.category[0]?._id) {
              const res = await searchProductsApi(
                search,
                `cat=${response.data?.data?.category[0]?._id}`
              );
              if (res) {
                setProducts(res?.data?.data);
              } else {
                setProducts([]);
              }
            }
          } catch (error) {
            setloadingProduct(false);
            message.error(error.response?.data?.messageEn);
          }
          setSearchLoading(false);
        }
      } catch (error) {
        setSearchLoading(false);
        message.error(error?.response?.data.message);
      }
      setSearchLoading(false);
      setloadingProduct(false);
    }
  };
  const showSubCategoriesFunc = () => {
    if (url !== "/" && category?.length > 0 && url.split("/").length === 3) {
      // const category = categories?.filter((cat)=>cat?.englishName==categoryName)
      // console.log("subCat", category);
      setSubCategories(category[0]?.subCategory);
    }
  };
  useEffect(() => {
    if (width > 767) {
      setToggleWidth(false);
    } else {
      setToggleWidth(true);
    }
  }, [width]);

  useEffect(() => {
    if (toggleWidth) {
      showSubCategoriesFunc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleWidth]);

  const onClose = () => setOpenDrawer(false);
  const CustomLink = ({ to, label, iconUrl, catId }) => {
    //console.log("catId ", catId);
    return (
      <Link onClick={() => setCategoryId(catId)} to={to}>
        {iconUrl ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <ImageComponent
              image={iconUrl}
              style={{ width: "20px", height: "20px", marginRight: "3px" }}
            />
            {label}
          </div>
        ) : (
          label
        )}
      </Link>
    );
  };
  return (
    <div className="mobileBar">
      <SideNav
        navItems={NavItems}
        onClose={onClose}
        open={openDrawer}
        renderLink={CustomLink}
        zIndex={1001}
      >
        <Row align="middle" className="border-item">
          <Col span={token ? 20 : 16}>
            <h2 className="sidebar-header">
              {token && userDetails ? `Hi, ${fullName}` : ""}
              {!token && (
                <CloseOutlined onClick={onClose} className={"log-icon"} />
              )}
            </h2>
          </Col>
          <Col span={token ? 4 : 8}>
            <div className="header-container">
              {!token && <label>Log in</label>}
              {token ? (
                <LogoutOutlined
                  onClick={() => {
                    localStorage.removeItem("token");
                    localStorage.removeItem("userData");
                    navigate("/login");
                    window.location.reload();
                  }}
                />
              ) : (
                <LoginOutlined onClick={() => navigate("/login")} />
              )}
            </div>
          </Col>
        </Row>
      </SideNav>
      <div className="landing-main-mobile">
        <div
          className="header-div-mobile"
          style={{ cursor: "pointer" }}
          // onClick={() => navigate("/my-profile")}
        >
          <GrAppsRounded
            className="text-4xl text-[#231F20]"
            onClick={() => setOpenDrawer(true)}
          />
          <img
            style={{ marginTop: 0 }}
            src="/Logo Landscape.svg"
            className="main-logo-img"
            alt=""
            onClick={() => navigate("/")}
          />
          {loadingProfile ? (
            <Skeleton.Avatar size={80} active />
          ) : imgUrl !== "" && imgUrl !== undefined ? (
            <img
              style={{ marginTop: 0, borderRadius: "50%" }}
              src={getImgVidUrl(imgUrl)}
              width={50}
              loading="lazy"
              className="man-img"
              onClick={() => navigate("/my-profile")}
              alt=""
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = "/user.png";
              }}
            />
          ) : (
            <img
              style={{ marginTop: 0 }}
              src="/anonymous-user.svg"
              width={50}
              loading="lazy"
              className="man-img"
              onClick={() =>
                token ? navigate("/my-profile") : navigate("/login")
              }
              alt=""
            />
          )}
        </div>
        <div className="userName">
          {url !== "/" ? null : (
            <>
              {token && userDetails ? (
                loadingProfile ? (
                  <Skeleton.Button
                    size={20}
                    block
                    style={{ width: 200 }}
                    active
                  />
                ) : (
                  <p
                    style={{
                      fontSize: "24px",
                      fontStyle: "italic",
                      color: "rgba(236, 32, 37, 1)",
                    }}
                  >
                    HI, {fullName}
                  </p>
                )
              ) : null}
              <p style={{ fontWeight: "700" }}>
                What Are You Looking For Today?
              </p>
            </>
          )}
        </div>
        {url === "/" ||
        url === "/home" ||
        url === `/${categoryName}` ||
        url === `/${getPath(SubCategoryData?.englishName)}` ||
        url === `/${categoryName}/${getPath(SubCategoryData?.englishName)}` ? (
          // url ===
          //   `/${categoryName}/${getPath(
          //     SubCategoryData?.englishName
          //   )}/${productName}` ||
          // url === `/${categoryName}/${productName}`
          <>
            <p style={{ marginTop: "8px", width: "100%", marginBottom: "8px" }}>
              Search Here:
            </p>
            <div style={{ position: "relative", width: "100%" }}>
              <div className="search-div-mobile">
                <input
                  type="search"
                  className="search-mobile"
                  placeholder="Ex: Headphones"
                  onChange={(e) => {
                    e.target.value === ""
                      ? setSearchData({})
                      : handleSearch(e.target.value);
                    e.target.value === "" && setopenSearch(false);
                  }}
                />
                <CiSearch
                  style={{ position: "absolute", top: "21%", left: "10px" }}
                  size={27}
                  color="#949398"
                />
                {url === "/" ||
                url === "/home" ||
                url ===
                  `/${categoryName}/${subCategoryName}/${productName}` ? null : (
                  <img
                    alt=""
                    style={{
                      marginTop: 0,
                      // width: "fit-content",
                      height: "25px",
                      position: "absolute",
                      top: "25%",
                      right: "10px",
                      cursor: "pointer",
                    }}
                    src="/filter.png"
                    onClick={() => showDrawer()}
                  />
                )}
              </div>
              {Object.keys(searchData).length !== 0 && openSearch && (
                <>
                  <div
                    className="searchResults"
                    style={{
                      width: "95%",
                      top: 50,
                      maxHeight: 300,
                      overflow: "auto",
                    }}
                  >
                    {searchData?.category?.length +
                      searchData?.brands?.length +
                      searchData?.tags?.length ===
                    0 ? (
                      <>
                        <Empty
                          style={{ width: "100%" }}
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                        />
                      </>
                    ) : (
                      <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={10}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 10,
                            }}
                          >
                            {searchData?.category?.length > 0 && (
                              <div className="itemSearch">
                                <Divider
                                  type="horizontal"
                                  orientation="left"
                                  orientationMargin={0}
                                  style={{
                                    color: "#d8d8d8",
                                    borderColor: "#d8d8d8",
                                    borderBlock: "20px",
                                    margin: "5px 0px",
                                  }}
                                >
                                  Categories
                                </Divider>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 0,
                                    paddingLeft: 0,
                                  }}
                                >
                                  {searchLoading ? (
                                    [1, 2, 3].map((_) => (
                                      <span style={{ lineHeight: 2 }}>
                                        <Skeleton.Button
                                          style={{ width: "50%" }}
                                          active
                                          size={20}
                                          block
                                          shape="round"
                                        />
                                      </span>
                                    ))
                                  ) : searchData?.category?.length === 0 ? (
                                    <>No Result</>
                                  ) : (
                                    searchData?.category?.map((data) => (
                                      <span
                                        onClick={() =>
                                          productHover(
                                            data?._id,
                                            "cat",
                                            data?.englishName
                                          )
                                        }
                                        className="subItem"
                                        style={{
                                          margin: 0,
                                          lineHeight: 2,
                                          paddingInline: 5,
                                        }}
                                      >
                                        {data?.englishName}
                                      </span>
                                    ))
                                  )}
                                </div>
                              </div>
                            )}
                            {searchData?.brands?.length > 0 && (
                              <div className="itemSearch">
                                <Divider
                                  type="horizontal"
                                  orientation="left"
                                  orientationMargin={0}
                                  style={{
                                    color: "#d8d8d8",
                                    borderColor: "#d8d8d8",
                                    borderBlock: "20px",
                                    margin: "5px 0px",
                                  }}
                                >
                                  Brands
                                </Divider>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 0,
                                    paddingLeft: 0,
                                  }}
                                >
                                  {searchLoading ? (
                                    [1, 2, 3].map((_) => (
                                      <span style={{ lineHeight: 2 }}>
                                        <Skeleton.Button
                                          style={{ width: "50%" }}
                                          active
                                          size={20}
                                          block
                                          shape="round"
                                        />
                                      </span>
                                    ))
                                  ) : searchData?.brands?.length === 0 ? (
                                    <>No Result</>
                                  ) : (
                                    searchData?.brands?.map((data) => (
                                      <span
                                        onClick={() =>
                                          productHover(
                                            data?._id,
                                            "brand",
                                            data?.englishName
                                          )
                                        }
                                        className="subItem"
                                        style={{
                                          margin: 0,
                                          lineHeight: 2,
                                          paddingInline: 5,
                                        }}
                                      >
                                        {data?.englishName}
                                      </span>
                                    ))
                                  )}
                                </div>
                              </div>
                            )}
                            {searchData?.tags?.length > 0 && (
                              <div className="itemSearch">
                                <Divider
                                  type="horizontal"
                                  orientation="left"
                                  orientationMargin={0}
                                  style={{
                                    color: "#d8d8d8",
                                    borderColor: "#d8d8d8",
                                    borderBlock: "20px",
                                    margin: "5px 0px",
                                  }}
                                >
                                  Offers
                                </Divider>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 0,
                                    paddingLeft: 0,
                                  }}
                                >
                                  {searchLoading ? (
                                    [1, 2, 3].map((_) => (
                                      <span style={{ lineHeight: 2 }}>
                                        <Skeleton.Button
                                          style={{ width: "50%" }}
                                          active
                                          size={20}
                                          block
                                          shape="round"
                                        />
                                      </span>
                                    ))
                                  ) : searchData?.tags?.length === 0 ? (
                                    <>No Result</>
                                  ) : (
                                    searchData?.tags?.map((data) => (
                                      <span
                                        onClick={() =>
                                          productHover(
                                            data?._id,
                                            "tag",
                                            data?.englishName
                                          )
                                        }
                                        className="subItem"
                                        style={{
                                          margin: 0,
                                          lineHeight: 2,
                                          paddingInline: 5,
                                        }}
                                      >
                                        {data?.englishName}
                                      </span>
                                    ))
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </Col>
                        <Divider
                          orientationMargin={0}
                          orientation="center"
                          style={{
                            borderColor: "#d8d8d8",
                            height: "auto",
                            margin: "5px 0",
                          }}
                          type={width > 1466 ? "vertical" : "horizontal"}
                        />
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
                          <div className="allProducts" style={{ gap: 0 }}>
                            {searchBy !== "" && (
                              <p
                                className="searchBy"
                                style={{
                                  paddingInline: 0,
                                  marginBottom: "5px",
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: 5,
                                  alignItems: "center",
                                }}
                              >
                                Recommended Products for
                                {searchBy !== "" && (
                                  <span
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "bold",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {searchBy}
                                  </span>
                                )}
                              </p>
                            )}

                            <div
                              className="productList"
                              style={{ paddingInline: 0 }}
                            >
                              {loadingProduct
                                ? [1, 2, 3].map((_) => (
                                    <>
                                      <Row
                                        gutter={[10, 10]}
                                        style={{
                                          width: "100%",
                                          marginBottom: 10,
                                        }}
                                        align={"middle"}
                                        justify={"space-between"}
                                      >
                                        {/* <img src={getImgVidUrl(prod?.productImage)} alt="" /> */}
                                        <Col
                                          xs={6}
                                          sm={6}
                                          md={24}
                                          lg={12}
                                          xl={6}
                                          xxl={6}
                                        >
                                          <Skeleton.Image
                                            active
                                            style={{
                                              width:
                                                width > 575 ? "100px" : "100%",
                                              height: 100,
                                            }}
                                          />
                                        </Col>
                                        <Col
                                          xs={10}
                                          sm={10}
                                          md={24}
                                          lg={12}
                                          xl={10}
                                          xxl={10}
                                        >
                                          {/* <span > */}
                                          <Skeleton.Button
                                            style={{
                                              width: "50%",
                                              display: "flex",
                                            }}
                                            active
                                            size={20}
                                            block
                                            shape="square"
                                          />
                                          <Skeleton.Button
                                            style={{
                                              width: "100%",
                                              display: "flex",
                                            }}
                                            active
                                            size={20}
                                            block
                                            shape="square"
                                          />
                                          {/* </span> */}
                                        </Col>
                                        <Col
                                          xs={8}
                                          sm={8}
                                          md={24}
                                          lg={24}
                                          xl={8}
                                          xxl={8}
                                        >
                                          <Skeleton.Button
                                            style={{
                                              width: "100%",
                                              height: 50,
                                            }}
                                            active
                                            block
                                            shape="square"
                                          />
                                        </Col>
                                      </Row>
                                    </>
                                  ))
                                : products?.map((prod) => (
                                    <>
                                      <Row
                                        cols={24}
                                        gutter={[5, 5]}
                                        style={{ width: "100%" }}
                                        align={"middle"}
                                        justify={"space-between"}
                                      >
                                        <Col
                                          span={24}
                                          style={{ height: "10px" }}
                                        >
                                          <Divider
                                            type="horizontal"
                                            orientation="center"
                                            orientationMargin={0}
                                            style={{
                                              color: "#d8d8d8",
                                              borderColor: "#d8d8d8",
                                              height: "10px",
                                              margin: "8px 0px 0px 0px",
                                            }}
                                          />
                                        </Col>
                                        <Col
                                          span={6}
                                          style={{
                                            height: "70px",
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <div
                                            className="imageDev"
                                            onClick={() =>
                                              handleNavigate(prod, navigate)
                                            }
                                            style={{
                                              backgroundImage: `url(${getImgVidUrl(
                                                prod?.productImage
                                              )})`,
                                              width: 100,
                                              height: "100%",
                                              cursor: "pointer",
                                            }}
                                          ></div>
                                        </Col>
                                        <Col
                                          span={10}
                                          style={{
                                            height: "70px",
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <span
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                              width: "100%",
                                            }}
                                          >
                                            <h3
                                              style={{
                                                lineHeight: 2,
                                                whiteSpace: "nowrap",
                                                textOverflow: "ellipsis",
                                                overflow: "hidden",
                                                textTransform: "capitalize",
                                              }}
                                              className="name"
                                            >
                                              {prod?.englishName}
                                            </h3>
                                            <span className="des">
                                              {prod?.englishDescription}
                                            </span>
                                          </span>
                                        </Col>
                                        <Col
                                          span={8}
                                          style={{
                                            height: "70px",
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Button
                                            icon={<BsEye size={15} />}
                                            className="view-product hover-bg-red"
                                            style={{
                                              padding: "3px 8px",
                                              fontSize: "12px",
                                              height: "35px",
                                            }}
                                            onClick={() => {
                                              setOneProduct(prod);
                                              handleNavigate(prod, navigate);
                                              setopenSearch(false);
                                            }}
                                          >
                                            View Product
                                          </Button>
                                        </Col>
                                      </Row>
                                    </>
                                  ))}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    )}
                  </div>
                </>
              )}
            </div>
            {Object.keys(searchData).length !== 0 && openSearch && (
              <div
                className="overLaySearh"
                style={{ backgroundColor: "transparent", marginTop: 80 }}
                onClick={() => setopenSearch(false)}
              ></div>
            )}
          </>
        ) : null}
      </div>
    </div>
  );
}

export default MobileNavBar;
