import React, { useContext, useState, useEffect } from "react";
import { appContext } from "../../Context/appContext";
import "./mobileFooter.css";
import { useLocation, useNavigate } from "react-router-dom";
import { CiHeart } from "react-icons/ci";
import { GoHome } from "react-icons/go";
import { HiOutlineTicket } from "react-icons/hi";
import { PiShoppingCartSimple } from "react-icons/pi";
import { Badge } from "antd";
function MobileFooter() {
  const { cartList } = useContext(appContext);
  const location = useLocation();
  const navigate = useNavigate();
  const userData = localStorage.getItem("userData");
  let roleName = JSON.parse(userData)?.role?.name;
  const pathname = location.pathname;
  const [CartItemsQuantity, setCartItemsQuantity] = useState(0);

  useEffect(() => {
    if (cartList?.length > 0) {
      let sumQuantity = 0;
      cartList?.forEach((ele) => {
        sumQuantity += ele?.quantity;
      });
      setCartItemsQuantity(sumQuantity);
    } else {
      setCartItemsQuantity(0);
    }
  }, [cartList]);

  return (
    <>
      <div className="footer-div-mobile">
        {roleName === "user" ? (
          <HiOutlineTicket
            style={{ color: pathname === "/become-a-vendor" && "#EC2025" }}
            className="footer-icon"
            onClick={() => navigate("/become-a-vendor")}
          />
        ) : !roleName ? (
          <HiOutlineTicket
            style={{ color: pathname === "/signup" && "#EC2025" }}
            className="footer-icon"
            onClick={() => navigate("/signup")}
          />
        ) : null}
        <GoHome
          style={{ color: pathname === "/" && "#EC2025" }}
          className="footer-icon"
          onClick={() => navigate("/")}
        />
        <CiHeart
          style={{ color: pathname === "/my-wishList" && "#EC2025" }}
          className="footer-icon"
          onClick={() => navigate("/my-wishList")}
        />
        <Badge count={CartItemsQuantity} onClick={() => navigate("/my-cart")}>
          <PiShoppingCartSimple
            style={{ color: pathname === "/my-cart" && "#EC2025" }}
            className="footer-icon"
          />
        </Badge>
      </div>
    </>
  );
}

export default MobileFooter;
