import React, { useEffect, useState, useContext } from "react";
import NavBar from "../../components/navBar/navBar";
import MobileNavBar from "../../components/mobileNavBar/mobileNavBar";
import { Content } from "antd/es/layout/layout";
import {
  Dropdown,
  Skeleton,
  Drawer,
  Col,
  Row,
  Pagination,
  Button,
  Tooltip,
} from "antd";
import { BsFunnel } from "react-icons/bs";
import { DownOutlined } from "@ant-design/icons";
import BreadcrumbComp from "../../components/Breadcrumb/Breadcrumb";
import "./index.css";
import Card from "./Card";
import { appContext } from "../../Context/appContext";
import Filter from "./Filter";
//import Carousel from "better-react-carousel";
import { Footer } from "../../components/Footer/Footer";
import { chunk, getImgVidUrl } from "../../lib/lib";
import { CiSearch } from "react-icons/ci";
import { AiOutlineClose } from "react-icons/ai";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import Carousel from "better-react-carousel";
import "react-multi-carousel/lib/styles.css";
import { Segmented } from "antd";
import ImageComponent from "../../components/Image";
function Automotive() {
  //const navigate = useNavigate();
  const {
    setSort,
    getAutomotiveWithFilter,
    AutomotiveWithFilter,
    LoadingAutomotive,
    AutomotiveFilter,
    LoadingAutomotiveFilter,
    AllCategories,
  } = useContext(appContext);
  let AutomotiveCatImage =
    AllCategories?.find((ele) => ele?.isAutomotive)?.image || undefined;
  const automotiveFilterLocalStorage = localStorage.getItem("automotiveFilter");
  //console.log("FeaturesBusiness ", FeaturesBusiness);
  const [width, setWidth] = useState(window.innerWidth);
  const [open, setOpen] = useState(false);
  //const [sortedVal, setSortedVal] = useState("");
  const [CurrentPage, setCurrentPage] = useState(1);
  const [PageSize, setPageSize] = useState(9);
  const [pagesAdsData, setPagesAdsData] = useState([]);
  const [pagesNonAdsData, setPagesNonAdsData] = useState([]);
  const [NonAds, setNonAds] = useState([]);
  const [Ads, setAds] = useState([]);
  const [sortName, setSortName] = useState("name asc");
  const [searchInput, setSearchInput] = useState("");
  const [SelectedFeaturesBusiness, setSelectedFeaturesBusiness] = useState(
    JSON.parse(automotiveFilterLocalStorage)?.featuredBusinessIds?.length > 0 &&
      Array.isArray(
        JSON.parse(automotiveFilterLocalStorage)?.featuredBusinessIds
      )
      ? JSON.parse(automotiveFilterLocalStorage)?.featuredBusinessIds[0]
      : undefined
  );
  const [ValueSegmented, setValueSegmented] = useState(
    JSON.parse(automotiveFilterLocalStorage)?.condition || "All"
  );
  const items = [
    {
      label: (
        <span>
          Name <AiOutlineArrowDown />
        </span>
      ),
      key: "englishName-asc",
    },
    {
      label: (
        <span>
          Name <AiOutlineArrowUp />
        </span>
      ),
      key: "englishName-desc",
    },
    {
      label: (
        <span>
          Price <AiOutlineArrowDown />
        </span>
      ),
      key: "price-asc",
    },
    {
      label: (
        <span>
          Price <AiOutlineArrowUp />
        </span>
      ),
      key: "price-desc",
    },
  ];

  //#region useEffect
  useEffect(() => {
    return () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };
  }, [CurrentPage]);
  useEffect(() => {
    let Ads = AutomotiveWithFilter?.filter((ele) => ele?.isPromoted === true);
    let Non_Ads = AutomotiveWithFilter?.filter((ele) => !ele?.isPromoted);
    setAds(Ads);
    setNonAds(Non_Ads);
    setPagesAdsData(chunk(Ads, PageSize));
    setPagesNonAdsData(chunk(Non_Ads, PageSize));
  }, [AutomotiveWithFilter, PageSize]);
  useEffect(() => {
    if (width < 1000) {
      setPageSize(10);
    } else {
      setPageSize(9);
    }
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  useEffect(() => {
    getAutomotiveWithFilter({
      ...JSON.parse(automotiveFilterLocalStorage),
      englishName: searchInput,
      featuredBusinessIds: SelectedFeaturesBusiness,
      condition: ValueSegmented === "All" ? undefined : ValueSegmented,
    });
  }, [searchInput, SelectedFeaturesBusiness, ValueSegmented]);
  //#endregion  useEffect
  const handleSort = (value) => {
    setSort(value?.key);
    //  setSortedVal(value?.key);
    setSortName(value?.key?.trim().replace("-", " "));
  };
  let cardSpan = width < 1000 ? 12 : 8;
  let cardFilterSpan = width < 1000 ? 8 : 7;

  const renderProductsPages = (label, pagesData, data, hidePagination) => {
    return (
      <>
        {data?.length > 0 && pagesData[CurrentPage - 1]?.length > 0 && (
          <Row gutter={[10, 10]}>
            <Col span={24}>
              <h2 style={{ color: "#ec2025" }}>{label}</h2>
            </Col>
            {data?.length === 0 && (
              <Col span={24}>
                <small>No {label} found.</small>
              </Col>
            )}
            {pagesData[CurrentPage - 1]?.map((val) => (
              <Col span={cardSpan}>
                <Card data={val} LoadingAutomotive={LoadingAutomotive} />
              </Col>
            ))}
          </Row>
        )}
        {!hidePagination && (
          <Pagination
            current={CurrentPage}
            pageSize={PageSize}
            onChange={(page) => {
              setCurrentPage(page);
            }}
            //total={data?.length}
            total={Ads?.length > NonAds?.length ? Ads?.length : NonAds?.length}
          />
        )}
      </>
    );
  };
  const renderFeaturesBusiness = () => {
    let DataList = [...(AutomotiveFilter["featuredBusiness"] || [])];
    let number = width < 1000 ? 2 : 5;
    return (
      <div className="featuresBusiness">
        <h3 className="label" style={{ display: "flex" }}>
          Featured Businesses{" "}
          <Tooltip title="Clear Featured Businesses">
            <Button
              onClick={() => setSelectedFeaturesBusiness(undefined)}
              type="text"
              shape="circle"
              icon={<BsFunnel size={18} />}
            />
          </Tooltip>
        </h3>
        {width > 767 ? (
          <Carousel
            responsiveLayout={[
              {
                breakpoint: 5000,
                rows: 1,
                cols: 1, //7,
                gap: 10,
                loop: true,
                autoPlay: 2000,
              },
            ]}
            // autoPlay={2000}
            dotColorActive="#231F20"
            rows={1}
            cols={1}
            // loop
          >
            {LoadingAutomotiveFilter ? (
              <Carousel.Item>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  {[1, 2, 3, 4, 5]?.map((val, i) => (
                    <Row gutter={[5, 5]} key={i} style={{ cursor: "pointer" }}>
                      <Col span={24}>
                        <Skeleton.Button
                          active
                          block
                          size={30}
                          shape={"square"}
                        />
                      </Col>
                      <Col span={24}>
                        <Skeleton.Button
                          active
                          block
                          size={20}
                          shape={"round"}
                        />
                      </Col>
                    </Row>
                  ))}
                </div>
              </Carousel.Item>
            ) : (
              chunk(DataList || [], number)?.map((arr, i) => {
                console.log("arr ", arr);
                return (
                  <Carousel.Item key={i}>
                    <div
                      style={{
                        display: "flex",
                        //alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      {arr?.map((val, i) => (
                        <Row
                          gutter={[5, 5]}
                          key={i}
                          style={{
                            cursor: "pointer",
                            width: `calc(100% / ${number})`,
                          }}
                          onClick={() => setSelectedFeaturesBusiness(val?._id)}
                        >
                          <Col span={24}>
                            <img
                              src={
                                getImgVidUrl(val?.image[0]) ||
                                "/img_placeholder.png"
                              }
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = "/img_placeholder.png";
                              }}
                              className={`${
                                SelectedFeaturesBusiness === val?._id &&
                                "active"
                              }`}
                              style={{
                                width: "90px",
                                height: "35px",
                                objectFit: "cover",
                                borderRadius: "5px",
                                display: "block",
                                margin: "0px auto",
                              }}
                              alt=""
                              decoding="async"
                            />
                          </Col>
                          <Col span={24}>
                            <h5 style={{ textAlign: "center" }}>
                              {val?.englishName}
                            </h5>
                          </Col>
                        </Row>
                      ))}
                      {DataList.length < number &&
                        Array(number - DataList.length)
                          .fill(null)
                          .map((_, i) => (
                            <div
                              style={{ flexGrow: 1, width: "100px" }}
                              key={i}
                            ></div>
                          ))}
                      {DataList.length > number &&
                        arr.length < number &&
                        Array(number - arr.length)
                          .fill(null)
                          .map((_, i) => (
                            <div
                              style={{ flexGrow: 1, width: "100px" }}
                              key={i}
                            ></div>
                          ))}
                    </div>
                  </Carousel.Item>
                );
              })
            )}
          </Carousel>
        ) : (
          <div>
            {LoadingAutomotiveFilter ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {[1, 2, 3, 4]?.map((val, i) => (
                  <Row gutter={[5, 5]} key={i} style={{ cursor: "pointer" }}>
                    <Col span={24}>
                      <Skeleton.Button
                        active
                        block
                        size={30}
                        shape={"square"}
                      />
                    </Col>
                    <Col span={24}>
                      <Skeleton.Button active block size={20} shape={"round"} />
                    </Col>
                  </Row>
                ))}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  //alignItems: "center",
                  width: "100%",
                  overflowX: "auto",
                }}
              >
                {DataList?.map((val, i) => (
                  <div
                    key={i}
                    style={{ marginRight: "10px", cursor: "pointer" }}
                    onClick={() => setSelectedFeaturesBusiness(val?._id)}
                  >
                    <Row gutter={[10, 10]}>
                      <Col span={24}>
                        <img
                          src={
                            getImgVidUrl(val?.image[0]) ||
                            "/img_placeholder.png"
                          }
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = "/img_placeholder.png";
                          }}
                          style={{
                            width: "80px",
                            height: "30px",
                            objectFit: "cover",
                            borderRadius: "5px",
                            display: "block",
                            margin: "0px auto",
                            // border:
                            //   SelectedFeaturesBusiness === val?._id
                            //     ? "1px solid #ec2025"
                            //     : "0x solid gray",
                          }}
                          alt=""
                          decoding="async"
                          className={`${
                            SelectedFeaturesBusiness === val?._id && "active"
                          }`}
                        />
                      </Col>
                      <Col span={24}>
                        <h5 style={{ textAlign: "center" }}>
                          {val?.englishName}
                        </h5>
                      </Col>
                    </Row>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    );
  };
  //#region tabs
  const renderSegmented = () => {
    return (
      <Segmented
        options={["All", "New", "Used"]}
        value={ValueSegmented}
        size="large"
        onChange={(value) => {
          console.log("seg ", value);
          setValueSegmented(value);
        }}
      />
    );
  };
  //#endregion tabs

  return (
    <>
      {width > 767 ? (
        <>
          <NavBar />
          <Content
            style={{
              // width: width > 1024 ? "1440px" : width <= 1024 ? "92%" : "70%",
              width: `calc(${width}px - ${width < 1200 ? 5 : 22}%)`,
              margin: "auto",
            }}
          >
            <Row gutter={[25, 25]}>
              {AutomotiveCatImage && (
                <Col span={24}>
                  <ImageComponent
                    image={AutomotiveCatImage}
                    style={{
                      width: "100%",
                      height: width > 767 ? "400px" : "130px",
                    }}
                  />
                </Col>
              )}
              <Col span={24}>
                <BreadcrumbComp />
              </Col>
              <Col span={cardFilterSpan}>
                <Filter
                  SelectedFeaturesBusiness={SelectedFeaturesBusiness}
                  setSelectedFeaturesBusiness={setSelectedFeaturesBusiness}
                  setCurrentPage={setCurrentPage}
                  ValueSegmented={ValueSegmented}
                  setValueSegmented={setValueSegmented}
                />
              </Col>
              <Col span={24 - cardFilterSpan}>
                <div className="categoriesListTitle">
                  <Row
                    justify="space-between"
                    align="middle"
                    style={{ width: "100%" }}
                  >
                    <Col span={8}>
                      <div className="catTitle1">
                        <span style={{ textTransform: "capitalize" }}>
                          Automotive
                        </span>
                      </div>
                    </Col>
                    <Col span={4} offset={12}>
                      <div className="catTitle2">
                        <span className="storeByTitle">Sort By</span>
                      </div>
                    </Col>
                    <Col span={4}>
                      <div className="catTitle1">
                        {LoadingAutomotive ? (
                          <Skeleton.Button
                            active
                            block
                            size={20}
                            shape={"round"}
                          />
                        ) : (
                          <p>{AutomotiveWithFilter?.length || 0} Results</p>
                        )}
                      </div>
                    </Col>
                    <Col span={4} offset={16}>
                      <div className="catTitle2">
                        <Dropdown
                          menu={{
                            items,
                            style: { marginTop: 20 },
                            onClick: handleSort,
                          }}
                          trigger={["click"]}
                        >
                          <p className="dropDownCat">
                            {sortName}{" "}
                            <DownOutlined
                              color="#231F20"
                              style={{ cursor: "pointer" }}
                            />
                          </p>
                        </Dropdown>
                      </div>
                    </Col>
                    {AutomotiveFilter["featuredBusiness"]?.length > 0 &&
                      !LoadingAutomotiveFilter && (
                        <Col span={24}>{renderFeaturesBusiness()}</Col>
                      )}
                    <Col span={24} style={{ marginTop: "10px" }}>
                      {renderSegmented()}
                    </Col>
                    <Col span={24} style={{ marginTop: "10px" }}>
                      {renderProductsPages(
                        "Featured Ads",
                        pagesAdsData,
                        Ads,
                        true
                      )}
                    </Col>
                    <Col
                      span={24}
                      className="automotive-div"
                      style={{ marginTop: "20px" }}
                    >
                      <Row gutter={[10, 10]}>
                        {renderProductsPages("Other", pagesNonAdsData, NonAds)}
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Content>
          <Footer />
        </>
      ) : (
        <>
          <MobileNavBar />
          <Content className="mobile-automotive">
            <Row gutter={[5, 5]}>
              {AutomotiveCatImage && (
                <Col span={24}>
                  <ImageComponent
                    image={AutomotiveCatImage}
                    style={{
                      width: "100%",
                      height: width > 767 ? "400px" : "130px",
                    }}
                  />
                </Col>
              )}
              <Col span={24}>
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <div className="search-div-mobile">
                    <input
                      type="search"
                      className="search-mobile"
                      placeholder="Ex: toyota"
                      value={searchInput}
                      onChange={(e) => {
                        setSearchInput(e.target.value);
                      }}
                    />
                    <CiSearch
                      style={{ position: "absolute", top: "21%", left: "10px" }}
                      size={27}
                      color="#949398"
                    />
                    <img
                      alt=""
                      style={{
                        marginTop: 0,
                        height: "25px",
                        position: "absolute",
                        top: "25%",
                        right: "10px",
                        cursor: "pointer",
                      }}
                      src="/filter.png"
                      onClick={() => setOpen(true)}
                    />
                  </div>
                </div>
              </Col>
              {AutomotiveFilter["featuredBusiness"]?.length > 0 &&
                !LoadingAutomotiveFilter && (
                  <Col span={24}>{renderFeaturesBusiness()}</Col>
                )}
              <Col span={24} style={{ marginTop: "10px" }}>
                {renderSegmented()}
              </Col>
              <Col span={24}>
                {renderProductsPages("Featured Ads", pagesAdsData, Ads, true)}
              </Col>
              {renderProductsPages("Other", pagesNonAdsData, NonAds)}
            </Row>
          </Content>
          <Footer />
        </>
      )}

      <Drawer
        title={<span style={{ fontSize: "24px", fontWeight: "bold" }}></span>}
        placement="bottom"
        closable={false}
        height={"95%"}
        style={{
          padding: "0",
          borderTopLeftRadius: 15,
          borderTopRightRadius: 15,
          boxSizing: "border-box",
        }}
        extra={
          <AiOutlineClose
            style={{ fontSize: 25, cursor: "pointer" }}
            onClick={() => setOpen(false)}
          />
        }
        onClose={() => setOpen(false)}
        open={open}
      >
        <div className="paymentForm" style={{ width: "100%", margin: "15px" }}>
          <Filter
            SelectedFeaturesBusiness={SelectedFeaturesBusiness}
            setSelectedFeaturesBusiness={setSelectedFeaturesBusiness}
            setOpen={setOpen}
            setCurrentPage={setCurrentPage}
            ValueSegmented={ValueSegmented}
            setValueSegmented={setValueSegmented}
          />
        </div>
      </Drawer>
    </>
  );
}

export default Automotive;
