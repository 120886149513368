import React from "react";
import DividerComp from "../../components/Divider/divider";
import CarouselProducts from "../../components/Carousel/carouselProducts";
import { Footer } from "../../components/Footer/Footer";
import NavBar from "../../components/navBar/navBar";
import { Content } from "antd/es/layout/layout";
import { Layout } from "antd";
import Banners from "./Banners";
import RealEstateCarousalDesktop from "../RealEstate/RealEstateCarousel/Desktop";
import AutomotivesCarousalDesktop from "../Automotives/AutomotivesCarousel/Desktop";
import { getPath } from "../../lib/lib";

const DesktopLanding = ({
  bannersLoading,
  banners,
  width,
  DynamicData,
  DynamicDataLoading,
}) => {
  console.log(width);
  const renderSection = (position) => {
    let products = DynamicData[position]?.products || [];
    let isTag = DynamicData[position]?.isTag;
    // const realStateCards  5 ;
    // let productCols = 7 ;
    const realStateCards = width >= 1300 ? 5 : width >= 992 ? 3 : 3; // width >= 992 ? 5 : 2;
    let productCols = width >= 1300 ? 7 : width >= 992 ? 5 : 4; //width >= 992 ? 7 : 4;
    return (
      <>
        {((products?.length > 0 && !DynamicDataLoading) ||
          DynamicDataLoading) && (
          <Content>
            {DynamicData[position]?.isRealEstate ? (
              <>
                <div>
                  <DividerComp
                    title={DynamicData[position]?.englishName}
                    route={DynamicData[position]?.englishName}
                    isCategory={true}
                  />
                </div>
                <div className="real-state-div">
                  <RealEstateCarousalDesktop
                    AllPropertyListing={
                      products?.length < realStateCards
                        ? products
                        : products?.length % realStateCards > 0
                        ? [
                            ...products,
                            ...[...products].slice(
                              0,
                              realStateCards -
                                (products.length % realStateCards)
                            ),
                          ]
                        : products || []
                    }
                    LoadingPropertyListing={DynamicDataLoading}
                    number={realStateCards}
                    span={realStateCards}
                  />
                </div>
              </>
            ) : DynamicData[position]?.isAutomotive ? (
              <>
                <div>
                  <DividerComp
                    title={DynamicData[position]?.englishName}
                    route={DynamicData[position]?.englishName}
                    isCategory={true}
                  />
                </div>
                <div className="real-state-div">
                  <AutomotivesCarousalDesktop
                    DataList={
                      products?.length < realStateCards
                        ? products
                        : products?.length % realStateCards > 0
                        ? [
                            ...products,
                            ...[...products].slice(
                              0,
                              realStateCards -
                                (products.length % realStateCards)
                            ),
                          ]
                        : products || []
                    }
                    Loading={DynamicDataLoading}
                    number={realStateCards}
                    span={realStateCards}
                  />
                </div>
              </>
            ) : (
              <>
                <div>
                  <DividerComp
                    title={DynamicData[position]?.englishName}
                    route={
                      isTag
                        ? `offer/${getPath(DynamicData[position]?.englishName)}`
                        : DynamicData[position]?.englishName
                    }
                    isCategory={isTag ? false : true}
                  />
                </div>
                <div>
                  <CarouselProducts
                    row={1}
                    products={products || []}
                    loading={DynamicDataLoading}
                    cols={productCols}
                  />
                </div>
              </>
            )}
          </Content>
        )}
      </>
    );
  };
  return (
    <Layout style={{ width: "100%", margin: "0 auto", overflowX: "hidden" }}>
      <NavBar />
      {/** up banner */}
      <Banners
        UpBanners={true}
        Banners={banners}
        bannersLoading={bannersLoading}
      />
      <Content style={{ width: "88%", margin: "0 auto" }}>
        {renderSection(1)}
        {renderSection(2)}

        <Content style={{ marginBottom: "30px" }}>
          <div>
            <>
              <Content
                style={{
                  padding: width > 767 ? "0" : "0",
                  margin: width > 767 ? "0" : "15px 0",
                }}
              >
                <div className="divider">
                  <p className="left">Editors Choice</p>
                  <div className="line"></div>
                  <p className="right"> View All</p>
                </div>
              </Content>
            </>
          </div>
          {/** carousel middle banner */}
          <Banners
            MiddleBanners={true}
            Banners={banners}
            bannersLoading={bannersLoading}
          />
          {/** end middle  carousel banner */}
        </Content>
        <Content>
          {renderSection(3)}
          {renderSection(4)}
        </Content>
      </Content>

      {/** bottom banner */}
      <Banners
        BottomBanners={true}
        Banners={banners}
        bannersLoading={bannersLoading}
      />

      <Footer />
    </Layout>
  );
};

export default DesktopLanding;
