import React, { useEffect, useState, useContext } from "react";
import { appContext } from "../../Context/appContext";
import { message } from "antd";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import "./Landing.css";
import {
  getBannersApi,
  getCategoryDynamicSectionsApi,
  getTagDynamicSectionsApi,
} from "../../Apis";
import MobileLanding from "./MobileLanding";
import DesktopLanding from "./DesktopLanding";

export const Landing = () => {
  const {
    // landingProducts,
    // topRatedProducts,
    // loadingTopRatedProducts,
    // getTopRatedProducts,
    // getTopSelling,
    // getFrequently,
    // getBestSelling,
    //bestSellingData,
    // bestSellingLoading,
    // frequentlyData,
    // frequentlyLoading,
    //getFavoritProducts,
    // topSellingData,
    // topSellingLoading,
    // allCategories,
    // setOneProduct,
    // AllProducts,
    // allCategoriesLoading,
    AllPropertyListing,
    AllAutomotive,
  } = useContext(appContext);

  const [banners, setBanners] = useState([]);
  const [bannersLoading, setBannersLoading] = useState(true);
  const [width, setWidth] = useState(window.innerWidth);
  const [DynamicData, setDynamicData] = useState({});
  const [DynamicDataLoading, setDynamicDataLoading] = useState([]);
  const getBanners = async () => {
    try {
      setBannersLoading(true);
      const response = await getBannersApi();
      // console.log(response);
      setBanners(response.data.data);
      setBannersLoading(false);
    } catch (error) {
      message.error(error?.response?.data?.messageEn);
    }
  };
  const getDynamicSections = async (AllPropertyListing, AllAutomotive) => {
    try {
      setDynamicDataLoading(true);
      const cat_response = await getCategoryDynamicSectionsApi();
      const tag_response = await getTagDynamicSectionsApi();
      let cat_data = cat_response?.data?.data?.map((ele) => {
        if (ele?.isRealEstate)
          return {
            ...ele,
            products: AllPropertyListing,
            englishName: "Real Estate",
          };
        if (ele?.isAutomotive)
          return {
            ...ele,
            products: AllAutomotive,
            englishName: "Automotive",
          };
        else return ele;
      });
      let tag_data = tag_response?.data?.data?.map((ele) => ({
        ...ele,
        isTag: true,
      }));
      let post_1 =
        cat_data?.find((ele) => ele?.priority === 1) ||
        tag_data?.find((ele) => ele?.priority === 1);
      let post_2 =
        cat_data?.find((ele) => ele?.priority === 2) ||
        tag_data?.find((ele) => ele?.priority === 2);
      let post_3 =
        cat_data?.find((ele) => ele?.priority === 3) ||
        tag_data?.find((ele) => ele?.priority === 3);
      let post_4 =
        cat_data?.find((ele) => ele?.priority === 4) ||
        tag_data?.find((ele) => ele?.priority === 4);
      setDynamicData({
        1: post_1 || {},
        2: post_2 || {},
        3: post_3 || {},
        4: post_4 || {},
      });
      setDynamicDataLoading(false);
    } catch (error) {
      message.error(error?.response?.data?.messageEn);
    }
  };

  useEffect(() => {
    getDynamicSections(AllPropertyListing, AllAutomotive);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AllPropertyListing, AllAutomotive]);
  useEffect(() => {
    getBanners();
    localStorage.setItem("prevRoute", window.location.pathname);

    return () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  //console.log("DynamicData ", DynamicData);
  return (
    <div className="relative" style={{ overflowX: "hidden" }}>
      {width > 767 ? (
        <>
          <DesktopLanding
            banners={banners}
            bannersLoading={bannersLoading}
            width={width}
            DynamicData={DynamicData}
            DynamicDataLoading={DynamicDataLoading}
          />
        </>
      ) : (
        <>
          <MobileLanding
            banners={banners}
            bannersLoading={bannersLoading}
            width={width}
            DynamicData={DynamicData}
            DynamicDataLoading={DynamicDataLoading}
          />
        </>
      )}
    </div>
  );
};
