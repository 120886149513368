import React, { useContext, useEffect, useState } from "react";
import "./carouselProducts.css";
import Carousel from "better-react-carousel";
import "react-multi-carousel/lib/styles.css";

import { appContext } from "../../Context/appContext";
import ProductSkeleton from "../Skeleton/ProductSkeleton";
import ProductCard from "../productItem";
import { handleNavigate } from "../../lib/lib";

function CarouselProductsDesktop({
  products = [],
  row = 1,
  loading: propsLoading,
  cols = 7,
}) {
  const [width, setWidth] = useState(window.innerWidth);
  //const navigate = useNavigate();
  // console.log("products ", products.length % 7);
  const [Products, setProducts] = useState([]);
  const {
    loadingProducts,
    favoritList,
    setFavoritList,
    handleFavoret,
    loadingFavorite,
    loading,
    itemId,
  } = useContext(appContext);

  useEffect(() => {
    products?.forEach((product) => {
      if (product.favorite) {
        setFavoritList((prev) => [...prev, product._id]);
      }
    });
    if (products?.length < cols) {
      setProducts(products);
      // let p = [...products, ...[...products].slice(0, cols - products.length)];
      // setProducts(p);
    } else if (products?.length % cols > 0) {
      // console.log("products.length % cols ", products.length % cols);
      let p = [
        ...products,
        ...[...products].slice(0, cols - (products.length % cols)),
      ];
      setProducts(p);
    } else {
      setProducts(products);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
  //console.log("cc Products", Products);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  return (
    <div className="carouselProducts carousel-fix-margin">
      <Carousel
        mobileBreakpoint={500}
        marginWidth={40}
        responsiveLayout={[
          {
            breakpoint: 5000,
            rows: 1,
            cols: cols, //7,
            gap: 10,
            loop: true,
          },
        ]}
        dotColorActive="#231F20"
        rows={row}
        cols={cols}
        // cols={7}
      >
        {loadingProducts || propsLoading
          ? Array(cols)
              .fill(null)
              .map((_, ind) => (
                <Carousel.Item key={ind}>
                  <div className="container">
                    <ProductSkeleton />
                  </div>
                </Carousel.Item>
              ))
          : Products?.map((product, i) => {
              return (
                <Carousel.Item id={i} key={i}>
                  <ProductCard
                    product={product}
                    favoritList={favoritList}
                    handleFavoret={handleFavoret}
                    handleNavigate={handleNavigate}
                    itemId={itemId}
                    loading={loading}
                    loadingFavorite={loadingFavorite}
                  />
                </Carousel.Item>
              );
            })}
      </Carousel>
    </div>
  );
}

export default CarouselProductsDesktop;
