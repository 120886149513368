import React, { useContext, useEffect, useState } from "react";
import { appContext } from "../../Context/appContext";
import StarRating from "../StarRateComp/StarRateComp";
import { Button, Row, Col, Tag } from "antd";
import { PiShoppingCartSimpleThin } from "react-icons/pi";
import { BsHeart } from "react-icons/bs";
//import ReadMoreReact from "read-more-react";
import "./index.css";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { IoMdTime } from "react-icons/io";
import Countdown from "react-countdown";
import { addCommas, getImgVidUrl } from "../../lib/lib";

const ProductCard = ({
  product,
  favoritList,
  handleNavigate,
  loading,
  itemId,
  handleFavoret,
  loadingFavorite,
  userDetails,
  style = {},
}) => {
  //console.log("product ", product);
  const { setOneProduct } = useContext(appContext);
  const [width, setWidth] = useState(window.innerWidth);
  let isExpire = moment(Date.now()).isAfter(
    product?.discountEndDate || "1970-01-01"
  );
  const navigate = useNavigate();
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  return (
    <>
      <div
        style={{ height: width > 768 ? "280px" : "270px", ...style }}
        className="product-container"
      >
        {!isExpire && (
          <div className="timer-container">
            <IoMdTime size={30} className="timer-icon" />
            <Row className="timer-text">
              <Col span={24}>
                <span>Sale</span>
              </Col>
              <Col span={24}>
                <Countdown
                  date={product?.discountEndDate}
                  renderer={({ days, hours, minutes, seconds, completed }) => {
                    return (
                      <span>
                        {days > 0
                          ? `${days}D ${hours}H`
                          : `${hours}H ${minutes}m`}
                        {/* {days}ds {hours}hs {minutes}mis */}
                      </span>
                    );
                  }}
                />
              </Col>
            </Row>
          </div>
        )}
        <div className="product-featured-tag">
          {product?.isPromoted && (
            <Tag
              color="#ec2025"
              style={{ marginRight: "5px", marginBottom: "5px" }}
              onClick={() => {
                setOneProduct(product);
                handleNavigate(product, navigate);
              }}
            >
              Featured
            </Tag>
          )}
        </div>
        <Button
          onClick={() => {
            setOneProduct(product);
            handleNavigate(product, navigate);
          }}
          className="product-img"
          style={{ border: "0px", padding: "0px" }}
        >
          <img
            className="product-img"
            src={getImgVidUrl(product?.featuredImage)}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = "/img_placeholder.png";
            }}
            style={{ cursor: "pointer", height: "100%", objectFit: "cover" }}
          />
        </Button>
        <Row gutter={[5, 5]} style={{ flexGrow: 1, alignItems: "flex-end" }}>
          <Col span={24}>
            <div className="productTitle">
              <p
                className="productName capitalize-text"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setOneProduct(product);
                  handleNavigate(product, navigate);
                }}
              >
                {/* {product?.englishName?.slice(0, 12)} */}
                {product?.englishName}
              </p>
              {isExpire ? (
                <p className="productPrice">${addCommas(product?.price)}</p>
              ) : (
                <div className="flex-center-end">
                  <small className="price_2">
                    ${addCommas(product?.price)}
                  </small>
                  <p className="productPrice">
                    ${addCommas(product?.afterDiscountPrice)}
                  </p>
                </div>
              )}
            </div>
          </Col>
          <Col span={24}>
            <div
              className="productDescription capitalize-text"
              style={{ textAlign: "left" }}
            >
              <p
                className="desc"
                //style={{ whiteSpace: "pre-line", maxHeight: "20px" }}
              >
                {product?.englishDescription?.length > 30
                  ? product?.englishDescription?.slice(0, 30) + "..."
                  : product?.englishDescription}
                {/* <ReadMoreReact
                  text={product?.englishDescription}
                  min={0}
                  ideal={60}
                  max={60}
                  readMoreText=" ..."
                /> */}
              </p>
            </div>
          </Col>
          <Col span={24}>
            <div className="productRate">
              <StarRating
                totalStars={5}
                initialRating={product?.avgRate}
                productId={product?._id}
                size={width > 768 ? 20 : 15}
              />
              <p className="rate">
                {
                  product?.rates?.filter(
                    (data) => data?.user == userDetails?._id
                  )[0]?.rate
                }{" "}
                Rate
              </p>
            </div>
          </Col>
          <Col span={18}>
            <Button
              loading={loading}
              className="productBtn"
              onClick={() => {
                setOneProduct(product);
                handleNavigate(product, navigate);
              }}
              icon={
                <PiShoppingCartSimpleThin
                  style={{ marginInlineEnd: "2px" }}
                  className="cartIcon"
                />
              }
            >
              Add to Cart
            </Button>
          </Col>
          <Col span={6}>
            <Button
              className="productBtn"
              loading={loadingFavorite && itemId == product?._id}
              style={{
                background: favoritList?.includes(product?._id) && "#EC2025",
                width: "100%",
              }}
              onClick={() => handleFavoret(product?._id, product?.favorite)}
              icon={
                <BsHeart
                  color={favoritList?.includes(product?._id) && "#fff"}
                  className="heartIcon"
                />
              }
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ProductCard;
