import React, { useContext, useEffect, useState } from "react";
import { appContext } from "../../Context/appContext";
import {
  Collapse,
  Divider,
  Skeleton,
  Slider,
  Row,
  Col,
  Select,
  Button,
  Tooltip,
  InputNumber,
  Input,
  Space,
} from "antd";
import { BsFunnel } from "react-icons/bs";
import _ from "lodash";
import {
  sortArray,
  sortArrayAsc,
  sortArrayOfObjects,
  uniqueArray,
} from "../../lib/lib";
import { HiMinusSm, HiPlusSm } from "react-icons/hi";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import "./index.css";
function FilterComp({
  setOpen,
  SelectedFeaturesBusiness = "",
  setSelectedFeaturesBusiness,
  setCurrentPage,
  ValueSegmented,
  setValueSegmented,
}) {
  const {
    sort,
    setSort,
    getAutomotiveWithFilter,
    // LoadingAutomotive,
    AutomotiveFilter,
    LoadingAutomotiveFilter,
    AutomotiveBrands,
  } = useContext(appContext);
  const automotiveFilterLocalStorage = localStorage.getItem("automotiveFilter");
  const [FilterValues, setFilterValues] = useState(
    JSON.parse(automotiveFilterLocalStorage) || {
      englishName: undefined,
      condition: undefined,
      carCategory: [],
      location: [],
      // brand: [],
      brand: undefined,
      subBrand: [],
      fuelType: [],
      paymentOptions: [],
      transmissionType: [],
      color: [],
      bodyType: [],
      features: [],
      minYear: undefined,
      maxYear: undefined,
      // minKilometers: undefined,
      // maxKilometers: undefined,
      minPrice: undefined,
      maxPrice: undefined,
      featuredBusinessIds: [],
      kilometers: [],
    }
  );
  console.log("filters ", AutomotiveFilter);
  console.log("FilterValues ", FilterValues);
  console.log("AutomotiveBrands ", AutomotiveBrands);
  const [width, setWidth] = useState(window.innerWidth);
  const [minMax, setMinMax] = useState({
    min: 0,
    max: 1000,
  });
  const [Prices, setPrices] = useState([]);
  const [sortedVal, setSortedVal] = useState("");
  // const [search, setSearch] = useState(
  //   JSON.parse(automotiveFilterLocalStorage)?.search || ""
  // );
  const items = [
    {
      label: (
        <span>
          Name <AiOutlineArrowDown />
        </span>
      ),
      key: "englishName-asc",
    },
    {
      label: (
        <span>
          Name <AiOutlineArrowUp />
        </span>
      ),
      key: "englishName-desc",
    },
    {
      label: (
        <span>
          Price <AiOutlineArrowDown />
        </span>
      ),
      key: "price-asc",
    },
    {
      label: (
        <span>
          Price <AiOutlineArrowUp />
        </span>
      ),
      key: "price-desc",
    },
  ];

  // console.log("automotiveFilter", JSON.parse(automotiveFilterLocalStorage));
  //#region useEffect
  useEffect(() => {
    setFilterValues((prev) => ({
      ...prev,
      featuredBusinessIds: SelectedFeaturesBusiness,
    }));
  }, [SelectedFeaturesBusiness]);
  useEffect(() => {
    setFilterValues((prev) => ({
      ...prev,
      condition: ValueSegmented === "All" ? undefined : ValueSegmented,
    }));
  }, [ValueSegmented]);
  useEffect(() => {
    // onClearFilter();
    if (AutomotiveFilter) {
      setFilterValues((prev) => ({
        ...prev,
        price: sortArrayAsc(AutomotiveFilter?.price || []),
      }));
      let min = Math.min(...(AutomotiveFilter?.price || []));
      let max = Math.max(...(AutomotiveFilter?.price || []));
      setPrices([min, max]);
      setMinMax({
        min: min,
        max: max,
      });
    }
  }, [AutomotiveFilter]);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  useEffect(() => {
    let _FilterValues = { ...FilterValues };
    delete _FilterValues["price"];
    setCurrentPage(1);
    getAutomotiveWithFilter({
      ..._FilterValues,
      sort: sort,
      carCategory: FilterValues?.carCategory?.join(","),
      location: FilterValues?.location?.join(","),
      // brand: FilterValues?.brand?.join(","),
      brand: FilterValues?.brand,
      subBrand: FilterValues?.subBrand?.join(","),
      fuelType: FilterValues?.fuelType?.join(","),
      paymentOptions: FilterValues?.paymentOptions?.join(","),
      transmissionType: FilterValues?.transmissionType?.join(","),
      color: FilterValues?.color?.join(","),
      bodyType: FilterValues?.bodyType?.join(","),
      features: FilterValues?.features?.join(","),
      featuredBusinessIds: Array.isArray(FilterValues?.featuredBusinessIds)
        ? FilterValues?.featuredBusinessIds?.join(",")
        : FilterValues?.featuredBusinessIds,
      kilometers: FilterValues?.kilometers?.join(","),
    });
    window.localStorage.setItem(
      "automotiveFilter",
      JSON.stringify({
        ..._FilterValues,
        sort: sort,
      })
    );
  }, [FilterValues, sort]);

  //#endregion useEffect
  //#region func
  const onClearFilter = () => {
    let min = Math.min(...(AutomotiveFilter?.price || []));
    let max = Math.max(...(AutomotiveFilter?.price || []));
    setPrices([min, max]);
    setFilterValues({
      englishName: undefined,
      condition: undefined,
      carCategory: [],
      location: [],
      // brand: [],
      brand: undefined,
      subBrand: [],
      fuelType: [],
      paymentOptions: [],
      transmissionType: [],
      color: [],
      bodyType: [],
      features: [],
      minYear: undefined,
      maxYear: undefined,
      // minKilometers: undefined,
      // maxKilometers: undefined,
      price: [],
      minPrice: undefined,
      maxPrice: undefined,
      price: sortArrayAsc(AutomotiveFilter?.price || []),
      featuredBusinessIds: [],
      kilometers: [],
    });
    setSelectedFeaturesBusiness(undefined);
    setValueSegmented("All");
  };

  const renderOptions = (key, type, options, minKey, maxKey, onChange) => {
    let filteredOptions = sortArray(
      [...options]?.filter(
        (ele) => ele !== null && ele !== undefined && ele !== ""
      )
    );
    //console.log("filteredOptions ", filteredOptions);

    // if (filteredOptions?.length === 0) return;
    if (type === "dropdown") {
      return (
        <Row>
          <Col span={24}>
            <Select
              defaultValue={FilterValues[key]}
              value={FilterValues[key]}
              onChange={(value) => {
                onChangeOption(value, key);
                if (typeof onChange === "function") {
                  onChange();
                }
              }}
              style={{ width: "100%" }}
              placeholder="Select option"
              options={filteredOptions?.map((ele) => ({
                value: ele?._id ? ele?._id : ele,
                label: ele?.englishName ? ele?.englishName : ele,
              }))}
              allowClear
            />
          </Col>
        </Row>
      );
    } else if (type === "multiSelect") {
      return (
        <Row>
          <Col span={24}>
            <Select
              mode="multiple"
              showSearch
              allowClear
              defaultValue={FilterValues[key]}
              value={FilterValues[key]}
              onChange={(value) => onChangeOption(value, key)}
              style={{ width: "100%" }}
              placeholder="Select option"
              // onSearch={(value) => {
              //   console.log("search:", value);
              // }}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={sortArrayOfObjects(filteredOptions, "englishName")?.map(
                (ele) => ({
                  value: ele?._id,
                  label: ele?.englishName,
                })
              )}
            />
          </Col>
        </Row>
      );
    } else if (type === "min-max") {
      return (
        <Row gutter={[5, 5]}>
          <Col span={12}>
            <InputNumber
              style={{ width: "100%" }}
              placeholder="Min"
              min={0}
              value={FilterValues[minKey]}
              onChange={(value) => {
                setFilterValues((prev) => ({
                  ...prev,
                  [minKey]: value,
                }));
              }}
            />
          </Col>
          <Col span={12}>
            <InputNumber
              style={{ width: "100%" }}
              placeholder="Max"
              min={0}
              value={FilterValues[maxKey]}
              onChange={(value) => {
                setFilterValues((prev) => ({
                  ...prev,
                  [maxKey]: value,
                }));
              }}
            />
          </Col>
        </Row>
      );
    } else if (type === "number") {
      return (
        <Row gutter={[5, 5]}>
          <Col span={24}>
            <InputNumber
              style={{ width: "100%" }}
              placeholder="Input number"
              min={0}
              value={FilterValues?.key}
              onChange={(value) => {
                setFilterValues((prev) => ({
                  ...prev,
                  [key]: value,
                }));
              }}
            />
          </Col>
        </Row>
      );
    }
  };
  const circleLoadingFilter = (
    <>
      <Row>
        {Array(3)
          .fill(null)
          .map((_, i) => (
            <Col span={4} key={i}>
              <Skeleton.Avatar shape="circle" size={50} />
            </Col>
          ))}
      </Row>
    </>
  );
  const onChangeOption = (value, key) => {
    if (FilterValues[key] === value) {
      setFilterValues((prev) => ({
        ...prev,
        [key]: undefined,
      }));
    } else {
      setFilterValues((prev) => ({
        ...prev,
        [key]: value,
      }));
    }
  };
  const renderItems = () => {
    return [
      {
        key: "Search",
        label: <span className="categoriesItemCollapse">Search</span>,
        children: (
          <>
            {LoadingAutomotiveFilter ? (
              circleLoadingFilter
            ) : (
              <Input
                placeholder="Enter name"
                value={FilterValues?.englishName}
                onChange={(e) => {
                  // setSearch(e.target.value);
                  setFilterValues((prev) => ({
                    ...prev,
                    englishName: e.target.value,
                  }));
                }}
                style={{ width: "100%" }}
              />
            )}
          </>
        ),
      },
      {
        key: "Brand",
        label: <span className="categoriesItemCollapse">Brand</span>,
        children: (
          <>
            {LoadingAutomotiveFilter
              ? circleLoadingFilter
              : renderOptions(
                  "brand",
                  "dropdown",
                  uniqueArray(AutomotiveFilter["brand"] || [], "englishName") ||
                    [],
                  _,
                  _,
                  () => {
                    setFilterValues((prev) => ({
                      ...prev,
                      subBrand: [],
                    }));
                  }
                )}
            {FilterValues["brand"]?.length > 0 && (
              <>
                <h3 style={{ marginTop: "10px" }}>Sub brands</h3>
                {renderOptions(
                  "subBrand",
                  "multiSelect",
                  uniqueArray(
                    AutomotiveBrands[FilterValues["brand"]],
                    "englishName"
                  ) || []
                )}
              </>
            )}
          </>
        ),
      },
      ...[
        { key: "Category", value: "carCategory", option: "carCategory" },
        { key: "Location", value: "location", option: "location" },
        { key: "Kilometers", value: "kilometers", option: "kilometers" },
        //{ key: "Brand", value: "brand", option: "brand" },
        //{ key: "Sub Brand", value: "subBrand", option: "subBrand"  },
        { key: "Fuel", value: "fuelType", option: "fuelType" },
        { key: "Payment", value: "paymentOptions", option: "paymentOptions" },
        {
          key: "Transmission",
          value: "transmissionType",
          option: "transmissionType",
        },
        { key: "Color", value: "color", option: "color" },
        { key: "Body Type", value: "bodyType", option: "bodyType" },
        { key: "Features", value: "features", option: "features" },
      ]?.map((val) => ({
        key: val.key,
        label: <span className="categoriesItemCollapse">{val.key}</span>,
        children: (
          <>
            {LoadingAutomotiveFilter
              ? circleLoadingFilter
              : renderOptions(
                  val.value,
                  "multiSelect",
                  uniqueArray(AutomotiveFilter[val.option], "englishName") || []
                )}
          </>
        ),
      })),
      ...[
        { key: "Year", type: "min-max", minKey: "minYear", maxKey: "maxYear" },
        // {
        //   key: "Kilometers",
        //   type: "min-max",
        //   minKey: "minKilometers",
        //   maxKey: "maxKilometers",
        // },
      ].map((val) => ({
        key: val.key,
        label: <span className="categoriesItemCollapse">{val.key}</span>,
        children: (
          <>
            {LoadingAutomotiveFilter
              ? circleLoadingFilter
              : renderOptions("", "min-max", [], val.minKey, val.maxKey)}
          </>
        ),
      })),
      {
        key: "Price",
        label: <span className="categoriesItemCollapse">Price</span>,
        children: (
          <>
            <Slider
              range={{ draggableTrack: true }}
              defaultValue={[minMax.min, minMax.max]}
              value={Prices}
              min={minMax.min}
              max={minMax.max}
              style={{ maxWidth: "100%" }}
              //step={null}
              marks={{
                [Prices?.length > 0 ? Prices[0] : 0]: `${
                  Prices?.length > 0 ? Prices[0] : 0
                }`,
                [Prices?.length > 1 ? Prices[1] : 0]: `${
                  Prices?.length > 1 ? Prices[1] : 0
                }`,
              }}
              onChange={(newValue) => {
                setPrices(newValue);
                setFilterValues((prev) => ({
                  ...prev,
                  price: newValue,
                  minPrice: newValue[0],
                  maxPrice: newValue[1],
                }));
              }}
            />
          </>
        ),
      },
    ];
  };
  const sortFunc = (value) => {
    if (sortedVal != value) {
      setSortedVal(value);
    } else {
      setSortedVal("");
    }
    setSort(value);
  };
  const sortCollapseArr = [
    {
      // key: "1",
      label: (
        <span
          style={{
            fontSize: "16px",
            textAlign: "end",
            display: "inline-block",
            width: "100%",
          }}
          className="categoriesItemCollapse"
        >
          Sort By Name/Price
        </span>
      ),
      children: (
        <div className="categoriesOffer">
          <>
            {items?.map((off, i) => (
              <div className="offerTitle1" key={i}>
                <span style={{ color: sortedVal == off?.key && "#EC2025" }}>
                  {off?.label}
                </span>
                {sortedVal == off.key ? (
                  <HiMinusSm
                    className="selectCat"
                    style={{ color: "#231F20" }}
                    onClick={() => sortFunc(off?.key)}
                  />
                ) : (
                  <HiPlusSm
                    className="selectCat"
                    style={{ color: "#231F20" }}
                    onClick={() => sortFunc(off?.key)}
                  />
                )}
              </div>
            ))}
          </>
        </div>
      ),
    },
  ];
  //#endregion func
  return (
    <>
      <div className="categoriesFilter">
        {width > 767 ? (
          <>
            <div className="categoryFilterTitle">
              <div>
                <span className="title1" style={{ marginRight: "5px" }}>
                  Filter
                </span>
                <Tooltip title="Clear filter">
                  <Button
                    onClick={onClearFilter}
                    type="text"
                    shape="circle"
                    icon={<BsFunnel size={18} />}
                  />
                </Tooltip>
              </div>
              <img src="/Group 10.svg" alt="" />
            </div>
            <div className="dividerPading">
              <Divider className="categoriesDivider" />
            </div>
          </>
        ) : (
          <>
            <div
              className="categoryFilterTitle"
              style={{
                marginLeft: "15px",
                marginRight: "30px",
              }}
            >
              <div>
                <span className="title1" style={{ marginRight: "5px" }}>
                  Filter
                </span>
                <Tooltip title="Clear filter">
                  <Button
                    onClick={onClearFilter}
                    type="text"
                    shape="circle"
                    icon={<BsFunnel size={18} />}
                  />
                </Tooltip>
              </div>
              <img src="/Group 10.svg" alt="" />
            </div>
            <div className="dividerPading">
              <Divider className="categoriesDivider" />
            </div>
            <div
              className="categorisFilterCollapse"
              style={{ margin: width > 767 ? "0px" : "0 30px 0 10px" }}
            >
              <Collapse
                defaultActiveKey={[...sortCollapseArr.map((data) => data.key)]}
                ghost
                items={sortCollapseArr}
                expandIconPosition="end"
              />
            </div>
          </>
        )}
        <div
          style={{ margin: width > 767 ? "0px" : "0 30px 0 10px" }}
          className="categorisFilterCollapse"
        >
          {!LoadingAutomotiveFilter ? (
            <Collapse
              defaultActiveKey={[
                "Search",
                "Category",
                "Location",
                "Brand",
                "Sub Brand",
                "Fuel",
                "Payment",
                "Year",
                "Kilometers",
                "Transmission",
                "Color",
                "Body Type",
                "Features",
                "Condition",
                "Price",
              ]}
              ghost
              items={renderItems()}
              expandIconPosition="end"
            />
          ) : (
            <>{circleLoadingFilter}</>
          )}

          {width <= 767 && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Button
                style={{
                  padding: "20px auto",
                  height: "50px",
                  width: "150px",
                }}
                className="red-btn hover-bg-w"
                onClick={() => setOpen(false)}
              >
                Apply
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default FilterComp;
