import React from "react";
import { chunk } from "../../../lib/lib";
import { Col, Row } from "antd";
import Card from "../Card";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

function CarouselRealEstateMobile({
  Loading,
  DataList,
  RowStyle = {},
  // number,
  // span,
}) {
  const renderResponsiveCarousel = (products = [], style) => {
    let chunkProducts = [];
    if (products % 2 !== 0) {
      let p = [
        ...products,
        ...[...products].slice(0, 2 - (products.length % 2)),
      ];
      chunkProducts = chunk(p, 2);
    } else {
      chunkProducts = chunk(products, 2);
    }
    //console.log(chunkProducts);
    return (
      <div style={{ width: "100%", height: "100%" }}>
        <>
          <Carousel // react-responsive-carousel
            autoPlay
            infiniteLoop
            showArrows={false}
            showThumbs={false}
            emulateTouch={true}
            showIndicators={false}
            swipeScrollTolerance={20}
            preventMovementUntilSwipeScrollTolerance={true}
            interval={5000}
            onChange={(e) => {
              //console.log("onclick ", e);
            }}
            onClickItem={(e) => {
              console.log("onclick item ", e);
            }}
            onClickThumb={(e) => {
              // console.log("onClickThumb ", e);
            }}
          >
            {chunkProducts?.map((products, i) => (
              <Row key={i} cols={24} gutter={5} style={RowStyle}>
                {products?.map((product, j) => (
                  <Col key={j} span={12}>
                    <Card data={product} LoadingAutomotive={Loading} />
                  </Col>
                ))}
              </Row>
            ))}
          </Carousel>
        </>
      </div>
    );
  };

  return (
    <div
      className="mobile-carousel-products"
      style={{ marginTop: "5px", marginBottom: "5px" }}
    >
      {renderResponsiveCarousel(DataList)}
    </div>
  );
}

export default CarouselRealEstateMobile;
