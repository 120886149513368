import React, { useContext, useEffect, useState } from "react";
import "./myCart.css";
import BreadcrumbComp from "../../components/Breadcrumb/Breadcrumb";
import NavBar from "../../components/navBar/navBar";
import { Footer } from "../../components/Footer/Footer";
import { Content } from "antd/es/layout/layout";
import { Button, Col, Input, Row, message, ColorPicker } from "antd";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import { CiWarning } from "react-icons/ci";
import { PiTrashSimpleLight } from "react-icons/pi";
import ProfileComp from "../../components/Profile/ProfileComp";
import { appContext } from "../../Context/appContext";
import { deleteFromCartApi, Url, addToCartApi } from "../../Apis";
import MobileNavBar from "../../components/mobileNavBar/mobileNavBar";
import { handleNavigate, getMaxQuantity, getImgVidUrl } from "../../lib/lib";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import Confirm from "../../components/ConfirmDialog";
function MyCart() {
  const {
    cartList,
    getCartData,
    userDetails,
    // loadingCart,
    getTotalPrice,
    priceDetails,
    setPriceDetails,
    IsProductDeleteSuccefully,
    setIsProductDeleteSuccefully,
    loadingQuantity,
    setLoadingQuantity,
  } = useContext(appContext);
  // console.log("cartList ", cartList);
  const [width, setWidth] = useState(window.innerWidth);
  const [loadingPromo, setLoadingPromo] = useState(false);
  const navigate = useNavigate();
  const [productId, setProductId] = useState("");
  const [isConfirmDeleteModal, setIsConfirmDeleteModal] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [SelectedOrder, setSelectedOrder] = useState({});
  const [CartList, setCartList] = useState([]);
  const [ActionName, setActionName] = useState("");
  const token = localStorage.getItem("token");
  //const [categories, setCategories] = useState([]);
  const orderDetail = [
    { name: "Sub Total Price:", value: `$${priceDetails.totalCart}` },
    { name: "Delivery:", value: `$${priceDetails.delivery}` },
    { name: "Discount:", value: `$${priceDetails.discount || 0}` },
  ];
  const promoSchema = yup.object().shape({
    promo: yup
      .string("Invalid Promo Code !")
      .required("Promo Code is required!"),
  });
  const {
    // eslint-disable-next-line no-unused-vars
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(promoSchema),
  });
  //#region useEffect
  useEffect(() => {
    onsubmit({ promo: "" });
    setCartList(cartList);
  }, [cartList]);
  useEffect(() => {
    localStorage.removeItem("promo");
    return () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };
  }, []);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  //#endregion useEffect
  //#region functions
  const openDeleteFavorite = (id) => {
    setIsConfirmDeleteModal(true);
    setProductId(id);
  };
  const cancelDeleteFunc = () => {
    setIsConfirmDeleteModal(false);
    setLoadingDelete(false);
  };
  const confirmDeleteFunc = async () => {
    try {
      setLoadingDelete(true);
      const response = await deleteFromCartApi(productId);
      // console.log("test ", response.data);
      getCartData();
      setIsConfirmDeleteModal(false);
      setLoadingDelete(false);
      setIsProductDeleteSuccefully(!IsProductDeleteSuccefully);
      if (response) message.success(response.data?.messageEn);
    } catch (error) {
      message.error(error?.message);
      setIsConfirmDeleteModal(false);
      setLoadingDelete(false);
    }
  };
  const _handleNavigate = (cProduct) => {
    const product = cProduct?.product;
    if (product) handleNavigate(product, navigate);
  };
  //#endregion functions
  const onsubmit = async (data) => {
    // console.log(data);
    const { promo } = data;
    //localStorage.setItem("promo", promo);
    // getTotalPrice

    const products = cartList?.map((item) => ({
      product: item._id,
      price: item.price,
      quantity: item.quantity,
      note: item.note,
    }));
    //if (products?.length > 0) {
    setLoadingPromo(true);
    getTotalPrice(promo || "", products || [])
      .then((data) => {
        // console.log(data.data);
        //message.success(data.data.messageEn);
        setPriceDetails((prev) => ({
          ...prev,
          salePrice: data.data.data.salePrice,
          discount: data.data.data.discount,
          vat: data.data.data.vat,
        }));
      })
      .catch((err) => {
        // console.log("err?.response ", err?.response);
        message.error(err?.response?.data?.messageEn);
      })
      .finally(() => setLoadingPromo(false));
    //}
  };
  const ConfirmEditQuantity = async (order) => {
    console.log("order ", order);
    if (!token) {
      message.warning("Please Login first");
      navigate("/login");
      return;
    }
    if (getMaxQuantityFunc(order) < order?.quantity) {
      message.warning("All quantities added.");
      return;
    }
    try {
      setLoadingQuantity(true);
      let details = order?.details;
      if ("quantity" in details) delete details["quantity"];
      let obj = {
        details: {
          ...(details || {}),
        },
        price: order?.price,
        product: order?.product?._id,
        quantity: order?.quantity,
        note: "",
      };

      const response = await addToCartApi({ product: obj });
      getCartData().then(() => {
        setLoadingQuantity(false);
        message.success(response?.data?.messageEn);
      });
    } catch (error) {
      message.error(error?.response?.data?.message);
      setLoadingQuantity(false);
    }
  };
  const style = {
    color: "#EC2025",
    //float: "right",
    padding: "0px 10px",
    fontStyle: "italic",
    fontSize: "14px",
    fontWeight: "500",
    //  textAlign: "center",
  };
  const borderStyle = {
    fontSize: "16px",
    fontWeight: "500",
    borderLeft: "1px solid rgba(236, 32, 37, 1)",
  };
  const getMaxQuantityFunc = (order) => {
    //console.log("order ", order);
    if (order?.product?.details?.length === 0) {
      return order?.product?.quantity;
    } else {
      let Alldetails = order?.product?.details;
      let orderDetails = order?.details;
      let maxQuantity = getMaxQuantity(Alldetails, orderDetails);
      return maxQuantity;
    }
  };
  function handelDecQuantity(order, i) {
    setSelectedOrder(order);
    setActionName("dec");
    let _cartL = [...CartList];
    _cartL[i].quantity = _cartL[i].quantity - 1;
    setCartList(_cartL);
    ConfirmEditQuantity({
      ...order,
      quantity: _cartL[i].quantity,
    });
  }
  function handelIncQuantity(order, i) {
    setSelectedOrder(order);
    setActionName("inc");
    let _cartL = [...CartList];
    _cartL[i].quantity = _cartL[i].quantity + 1;
    let _order = {
      ...order,
      quantity: _cartL[i].quantity,
    };
    if (getMaxQuantityFunc(_order) < _order?.quantity) {
      message.warning("All quantities added.");
      _cartL[i].quantity = _cartL[i].quantity - 1;
      let _order = {
        ...order,
        quantity: _cartL[i].quantity,
      };
      setCartList(_cartL);
      return;
    } else {
      setCartList(_cartL);
      ConfirmEditQuantity({
        ...order,
        quantity: _cartL[i].quantity,
      });
    }
  }
  const renderCartTable = () => {
    return (
      <>
        <Row gutter={10} style={{ margin: "15px 0px" }}>
          <Col xs={6} style={style}>
            Product Details
          </Col>
          <Col xs={4} style={style}>
            Item Price
          </Col>
          <Col xs={8} style={style}>
            Your Specifications
          </Col>
          <Col xs={3} style={style}>
            QTY
          </Col>
          <Col xs={1} style={style} />
        </Row>
        {cartList?.length === 0 ? (
          <Row>
            <Col
              xs={24}
              style={{
                color: "#ccc",
                fontSize: 18,
                fontWeight: 500,
                textAlign: "center",
              }}
            >
              No Data
            </Col>
          </Row>
        ) : (
          cartList?.map((order, i) => {
            let price = order?.price ? order?.price : order?.product?.price;
            let detail = order?.details;
            return (
              <Row key={i} gutter={5} style={{ margin: "0px" }}>
                <Col
                  xs={6}
                  className="suborderHistoryTitle2"
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  <button
                    style={{
                      fontSize: "20px",
                      fontWeight: "700",
                      color: "rgba(236, 32, 37, 1)",
                    }}
                    className="bg-transparent border-none text-left cursor-pointer"
                    onClick={() => _handleNavigate(order)}
                  >
                    {order?.product?.englishName}
                  </button>{" "}
                  {/* <br />
                  <span>{order?.product?.updatedAt?.split("T")[0]}</span> */}
                </Col>
                <Col
                  xs={4}
                  className="suborderHistoryTitle2"
                  style={borderStyle}
                >
                  {price} ${" "}
                  <span
                    style={{
                      color: "#EC2025",
                      fontSize: 16,
                      marginInline: 5,
                      minHeight: "50px",
                    }}
                  >
                    x {order?.quantity}
                  </span>
                </Col>
                <Col
                  xs={8}
                  className="suborderHistoryTitle2"
                  style={borderStyle}
                >
                  {detail && (
                    <div
                      className="specificationsComp"
                      style={{
                        // minHeight: "75px",
                        marginBottom: 10,
                        gap: "15px",
                      }}
                    >
                      {Object.keys(detail || {})?.map((key, i) => {
                        if (key?.toLowerCase() === "color") {
                          return (
                            <span
                              className="specifications"
                              key={i}
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {key}:{" "}
                              <ColorPicker
                                size="small"
                                style={{ marginLeft: "3px" }}
                                defaultValue={"#" + detail[key]}
                              />
                            </span>
                          );
                        } else {
                          return (
                            <span className="specifications" key={i}>
                              {key}: {detail[key]}
                            </span>
                          );
                        }
                      })}
                    </div>
                  )}
                </Col>
                <Col
                  xs={3}
                  style={borderStyle}
                  className="suborderHistoryTitle2"
                >
                  <div
                    style={{
                      display: "flex",
                      //alignItems: "center",
                      marginBottom: 10,
                      justifyContent: "space-evenly",
                      minHeight: "75px",
                    }}
                  >
                    <Button
                      icon={<MinusOutlined />}
                      size="small"
                      disabled={CartList[i]?.quantity === 1 || loadingQuantity}
                      loading={
                        loadingQuantity &&
                        SelectedOrder?._id === order?._id &&
                        ActionName === "dec"
                      }
                      className="white-btn hover-bg-red"
                      onClick={() => handelDecQuantity(order, i)}
                    />
                    <h4>{CartList[i]?.quantity}</h4>
                    <Button
                      size="small"
                      icon={<PlusOutlined />}
                      loading={
                        loadingQuantity &&
                        SelectedOrder?._id === order?._id &&
                        ActionName === "inc"
                      }
                      disabled={loadingQuantity}
                      className="white-btn hover-bg-red"
                      onClick={() => handelIncQuantity(order, i)}
                    />
                  </div>
                </Col>
                <Col xs={1} className="suborderHistoryTitle2">
                  <div
                    style={{
                      display: "flex",
                      //  alignItems: "center",
                      marginBottom: 10,
                      justifyContent: "space-between",
                      minHeight: "75px",
                      gap: 20,
                    }}
                  >
                    <PiTrashSimpleLight
                      size={25}
                      className="trushIcon"
                      onClick={() => openDeleteFavorite(order?._id)}
                    />
                  </div>
                </Col>
              </Row>
            );
          })
        )}
      </>
    );
  };
  return (
    <>
      {width > 767 ? (
        <>
          <NavBar />
          <Content style={{ width: "80%", margin: "auto" }}>
            <div className="myProfile">
              <BreadcrumbComp />
              <div className="profileComponents">
                <div className="profileinfo">
                  <div className="userInfo">
                    <ProfileComp />
                  </div>
                  {userDetails?.role?.name === "user" &&
                  userDetails !== undefined ? (
                    <div className="userVendorCart">
                      <div className="vendorImg">
                        <img src="/Fill 7.svg" alt="" />
                      </div>
                      <div className="vendorTitle">
                        <span>Become a Vendor !</span>
                      </div>
                      <div className="vendorBtnComp">
                        <small className="vendorHint">
                          Read Terms and Conditions
                        </small>
                        <Button
                          //className="vendorBtn"
                          className="vendorBtn red-btn hover-bg-w"
                          onClick={() => navigate("/signup")}
                        >
                          Become a Vendor
                        </Button>
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="profileForm">
                  <div className="profileFormComp">
                    <div className="cart">
                      <div className="cartTitle">
                        <div className="title1"></div>
                        <div
                          className="note"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <CiWarning
                            style={{ fontSize: 18, margin: "0 3px" }}
                          />{" "}
                          Report Issue !
                        </div>
                      </div>
                      <div>{renderCartTable()}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="calculations">
                {orderDetail?.map((order, i) => (
                  <div key={i} className="calculationsTitle">
                    <div
                      className="note"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {order.name}
                    </div>
                    <div className="title1">{order.value}</div>
                  </div>
                ))}
                <div className="total">
                  <div className="totalTitle">Grand Total:</div>
                  <div className="totalTitle1">{priceDetails.salePrice}$</div>
                </div>
              </div>
              <div className="cartActionBtn" style={{ width: "100%" }}>
                <Row
                  align={"bottom"}
                  justify={"space-between"}
                  gutter={[24, 24]}
                  style={{ width: "100%" }}
                >
                  <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                    <div
                      className="promoCodeForm"
                      style={{ width: "100%", display: "block" }}
                    >
                      <form
                        onSubmit={handleSubmit(onsubmit)}
                        style={{ position: "relative" }}
                      >
                        <span
                          className="promoLable"
                          style={{ position: "relative" }}
                        >
                          Promo Code
                        </span>
                        <span
                          className="subcartButons"
                          style={{ whiteSpace: "nowrap", width: "100%" }}
                        >
                          <Controller
                            name="promo"
                            control={control}
                            render={({ field }) => {
                              //console.log("field ", field);
                              return (
                                <Input
                                  {...field}
                                  style={{ width: "100%", margin: 0 }}
                                  className="profileInput"
                                  placeholder="Enter Promo Code Here."
                                />
                              );
                            }}
                          />
                          <Button
                            htmlType="submit"
                            className="infoBtn white-btn hover-bg-red"
                            loading={loadingPromo}
                          >
                            Apply Code
                          </Button>
                        </span>
                        <p style={{ color: "red" }}>{errors.promo?.message}</p>
                      </form>
                    </div>
                  </Col>
                  {/* <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                    <div className="shoppingBtn" style={{ width: "100%" }}>
                      <Button
                        className="vendorBtn"
                        style={{ width: "100%" }}
                        onClick={() => navigate("/")}
                      >
                        Continue Shopping
                      </Button>
                    </div>
                  </Col> */}
                  <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                    <div className="shoppingBtn2" style={{ width: "100%" }}>
                      <Button
                        className="vendorBtn2 red-btn hover-bg-w"
                        style={{ width: "100%" }}
                        onClick={() =>
                          cartList?.length > 0
                            ? navigate(`/my-cart/checkout`)
                            : message.warning(
                                "Please add items to cart to proceed to checkout"
                              )
                        }
                      >
                        Proceed to Check Out
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Content>
          <Footer />
        </>
      ) : (
        <>
          <div style={{ width: "100%", overflowX: "hidden" }}>
            <MobileNavBar />
            <Content
              style={{
                width: "100%",
                margin: "auto",
                padding: "0px 25px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  //,padding:"10px 25px"
                }}
              >
                <div style={{ width: "50%" }}>
                  <p style={{ fontSize: "14px", fontWeight: "700" }}>My Cart</p>
                  <p style={{ fontSize: "12px", fontWeight: "500" }}>
                    Add Some More
                  </p>
                </div>
                <div style={{ width: "50%" }}>
                  <button
                    className="categories-btns-mobile white-btn-btn hover-bg-red"
                    style={{
                      // backgroundColor: "rgba(236, 32, 37, 1)",
                      //color: "white",
                      width: "100%",
                      margin: "1em 0em",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "13px",
                      padding: "8px 10px",
                    }}
                    onClick={() => navigate("/")}
                  >
                    {" "}
                    Continue Shopping
                  </button>
                </div>
              </div>
            </Content>
            <div
              style={{
                width: "100%",
                boxSizing: "border-box",
                maxHeight: "250px",
                overflow: "auto",
                whiteSpace: "nowrap",
                border: "none",
                outline: "none",
                scrollbarWidth: "none",
                scrollbarColor: "transparent transparent",
                msOverflowStyle: "none",
              }}
            >
              {cartList?.map((order, i) => {
                let price = order?.price ? order?.price : order?.product?.price;
                let detail = order?.product?.details?.find(
                  (ele) => ele?.price === order?.price
                );
                return (
                  <Row
                    key={i}
                    gutter={10}
                    style={{
                      backgroundColor: "rgba(235, 235, 235, 1)",
                      minHeight: "82px",
                      padding: "10px",
                      borderBottom: "1px solid gray",
                      boxSizing: "border-box",
                      width: "100%",
                      scrollbarWidth: "none",
                      scrollbarColor: "transparent transparent",
                      msOverflowStyle: "none",
                    }}
                    className="cart-products-div-mob cursor-pointer"
                    // onClick={() => _handleNavigate(order)}
                  >
                    <Col xs={6}>
                      <img
                        src={getImgVidUrl(order?.product?.productImage[0])}
                        style={{ width: "100%", height: "75px" }}
                        alt={"product " + order?.product?.englishName}
                        onClick={() => _handleNavigate(order)}
                      />
                    </Col>
                    <Col xs={7}>
                      <p
                        style={{
                          fontSize: "12px",
                          fontWeight: "600",
                          color: "rgba(236, 32, 37, 1)",
                        }}
                      >
                        {order?.product?.englishName}
                      </p>
                      <div
                        className="specificationsComp"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {Object.keys(detail || {})?.map((key, i) => {
                          if (key !== "quantity" && key !== "price")
                            if (key?.toLowerCase() === "color") {
                              return (
                                <span
                                  className="specifications"
                                  key={i}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {key}:{" "}
                                  <ColorPicker
                                    style={{ marginLeft: "3px" }}
                                    size="small"
                                    defaultValue={"#" + detail[key]}
                                  />
                                </span>
                              );
                            } else {
                              return (
                                <span className="specifications" key={i}>
                                  {key}: {detail[key]}
                                </span>
                              );
                            }
                        })}
                      </div>
                    </Col>
                    <Col xs={4}>
                      <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                        {price}$
                        <span style={{ color: "#EC2025", marginLeft: "2px" }}>
                          x {order?.quantity}
                        </span>
                      </p>
                    </Col>
                    <Col xs={5} className="suborderHistoryTitle2">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          minHeight: "75px",
                          width: "100%",
                        }}
                      >
                        <Button
                          icon={<MinusOutlined />}
                          size="small"
                          disabled={
                            CartList[i]?.quantity === 1 || loadingQuantity
                          }
                          loading={
                            loadingQuantity &&
                            SelectedOrder?._id === order?._id &&
                            ActionName === "dec"
                          }
                          onClick={() => handelDecQuantity(order, i)}
                          className="white-btn hover-bg-red"
                        />
                        <h4>{CartList[i]?.quantity}</h4>
                        <Button
                          size="small"
                          icon={<PlusOutlined />}
                          loading={
                            loadingQuantity &&
                            SelectedOrder?._id === order?._id &&
                            ActionName === "inc"
                          }
                          disabled={
                            loadingQuantity
                            // CartList[i]?.quantity === MaxQuantities[order?._id]
                          }
                          onClick={() => handelIncQuantity(order, i)}
                          className="white-btn hover-bg-red"
                        />
                      </div>
                    </Col>
                    <Col
                      xs={2}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <PiTrashSimpleLight
                        style={{ color: "#ec2025", fontSize: "18px" }}
                        onClick={() => openDeleteFavorite(order?._id)}
                      />
                    </Col>
                  </Row>
                );
              })}
            </div>
            <Row gutter={10} style={{ marginTop: "5px" }}>
              <Col
                xs={17}
                style={{
                  textAlign: "right",
                  fontSize: "14px",
                  color: "rgba(236, 32, 37, 1)",
                  fontStyle: "italic",
                }}
              >
                Sub Total Price
              </Col>
              <Col xs={7}>{priceDetails.totalCart} $</Col>
            </Row>
            <Row gutter={10} style={{ marginTop: "5px" }}>
              <Col
                xs={17}
                style={{
                  textAlign: "right",
                  fontSize: "14px",
                  color: "rgba(236, 32, 37, 1)",
                  fontStyle: "italic",
                }}
              >
                Delivery
              </Col>
              <Col xs={7}>{priceDetails.delivery || 0} $</Col>
            </Row>
            <Row gutter={10} style={{ marginTop: "5px" }}>
              <Col
                xs={17}
                style={{
                  textAlign: "right",
                  fontSize: "14px",
                  color: "rgba(236, 32, 37, 1)",
                  fontStyle: "italic",
                }}
              >
                Discount
              </Col>
              {/* // todo */}
              <Col xs={7}>{priceDetails.discount || 0} $</Col>
            </Row>
            <Row gutter={10} style={{ marginTop: "5px" }}>
              <Col
                xs={17}
                style={{ textAlign: "right", fontStyle: "normal" }}
                className="totalTitle"
              >
                Grand Total
              </Col>
              <Col xs={7} className="totalTitle1">
                {priceDetails.salePrice} $
              </Col>
            </Row>
            <div style={{ padding: "10px 25px", margin: "40px 0px 100px" }}>
              <Row
                align={"bottom"}
                justify={"space-around"}
                gutter={[10, 10]}
                style={{ width: "100%", margin: "auto" }}
              >
                <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                  <div
                    className="promoCodeForm"
                    style={{ width: "100%", display: "block" }}
                  >
                    <form
                      onSubmit={handleSubmit(onsubmit)}
                      style={{ position: "relative" }}
                    >
                      <span
                        className="promoLable"
                        style={{ position: "relative" }}
                      >
                        Promo Code
                      </span>
                      <span
                        className="subcartButons"
                        style={{
                          whiteSpace: "nowrap",
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Controller
                          name="promo"
                          control={control}
                          render={({ field }) => (
                            <Input
                              {...field}
                              style={{
                                width: "100%",
                                margin: 0,
                                height: "40px",
                              }}
                              className="profileInput"
                              placeholder="Enter Promo "
                            />
                          )}
                        />
                        <Button
                          htmlType="submit"
                          className="infoBtn white-btn hover-bg-red"
                          loading={loadingPromo}
                          style={{ height: "40px", fontSize: "13px" }}
                        >
                          Apply Code
                        </Button>
                      </span>
                      <p style={{ color: "red" }}>{errors.promo?.message}</p>
                    </form>
                  </div>
                </Col>

                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <div className="shoppingBtn2" style={{ width: "100%" }}>
                    <Button
                      className="vendorBtn2 red-btn hover-bg-w"
                      style={{
                        width: "100%",
                        height: "40px",
                        fontSize: "13px",
                      }}
                      onClick={() =>
                        cartList?.length > 0
                          ? navigate(`/my-cart/checkout`)
                          : message.warning(
                              "Please add items to cart to proceed to checkout"
                            )
                      }
                    >
                      Proceed to Check Out
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
            <Footer />
          </div>
        </>
      )}
      {/** Delete */}
      <Confirm
        open={isConfirmDeleteModal}
        title={"Confirm Delete Product"}
        message={"Are you sure to delete this product from Cart ?"}
        loading={loadingDelete}
        confirmLabel={"Delete"}
        confirmFunc={confirmDeleteFunc}
        cancelFunc={cancelDeleteFunc}
      />
    </>
  );
}

export default MyCart;
