import React, { useContext, useEffect, useState } from "react";
import "./myWishList.css";
import { getFavouritesApi, deleteFavoriteApi } from "../../Apis";
import BreadcrumbComp from "../../components/Breadcrumb/Breadcrumb";
import NavBar from "../../components/navBar/navBar";
import { Footer } from "../../components/Footer/Footer";
import { Content } from "antd/es/layout/layout";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import { Button, message, Modal, Row, Col, Spin } from "antd";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import { CiWarning } from "react-icons/ci";
import { PiTrashSimpleLight } from "react-icons/pi";
import ProfileComp from "../../components/Profile/ProfileComp";
import { appContext } from "../../Context/appContext";
import MobileNavBar from "../../components/mobileNavBar/mobileNavBar";
import MyWishListMobile from "../../components/Carousel/myWishListMobile";
import { getPath } from "../../lib/lib";

function MyWishList() {
  const [width, setWidth] = useState(window.innerWidth);
  const [productId, setProductId] = useState("");
  const [isConfirmDeleteModal, setIsConfirmDeleteModal] = useState(false);
  const [favouritesArr, setFavouritesArr] = useState([]);
  const navigate = useNavigate();
  const [loadingDelete, setLoadingDelete] = useState(false);
  const { setLoadingFavorit, loadingFavorit, loading, itemId, userDetails } =
    useContext(appContext);

  const antIcon = (
    <Loading3QuartersOutlined
      style={{
        fontSize: 15,
        color: "#fff",
      }}
      spin
    />
  );
  // console.log(errors);
  const openDeleteFavorite = (id) => {
    setIsConfirmDeleteModal(true);
    setProductId(id);
  };

  const getFavouritesFunc = async () => {
    try {
      setLoadingFavorit(true);
      const response = await getFavouritesApi();
      // console.log("favourites", response);
      setFavouritesArr(response?.data?.data?.favorite);
      setLoadingFavorit(false);
    } catch (error) {
      // console.log(error.message);
      setLoadingFavorit(false);
    }
  };

  useEffect(() => {
    getFavouritesFunc();
  }, []);

  const cancelDeleteFunc = () => {
    setIsConfirmDeleteModal(false);
  };

  const confirmDeleteFunc = async () => {
    try {
      setLoadingDelete(true);
      const response = await deleteFavoriteApi(productId);
      getFavouritesFunc();
      setIsConfirmDeleteModal(false);
      setLoadingDelete(false);
    } catch (error) {
      message.error(error?.message);
      setIsConfirmDeleteModal(false);
      setLoadingDelete(false);
    }
  };

  const handleNavigate = (product) => {
    // console.log(product);
    if (Object.keys(product?.mainCategory).length !== 0) {
      navigate(
        `/${getPath(product?.mainCategory?.englishName)}/${getPath(
          product?.category?.englishName
        )}/${getPath(product?.englishName)}`,
        { state: product?._id }
      );
    } else {
      navigate(
        `/${getPath(product?.category?.englishName)}/${getPath(
          product?.englishName
        )}`,
        { state: product?._id }
      );
    }
  };

  useEffect(() => {
    return () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };
  }, []);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  return (
    <>
      {width > 767 ? (
        <>
          <NavBar />
          <Content
            style={{
              width: "80%",
              margin: "auto",
            }}
          >
            <div className="myProfile">
              <BreadcrumbComp />
              <div className="profileComponents">
                <div className="profileinfo">
                  <div className="userInfo">
                    <ProfileComp />
                  </div>
                  {userDetails?.roleName == "user" &&
                  userDetails !== undefined ? (
                    <div className="userVendor">
                      <div className="vendorImg">
                        <img src="/Fill 7.svg" alt="" />
                      </div>
                      <div className="vendorTitle">
                        <span>Become a Vendor !</span>
                      </div>
                      <div className="vendorBtnComp">
                        <small className="vendorHint">
                          Read Terms and Conditions
                        </small>
                        <Button
                          className="vendorBtn"
                          onClick={() => navigate("/become-a-vendor")}
                        >
                          Become a Vendor
                        </Button>
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="profileForm">
                  <div className="profileFormComp">
                    <div className="wishList">
                      <div className="wishListTitle">
                        <div className="title1">My Wishlist :</div>
                        <div
                          className="note"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <CiWarning
                            style={{ fontSize: 16, margin: "0 3px" }}
                          />{" "}
                          Report Issue !
                        </div>
                      </div>
                      <div
                      //className="wishListTable"
                      >
                        <Row gutter={20} style={{ margin: "15px 0px" }}>
                          <Col
                            xs={8}
                            style={{
                              color: "#EC2025",
                              //float: "right",
                              padding: "0px 10px",
                              fontStyle: "italic",
                              fontSize: "14px",
                              fontWeight: "500",
                            }}
                          >
                            Product Details
                          </Col>
                          <Col
                            xs={6}
                            style={{
                              // borderLeft:"1px solid rgba(236, 32, 37, 1)",
                              color: "#EC2025",
                              //float: "right",
                              padding: "0px 10px",
                              fontStyle: "italic",
                              fontSize: "14px",
                              fontWeight: "500",
                            }}
                          >
                            Item Price
                          </Col>

                          <Col
                            xs={10}
                            style={{
                              padding: "0px 10px",
                              color: "#EC2025",
                              //float: "right",
                              //borderLeft:"1px solid rgba(236, 32, 37, 1)",
                              fontStyle: "italic",
                              fontSize: "14px",
                              fontWeight: "500",
                            }}
                          ></Col>
                        </Row>
                        {favouritesArr?.length == 0 ? (
                          <Row>
                            <Col
                              xs={24}
                              style={{
                                color: "#ccc",
                                fontSize: 18,
                                fontWeight: 500,
                                textAlign: "center",
                              }}
                            >
                              No Data
                            </Col>
                          </Row>
                        ) : (
                          favouritesArr?.map((order, i) => (
                            <>
                              <Row
                                key={i}
                                gutter={20}
                                style={{ margin: "0px" }}
                              >
                                <Col
                                  xs={8}
                                  className="suborderHistoryTitle2"
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: "20px",
                                      fontWeight: "700",
                                      color: "rgba(236, 32, 37, 1)",
                                    }}
                                    className="cursor-pointer"
                                    onClick={() => {
                                      handleNavigate(order);
                                    }}
                                  >
                                    {order?.englishName}
                                  </span>{" "}
                                  <br />
                                  <span>{order?.updatedAt?.split("T")[0]}</span>
                                </Col>
                                <Col
                                  xs={6}
                                  className="suborderHistoryTitle2"
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    borderLeft:
                                      "1px solid rgba(236, 32, 37, 1)",
                                  }}
                                >
                                  {order?.price} $
                                </Col>
                                <Col
                                  xs={10}
                                  className="suborderHistoryTitle2"
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    borderLeft:
                                      "1px solid rgba(236, 32, 37, 1)",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginBottom: 20,
                                    }}
                                  >
                                    <span
                                      className="subwishListButons"
                                      style={{ whiteSpace: "nowrap" }}
                                      key={i}
                                    >
                                      <Button
                                        className="infoBtn white-btn hover-bg-red"
                                        loading={
                                          loading && itemId == order?._id
                                        }
                                        onClick={() =>
                                          // addToCart(
                                          //   order?.price,
                                          //   order?._id
                                          // )
                                          handleNavigate(order)
                                        }
                                      >
                                        Add To Cart
                                      </Button>

                                      <PiTrashSimpleLight
                                        onClick={() =>
                                          openDeleteFavorite(order?._id)
                                        }
                                        className="trushIcon"
                                      />
                                    </span>
                                  </div>
                                </Col>
                              </Row>
                            </>
                          ))
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Content>
          <Footer />{" "}
        </>
      ) : (
        <div style={{ width: "95%", overflowX: "hidden", margin: "0 auto" }}>
          <MobileNavBar />
          <Content
            style={{
              width: "100%",
              margin: "0px auto 100px",
              padding: "10px 0px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "10px 20px",
              }}
            >
              <div style={{ width: "50%" }}>
                <p style={{ fontSize: "16px", fontWeight: "700" }}>
                  My Wishlist
                </p>
                <p style={{ fontSize: "14px", fontWeight: "500" }}>
                  Add Some More
                </p>
              </div>
              <div style={{ width: "50%" }}>
                <button
                  className="categories-btns-mobile"
                  style={{
                    // backgroundColor: "rgba(236, 32, 37, 1)",
                    // color: "white",
                    width: "100%",
                    margin: "1em 0em",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "13px",
                    padding: "8px 10px",
                  }}
                  onClick={() => navigate("/")}
                >
                  {" "}
                  Continue Shopping
                </button>
              </div>
            </div>
            <div style={{ margin: "1em 0em" }}>
              <MyWishListMobile
                loading={loadingFavorit}
                products={favouritesArr}
                rows={2}
              />
            </div>
          </Content>
          <Footer />
        </div>
      )}
      {/****** confirm delete  modal ******/}
      <Modal
        title=""
        open={isConfirmDeleteModal}
        // onOk={confirmDeleteFunc}
        // onCancel={cancelDeleteFunc}

        closable={false}
        footer={null}
        maskClosable={true}
        okText={"Delete"}
        cancelText={"Cancel"}
        // confirmLoading={loading ? true : false}
        // cancelButtonProps={
        //   {disabled:loading ? true : false}
        // }
        className="add-address-modal"
        bodyStyle={{
          padding: 0,
          border: "none",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            padding: "1em",
            backgroundColor: "rgba(236, 32, 37, 1)",
            borderRadius: "8px",
            color: "white",
            fontWeight: "bold",
            width: "100%",
            boxSizing: "border-box",
            fontSize: "18px",
          }}
        >
          <p>Confirm Delete Product</p>
        </div>
        <div className="modalContentImei">
          <p style={{ padding: "3em", fontWeight: "700" }}>
            Are you sure to delete this product from wishlist ?
          </p>
        </div>
        <Row
          style={{
            padding: "1em",
            width: "100%",
          }}
        >
          <Col
            xs={24}
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <button
              onClick={cancelDeleteFunc}
              className="add-address-cancel-btn"
            >
              {" "}
              Cancel{" "}
            </button>
            <button
              type="submit"
              onClick={confirmDeleteFunc}
              className="add-address-add-btn"
            >
              {" "}
              {loadingDelete ? <Spin indicator={antIcon} /> : " Delete"}
            </button>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

export default MyWishList;
