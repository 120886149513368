import Carousel from "better-react-carousel";
import "react-multi-carousel/lib/styles.css";
import { chunk } from "../../../lib/lib";
import Card from "../Card";
import "../index.css";

function RealEstateCarousal({ Loading, DataList, number, span }) {
  console.log("number ", number);
  console.log("DataList ", DataList);
  console.log("DataList?.length ", DataList?.length);
  return (
    <div>
      <Carousel
        mobileBreakpoint={500}
        marginWidth={40}
        responsiveLayout={[
          {
            breakpoint: 5000,
            rows: 1,
            cols: 1, //7,
            gap: 10,
            loop: true,
            autoPlay: 2000,
          },
        ]}
        autoPlay={2000}
        dotColorActive="#231F20"
        rows={1}
        cols={1}
        loop
      >
        {chunk(DataList || [], number)?.map((arr, i) => {
          console.log("arr ", arr);
          return (
            <Carousel.Item key={i}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {arr?.map((val, i) => (
                  <Card
                    key={i}
                    data={val}
                    LoadingAutomotive={Loading}
                    style={{ marginRight: i < number - 1 ? "10px" : "0px" }}
                  />
                ))}
                {DataList.length < number &&
                  Array(number - DataList.length)
                    .fill(null)
                    .map((_, i) => (
                      <div style={{ flexGrow: 1, width: "100%" }} key={i}></div>
                    ))}
                {DataList.length > number &&
                  arr.length < number &&
                  Array(number - arr.length)
                    .fill(null)
                    .map((_, i) => (
                      <div style={{ flexGrow: 1, width: "100%" }} key={i}></div>
                    ))}
              </div>
            </Carousel.Item>
          );
        })}
      </Carousel>
    </div>
  );
}

export default RealEstateCarousal;
