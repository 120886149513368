import React, { useState, useEffect } from "react";
import "./index.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Image } from "antd";
import { getImgVidUrl } from "../../lib/lib";

function Gallery({
  images = [],
  selectedImage = "",
  setSelectedImage = () => {},
}) {
  const [selectedItem, setSelectedItem] = useState(0);
  useEffect(() => {
    setSelectedItem(images.indexOf(selectedImage));
  }, [selectedImage, images]);

  return (
    <>
      <div className="gallery">
        <Carousel // react-responsive-carousel
          //   autoPlay
          //   infiniteLoop
          swipeScrollTolerance={10}
          showArrows={true}
          onChange={(index) => {
            //console.log("onChange ", e);
            setSelectedItem(index);
            let img = images[index];
            setSelectedImage(img);
          }}
          onClickItem={(e) => {
            // console.log("onclick item ", e);
          }}
          onClickThumb={(e) => {
            // console.log("onClickThumb ", e);
          }}
          // selectedItem={images.indexOf(selectedImage)}
          selectedItem={selectedItem}
        >
          {images?.map((item, i) => {
            let _imgs = [...images];
            _imgs.splice(i, 1);
            _imgs = [item, ..._imgs];
            return (
              <>
                <Image.PreviewGroup
                  items={_imgs?.map((imgURL) => getImgVidUrl(imgURL))}
                >
                  <Image
                    loading="lazy"
                    wrapperStyle={{
                      heigh: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    height={"100%"}
                    width={"100%"}
                    className="img"
                    //src={getImgVidUrl(selectedImage)}
                    src={getImgVidUrl(item)}
                    //onClick={() => setVisible(true)}
                  />
                </Image.PreviewGroup>
              </>
            );
          })}
        </Carousel>
      </div>
      {/* <Image.PreviewGroup
        items={images?.map((imgURL) => Url + imgURL)}
        preview={{
          visible,
          onVisibleChange: (value) => {
            setVisible(value);
          },
        }}
      ></Image.PreviewGroup> */}
    </>
  );
}

export default Gallery;
