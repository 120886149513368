import React, { useContext, useEffect, useState } from "react";
import { appContext } from "../../Context/appContext";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Url, getProfileApi, updateProfileApi } from "../../Apis";
import { HiOutlineUser } from "react-icons/hi";
import { BiEditAlt } from "react-icons/bi";
import { CopyOutlined, LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Col, Input, Modal, Row, Skeleton, Upload, message } from "antd";
import PhoneInput, { isValidPhoneNumber } from "react-phone-input-2";
import { getImgVidUrl } from "../../lib/lib";
function ProfileComp() {
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [copyColor, setCopyColor] = useState("");
  const {
    fullName,
    setFullName,
    imgUrl,
    setImgUrl,
    setLoadingProfile,
    loadingProfile,
    IsProfileDataUpdated,
    setIsProfileDataUpdated,
  } = useContext(appContext);
  const [imageUrl, setImageUrl] = useState("");
  const [isUpdateInfo, setIsUpdateInfo] = useState(false);
  const profileSchema = yup.object().shape({
    fullName: yup
      .string("Invalid Full Name!")
      .required("Full Name is required!"),
    profileImg: yup.mixed(),
    // email: yup.string("Invalid Email!").email("Invalid Email!"),

    mobileNumber: yup
      .string()
      .required("Phone number is required")
      .min(10, "phone number  should be at least 10 numbers!")
      .max(16, "phone number  should be at most 16 numbers!"),

    // password: yup.string("Invalid password!"),
  });

  const {
    register: register,
    handleSubmit: handleSubmit,
    formState: { errors: errors },
    setValue,
    reset,
    control,
  } = useForm({
    resolver: yupResolver(profileSchema),
  });
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const handleChange = (info) => {
    // console.log(info);
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      // console.log(info);
      // console.log(info.file.response?.data[0]?.fileName);
      setValue("profileImg", info.file.response?.data[0]?.path);
      setImgUrl(info.file.response?.data[0]?.path);
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };
  const handleChangeInfoCancel = () => {
    setIsUpdateInfo(false);
  };
  const onsubmit = async (data) => {
    const { mobileNumber, fullName, profileImg } = data;
    try {
      const response = await updateProfileApi({
        mobileNumber,
        fullName,
        profileImg,
      });
      // console.log(response);
      if (response.data.data) {
        message.success(response.data.messageEn);
        setIsProfileDataUpdated(!IsProfileDataUpdated);
        getProfileData();
        setIsUpdateInfo(false);
      }
    } catch (error) {
      if (error.response) {
        message.error(error.response.data.messageEn);
      } else {
        message.error(error.message);
      }
    }
  };
  const getProfileData = async () => {
    try {
      setLoadingProfile(true);
      const response = await getProfileApi();
      // console.log(response);
      if (response.data.data) {
        setValue("myemail", response.data.data.email);
        setValue("myname", response.data.data.fullName);
        setValue("myphoneNumber", response.data.data.mobileNumber);
        setFullName(response.data.data.fullName);
        // setEmail(response.data.data.email)
        setPhoneNumber(response.data.data.mobileNumber);
        setImgUrl(response.data.data.profileImg);
        // // console.log(response.data.data.profileImg);
        // console.log(response.data.data.profileImg);
        setLoadingProfile(false);
      }
    } catch (error) {
      setLoadingProfile(false);
      if (error.response) {
        // message.error(error.response.data.messageEn);
      } else {
        // message.error(error.message);
      }
    }
  };
  useEffect(() => {
    getProfileData();
  }, [IsProfileDataUpdated]);

  const handleChangeInfoOpen = () => {
    setIsUpdateInfo(true);
    // setValue("myemail",response.data.data.email);
    setValue("fullName", fullName);
    setValue("mobileNumber", phoneNumber);
    if (imgUrl !== "" && imgUrl !== null) {
      setImageUrl(getImgVidUrl(imgUrl));
    }
  };
  return (
    <>
      <div className="imageDiv">
        <HiOutlineUser className="userIcon" />
        {loadingProfile ? (
          <>
            <Skeleton.Avatar size={186} active />
          </>
        ) : imgUrl !== "" && imgUrl !== null ? (
          <img
            src={getImgVidUrl(imageUrl)}
            className="profileImg"
            loading="lazy"
            alt=""
          />
        ) : (
          <img
            src="/anonymous-user.svg"
            className="profileImg"
            loading="lazy"
            alt=""
          />
        )}
        <BiEditAlt className="editIcon" onClick={handleChangeInfoOpen} />
      </div>
      <div className="titleDiv">
        <div className="title" style={{ textAlign: "center" }}>
          <span style={{ textAlign: "center" }}>
            {loadingProfile ? (
              <Skeleton.Button size={20} style={{ width: 200 }} active />
            ) : (
              fullName
            )}
          </span>
        </div>
        <div className="subTitle">
          <span>
            {" "}
            <p>Account ID : </p> 01230123
          </span>
          <CopyOutlined
            className="copyIcon"
            style={{ color: copyColor == "red" ? "red" : "black" }}
            onClick={() => {
              navigator.clipboard.writeText("01230123");
              setCopyColor("red");
              setTimeout(() => {
                setCopyColor("black");
              }, 1000);
            }}
          />
        </div>
      </div>

      {/*******change Info Modal***** */}
      <Modal
        // title="Basic Modal"
        className="add-address-modal"
        bodyStyle={{
          padding: 0,
          border: "none",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        // style={{ border: "1px solid #707070", padding: 0 }}
        // okText="Add"
        closable={false}
        open={isUpdateInfo}
        // onOk={handleAddAddressOk}
        onCancel={handleChangeInfoCancel}
        footer={null}
      >
        <div
          style={{
            padding: "1em",
            backgroundColor: "rgba(236, 32, 37, 1)",
            borderRadius: "8px",
            color: "white",
            fontWeight: "bold",
            width: "100%",
            boxSizing: "border-box",
            fontSize: "18px",
          }}
        >
          Update Profile
        </div>
        <div
          className="paymentForm"
          style={{
            padding: "1em",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <form onSubmit={handleSubmit(onsubmit)}>
            <Row gutter={[24, 10]}>
              <Col xs={24} md={24}>
                <div className="uploadProfile">
                  <p className="becomeVendorLable"> Profile Image :</p>
                  <Controller
                    name="file"
                    control={control}
                    render={({ field }) => (
                      <Upload
                        style={{
                          width: 140,
                          height: 140,
                          margin: "20px auto",
                          display: "flex",
                          justifyContent: "center",
                        }}
                        name="file"
                        {...field}
                        listType="picture-circle"
                        className="avatar-uploader"
                        showUploadList={false}
                        action={Url + "/uploadImage"}
                        beforeUpload={beforeUpload}
                        onChange={handleChange}
                        multiple
                      >
                        {imageUrl ? (
                          imageUrl !== "" ? (
                            <img
                              src={getImgVidUrl(imageUrl)}
                              alt="avatar"
                              style={{
                                width: "100%",
                                borderRadius: "50%",
                              }}
                            />
                          ) : (
                            <img
                              src="/anonymous-user.svg"
                              className="profileImg"
                              loading="lazy"
                              alt=""
                            />
                          )
                        ) : (
                          uploadButton
                        )}
                      </Upload>
                    )}
                  />
                </div>
              </Col>
              <Col xs={24} md={24}>
                <p className="becomeVendorLable">Your Name :</p>
                <Controller
                  name="fullName"
                  control={control}
                  render={({ field }) => (
                    <Input
                      style={{ height: "50px" }}
                      {...field}
                      className="becomeVendorInput"
                      placeholder=" ex: John Michael Doe"
                    />
                  )}
                />
                <p style={{ color: "red" }}>{errors.fullName?.message}</p>
              </Col>
              <Col xs={24} md={24}>
                <p className="becomeVendorLable">Phone Number :</p>
                <Controller
                  name="mobileNumber"
                  control={control}
                  rules={{
                    validate: (value) => isValidPhoneNumber(value),
                  }}
                  render={({ field: { onChange, value } }) => (
                    <PhoneInput
                      onChange={onChange}
                      value={value}
                      id="phoneNumber"
                      containerStyle={{
                        margin: "10px auto",
                      }}
                      enableSearch
                      enableClickOutside
                      enableTerritories
                      country={"lb"}
                      inputClass="profileInput"
                      inputStyle={{
                        width: "100%",
                        height: "50px",
                        position: "relative",
                        // margin: "10px 0",
                        boxSizing: "border-box",
                        // paddingTop: "1.8em ",
                        // paddingBottom: "1.8em ",
                        // paddingLeft:"2.5em",
                        // textIndent: "1em",
                        border: "1px solid #ccc",
                        outline: "none",
                        borderRadius: "7px",
                        backgroundColor: "#EBEBEB",
                        boxShadow: "0 0 4px lightgray",
                      }}

                      // onChange={phone =>addPhoneNumber(phone)}
                      // {...register("mobileNumber")}
                    />
                  )}
                />

                <p style={{ color: "red" }}>{errors.phoneNumber?.message}</p>
              </Col>
            </Row>
            <Row gutter={[24, 24]} justify={"end"}>
              <Col
                xs={24}
                style={{
                  display: "flex",
                  margin: "20px 0",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <button
                  type="button"
                  // style={{ width: "100%" }}
                  onClick={handleChangeInfoCancel}
                  className="dialog-cancel-btn"
                >
                  {" "}
                  Cancel{" "}
                </button>
                <button
                  // style={{ width: "100%" }}
                  type="submit"
                  className="dialog-submit-btn"
                >
                  {" "}
                  Edit
                </button>
              </Col>
            </Row>
          </form>
        </div>
      </Modal>
    </>
  );
}

export default ProfileComp;
