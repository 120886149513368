import React, { useContext, useEffect, useState } from "react";
import "./navBar.css";
import {
  Input,
  Layout,
  Menu,
  Dropdown,
  Drawer,
  message,
  Skeleton,
  Row,
  Col,
  Divider,
  Button,
  Empty,
} from "antd";
import { RiSearch2Line } from "react-icons/ri";
import { CiPercent } from "react-icons/ci";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { searchApi, searchProductsApi } from "../../Apis";
import { appContext } from "../../Context/appContext";
import MobileNavBar from "../mobileNavBar/mobileNavBar";
import { AiOutlineRight } from "react-icons/ai";
import {
  getImgVidUrl,
  getPath,
  handleNavigate,
  isCategory,
} from "../../lib/lib";
import { BsEye } from "react-icons/bs";
import ImageComponent from "../Image";

const { Header } = Layout;
function NavBar() {
  const navigate = useNavigate();
  const [itemActive, setItemActive] = useState("All Categories");
  const [openDropDown, setOpenDropDown] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [open, setOpen] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [products, setProducts] = useState([]);
  const [searchData, setSearchData] = useState({});
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchBy, setSearchBy] = useState("");
  const [openSearch, setopenSearch] = useState(false);
  const [loadingProduct, setloadingProduct] = useState(false);
  const {
    token,
    userDetails,
    root,
    priceDetails,
    setCategoryId,
    setOneProduct,
    AllPropertyListing,
    AllAutomotive,
    AllCategories,
    AllCategoriesLoading,
  } = useContext(appContext);
  root.style.setProperty("--noscroll", open || openSearch ? "hidden" : "");
  let realEstateCatIcon =
    AllCategories?.find((ele) => ele?.isRealEstate && !ele?.isDeleted)?.icon ||
    undefined;
  let AutomotiveCatIcon =
    AllCategories?.find((ele) => ele?.isAutomotive && !ele?.isDeleted)?.icon ||
    undefined;
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  const changeActive = (value) => {
    setItemActive(value.englishName);
    if (value.name === "All Categories") {
      showDrawer();
    }
    setOpenDropDown(!openDropDown);
  };
  const onLeave = () => {
    setItemActive("");
    setOpenDropDown(!openDropDown);
  };
  const showDrawer = () => setOpen(true);
  const onClose = () => {
    setOpen(false);
    setOpenDropDown(!openDropDown);
  };
  const categoriesMenu = (e) => {
    //console.log("e ", e);
    if (e.keyPath?.length === 3) {
      navigate(
        `/${getPath(e.keyPath[2])}/${getPath(e.keyPath[1])}/${getPath(
          e.keyPath[0]?.split(",")[0]
        )}`
        //  { state: { pId: e.keyPath[0]?.split(",")[1] } }
      );
    } else {
      navigate(
        `/${getPath(e.keyPath[1])}/${getPath(e.keyPath[0]?.split(",")[0])}`
        //  { state: { pId: e.keyPath[0]?.split(",")[1] } }
      );
    }
  };
  const productHover = async (id, type, name) => {
    // console.log(id);
    if (type === "cat") {
      try {
        setSearchBy(name);
        setloadingProduct(true);
        const response = await searchProductsApi(searchText, `cat=${id}`);
        if (response) {
          setProducts(response?.data?.data);
          setloadingProduct(false);
        }
      } catch (error) {
        setloadingProduct(false);
        message.error(error.response?.data?.messageEn);
      }
    } else if (type === "brand") {
      try {
        setSearchBy(name);
        setloadingProduct(true);
        const response = await searchProductsApi(searchText, `brand=${id}`);
        if (response) {
          setProducts(response?.data?.data);
          setloadingProduct(false);
        }
      } catch (error) {
        setloadingProduct(false);
        message.error(error.response?.data?.messageEn);
      }
    } else {
      try {
        setSearchBy(name);
        setloadingProduct(true);
        const response = await searchProductsApi(searchText, `tag=${id}`);
        if (response) {
          setProducts(response?.data?.data);
          setloadingProduct(false);
        }
      } catch (error) {
        setloadingProduct(false);
        message.error(error.response?.data?.messageEn);
      }
    }
  };
  const handleSearch = async (search) => {
    setopenSearch(true);
    setloadingProduct(true);
    setSearchLoading(true);
    console.log(search);
    setSearchText(search);
    if (search?.length > 1) {
      try {
        const response = await searchApi(search);
        if (response.data) {
          setSearchData(response.data?.data);
          setSearchBy(response.data?.data?.category[0]?.englishName);
          try {
            setloadingProduct(true);
            if (response.data?.data?.category[0]?._id) {
              const res = await searchProductsApi(
                search,
                `cat=${response.data?.data?.category[0]?._id}`
              );
              if (res) {
                setProducts(res?.data?.data);
              } else {
                setProducts([]);
              }
            }
          } catch (error) {
            setloadingProduct(false);
            message.error(error.response?.data?.messageEn);
          }
          setSearchLoading(false);
        }
      } catch (error) {
        setSearchLoading(false);
        message.error(error?.response?.data.message);
      }
      setSearchLoading(false);
      setloadingProduct(false);
    }
  };
  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }
  const getSubCategories = (data, cat) => {
    // console.log("data ", data);
    return [
      ...data
        ?.map((val) => ({ ...val, products: [] }))
        ?.map((sub, i) =>
          getItem(
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              onClick={() => {
                if (sub?.products?.length > 0) {
                  setCategoryId(data?._id);
                  navigate(`/${getPath(cat)}/${getPath(sub?.englishName)}`);
                }
              }}
            >
              <span className="menueTitle">{sub?.englishName} </span>
              {sub?.products?.length > 0 && (
                <AiOutlineRight style={{ marginRight: 10 }} />
              )}
            </div>,
            sub?.products?.length > 0
              ? sub?.englishName
              : [sub?.englishName, sub?._id],
            null,
            sub?.products?.length > 0
              ? [
                  ...sub?.products.map((prod, index) =>
                    getItem(
                      prod?.englishName,
                      [prod?.englishName, prod?._id],
                      null,
                      null,
                      null
                    )
                  ),
                ]
              : null,
            null
          )
        ),
    ];
  };
  const getCategories = (data) => {
    return [
      ...data
        ?.map((val) => ({ ...val, products: [] }))
        ?.filter((val) => val?.isActive === true)
        ?.map((cat, i) =>
          getItem(
            <div
              onClick={() =>
                navigate(`/${getPath(cat?.englishName)}`, {
                  state: { catHome: false },
                })
              }
            >
              {cat?.englishName}
            </div>,
            cat?.englishName,
            null,
            cat?.subCategory?.length > 0
              ? [
                  ...cat?.subCategory
                    ?.filter((val) => val?.isActive === true)
                    ?.map((sub, index) =>
                      getItem(
                        <div
                          onClick={() =>
                            navigate(
                              `/${getPath(cat?.englishName)}/${getPath(
                                sub?.englishName
                              )}`,
                              {
                                state: { catHome: false },
                              }
                            )
                          }
                        >
                          {sub?.englishName}
                        </div>,
                        sub?.englishName,
                        null,
                        sub?.products?.length > 0
                          ? [
                              ...sub?.products?.map((prod, ind) =>
                                getItem(
                                  prod?.englishName,
                                  [prod?.englishName, prod?._id],
                                  null,
                                  null
                                )
                              ),
                            ]
                          : null
                      )
                    ),
                ]
              : [
                  ...cat?.products.map((produc, index) =>
                    getItem(
                      produc?.englishName,
                      [produc?.englishName, produc?._id],
                      null,
                      null
                    )
                  ),
                ]
          )
        ),
    ];
  };
  const onClick = (e, cat) => {
    //console.log("click", cat);
    //resetRealEstateLocalStorageFilter();
    setCategoryId(cat?._id);
    if (e.keyPath?.length === 2) {
      navigate(
        `/${getPath(cat?.englishName)}/${getPath(e.keyPath[1])}/${getPath(
          e.keyPath[0]?.split(",")[0]
        )}`
      );
    } else {
      navigate(
        `/${getPath(cat?.englishName)}/${getPath(e.keyPath[0]?.split(",")[0])}`
      );
    }
  };
  return (
    <>
      {width > 767 ? (
        <Header style={{ height: "unset" }}>
          <div className="navWrapper !gap-4">
            <div className="logo-search-div self-end !gap-4">
              <img
                loading="lazy"
                style={{ marginTop: 0, cursor: "pointer" }}
                src="/Logo Landscape.svg"
                className="!h-[40px] lg:!h-[67px]"
                alt="logo"
                onClick={() => navigate("/")}
              />
              <div style={{ position: "relative", width: "100%" }}>
                <Input
                  className="navInput"
                  onChange={(e) => {
                    e.target.value === ""
                      ? setSearchData({})
                      : handleSearch(e.target.value);
                    e.target.value === "" && setopenSearch(false);
                  }}
                  size="large"
                  placeholder="Search Products, Categories, Brands ..."
                  suffix={
                    <RiSearch2Line
                      style={{ color: "#EC2025", fontSize: "22px" }}
                    />
                  }
                />
                {Object.keys(searchData).length !== 0 && openSearch && (
                  <>
                    <div className="searchResults">
                      {searchData?.category?.length +
                        searchData?.brands?.length +
                        searchData?.tags?.length ===
                      0 ? (
                        <>
                          <Empty
                            style={{ width: "100%" }}
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                          />
                        </>
                      ) : (
                        <Row cols={24}>
                          <Col xs={24} sm={24} md={24} lg={24} xl={9}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 10,
                              }}
                            >
                              {searchData?.category?.length > 0 && (
                                <div className="itemSearch">
                                  <Divider
                                    type="horizontal"
                                    orientation="left"
                                    orientationMargin={0}
                                    style={{
                                      color: "#d8d8d8",
                                      borderColor: "#d8d8d8",
                                      borderBlock: "20px",
                                      margin: "10px 0px",
                                    }}
                                  >
                                    Categories
                                  </Divider>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: 0,
                                      paddingLeft: 0,
                                    }}
                                  >
                                    {searchLoading ? (
                                      [1, 2, 3].map((_, i) => (
                                        <span key={i} style={{ lineHeight: 2 }}>
                                          <Skeleton.Button
                                            style={{ width: "50%" }}
                                            active
                                            size={20}
                                            block
                                            shape="round"
                                          />
                                        </span>
                                      ))
                                    ) : searchData?.category?.length === 0 ? (
                                      <>No Result</>
                                    ) : (
                                      searchData?.category?.map((data, i) => (
                                        <span
                                          key={i}
                                          onClick={() =>
                                            productHover(
                                              data?._id,
                                              "cat",
                                              data?.englishName
                                            )
                                          }
                                          className="subItem"
                                          style={{
                                            margin: 0,
                                            lineHeight: 2,
                                            paddingInline: 5,
                                          }}
                                        >
                                          {data?.englishName}
                                        </span>
                                      ))
                                    )}
                                  </div>
                                </div>
                              )}
                              {searchData?.brands?.length > 0 && (
                                <div className="itemSearch">
                                  <Divider
                                    type="horizontal"
                                    orientation="left"
                                    orientationMargin={0}
                                    style={{
                                      color: "#d8d8d8",
                                      borderColor: "#d8d8d8",
                                      borderBlock: "20px",
                                      margin: "10px 0px",
                                    }}
                                  >
                                    Brands
                                  </Divider>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: 0,
                                      paddingLeft: 0,
                                    }}
                                  >
                                    {searchLoading ? (
                                      [1, 2, 3].map((_, i) => (
                                        <span key={i} style={{ lineHeight: 2 }}>
                                          <Skeleton.Button
                                            style={{ width: "50%" }}
                                            active
                                            size={20}
                                            block
                                            shape="round"
                                          />
                                        </span>
                                      ))
                                    ) : searchData?.brands?.length === 0 ? (
                                      <>No Result</>
                                    ) : (
                                      searchData?.brands?.map((data, i) => (
                                        <span
                                          key={i}
                                          onClick={() =>
                                            productHover(
                                              data?._id,
                                              "brand",
                                              data?.englishName
                                            )
                                          }
                                          className="subItem"
                                          style={{
                                            margin: 0,
                                            lineHeight: 2,
                                            paddingInline: 5,
                                          }}
                                        >
                                          {data?.englishName}
                                        </span>
                                      ))
                                    )}
                                  </div>
                                </div>
                              )}
                              {searchData?.tags?.length > 0 && (
                                <div className="itemSearch">
                                  <Divider
                                    type="horizontal"
                                    orientation="left"
                                    orientationMargin={0}
                                    style={{
                                      color: "#d8d8d8",
                                      borderColor: "#d8d8d8",
                                      borderBlock: "20px",
                                      margin: "10px 0px",
                                    }}
                                  >
                                    Offers
                                  </Divider>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: 0,
                                      paddingLeft: 0,
                                    }}
                                  >
                                    {searchLoading ? (
                                      [1, 2, 3].map((_, i) => (
                                        <span key={i} style={{ lineHeight: 2 }}>
                                          <Skeleton.Button
                                            style={{ width: "50%" }}
                                            active
                                            size={20}
                                            block
                                            shape="round"
                                          />
                                        </span>
                                      ))
                                    ) : searchData?.tags?.length === 0 ? (
                                      <>No Result</>
                                    ) : (
                                      searchData?.tags?.map((data, i) => (
                                        <span
                                          key={i}
                                          onClick={() =>
                                            productHover(
                                              data?._id,
                                              "tag",
                                              data?.englishName
                                            )
                                          }
                                          className="subItem"
                                          style={{
                                            margin: 0,
                                            lineHeight: 2,
                                            paddingInline: 5,
                                          }}
                                        >
                                          {data?.englishName}
                                        </span>
                                      ))
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          </Col>
                          <Divider
                            orientationMargin={0}
                            orientation="center"
                            style={{
                              borderColor: "#d8d8d8",
                              height: "auto",
                              margin: width > 767 ? "0 10px" : "10px 0",
                            }}
                            type={width > 767 ? "vertical" : "horizontal"}
                          />
                          {/* <Col xs={24} sm={24} md={1} lg={1} xl={1}>
                            
                          </Col> */}
                          <Col xs={24} sm={24} md={24} lg={24} xl={14}>
                            <div className="allProducts">
                              {searchBy !== "" && (
                                <div className="searchBy">
                                  <span style={{ lineHeight: 1 }}>
                                    Recommended Products for
                                  </span>
                                  <h3
                                    style={{
                                      lineHeight: 1,
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {searchBy}
                                  </h3>
                                </div>
                              )}
                              <Divider
                                type={"horizontal"}
                                orientation="center"
                                orientationMargin={0}
                                style={{
                                  color: "#d8d8d8",
                                  borderColor: "#d8d8d8",
                                  margin: "13px 0px 10px",
                                }}
                              ></Divider>
                              <div className="productList">
                                {loadingProduct
                                  ? [1, 2, 3].map((_, i) => (
                                      <div key={i}>
                                        <Row
                                          gutter={[20, 20]}
                                          style={{ width: "100%" }}
                                          align={"middle"}
                                          justify={"space-between"}
                                        >
                                          {/* <img src={getImgVidUrl(prod?.productImage)} alt="" /> */}
                                          <Col
                                            xs={24}
                                            sm={24}
                                            md={24}
                                            lg={12}
                                            xl={6}
                                            xxl={6}
                                          >
                                            <Skeleton.Image
                                              active
                                              style={{
                                                width: "100px",
                                                height: 100,
                                              }}
                                            />
                                          </Col>
                                          <Col
                                            xs={24}
                                            sm={24}
                                            md={24}
                                            lg={12}
                                            xl={10}
                                            xxl={10}
                                          >
                                            {/* <span > */}
                                            <Skeleton.Button
                                              style={{
                                                width: "50%",
                                                display: "flex",
                                              }}
                                              active
                                              size={20}
                                              block
                                              shape="square"
                                            />
                                            <Skeleton.Button
                                              style={{
                                                width: "100%",
                                                display: "flex",
                                              }}
                                              active
                                              size={20}
                                              block
                                              shape="square"
                                            />
                                            {/* </span> */}
                                          </Col>
                                          <Col
                                            xs={24}
                                            sm={24}
                                            md={24}
                                            lg={24}
                                            xl={8}
                                            xxl={8}
                                          >
                                            <Skeleton.Button
                                              style={{
                                                width: "100%",
                                                height: 40,
                                              }}
                                              active
                                              block
                                              shape="square"
                                            />
                                          </Col>
                                        </Row>
                                      </div>
                                    ))
                                  : products?.map((prod, i) => (
                                      <div key={i} style={{ width: "100%" }}>
                                        <Row
                                          gutter={[10, 10]}
                                          style={{ width: "100%" }}
                                          align={"middle"}
                                          justify={"space-between"}
                                        >
                                          {/* <img src={getImgVidUrl(prod?.productImage)} alt="" /> */}
                                          <Col
                                            xs={24}
                                            sm={24}
                                            md={24}
                                            lg={6}
                                            xl={6}
                                            xxl={6}
                                          >
                                            <div
                                              className="imageDev"
                                              onClick={() => {
                                                setOneProduct(prod);
                                                handleNavigate(prod, navigate);
                                              }}
                                              style={{
                                                backgroundImage: `url(${getImgVidUrl(
                                                  prod?.productImage
                                                )})`,
                                                width: "auto",
                                                height: 100,
                                                cursor: "pointer",
                                              }}
                                            ></div>
                                          </Col>
                                          <Col
                                            xs={24}
                                            sm={24}
                                            md={24}
                                            lg={8}
                                            xl={10}
                                            xxl={10}
                                          >
                                            <span
                                              style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                width: "100%",
                                              }}
                                            >
                                              <h3
                                                style={{
                                                  lineHeight: 2,
                                                  textTransform: "capitalize",
                                                }}
                                                className="name"
                                              >
                                                {prod?.englishName}
                                              </h3>
                                              <span className="des">
                                                {prod?.englishDescription}
                                              </span>
                                            </span>
                                          </Col>
                                          <Col
                                            xs={24}
                                            sm={24}
                                            md={24}
                                            lg={10}
                                            xl={8}
                                            xxl={8}
                                          >
                                            <Button
                                              icon={<BsEye size={20} />}
                                              // className="productBtn"
                                              className="view-product hover-bg-red"
                                              onClick={() => {
                                                setOneProduct(prod);
                                                handleNavigate(prod, navigate);
                                                setopenSearch(false);
                                              }}
                                            >
                                              View Product
                                            </Button>
                                          </Col>
                                        </Row>
                                        <Divider
                                          type="horizontal"
                                          orientation="center"
                                          orientationMargin={0}
                                          style={{
                                            color: "#d8d8d8",
                                            borderColor: "#d8d8d8",
                                            margin: "10px 0",
                                          }}
                                        ></Divider>
                                      </div>
                                    ))}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      )}
                    </div>
                  </>
                )}
              </div>
              {Object.keys(searchData).length !== 0 && openSearch && (
                <div
                  className="overLaySearh"
                  onClick={() => setopenSearch(false)}
                ></div>
              )}
            </div>
            <div className="navBarRigt !gap-2">
              <div className="language">
                <div
                  style={{
                    color: "#231F20",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <CiPercent style={{ fontSize: 24, marginRight: 10 }} />{" "}
                  <span
                    className="leading-4"
                    style={{ fontSize: 16, fontWeight: 500 }}
                  >
                    Up to 70% on ... ?
                  </span>
                </div>
              </div>
              <div className="navbarIcons !gap-4">
                <div
                  className="navIcon"
                  onClick={() =>
                    token ? navigate("/my-profile") : navigate("/login")
                  }
                >
                  <img src="/Profile.svg" alt="logo" className="header-icon" />
                  <div className="flex flex-col justify-between self-stretch">
                    {!token ? (
                      <p className="leading-[12px] text-[#ec2025] text-xs w-[101px] whitespace-nowrap">
                        Sign In or Register
                      </p>
                    ) : (
                      <p className="leading-[12px] text-[#ec2025] text-xs" />
                    )}
                    <p className="navIconTitle1 leading-[24px]">Account</p>
                  </div>
                </div>
                {userDetails?.role?.name !== "vendor" && (
                  <div className="navIcon" onClick={() => navigate("/signup")}>
                    <img src="/Ticket.svg" alt="logo" className="header-icon" />
                    <div className=" flex flex-col justify-between self-stretch">
                      <p className=" leading-[12px] text-[#ec2025] text-xs whitespace-nowrap">
                        Become A
                      </p>
                      <p className="navIconTitle1 leading-[24px]">Vendor</p>
                    </div>
                  </div>
                )}
                <div
                  className="navIcon"
                  onClick={() => navigate("/my-wishList")}
                >
                  <img src="/Heart.svg" alt="logo" className="header-icon" />
                </div>
                <div className="navIcon" onClick={() => navigate("/my-cart")}>
                  <img src="/Buy.svg" alt="logo" className="header-icon" />
                  {/* <div className="navIconTitle">
										<p className="navIconTitle2"></p>
										<p className="navIconTitle1" style={{ fontSize: "25px" }}>
											
										</p>
									</div> */}
                  <div className=" flex flex-col justify-between self-stretch">
                    <p className=" leading-[12px] text-[#ec2025] text-xs whitespace-nowrap">
                      Total
                    </p>
                    <p className="navIconTitle1 leading-[24px]">
                      {priceDetails?.salePrice}$
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="categories">
            {AllCategoriesLoading ? (
              [1, 2, 3, 4, 5, 6].map((_, i) => (
                <div className="item" key={i}>
                  <Skeleton.Button
                    style={{ margin: "25px 10px 0" }}
                    active
                    block
                    size={20}
                    shape="round"
                  />
                </div>
              ))
            ) : (
              <>
                {AllPropertyListing?.filter(
                  (val) => val?.isActive && !val?.isDeleted
                )?.length > 0 && (
                  <div
                    onMouseLeave={() => onLeave()}
                    onMouseEnter={() =>
                      changeActive({ englishName: "real-estate" })
                    }
                    className="item"
                  >
                    <div
                      style={{
                        color:
                          itemActive === "real-estate" ? "#EC2025" : "#231F20",
                      }}
                      className="itemTitle"
                    >
                      <p
                        className="categoryName"
                        onClick={() => navigate(`/real-estate`)}
                      >
                        {realEstateCatIcon && (
                          <ImageComponent
                            image={realEstateCatIcon}
                            style={{
                              width: "20px",
                              height: "20px",
                              marginRight: "3px",
                            }}
                          />
                        )}
                        Real Estate
                      </p>
                      <span></span>
                    </div>
                  </div>
                )}
                {AllAutomotive?.filter(
                  (val) => val?.isActive && !val?.isDeleted
                )?.length > 0 && (
                  <div
                    onMouseLeave={() => onLeave()}
                    onMouseEnter={() =>
                      changeActive({ englishName: "automotive" })
                    }
                    className="item"
                  >
                    <div
                      style={{
                        color:
                          itemActive === "automotive" ? "#EC2025" : "#231F20",
                      }}
                      className="itemTitle"
                    >
                      <p
                        className="categoryName"
                        onClick={() => navigate(`/automotive`)}
                      >
                        {AutomotiveCatIcon && (
                          <ImageComponent
                            image={AutomotiveCatIcon}
                            style={{
                              width: "20px",
                              height: "20px",
                              marginRight: "3px",
                            }}
                          />
                        )}
                        Automotive
                      </p>
                      <span></span>
                    </div>
                  </div>
                )}
                {AllCategories?.filter(
                  (ele) =>
                    !isCategory([getPath(ele?.englishName)], AllCategories)
                      ?.isSubCategory
                )
                  ?.filter(
                    (val) =>
                      val?.isActive === true &&
                      !val?.isRealEstate &&
                      !val?.isAutomotive &&
                      !val?.isDeleted
                  )
                  ?.map((data, index) => {
                    // console.log("data ", data);
                    return data?.subCategory?.length > 0 &&
                      data?.subCategory?.filter(
                        (val) =>
                          val?.isActive === true &&
                          !val?.isRealEstate &&
                          !val?.isAutomotive &&
                          !val?.isDeleted
                      ).length > 0 ? (
                      <Dropdown
                        key={index}
                        menu={{
                          items: [
                            {
                              key: "1",
                              style: { padding: 0 },
                              label: (
                                <Menu
                                  onMouseEnter={() => changeActive("")}
                                  onMouseLeave={() => onLeave()}
                                  onClick={(e) => onClick(e, data)}
                                  style={{ width: 256, padding: 0 }}
                                  mode="vertical"
                                  items={getSubCategories(
                                    data?.subCategory?.length > 0
                                      ? data?.subCategory?.filter(
                                          (val) => val?.isActive === true
                                        )
                                      : [],
                                    data?.englishName
                                  )}
                                />
                              ),
                            },
                          ],
                        }}
                      >
                        <div
                          onMouseLeave={() => onLeave()}
                          onMouseEnter={() => changeActive(data)}
                          className="item"
                        >
                          <div
                            style={{
                              color:
                                itemActive === data.englishName
                                  ? "#EC2025"
                                  : "#231F20",
                            }}
                            className="itemTitle"
                          >
                            <p
                              className="categoryName"
                              onClick={() => {
                                //console.log("data 1 ", data);
                                navigate(`/${getPath(data.englishName)}`, {
                                  state: { catHome: false },
                                });
                              }}
                            >
                              {data?.icon && (
                                <ImageComponent
                                  image={data?.icon}
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                    marginRight: "3px",
                                  }}
                                />
                              )}
                              {data.englishName}
                            </p>
                            <span></span>
                          </div>

                          {openDropDown && itemActive === data.englishName ? (
                            <UpOutlined
                              style={{
                                fontSize: 10,
                                fontWeight: 200,
                                marginLeft: 5,
                                marginTop: 10,
                                color:
                                  itemActive === data.englishName
                                    ? "#EC2025"
                                    : "#231F20",
                              }}
                            />
                          ) : (
                            <>
                              <DownOutlined
                                style={{
                                  fontSize: 10,
                                  fontWeight: 200,
                                  marginLeft: 5,
                                  marginTop: 10,
                                  color:
                                    itemActive === data.englishName
                                      ? "#EC2025"
                                      : "#231F20",
                                }}
                              />
                            </>
                          )}
                        </div>
                      </Dropdown>
                    ) : (
                      data?.products?.length > 0 && (
                        <div
                          onMouseLeave={() => onLeave()}
                          onMouseEnter={() => changeActive(data)}
                          className="item"
                        >
                          <div
                            style={{
                              color:
                                itemActive === data.englishName
                                  ? "#EC2025"
                                  : "#231F20",
                            }}
                            className="itemTitle"
                          >
                            <p
                              className="categoryName"
                              onClick={() => {
                                // console.log("data 2 ", data);
                                setCategoryId(data?._id);
                                navigate(`/${getPath(data.englishName)}`, {
                                  state: { catHome: false },
                                });
                              }}
                            >
                              {data?.icon && (
                                <ImageComponent
                                  image={data?.icon}
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                    marginRight: "3px",
                                  }}
                                />
                              )}
                              {data.englishName}
                            </p>
                            <span></span>
                          </div>
                        </div>
                      )
                    );
                  })}
              </>
            )}
          </div>
        </Header>
      ) : (
        <>
          <MobileNavBar />
        </>
      )}

      <Drawer
        title=""
        placement={"left"}
        closable={false}
        onClose={onClose}
        onMouseOver={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
        open={open}
        key={"left"}
        width={165}
        mask={false}
        maskStyle={{ background: "transparent" }}
        contentWrapperStyle={{
          marginTop: "135px",
          maxHeight: "400px",
          height: "min-content",
        }}
        style={{ marginTop: "0px" }}
      >
        <Menu
          // onMouseEnter={() => changeActive(category)}
          // onMouseLeave={()=>onLeave()}
          onClick={categoriesMenu}
          className="hoverCategory"
          style={{ padding: 0 }}
          mode="vertical"
          items={getCategories(AllCategories)}
        />
      </Drawer>
    </>
  );
}

export default NavBar;
