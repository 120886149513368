import React, { useContext, useEffect, useState } from "react";
import "./carouselProducts.css";
import "react-multi-carousel/lib/styles.css";
import { Empty, Row, Col } from "antd";
import { appContext } from "../../Context/appContext";
import ProductSkeleton from "../Skeleton/ProductSkeleton";
import { handleNavigate } from "../../lib/lib";
import ProductCard from "../productItem";

function MyWishListMobile(props) {
  const [width, setWidth] = useState(window.innerWidth);
  const {
    favoritList,
    setFavoritList,
    userDetails,
    handleFavoret,
    loadingFavorit,
    loadingFavorite,
    loading,
    itemId,
  } = useContext(appContext);
  //#region useEffect
  useEffect(() => {
    // setLoadingFavoritProduct(true);
    props?.products?.map((product) => {
      if (product.favorite) {
        setFavoritList((prev) => [...prev, product._id]);
      }
    });
  }, [props?.products]);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  //#endregion useEffect

  const responsive = [
    {
      breakpoint: 2000,
      cols: 6,
      rows: props.row,
      gap: 10,
      loop: false,
      //   autoplay: 2000
    },
    {
      breakpoint: 1920,
      cols: 6,
      rows: props.row,
      gap: 10,
      loop: false,
      //   autoplay: 2000
    },
    {
      breakpoint: 1600,
      cols: 6,
      rows: props.row,
      gap: 10,
      loop: false,
      // autoplay: 2000
    },
    {
      breakpoint: 1536,
      cols: 5,
      rows: props.row,
      gap: 10,
      loop: false,
      // autoplay: 2000
    },
    {
      breakpoint: 1440,
      cols: 5,
      rows: props.row,
      gap: 10,
      loop: false,
      // autoplay: 2000
    },
    {
      breakpoint: 1366,
      cols: 4,
      rows: props.row,
      gap: 10,
      loop: false,
      // autoplay: 2000
    },
    {
      breakpoint: 1300,
      cols: 4,
      rows: props.row,
      gap: 10,
      loop: false,
      // autoplay: 2000
    },
    {
      breakpoint: 1280,
      cols: 4,
      rows: props.row,
      gap: 10,
      loop: false,
      // autoplay: 2000
    },
    {
      breakpoint: 1130,
      cols: 3,
      rows: props.row,
      gap: 10,
      loop: false,
      // autoplay: 2000
    },

    {
      breakpoint: 1000,
      cols: 3,
      rows: props.row,
      gap: 10,
      loop: false,
      // autoplay: 2000
    },
    {
      breakpoint: 900,
      cols: 2,
      rows: props.row,
      gap: 10,
      loop: false,
      // autoplay: 2000
    },
    {
      breakpoint: 800,
      cols: 2,
      rows: props.row,
      gap: 10,
      loop: false,
      // autoplay: 2000
    },
    {
      breakpoint: 700,
      cols: 2,
      rows: props.row,
      gap: 10,
      loop: false,
      // autoplay: 2000
    },
    {
      breakpoint: 660,
      cols: 2,
      rows: props.row,
      gap: 10,
      loop: false,
      // autoplay: 2000
    },
    {
      breakpoint: 600,
      cols: 2,
      rows: props.row,
      gap: 10,
      loop: false,
      // autoplay: 2000
    },

    {
      breakpoint: 550,
      cols: 2,
      rows: props.row,
      gap: 10,
      loop: false,
      // autoplay: 2000
    },
    {
      breakpoint: 500,
      cols: 2,
      rows: props.row,
      gap: 10,
      loop: false,
      // autoplay: 2000
    },
    {
      breakpoint: 400,
      cols: 2,
      rows: props.row,
      gap: 10,
      loop: false,
      // autoplay: 2000
    },
    {
      breakpoint: 350,
      cols: 1,
      rows: props.row,
      gap: 10,
      loop: false,
      // autoplay: 2000
    },
    {
      breakpoint: 300,
      cols: 1,
      rows: props.row,
      gap: 10,
      loop: false,
      // autoplay: 2000
    },
  ];
  return (
    <>
      <div
        className="categoriesProducts"
        style={{
          justifyContent:
            props?.products?.length > 2 || props?.products?.length == 1
              ? "space-between"
              : "space-around",
        }}
      >
        {loadingFavorit || props?.loading ? (
          Array(6)
            .fill(null)
            .map((_, ind) => (
              //   <div className="container">
              <ProductSkeleton />
              //   </div>
            ))
        ) : props?.products?.length > 0 ? (
          <Row gutter={[5, 5]} cols={24}>
            {props?.products?.map((product, i) => (
              <Col span={12}>
                <ProductCard
                  product={product}
                  favoritList={favoritList}
                  handleFavoret={handleFavoret}
                  handleNavigate={handleNavigate}
                  itemId={itemId}
                  loading={loading}
                  loadingFavorite={loadingFavorite}
                  userDetails={userDetails}
                  // style={{ width: "200px" }}
                />
              </Col>
            ))}
          </Row>
        ) : (
          <>
            <Empty
              style={{ width: "100%", marginTop: "50%" }}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          </>
        )}
      </div>
    </>
  );
}

export default MyWishListMobile;
