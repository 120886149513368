/* eslint-disable eqeqeq */
import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import { Image } from "antd";
import { Button, Col, Divider, Row, Skeleton, Tabs } from "antd";
import NavBar from "../../components/navBar/navBar";
import { Content } from "antd/es/layout/layout";
import BreadcrumbComp from "../../components/Breadcrumb/Breadcrumb";
import { Footer } from "../../components/Footer/Footer";
import { appContext } from "../../Context/appContext";
import { useParams } from "react-router-dom";
import { Url, phoneNumber, mapKey } from "../../Apis";
import {
  WhatsAppOutlined,
  PhoneOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";
import { getPath, chunk, addCommas, getImgVidUrl } from "../../lib/lib";
import _ from "lodash";
import "./index.css";
import { LuBath } from "react-icons/lu";
import { TfiRulerAlt2 } from "react-icons/tfi";
import { IoBedOutline } from "react-icons/io5";
import { BsMap, BsCardImage } from "react-icons/bs";
import { LoadScript, GoogleMap, Marker } from "@react-google-maps/api";
import { FaVideo } from "react-icons/fa";
import RealEstateCarousalDesktop from "./RealEstateCarousel/Desktop";
import RealEstateCarousalMobile from "./RealEstateCarousel/Mobile";
//import { PlusSquareFilled } from "@ant-design/icons";
import Gallery from "../../components/Carousel/gallery";

function Property() {
  const [width, setWidth] = useState(window.innerWidth);
  const [Loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState("");
  const [SelectVideo, setSelectVideo] = useState("");
  const [productInfo, setProductInfo] = useState("Overview");
  const [Property, setProperty] = useState({});
  const [imageList, setImageList] = useState([]);
  const { AllPropertyListing, LoadingPropertyListing } = useContext(appContext);
  const [View, setView] = useState("image"); // image, map
  const mapRef = useRef(null);
  const [map, setMap] = useState(null);
  const containerStyle = {
    width: "100%",
    height: "100%",
  };
  let center = {
    lat: 33.894,
    lng: 35.502,
  };
  const { propertyUrl } = useParams();
  useEffect(() => {
    return () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };
  }, [propertyUrl]);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  useEffect(() => {
    setSelectVideo("");
  }, [propertyUrl, AllPropertyListing]);

  useEffect(() => {
    let findProperty = AllPropertyListing?.find(
      (ele) => ele?.url === propertyUrl
    );
    console.log(findProperty);
    if (findProperty) {
      let lat = findProperty?.map[0]?.lat;
      let lng = findProperty?.map[0]?.long;
      setProperty({ ...findProperty, lat, lng });
      setSelectedImage(findProperty?.featuredImage);
      let imgs = findProperty?.images || [];
      setImageList([
        findProperty?.featuredImage,
        ...imgs?.filter((url) => url !== findProperty?.featuredImage),
      ]);
    }
    setLoading(false);
  }, [propertyUrl, AllPropertyListing, width]);
  const itemsTabs = [
    {
      key: "1",
      label: "Description",
      children: (
        <>
          <Row>
            <Col span={3}>
              <h4 style={{ fontSize: "16px" }}>Description:</h4>
            </Col>
            <Col span={21}>
              <p style={{ fontSize: "14px", whiteSpace: "pre-line" }}>
                {Property?.englishDescription}
              </p>
            </Col>
          </Row>
        </>
      ),
    },
    {
      key: "2",
      label: "Address",
      children: (
        <>
          <Row gutter={[25, 25]} style={{ marginTop: 20 }}>
            {[
              {
                label: "City:",
                value: Property?.city?.englishName,
                span: 10,
              },
              {
                label: "Address:",
                value: Property?.address,
                span: 12,
              },
            ].map((val, i) => {
              return (
                <Col key={i} span={val.span}>
                  <Row>
                    <Col span={8}>
                      <h4 style={{ fontSize: "16px" }}>{val?.label}</h4>
                    </Col>
                    <Col span={16}>
                      <p style={{ fontSize: "14px" }}>{val?.value}</p>
                    </Col>
                  </Row>
                </Col>
              );
            })}
            {/* <Col span={3}>
              <a
                target="_blank"
                href={`http://maps.google.com/?q=${Property?.address}`}
              >
                <Button
                  style={{
                    padding: "20px auto",
                    height: "50px",
                    width: "380px",
                  }}
                  className="white-btn hover-bg-red"
                  icon={<BsMap />}
                >
                  Open on Google Maps
                </Button>
              </a>
            </Col> */}
          </Row>
        </>
      ),
    },
    {
      key: "3",
      label: "Details",
      children: (
        <>
          <Row gutter={[10, 10]} style={{ marginTop: 20 }}>
            {[
              {
                label: "Price:",
                value: addCommas(Property?.price) + " $",
              },
              {
                label: "Property Size:",
                value: Property?.size + " m²",
                className: "unset-text-transform",
              },
              {
                label: "Reference No:",
                value: Property?.referenceNo,
                className: "unset-text-transform",
              },
              {
                label: "Bedrooms:",
                value: Property?.numberOfBedRooms,
              },
              {
                label: "Bathrooms:",
                value: Property?.numberOfBathRooms,
              },
              {
                label: "Property Type:",
                value: Property?.type?.englishName,
              },
              {
                label: "Status:",
                value: Property?.status
                  ?.map((val) => val?.englishName)
                  .join(", "),
                // span: 24,
                // col_1: 4,
                // col_2: 20,
              },
              {
                label: "Features:",
                value: Property?.amenities
                  ?.map((val) => val?.englishName)
                  .join(", "),
                span: 16,
                col_1: 5,
                col_2: 19,
                hide: Property?.amenities?.length === 0,
              },
            ].map((val, i) => {
              if (val?.hide) return;
              return (
                <Col key={i} span={val?.span ? val?.span : 8}>
                  <Row>
                    <Col span={val?.col_1 ? val?.col_1 : 10}>
                      <h4
                        className="unset-text-transform"
                        style={{ fontSize: "16px" }}
                      >
                        {val?.label}
                      </h4>
                    </Col>
                    <Col span={val?.col_2 ? val?.col_2 : 14}>
                      <p
                        className={val?.className || ""}
                        style={{ fontSize: "14px" }}
                      >
                        {val?.value}
                      </p>
                    </Col>
                  </Row>
                </Col>
              );
            })}
          </Row>
        </>
      ),
    },
  ];
  //#region map
  const onLoad = useCallback(function callback(map) {
    // const bounds = new window.google.maps.LatLngBounds({
    //   north: Property?.lat + 1,
    //   south: Property?.lat - 1,
    //   east: Property?.lng + 1,
    //   west: Property?.lng - 1,
    // });
    // map.fitBounds(bounds);
    setMap(map);
    mapRef.current = map;
  }, []);
  const renderMap = (
    <>
      <LoadScript googleMapsApiKey={mapKey}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          onLoad={onLoad}
          zoom={7}
          center={{
            lat: parseFloat(Property?.lat || center?.lat),
            lng: parseFloat(Property?.lng || center?.lng),
          }}
          //onUnmount={onUnmount}
        >
          <Marker
            position={{
              lat: +(Property?.lat || center?.lat),
              lng: +(Property?.lng || center?.lng),
            }}
          />
          <></>
        </GoogleMap>
      </LoadScript>
    </>
  );
  //#endregion map
  const overViewMobile = (
    <div className="productOverView">
      <Row cols={24} gutter={[10, 10]}>
        <Col span={24}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <Button
              style={{ marginRight: "10px" }}
              className={
                View === "map" ? "red-btn hover-bg-w" : "white-btn hover-bg-red"
              }
              icon={<BsMap />}
              onClick={() => setView("map")}
            />
            <Button
              className={
                View === "image"
                  ? "red-btn hover-bg-w"
                  : "white-btn hover-bg-red"
              }
              icon={<BsCardImage />}
              onClick={() => setView("image")}
            />
          </div>
        </Col>
        <Col span={24}>
          <div className="productMobileCarousel">
            {Loading ? (
              [1, 2, 3].map((_) => (
                <>
                  <Skeleton.Image
                    active
                    style={{
                      width: "220px",
                      height: "220px",
                      borderRadius: 15,
                    }}
                  />
                </>
              ))
            ) : (
              <>
                {View === "map" && (
                  <div
                    style={{
                      width: "100%",
                      height: "220px",
                      borderRadius: 15,
                    }}
                  >
                    {renderMap}
                  </div>
                )}
                {View === "image" && (
                  <>
                    {Property?.video && (
                      <div className="prodImg">
                        <video
                          controls
                          src={getImgVidUrl(Property?.video)}
                          width={"100%"}
                          height={"100%"}
                          style={{
                            border: "1px solid #fff",
                            borderRadius: "7px",
                          }}
                        />
                      </div>
                    )}
                    <Image.PreviewGroup
                      preview={{
                        onChange: (current, prev) => "",
                      }}
                    >
                      {imageList?.map((img, i) => (
                        <div key={i} className="prodImg">
                          <Image
                            loading="lazy"
                            width={"100%"}
                            className="img"
                            src={getImgVidUrl(img)}
                          />
                        </div>
                      ))}
                    </Image.PreviewGroup>
                  </>
                )}
              </>
            )}
          </div>
        </Col>
        <Col span={24}>
          <div className="productDelivery">
            <span
              style={{
                display: "flex",
                gap: 5,
                alignItems: "center",
              }}
            >
              <h4>
                <EnvironmentOutlined style={{ fontWeight: "bold" }} />
                Address : &nbsp;&nbsp;
              </h4>
              <span
                style={{
                  whiteSpace: "pre-line",
                  alignItems: "start",
                  fontSize: "14px",
                }}
              >
                {Property?.address}
              </span>
            </span>
          </div>
        </Col>
        {/* <Col span={24}>
          <a
            target="_blank"
            href={`http://maps.google.com/?q=${Property?.address}`}
          >
            <Button
              style={{
                padding: "20px auto",
                height: "50px",
              }}
              className="white-btn hover-bg-red"
              icon={<BsMap />}
            >
              Open on Google Maps
            </Button>
          </a>
        </Col> */}
        <Col span={24}>
          <div className="productDelivery">
            <span style={{ display: "flex", gap: 5 }}>
              <h4>Description:</h4>
              <span
                //className="title2"
                style={{
                  whiteSpace: "pre-line",
                  alignItems: "start",
                  fontSize: "14px",
                }}
              >
                {Property?.englishDescription}
              </span>
            </span>
          </div>
        </Col>
        <Col span={24}>
          <Divider className="categoriesDivider" orientation="left">
            Overview
          </Divider>
        </Col>
        <Col span={12}>
          <Row>
            <Col
              span={24}
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "16px",
                fontWeight: "bold",
                textTransform: "capitalize",
              }}
            >
              {Property?.type?.englishName}
            </Col>
            <Col span={24}>
              <p style={{ fontSize: "14px" }}>Property Type</p>
            </Col>
          </Row>
        </Col>
        {[
          {
            icon: <IoBedOutline size={30} className="item-icon" />,
            value: Property?.numberOfBedRooms,
            label: "Bedroom",
          },
          {
            icon: <LuBath size={30} className="item-icon" />,
            value: Property?.numberOfBathRooms,
            label: "Bathroom",
          },
          {
            icon: <TfiRulerAlt2 size={30} className="item-icon" />,
            value: Property?.size,
            label: "m²",
          },
        ].map((val, i) => (
          <Col key={i} span={12} className="unset-text-transform">
            <Row>
              <Col
                span={24}
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                {val.icon} &nbsp; {val.value}
              </Col>
              <Col span={24}>
                <p style={{ fontSize: "14px" }}>{val.label}</p>
              </Col>
            </Row>
          </Col>
        ))}
        <Row cols={24} gutter={[10, 10]}>
          <Col span={12}>
            <a href={`tel:+${phoneNumber}`} target="_blank" rel="noreferrer">
              <Button
                style={{
                  padding: "20px auto",
                  height: "45px",
                  width: "150px",
                }}
                className="red-btn hover-bg-w"
                icon={<PhoneOutlined />}
              >
                Call
              </Button>
            </a>
          </Col>
          <Col span={12}>
            <a
              href={`https://api.whatsapp.com/send?phone=${phoneNumber}`}
              target="_blank"
              rel="noreferrer"
            >
              <Button
                style={{
                  padding: "20px auto",
                  height: "45px",
                  width: "150px",
                }}
                className="Whatsapp-btn hover-Whats"
                icon={<WhatsAppOutlined />}
              >
                Whatsapp
              </Button>
            </a>
          </Col>
        </Row>
      </Row>
    </div>
  );
  const DetailsMobile = (
    <Row
      cols={24}
      gutter={10}
      style={{ marginBottom: "10px", marginTop: "10px" }}
    >
      {[
        {
          label: "Size:",
          value: Property?.size + " m²",
          className: "unset-text-transform",
        },
        {
          label: "Bedrooms:",
          value: Property?.numberOfBedRooms,
        },
        {
          label: "Bathrooms:",
          value: Property?.numberOfBathRooms,
        },
        {
          label: "Reference No:",
          value: Property?.referenceNo,
          className: "unset-text-transform",
        },
        {
          label: "City:",
          value: Property?.city?.englishName,
        },
        {
          label: "Address:",
          value: Property?.address,
        },
        {
          label: "Property Type:",
          value: Property?.type?.englishName,
        },
        {
          label: "Property Status:",
          value: Property?.status?.map((val) => val?.englishName).join(", "),
        },
        {
          label: "Features:",
          value: Property?.amenities?.map((val) => val?.englishName).join(", "),
          hide: Property?.amenities?.length === 0,
        },
      ].map((ele, i) => {
        if (ele?.hide) return;
        return (
          <Col key={i} span={24} style={{ marginBottom: "10px" }}>
            <Row cols={24} gutter={10}>
              <Col span={8}>
                <h4>{ele?.label}</h4>
              </Col>
              <Col span={16} className={ele?.className || ""}>
                {Loading ? (
                  <Skeleton.Button size={20} block active />
                ) : (
                  ele?.value
                )}
              </Col>
            </Row>
          </Col>
        );
      })}
    </Row>
  );
  console.log("Property ", Property);
  return (
    <>
      <NavBar />
      <Content className="proerty-container capitalize-text">
        {width > 767 ? (
          <Content
            style={{
              // width: width > 767 ? "71%" : "85%",
              // width: `calc(${width}px - 15%)`,
              width: `calc(${width}px - ${width < 1200 ? 20 : 30}%)`,
              margin: "auto",
            }}
          >
            <div className="product">
              <div className="productGalary">
                <Row gutter={[24, 24]} justify={"center"}>
                  <Col xs={24} sm={24} md={24} lg={13}>
                    <div className="productCarousel">
                      {Loading ? (
                        <>
                          <div style={{ width: "65%" }} className="imgSkelton">
                            <Skeleton.Image
                              active
                              style={{
                                width: "100%",
                                height: "100%",
                                borderRadius: 15,
                              }}
                            />
                          </div>
                          <div className="imageSelected">
                            <div className="oneImage">
                              <Skeleton.Image active />
                            </div>
                            <div className="oneImage">
                              <Skeleton.Image active />
                            </div>
                            <div
                              className="oneImage"
                              style={{ position: "relative" }}
                            >
                              <Skeleton.Image active />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            className="imageCard"
                            style={{ flex: "1", position: "relative" }}
                          >
                            {View === "image" && (
                              <>
                                {SelectVideo ? (
                                  <video
                                    height={"100%"}
                                    width={"100%"}
                                    src={getImgVidUrl(Property?.video)}
                                    controls
                                    autoPlay
                                  />
                                ) : (
                                  <>
                                    <Gallery
                                      images={imageList}
                                      selectedImage={selectedImage}
                                      setSelectedImage={setSelectedImage}
                                    />
                                  </>
                                )}
                              </>
                            )}
                            {View === "map" && <>{renderMap}</>}
                            <div
                              style={{
                                position: "absolute",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-around",
                                top: 10,
                                right: 5,
                              }}
                            >
                              <Button
                                style={{ marginRight: "5px" }}
                                className={
                                  View === "map"
                                    ? "red-btn hover-bg-w"
                                    : "white-btn hover-bg-red"
                                }
                                icon={<BsMap />}
                                onClick={() => setView("map")}
                              />
                              <Button
                                className={
                                  View === "image"
                                    ? "red-btn hover-bg-w"
                                    : "white-btn hover-bg-red"
                                }
                                icon={<BsCardImage />}
                                onClick={() => setView("image")}
                              />
                            </div>
                          </div>
                          <div className="imageSelected">
                            {Property?.video && (
                              <div
                                className="oneImage"
                                style={{ position: "relative" }}
                              >
                                <video
                                  pause
                                  src={getImgVidUrl(Property?.video)}
                                  onClick={() => {
                                    setSelectedImage("");
                                    setSelectVideo(Property?.video);
                                  }}
                                />
                                <FaVideo
                                  size={25}
                                  style={{
                                    position: "absolute",
                                    bottom: 5,
                                    right: 10,
                                    color: "#ec2025",
                                  }}
                                />
                              </div>
                            )}
                            {imageList?.map((img, i) => (
                              <div
                                key={i}
                                className="oneImage"
                                style={{
                                  backgroundImage: `url(${getImgVidUrl(img)})`,
                                  opacity: selectedImage !== img && "0.5",
                                  position: "relative",
                                }}
                                onClick={() => {
                                  setSelectedImage(img);
                                  setSelectVideo("");
                                }}
                              >
                                {/* {imageList?.length > 3 && i === 2 && (
                                  <PlusSquareFilled
                                    onClick={() => setVisible(true)}
                                    style={{
                                      position: "absolute",
                                      bottom: 5,
                                      right: 10,
                                      color: "#ec2025",
                                      fontSize: "24px",
                                      backgroundColor: "#fff",
                                    }}
                                  />
                                )} */}
                              </div>
                            ))}
                            {((imageList?.length === 2 && !Property?.video) ||
                              (imageList?.length === 1 && Property?.video)) && (
                              <div
                                className="oneImage"
                                style={{ visibility: "hidden" }}
                              />
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </Col>
                  <Col span={2} />
                  <Col xs={24} sm={24} md={24} lg={9}>
                    <BreadcrumbComp />
                    <div className="productData" style={{ height: "96%" }}>
                      <div className="productDataDetails">
                        <span className="productName property-name">
                          {Loading ? (
                            <Skeleton.Button size={20} block active />
                          ) : (
                            Property?.englishName
                          )}
                        </span>
                      </div>
                      <div className="productPrice">
                        <span className="title1">
                          $
                          {Loading ? (
                            <Skeleton.Button size={20} block active />
                          ) : (
                            addCommas(Property?.price)
                          )}
                        </span>
                      </div>
                      <div className="productDelivery">
                        <span
                          style={{
                            display: "flex",
                            gap: 5,
                            alignItems: "center",
                          }}
                        >
                          <span className="title2">
                            <EnvironmentOutlined
                              style={{ fontWeight: "bold" }}
                            />{" "}
                            Address:
                          </span>
                          <span className="title2">{Property?.address}</span>
                        </span>
                      </div>
                      <div
                        className="productRequest"
                        style={{ marginTop: "auto" }}
                      >
                        <div
                          className="shoppingBtn"
                          style={{
                            marginTop: "1em",
                            width: "100%",
                            display: "flex",
                          }}
                        >
                          <a
                            href={`tel:+${phoneNumber}`}
                            target="_blank"
                            rel="noreferrer"
                            style={{ width: "100%" }}
                          >
                            <Button
                              style={{
                                padding: "20px auto",
                                height: "50px",
                                // width: "380px",
                                width: "100%",
                              }}
                              className="red-btn hover-bg-w"
                              icon={<PhoneOutlined />}
                            >
                              Call
                            </Button>
                          </a>
                        </div>
                        <div
                          className="shoppingBtn"
                          style={{
                            marginTop: "1em",
                            width: "100%",
                            display: "flex",
                          }}
                        >
                          <a
                            href={`https://api.whatsapp.com/send?phone=${phoneNumber}`}
                            target="_blank"
                            rel="noreferrer"
                            style={{ width: "100%" }}
                          >
                            <Button
                              style={{
                                padding: "20px auto",
                                height: "50px",
                                // width: "380px",
                                width: "100%",
                              }}
                              className="Whatsapp-btn hover-Whats"
                              icon={<WhatsAppOutlined />}
                            >
                              Whatsapp
                            </Button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <Row gutter={[10, 10]} style={{ marginTop: "10px" }}>
                <Col xs={24} sm={24} md={24}>
                  {/* <Divider className="categoriesDivider" /> */}
                  <Tabs defaultActiveKey="1" items={itemsTabs} />
                </Col>
                <Col span={24}>
                  <Divider className="categoriesDivider" orientation="left">
                    Overview
                  </Divider>
                </Col>
                <Col span={6}>
                  <Row>
                    <Col
                      span={24}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "20px",
                        fontWeight: "bold",
                        textTransform: "capitalize",
                      }}
                    >
                      {Property?.type?.englishName}
                    </Col>
                    <Col span={24}>
                      <p style={{ fontSize: "20px" }}>Property Type</p>
                    </Col>
                  </Row>
                </Col>
                {[
                  {
                    icon: <IoBedOutline size={30} className="item-icon" />,
                    value: Property?.numberOfBedRooms,
                    label: "Bedroom",
                  },
                  {
                    icon: <LuBath size={30} className="item-icon" />,
                    value: Property?.numberOfBathRooms,
                    label: "Bathroom",
                  },
                  {
                    icon: <TfiRulerAlt2 size={30} className="item-icon" />,
                    value: Property?.size,
                    label: "m²",
                  },
                ].map((val, i) => (
                  <Col key={i} span={6}>
                    <Row className="unset-text-transform">
                      <Col
                        span={24}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "24px",
                          fontWeight: "bold",
                        }}
                      >
                        {val.icon} &nbsp; {val.value}
                      </Col>
                      <Col span={24}>
                        <p style={{ fontSize: "20px" }}>{val.label}</p>
                      </Col>
                    </Row>
                  </Col>
                ))}
              </Row>
              <Divider className="categoriesDivider" orientation="left">
                Similar Listings
              </Divider>
              <RealEstateCarousalDesktop
                // AllPropertyListing={AllPropertyListing}
                // LoadingPropertyListing={LoadingPropertyListing}
                // number={4}
                // span={6}
                AllPropertyListing={AllPropertyListing || []}
                LoadingPropertyListing={LoadingPropertyListing}
                number={width >= 1300 ? 4 : width >= 992 ? 3 : 3}
                span={width >= 1300 ? 4 : width >= 992 ? 3 : 3}
              />
            </div>
          </Content>
        ) : (
          <Content
            style={{
              // width: width > 767 ? "71%" : "85%",
              width: `calc(${width}px - 10%)`,
              margin: "80px auto",
            }}
          >
            <div className="productMobile" style={{ marginBottom: 130 }}>
              <div
                className="productMpbileData"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "5px",
                }}
              >
                <h3 style={{ color: "#ec2025" }}>
                  {Loading ? (
                    <Skeleton.Button size={20} block active />
                  ) : (
                    Property?.englishName
                  )}
                </h3>
                <div className="pricetitle">
                  {Loading ? (
                    <Skeleton.Button size={20} block active />
                  ) : (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <p style={{ fontSize: "18px", fontWeight: "bold" }}>
                        {addCommas(Property?.price)} $
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div
                style={{ display: "flex", marginTop: "5px" }}
                className="my-profile-btns-mobile"
              >
                <Button
                  className="infoBtn"
                  style={{
                    fontSize: "13px",
                    marginRight: "10px",
                    backgroundColor:
                      productInfo === "Overview"
                        ? "rgba(236, 32, 37, 1)"
                        : "transparent",
                    color: productInfo === "Overview" ? "white" : "black",
                  }}
                  onClick={() => setProductInfo("Overview")}
                >
                  Overview
                </Button>
                <Button
                  className="infoBtn"
                  style={{
                    fontSize: "13px",
                    marginLeft: "10px",
                    backgroundColor:
                      productInfo === "Details"
                        ? "rgba(236, 32, 37, 1)"
                        : "transparent",
                    color: productInfo === "Details" ? "white" : "black",
                  }}
                  onClick={() => setProductInfo("Details")}
                >
                  Details
                </Button>
              </div>
              {productInfo === "Overview" ? <>{overViewMobile}</> : null}
              {productInfo === "Details" && <>{DetailsMobile}</>}
              <Divider className="categoriesDivider" orientation="left">
                Similar Listings
              </Divider>
              <RealEstateCarousalMobile
                AllPropertyListing={AllPropertyListing}
                LoadingPropertyListing={LoadingPropertyListing}
                number={2}
                span={12}
              />
            </div>
          </Content>
        )}
      </Content>

      {/* <Image.PreviewGroup
        items={Property?.images?.map((imgURL) => Url + imgURL)}
        preview={{
          visible,
          onVisibleChange: (value) => {
            setVisible(value);
          },
        }}
      ></Image.PreviewGroup> */}
      <Footer />
    </>
  );
}

export default Property;
