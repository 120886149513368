import React, { useContext, useEffect, useState } from "react";
import { appContext } from "../../Context/appContext";
//import { useParams } from "react-router-dom";
import {
  Collapse,
  Divider,
  Skeleton,
  Slider,
  Row,
  Col,
  Select,
  Button,
  Tooltip,
  InputNumber,
  Input,
} from "antd";
import { BsFunnel } from "react-icons/bs";
import _ from "lodash";
import { sortArray, sortArrayAsc, sortArrayOfObjects } from "../../lib/lib";
import { HiMinusSm, HiPlusSm } from "react-icons/hi";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import "./index.css";

//import Slider, { Range } from "rc-slider";
//import "rc-slider/assets/index.css";

function FilterComp({ setOpen, setCurrentPage }) {
  const {
    getAllPropertyListing,
    PropertyListingFilter,
    LoadingPropertyListingFilter,
    sort,
    setSort,
  } = useContext(appContext);
  const realEstateFilterLocalStorage = localStorage.getItem("realEstateFilter");
  const [FilterValues, setFilterValues] = useState(
    JSON.parse(realEstateFilterLocalStorage) || {
      status: [],
      type: [],
      amenities: [],
      city: [],
      priceMin: undefined,
      priceMax: undefined,
      areaMin: undefined,
      areaMax: undefined,
      search: undefined,
      numberOfBedRoomsMin: undefined,
      numberOfBedRoomsMax: undefined,
      numberOfBathRoomsMin: undefined,
      numberOfBathRoomsMax: undefined,
    }
  );
  console.log("filters ", PropertyListingFilter);
  //   console.log("FilterValues ", FilterValues);
  const [width, setWidth] = useState(window.innerWidth);
  const [minMax, setMinMax] = useState({
    min: 0,
    max: 1000,
  });
  const [Prices, setPrices] = useState([]);
  const [sortedVal, setSortedVal] = useState("");
  const [search, setSearch] = useState(
    JSON.parse(realEstateFilterLocalStorage)?.search || ""
  );
  const items = [
    {
      label: (
        <span>
          Name <AiOutlineArrowDown />
        </span>
      ),
      key: "name-asc",
    },
    {
      label: (
        <span>
          Name <AiOutlineArrowUp />
        </span>
      ),
      key: "name-desc",
    },
    {
      label: (
        <span>
          Price <AiOutlineArrowDown />
        </span>
      ),
      key: "price-asc",
    },
    {
      label: (
        <span>
          Price <AiOutlineArrowUp />
        </span>
      ),
      key: "price-desc",
    },
  ];

  // console.log("realEstateFilter", JSON.parse(realEstateFilterLocalStorage));
  //#region useEffect
  useEffect(() => {
    // onClearFilter();
    if (PropertyListingFilter) {
      setFilterValues((prev) => ({
        ...prev,
        price: sortArrayAsc(PropertyListingFilter?.price || []),
      }));
      let min = Math.min(...(PropertyListingFilter?.price || []));
      let max = Math.max(...(PropertyListingFilter?.price || []));
      setPrices([min, max]);
      setMinMax({
        min: min,
        max: max,
      });
    }
  }, [PropertyListingFilter]);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  useEffect(() => {
    let _FilterValues = { ...FilterValues };
    delete _FilterValues["price"];
    getAllPropertyListing({ ..._FilterValues, sort: sort });
    setCurrentPage(1);
    window.localStorage.setItem(
      "realEstateFilter",
      JSON.stringify({
        ..._FilterValues,
        sort: sort,
      })
    );
  }, [FilterValues, sort]);

  //#endregion useEffect
  //#region func
  const onClearFilter = () => {
    let min = Math.min(...(PropertyListingFilter?.price || []));
    let max = Math.max(...(PropertyListingFilter?.price || []));
    setPrices([min, max]);
    setFilterValues({
      // tag: [],
      status: [],
      type: [],
      amenities: [],
      city: [],
      priceMin: undefined,
      priceMax: undefined,
      areaMin: undefined,
      areaMax: undefined,
      search: undefined,
      price: sortArrayAsc(PropertyListingFilter?.price || []),
      numberOfBedRoomsMin: undefined,
      numberOfBedRoomsMax: undefined,
      numberOfBathRoomsMin: undefined,
      numberOfBathRoomsMax: undefined,
    });
  };

  const renderOptions = (key, type, options, minKey, maxKey) => {
    let filteredOptions = sortArray(
      [...options]?.filter(
        (ele) => ele !== null && ele !== undefined && ele !== ""
      )
    );
    //console.log("filteredOptions ", filteredOptions);

    // if (filteredOptions?.length === 0) return;
    if (type === "dropdown") {
      return (
        <Row>
          <Col span={24}>
            <Select
              defaultValue={FilterValues[key]}
              value={FilterValues[key]}
              onChange={(value) => onChangeOption(value, key)}
              style={{ width: "100%" }}
              placeholder="Select option"
              options={filteredOptions?.map((ele) => ({
                value: ele,
                label: ele,
              }))}
              allowClear
            />
          </Col>
        </Row>
      );
    } else if (type === "multiSelect") {
      return (
        <Row>
          <Col span={24}>
            <Select
              mode="multiple"
              showSearch
              allowClear
              defaultValue={FilterValues[key]}
              value={FilterValues[key]}
              onChange={(value) => onChangeOption(value, key)}
              style={{ width: "100%" }}
              placeholder="Select option"
              // onSearch={(value) => {
              //   console.log("search:", value);
              // }}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={sortArrayOfObjects(filteredOptions, "englishName")?.map(
                (ele) => ({
                  value: ele?._id,
                  label: ele?.englishName,
                })
              )}
            />
          </Col>
        </Row>
      );
    } else if (type === "min-max") {
      return (
        <Row gutter={[5, 5]}>
          <Col span={12}>
            <InputNumber
              style={{ width: "100%" }}
              placeholder="Min"
              min={0}
              value={FilterValues[minKey]}
              onChange={(value) => {
                setFilterValues((prev) => ({
                  ...prev,
                  [minKey]: value,
                }));
              }}
            />
          </Col>
          <Col span={12}>
            <InputNumber
              style={{ width: "100%" }}
              placeholder="Max"
              min={0}
              value={FilterValues[maxKey]}
              onChange={(value) => {
                setFilterValues((prev) => ({
                  ...prev,
                  [maxKey]: value,
                }));
              }}
            />
          </Col>
        </Row>
      );
    } else if (type === "number") {
      return (
        <Row gutter={[5, 5]}>
          <Col span={24}>
            <InputNumber
              style={{ width: "100%" }}
              placeholder="Input number"
              min={0}
              value={FilterValues?.key}
              onChange={(value) => {
                setFilterValues((prev) => ({
                  ...prev,
                  [key]: value,
                }));
              }}
            />
          </Col>
        </Row>
      );
    }
  };
  const circleLoadingFilter = (
    <>
      <Row>
        {Array(3)
          .fill(null)
          .map((_, i) => (
            <Col span={4} key={i}>
              <Skeleton.Avatar shape="circle" size={50} />
            </Col>
          ))}
      </Row>
    </>
  );
  const onChangeOption = (value, key) => {
    if (FilterValues[key] === value) {
      setFilterValues((prev) => ({
        ...prev,
        [key]: undefined,
      }));
    } else {
      setFilterValues((prev) => ({
        ...prev,
        [key]: value,
      }));
    }
  };
  const renderItems = () => {
    return [
      {
        key: "Search",
        label: <span className="categoriesItemCollapse">Search</span>,
        children: (
          <>
            {LoadingPropertyListingFilter ? (
              circleLoadingFilter
            ) : (
              <Input
                placeholder="Enter property name"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  setFilterValues((prev) => ({
                    ...prev,
                    search: e.target.value,
                  }));
                }}
                style={{ width: "100%" }}
              />
            )}
          </>
        ),
      },
      ...[
        { key: "Location", value: "city", option: "city" },
        //{ key: "Tags", value: "tag", option: "tag" },
        { key: "Status", value: "status", option: "status" },
        { key: "Type", value: "type", option: "type" },
        { key: "Amenities", value: "amenities", option: "amenities" },
      ]?.map((val) => ({
        key: val.key,
        label: <span className="categoriesItemCollapse">{val.key}</span>,
        children: (
          <>
            {LoadingPropertyListingFilter
              ? circleLoadingFilter
              : renderOptions(
                  val.value,
                  "multiSelect",
                  PropertyListingFilter[val.option] || []
                )}
          </>
        ),
      })),
      ...[
        { key: "Area", type: "min-max", minKey: "areaMin", maxKey: "areaMax" },
        {
          key: "Bedrooms",
          type: "min-max",
          minKey: "numberOfBedRoomsMin",
          maxKey: "numberOfBedRoomsMax",
        },
        {
          key: "Bathrooms",
          type: "min-max",
          minKey: "numberOfBathRoomsMin",
          maxKey: "numberOfBathRoomsMax",
        },
      ].map((val) => ({
        key: val.key,
        label: <span className="categoriesItemCollapse">{val.key}</span>,
        children: (
          <>
            {LoadingPropertyListingFilter
              ? circleLoadingFilter
              : renderOptions("", "min-max", [], val.minKey, val.maxKey)}
          </>
        ),
      })),
      {
        key: "Price",
        label: <span className="categoriesItemCollapse">Price</span>,
        children: (
          <>
            <Slider
              range={{ draggableTrack: true }}
              defaultValue={[minMax.min, minMax.max]}
              value={Prices}
              min={minMax.min}
              max={minMax.max}
              style={{ maxWidth: "100%" }}
              //step={null}
              marks={{
                [Prices?.length > 0 ? Prices[0] : 0]: `${
                  Prices?.length > 0 ? Prices[0] : 0
                }`,
                [Prices?.length > 1 ? Prices[1] : 0]: `${
                  Prices?.length > 1 ? Prices[1] : 0
                }`,
              }}
              onChange={(newValue) => {
                setPrices(newValue);
                setFilterValues((prev) => ({
                  ...prev,
                  price: newValue,
                  priceMin: newValue[0],
                  priceMax: newValue[1],
                }));
              }}
            />
          </>
        ),
      },
    ];
  };
  const sortFunc = (value) => {
    if (sortedVal != value) {
      setSortedVal(value);
    } else {
      setSortedVal("");
    }
    setSort(value);
  };
  const sortCollapseArr = [
    {
      // key: "1",
      label: (
        <span
          style={{
            fontSize: "16px",
            textAlign: "end",
            display: "inline-block",
            width: "100%",
          }}
          className="categoriesItemCollapse"
        >
          Sort By Name/Price
        </span>
      ),
      children: (
        <div className="categoriesOffer">
          <>
            {items?.map((off, i) => (
              <div className="offerTitle1" key={i}>
                <span style={{ color: sortedVal == off?.key && "#EC2025" }}>
                  {off?.label}
                </span>
                {sortedVal == off.key ? (
                  <HiMinusSm
                    className="selectCat"
                    style={{ color: "#231F20" }}
                    onClick={() => sortFunc(off?.key)}
                  />
                ) : (
                  <HiPlusSm
                    className="selectCat"
                    style={{ color: "#231F20" }}
                    onClick={() => sortFunc(off?.key)}
                  />
                )}
              </div>
            ))}
          </>
        </div>
      ),
    },
  ];
  //#endregion func
  return (
    <>
      <div className="categoriesFilter">
        {width > 767 ? (
          <>
            <div className="categoryFilterTitle">
              <div>
                <span className="title1" style={{ marginRight: "5px" }}>
                  Filter
                </span>
                <Tooltip title="Clear filter">
                  <Button
                    onClick={onClearFilter}
                    type="text"
                    shape="circle"
                    icon={<BsFunnel size={18} />}
                  />
                </Tooltip>
              </div>
              <img src="/Group 10.svg" alt="" />
            </div>
            <div className="dividerPading">
              <Divider className="categoriesDivider" />
            </div>
          </>
        ) : (
          <>
            <div
              className="categoryFilterTitle"
              style={{
                marginLeft: "15px",
                marginRight: "30px",
              }}
            >
              <div>
                <span className="title1" style={{ marginRight: "5px" }}>
                  Filter
                </span>
                <Tooltip title="Clear filter">
                  <Button
                    onClick={onClearFilter}
                    type="text"
                    shape="circle"
                    icon={<BsFunnel size={18} />}
                  />
                </Tooltip>
              </div>
              <img src="/Group 10.svg" alt="" />
            </div>
            <div className="dividerPading">
              <Divider className="categoriesDivider" />
            </div>
            <div
              className="categorisFilterCollapse"
              style={{ margin: width > 767 ? "0px" : "0 30px 0 10px" }}
            >
              <Collapse
                defaultActiveKey={[...sortCollapseArr.map((data) => data.key)]}
                ghost
                items={sortCollapseArr}
                expandIconPosition="end"
              />
            </div>
          </>
        )}
        <div
          style={{ margin: width > 767 ? "0px" : "0 30px 0 10px" }}
          className="categorisFilterCollapse"
        >
          {!LoadingPropertyListingFilter ? (
            <Collapse
              defaultActiveKey={[
                "Search",
                "Location",
                "Status",
                "Type",
                "Amenities",
                "Bedrooms",
                "Bathrooms",
                "Area",
                "Price",
              ]}
              ghost
              items={renderItems()}
              expandIconPosition="end"
            />
          ) : (
            <>{circleLoadingFilter}</>
          )}

          {width <= 767 && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Button
                style={{
                  padding: "20px auto",
                  height: "50px",
                  width: "150px",
                }}
                className="red-btn hover-bg-w"
                onClick={() => setOpen(false)}
              >
                Apply
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default FilterComp;
