import React, { useContext, useEffect, useState } from "react";
import "./mySingleOrder.css";
import BreadcrumbComp from "../../components/Breadcrumb/Breadcrumb";
import NavBar from "../../components/navBar/navBar";
import { Footer } from "../../components/Footer/Footer";
import { Content } from "antd/es/layout/layout";
import { Button, Input, Skeleton, Col, Row, ColorPicker } from "antd";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { HiOutlineLocationMarker } from "react-icons/hi";
import "react-phone-input-2/lib/style.css";
import { useLocation, useNavigate } from "react-router-dom";
import { CiWarning } from "react-icons/ci";
import ProfileComp from "../../components/Profile/ProfileComp";
import { appContext } from "../../Context/appContext";
import { getPath } from "../../lib/lib";
function MySingleOrder() {
  const [width, setWidth] = useState(window.innerWidth);
  const location = useLocation();
  const navigate = useNavigate();
  const {
    oneOrder,
    oneOrderLoading,
    getOneOrder,
    userDetails,
    priceDetails,
    AllCategories,
    shippingPrice,
  } = useContext(appContext);
  const orderId = location.state ? location.state : "";

  const promoSchema = yup.object().shape({
    deliveryAddress: yup
      .string("Invalid Delivery Address!")
      .required("Delivery Address is required!"),
  });
  const {
    register: register,
    handleSubmit: handleSubmit,
    formState: { errors: errors },
    control,
  } = useForm({
    resolver: yupResolver(promoSchema),
  });
  // console.log(errors);
  //#region useEffect
  useEffect(() => {
    // console.log("order:",oneOrder)
    if (orderId) {
      getOneOrder(orderId);
    }
  }, [orderId]);
  useEffect(() => {
    return () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };
  }, []);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  //#endregion useEffect
  const onsubmit = (data) => {
    // console.log(data);
  };
  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userData");
    navigate("/login");
  };
  //console.log(" order ", oneOrder);
  return (
    <>
      <NavBar />
      <Content style={{ width: "80%", margin: "auto" }}>
        <div className="myProfile">
          <BreadcrumbComp />
          <div className="profileComponents">
            <div className="profileinfo">
              <div className="userInfo">
                <ProfileComp />
                <div className="userInfoBtn">
                  <Button
                    className="infoBtn white-btn hover-bg-red"
                    onClick={() => logout()}
                  >
                    Log Out
                  </Button>
                </div>
              </div>
              {userDetails?.role?.name == "user" &&
              userDetails !== undefined ? (
                <div className="userVendormySingleOrder">
                  <div className="vendorImg">
                    <img src="/Fill 7.svg" alt="" />
                  </div>
                  <div className="vendorTitle">
                    <span>Become a Vendor !</span>
                  </div>
                  <div className="vendorDescription">
                    <p>
                      Lorem ipsum dolor sit amet consectetur.
                      <br />
                      amet fermentum egestas facilisis cras egestas. Netus diam
                      porta commodo massa lectus massa magna. Gravida tortor
                      metus sapien blandit feugiat odio potenti iaculis. Arcu
                      pellentesque tellus suspendisse leo nibh. Venenatis id
                      imperdiet ipsum mollis morbi ultrices lacus. Tristique
                      sagittis sed eget elit in id amet nam. Mauris purus nibh
                      habitant sed amet. Vivamus fusce amet auctor enim.
                      Venenatis et consectetur malesuada amet.
                    </p>
                  </div>
                  <div className="vendorBtnComp">
                    <small className="vendorHint">
                      Read Terms and Conditions
                    </small>
                    <Button
                      className="vendorBtn"
                      onClick={() => navigate("/become-a-vendor")}
                    >
                      Become a Vendor
                    </Button>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="profileForm">
              <div className="profileFormComp">
                <div className="mySingleOrder">
                  <div className="mySingleOrderTitle">
                    <div className="title1">
                      Order ID: {oneOrder?.orderNumber}
                      <p className="datetitle">
                        {oneOrderLoading ? (
                          <Skeleton.Button size={20} block active />
                        ) : (
                          oneOrder?.chosenDate
                        )}
                      </p>
                    </div>

                    <div
                      className="note"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <CiWarning style={{ fontSize: 18, margin: "0 3px" }} />{" "}
                      Report Issue !
                    </div>
                  </div>
                  <div
                  //className="mySingleOrderTable"
                  >
                    <Row gutter={20} style={{ margin: "15px 0px" }}>
                      <Col
                        xs={6}
                        style={{
                          color: "#EC2025",
                          //float: "right",
                          padding: "0px 10px",
                          fontStyle: "italic",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        Product Details
                      </Col>
                      <Col
                        xs={5}
                        style={{
                          // borderLeft:"1px solid rgba(236, 32, 37, 1)",
                          color: "#EC2025",
                          //float: "right",
                          padding: "0px 10px",
                          fontStyle: "italic",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        Item Price
                      </Col>
                      <Col
                        xs={8}
                        style={{
                          padding: "0px 10px",
                          color: "#EC2025",
                          //float: "right",
                          // borderLeft:"1px solid rgba(236, 32, 37, 1)",
                          fontStyle: "italic",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        Your Specifications
                      </Col>
                      <Col
                        xs={5}
                        style={{
                          padding: "0px 10px",
                          color: "#EC2025",
                          //float: "right",
                          //borderLeft:"1px solid rgba(236, 32, 37, 1)",
                          fontStyle: "italic",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        Status
                      </Col>
                    </Row>
                    {oneOrder?.orderDetails?.length === 0 ? (
                      <Row>
                        <Col
                          xs={24}
                          style={{
                            color: "#ccc",
                            fontSize: 18,
                            fontWeight: 500,
                            textAlign: "center",
                          }}
                        >
                          No Data
                        </Col>
                      </Row>
                    ) : (
                      oneOrder?.orderDetails?.map((order, i) => {
                        let detail = order?.product?.details?.find(
                          (ele) => Number(ele?.price) === Number(order?.price)
                        );
                        let category =
                          AllCategories?.find(
                            (ele) => ele?._id === order?.product?.category
                          )?.englishName || "";
                        let url = `${category ? "/" + getPath(category) : ""}/${
                          order?.product?.URL
                        }`;
                        return (
                          <>
                            <Row key={i} gutter={20} style={{ margin: "0px" }}>
                              <Col
                                xs={6}
                                className="suborderHistoryTitle2"
                                style={{
                                  color: "rgba(236, 32, 37, 1)",
                                  cursor: "pointer",
                                }}
                                onClick={() => navigate(url)}
                              >
                                {order?.product?.englishName}
                              </Col>
                              <Col
                                xs={5}
                                className="suborderHistoryTitle2"
                                style={{
                                  borderLeft: "1px solid rgba(236, 32, 37, 1)",
                                }}
                              >
                                {order?.price} $
                              </Col>
                              <Col
                                xs={8}
                                className="suborderHistoryTitle2"
                                style={{
                                  borderLeft: "1px solid rgba(236, 32, 37, 1)",
                                }}
                              >
                                <div className="specificationsComp">
                                  {Object.keys(detail || {})?.map((key, i) => {
                                    if (key !== "quantity" && key !== "price")
                                      if (key?.toLowerCase() === "color") {
                                        return (
                                          <span
                                            className="specifications"
                                            key={i}
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            {key}:{" "}
                                            <ColorPicker
                                              style={{ marginLeft: "3px" }}
                                              size="small"
                                              defaultValue={"#" + detail[key]}
                                            />
                                          </span>
                                        );
                                      } else {
                                        return (
                                          <span
                                            className="specifications"
                                            key={i}
                                          >
                                            {key}: {detail[key]}
                                          </span>
                                        );
                                      }
                                  })}
                                  {/* <span className="specifications" key={i}>
                                    Size:{" "}
                                    {order?.product?.details[0]?.sizes[0]?.size}
                                  </span>

                                  <span className="specifications" key={i}>
                                    Color: {order?.product?.color}
                                  </span>

                                  <span className="specifications" key={i}>
                                    QTY Applied
                                  </span> */}
                                </div>
                              </Col>
                              <Col
                                xs={5}
                                className="suborderHistoryTitle2"
                                style={{
                                  color:
                                    oneOrder?.status == "PROCESSING"
                                      ? "#f6a94a"
                                      : oneOrder?.status == "CANCELLED"
                                      ? "rgb(220, 53, 69)"
                                      : "rgba(3, 204, 48, 1)",
                                  borderLeft: "1px solid rgba(236, 32, 37, 1)",
                                  padding: "7px",
                                }}
                              >
                                {oneOrder?.status}
                              </Col>
                            </Row>
                          </>
                        );
                      })
                    )}
                  </div>
                </div>
              </div>
              <div className="mySingleOrderActionBtn">
                {/* <div className="promoCodeForm"> */}
                <form onSubmit={handleSubmit(onsubmit)}>
                  <span className="deliveryAddress">Delivery Address:</span>
                  {/* <span className='submySingleOrderButons' style={{whiteSpace:"nowrap"}} >  */}
                  <Controller
                    name="deliveryAddress"
                    control={control}
                    render={({ field }) => (
                      <Input
                        prefix={
                          <HiOutlineLocationMarker
                            style={{ fontSize: "25px", color: "#c9c9c9" }}
                          />
                        }
                        {...field}
                        className="profileInput"
                        value={oneOrder?.shippingAddress?.fullAddress || ""}
                        placeholder={
                          oneOrder?.shippingAddress?.fullAddress || ""
                        }
                        readOnly
                      />
                    )}
                  />
                  <p style={{ color: "red" }}>{errors.promo?.message}</p>
                </form>
                <div
                  style={{ display: "flex", justifyContent: "space-evenly" }}
                >
                  <div
                    className="singleOrdertotal"
                    style={{ marginRight: "20px" }}
                  >
                    <div className="totalTitle">Delivery:</div>
                    <div className="totalTitle1">
                      $ {shippingPrice} {/*priceDetails.delivery */}
                    </div>
                  </div>
                  <div className="singleOrdertotal">
                    <div className="totalTitle">Total Price:</div>
                    <div className="totalTitle1">$ {oneOrder?.salePrice}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Content>
      <Footer />
    </>
  );
}

export default MySingleOrder;
