import React from "react";

function ButtonsFooter({ submitLabel = "Submit", onCancel, onSubmit }) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        marginTop: "10px",
      }}
    >
      <button
        onClick={onCancel}
        type="button"
        className="dialog-cancel-btn"
        style={{ width: "120px" }}
      >
        Cancel
      </button>
      <button
        type="submit"
        onClick={onSubmit}
        className="dialog-submit-btn"
        style={{ width: "120px" }}
      >
        {submitLabel}
      </button>
    </div>
  );
}

export default ButtonsFooter;
