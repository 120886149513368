import React from "react";
import "./divider.css";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
//import { appContext } from "../../Context/appContext";
import { getPath } from "../../lib/lib";

function DividerComp(props) {
  const navigate = useNavigate();
  //const { categoryId } = useContext(appContext);

  const handleNavigate = (route) => {
    try {
      if (props.isCategory) {
        navigate(getPath(route));
      } else {
        navigate(`/${getPath(route)}`, {
          state: { route, catHome: true },
        });
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <div style={{ position: "relative", zIndex: 1000 }}>
      <div className="flex flex-1 items-center w-full">
        <p
          style={{ textTransform: "capitalize" }}
          className="left break-after-all !text-xs md:!text-base"
        >
          {props.title}
        </p>
        <div className="line" />
        <p
          className="right !text-xs md:!text-base"
          onClick={() => handleNavigate(props.route)}
        >
          View All
        </p>
      </div>
    </div>
  );
}

export default DividerComp;
