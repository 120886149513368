import React, { useEffect, useState } from "react";
import NavBar from "../../components/navBar/navBar";
import { Footer } from "../../components/Footer/Footer";
import BreadcrumbComp from "../../components/Breadcrumb/Breadcrumb";
import { Content } from "antd/es/layout/layout";
import { Divider, message } from "antd";
import { useParams } from "react-router-dom";
import CarouselCategoriesHome from "../../components/Carousel/carouselCategoriesHome";
import { getPath } from "../../lib/lib";
import { getTagDynamicSectionsApi } from "../../Apis";

function OfferProducts() {
  const [width, setWidth] = useState(window.innerWidth);
  const [Products, setProducts] = useState([]);
  const [DynamicDataLoading, setDynamicDataLoading] = useState(false);
  const { offerName } = useParams();
  console.log("offerName ", offerName);
  //#region useEffect
  const getDynamicSections = async (offerName) => {
    try {
      setDynamicDataLoading(true);
      const tag_response = await getTagDynamicSectionsApi();
      let tag_data = tag_response?.data?.data;
      let products = tag_data?.find(
        (ele) => getPath(ele?.englishName) === offerName
      )?.products;
      setProducts(products || []);
      setDynamicDataLoading(false);
    } catch (error) {
      message.error(error?.response?.data?.messageEn);
    }
  };
  useEffect(() => {
    getDynamicSections(offerName);
    return () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };
  }, [offerName]);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  //#endregion useEffect
  return (
    <>
      <NavBar />

      <Content
        style={{
          width: width > 768 ? "80%" : "100%",
          margin: "auto",
          marginBottom: 100,
        }}
      >
        <div className="homeCategory">
          <Content
            style={{
              padding: width > 550 && "0 10px",
              margin: "30px 0",
            }}
          >
            <div style={{ margin: width > 768 ? "40px 0" : "60px 12px 30px" }}>
              {width > 768 && <BreadcrumbComp />}
              <Divider
                type="horizontal"
                orientation="left"
                orientationMargin={width > 768 ? 0 : 0}
                style={{ borderColor: "#EC2025" }}
              >
                {offerName?.trim()?.replaceAll("-", " ")}
              </Divider>
            </div>

            <CarouselCategoriesHome products={Products} row={2} />
          </Content>
        </div>
      </Content>
      <Footer />
    </>
  );
}

export default OfferProducts;
