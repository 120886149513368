import React from "react";
import MobileNavBar from "../../components/mobileNavBar/mobileNavBar";
import MobileFooter from "../../components/mobileFooter/mobileFooter";
import DividerComp from "../../components/Divider/divider";
import ProductSection from "./ProductSection";
import Banners from "./Banners";
import RealEstateCarousalMobile from "../RealEstate/RealEstateCarousel/Mobile";
import AutomotivesCarousalMobile from "../Automotives/AutomotivesCarousel/Mobile";
import { Content } from "antd/es/layout/layout";
import { getPath } from "../../lib/lib";

const MobileLanding = ({
  bannersLoading,
  banners,
  width,
  DynamicData,
  DynamicDataLoading,
}) => {
  const renderSection = (position) => {
    let products = DynamicData[position]?.products || [];
    let isTag = DynamicData[position]?.isTag;
    return (
      <>
        {((products?.length > 0 && !DynamicDataLoading) ||
          DynamicDataLoading) && (
          <>
            {DynamicData[position]?.isRealEstate ? (
              <>
                <Content
                  style={{
                    padding: width > 550 && "0 80px",
                  }}
                >
                  <div
                    style={{
                      margin: width > 550 ? "auto" : "0 15px",
                      padding: "0px",
                    }}
                  >
                    <DividerComp
                      title={DynamicData[position]?.englishName}
                      route={DynamicData[position]?.englishName}
                      isCategory={true}
                    />
                  </div>
                  <div>
                    <RealEstateCarousalMobile
                      AllPropertyListing={products || []}
                      LoadingPropertyListing={DynamicDataLoading}
                      RowStyle={{ marginLeft: "5px", marginRight: "5px" }}
                    />
                  </div>
                </Content>
              </>
            ) : DynamicData[position]?.isAutomotive ? (
              <>
                <Content
                  style={{
                    padding: width > 550 && "0 80px",
                  }}
                >
                  <div
                    style={{
                      margin: width > 550 ? "auto" : "0 15px",
                      padding: "0px",
                    }}
                  >
                    <DividerComp
                      title={DynamicData[position]?.englishName}
                      route={DynamicData[position]?.englishName}
                      isCategory={true}
                    />
                  </div>
                  <div>
                    <AutomotivesCarousalMobile
                      DataList={products}
                      Loading={DynamicDataLoading}
                      RowStyle={{ marginLeft: "5px", marginRight: "5px" }}
                      // number={2}
                      // span={12}
                    />
                  </div>
                </Content>
              </>
            ) : (
              <>
                <ProductSection
                  title={DynamicData[position]?.englishName}
                  products={products || []}
                  loadingProducts={DynamicDataLoading}
                  width={width}
                  isCategory={isTag ? false : true}
                  route={
                    isTag
                      ? `offer/${getPath(DynamicData[position]?.englishName)}`
                      : getPath(DynamicData[position]?.englishName)
                  }
                />
              </>
            )}
          </>
        )}
      </>
    );
  };
  return (
    <div className="mb-16">
      <MobileNavBar />
      {/** up banner */}
      <Banners
        UpBanners={true}
        Banners={banners}
        bannersLoading={bannersLoading}
        showArrows={false}
      />
      {renderSection(1)}
      {renderSection(2)}
      <ProductSection
        title="Editors Choice"
        route="Editors Choice"
        products={[]}
        loadingProducts={false}
        width={width}
      />

      {/** carousel middle banner */}
      <Banners
        MiddleBanners={true}
        Banners={banners}
        bannersLoading={bannersLoading}
        showArrows={false}
      />
      {renderSection(3)}
      {renderSection(4)}

      {/** bottom banner */}
      <div style={{ marginTop: "15px" }}>
        <Banners
          BottomBanners={true}
          Banners={banners}
          bannersLoading={bannersLoading}
          showArrows={false}
        />
      </div>

      <MobileFooter />
    </div>
  );
};

export default MobileLanding;
