import "./splash.css";
function Splash() {
	return (
		<div className="splash">
			<div className="splashLogo">
				<img className="img" src="/Logo Icon.svg" alt="logo" />
				<div className="splashLogoTitle">
					<h1 className="logoTitle1">Beirut Mart</h1>
					<span className="logoTitle2">It is all about your needs</span>
				</div>
			</div>
		</div>
	);
}

export default Splash;
