import React, { useContext, useEffect, useState } from "react";
import "./carouselProducts.css";
import Carousel from "better-react-carousel";
import { Row, Col } from "antd";
import { appContext } from "../../Context/appContext";
import ProductSkeleton from "../Skeleton/ProductSkeleton";
import { handleNavigate } from "../../lib/lib";
import ProductCard from "../productItem";

function CarouselCategoriesHome(props) {
  const [width, setWidth] = useState(window.innerWidth);
  const {
    loadingCategoryProducts,
    favoritList,
    setFavoritList,
    handleFavoret,
    loadingFavorite,
    loading,
    itemId,
  } = useContext(appContext);
  //#region useEffect
  useEffect(() => {
    props?.products?.map((product) => {
      if (product.favorite) {
        setFavoritList((prev) => [...prev, product._id]);
      }
    });
  }, [props?.products]);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  //#endregion useEffect
  const responsive = [
    {
      breakpoint: 2000,
      cols: 6,
      rows: props.row,
      gap: 10,
      loop: false,
      //   autoplay: 2000
    },
    {
      breakpoint: 1920,
      cols: 6,
      rows: props.row,
      gap: 10,
      loop: false,
      //   autoplay: 2000
    },
    {
      breakpoint: 1600,
      cols: 6,
      rows: props.row,
      gap: 10,
      loop: false,
      // autoplay: 2000
    },
    {
      breakpoint: 1536,
      cols: 5,
      rows: props.row,
      gap: 10,
      loop: false,
      // autoplay: 2000
    },
    {
      breakpoint: 1440,
      cols: 5,
      rows: props.row,
      gap: 10,
      loop: false,
      // autoplay: 2000
    },
    {
      breakpoint: 1366,
      cols: 4,
      rows: props.row,
      gap: 10,
      loop: false,
      // autoplay: 2000
    },
    {
      breakpoint: 1300,
      cols: 4,
      rows: props.row,
      gap: 10,
      loop: false,
      // autoplay: 2000
    },
    {
      breakpoint: 1280,
      cols: 4,
      rows: props.row,
      gap: 10,
      loop: false,
      // autoplay: 2000
    },
    {
      breakpoint: 1130,
      cols: 3,
      rows: props.row,
      gap: 10,
      loop: false,
      // autoplay: 2000
    },

    {
      breakpoint: 1000,
      cols: 3,
      rows: props.row,
      gap: 10,
      loop: false,
      // autoplay: 2000
    },
    {
      breakpoint: 900,
      cols: 2,
      rows: props.row,
      gap: 10,
      loop: false,
      // autoplay: 2000
    },
    {
      breakpoint: 800,
      cols: 2,
      rows: props.row,
      gap: 10,
      loop: false,
      // autoplay: 2000
    },
    {
      breakpoint: 700,
      cols: 2,
      rows: props.row,
      gap: 10,
      loop: false,
      // autoplay: 2000
    },
    {
      breakpoint: 660,
      cols: 2,
      rows: props.row,
      gap: 10,
      loop: false,
      // autoplay: 2000
    },
    {
      breakpoint: 600,
      cols: 2,
      rows: props.row,
      gap: 10,
      loop: false,
      // autoplay: 2000
    },

    {
      breakpoint: 550,
      cols: 2,
      rows: props.row,
      gap: 10,
      loop: false,
      // autoplay: 2000
    },
    {
      breakpoint: 500,
      cols: 2,
      rows: props.row,
      gap: 10,
      loop: false,
      // autoplay: 2000
    },
    {
      breakpoint: 300,
      cols: 2,
      rows: props.row,
      gap: 10,
      loop: false,
      // autoplay: 2000
    },
  ];
  return (
    <>
      {width > 768 ? (
        <div className="carouselProducts">
          <Carousel
            mobileBreakpoint={500}
            responsiveLayout={responsive}
            hideArrow={props?.products?.length == 0}
            showDots={width > 500}
            dotColorActive="#231F20"
            cols={6}
            rows={props.row}
            gap={10}
          >
            {loadingCategoryProducts
              ? Array(6)
                  .fill(null)
                  .map((_, ind) => (
                    <Carousel.Item key={ind}>
                      <div className="container">
                        <ProductSkeleton />
                      </div>
                    </Carousel.Item>
                  ))
              : props?.products?.map((product, i) => (
                  <Carousel.Item key={i}>
                    <ProductCard
                      product={product}
                      favoritList={favoritList}
                      handleFavoret={handleFavoret}
                      handleNavigate={handleNavigate}
                      itemId={itemId}
                      loading={loading}
                      loadingFavorite={loadingFavorite}
                    />
                  </Carousel.Item>
                ))}
          </Carousel>
        </div>
      ) : (
        <div style={{ margin: "0 5px" }}>
          {loadingCategoryProducts ? (
            Array(6)
              .fill(null)
              .map((_, ind) => (
                <div className="container">
                  <ProductSkeleton />
                </div>
              ))
          ) : (
            <Row gutter={5}>
              {props?.products?.map((product, i) => (
                <Col span={12} style={{ marginBottom: "5px" }}>
                  <ProductCard
                    product={product}
                    favoritList={favoritList}
                    handleFavoret={handleFavoret}
                    handleNavigate={handleNavigate}
                    itemId={itemId}
                    loading={loading}
                    loadingFavorite={loadingFavorite}
                  />
                </Col>
              ))}
            </Row>
          )}
        </div>
      )}
    </>
  );
}

export default CarouselCategoriesHome;
