import React, { useEffect, useState } from "react";
import { Col, Row, Typography, Select, Radio } from "antd";
import { isMatchedDetails } from "../../lib/lib";
const { Text, Title } = Typography;

function Variant({
  CategoryVariants,
  product,
  setSelectedVarientsVals,
  width,
  dropdownWidth,
}) {
  const [VariantsOptions, setVariantsOptions] = useState({});
  const [VariantsTypes, setVariantsTypes] = useState({});
  const [FilterValues, setFilterValues] = useState({});
  const [MainVariant, setMainVariant] = useState({
    type: "",
    key: "",
    options: [],
  });

  useEffect(() => {
    showAllOptions();
  }, [product?._id, CategoryVariants]);
  useEffect(() => {
    setSelectedVarientsVals(FilterValues);
  }, [FilterValues]);

  const updateOptions = (key, val) => {
    if (MainVariant.key === key) {
      let details = [...(product?.details || [])]?.filter(
        (ele) => ele[key] === val
      );
      CategoryVariants?.forEach((element) => {
        setVariantsOptions((prev) => ({
          ...prev,
          [element?.key]: [
            ...new Set(details?.map((val) => val[element?.key])),
          ].filter((ele) => ele !== undefined),
        }));
      });
    } else {
      let values = { [key]: val };
      Object.keys(FilterValues).forEach((key) => {
        if (FilterValues[key]) values = { ...values, [key]: FilterValues[key] };
      });
      let details = [...(product?.details || [])]?.filter((ele) =>
        isMatchedDetails(values, ele)
      );
      CategoryVariants?.forEach((element) => {
        setVariantsOptions((prev) => ({
          ...prev,
          [element?.key]: [
            ...new Set(details?.map((val) => val[element?.key])),
          ].filter((ele) => ele !== undefined),
        }));
      });
    }
  };
  const showAllOptions = () => {
    let keys = [];
    let mainVariants = [];
    CategoryVariants?.forEach((element) => {
      keys = [...keys, element?.key];
      setVariantsTypes((prev) => ({
        ...prev,
        [element?.key]: element?.type,
      }));
      setFilterValues((prev) => ({
        ...prev,
        [element?.key]: undefined,
      }));
      setVariantsOptions((prev) => ({
        ...prev,
        [element?.key]: [
          ...new Set(product?.details?.map((val) => val[element?.key])),
        ].filter((ele) => ele !== undefined),
      }));
    });
    keys?.forEach((k) => {
      let boolArr = [];
      product?.details?.forEach((element) => {
        if (element[k] !== undefined) {
          boolArr = [...boolArr, true];
        } else {
          boolArr = [...boolArr, false];
        }
      });
      if (!boolArr.includes(false)) {
        mainVariants = [...mainVariants, k];
      }
    });
    // setMainVariants(mainVariants);

    if (mainVariants?.length > 0) {
      let findVariant = CategoryVariants?.find(
        (ele) => ele["key"] === mainVariants[0]
      );
      setMainVariant({
        type: findVariant?.type,
        key: mainVariants[0],
        options: [
          ...new Set(product?.details?.map((val) => val[mainVariants[0]])),
        ].filter((ele) => ele !== undefined),
      });
    }
    //console.log("mainVariants ", mainVariants);
  };
  //console.log("CategoryVariants ", CategoryVariants);
  //console.log("VariantsOptions ", VariantsOptions);
  // console.log("MainVariants ", mainVariants);
  // console.log("MainVariant ", MainVariant);
  //console.log("FilterValues ", FilterValues);

  const onChangeOption = (value, key) => {
    if (MainVariant.key === key) {
      Object.keys(FilterValues).forEach((k) => {
        setFilterValues((prev) => ({
          ...prev,
          [k]: undefined,
          [MainVariant.key]: value,
        }));
      });
    } else {
      setFilterValues((prev) => ({
        ...prev,
        [key]: value,
      }));
    }
    //  if (MainVariant.key === key)
    updateOptions(key, value);
  };
  const renderOptions = (key, type, options) => {
    let filteredOptions = [...options].filter(
      (ele) => ele !== null && ele !== undefined && ele?.length > 0
    );
    if (filteredOptions?.length === 0) return;
    if (type === "color") {
      return (
        <Row>
          {filteredOptions?.map((val, i) => (
            <Col span={width > 767 ? 3 : 3} key={i}>
              <div
                className="divcolor"
                style={{
                  background: "#" + val,
                  cursor: "pointer",
                  border:
                    FilterValues[key] === val && "1px solid rgb(236, 32, 37)",
                }}
                onClick={() => onChangeOption(val, key)}
              />
            </Col>
          ))}
        </Row>
      );
    } else if (type === "selection circles") {
      return (
        <Row>
          {filteredOptions?.map((val, i) => (
            <Col span={4} key={i}>
              <div
                className="divsize"
                style={{
                  cursor: "pointer",
                  border:
                    FilterValues[key] === val && "1px solid rgb(236, 32, 37)",
                }}
                onClick={() => onChangeOption(val, key)}
              >
                {val}
              </div>
            </Col>
          ))}
        </Row>
      );
    } else if (type === "dropdown") {
      return (
        <Row style={{ width: "100%" }}>
          <Col span={24}>
            <Select
              defaultValue={FilterValues[key]}
              value={FilterValues[key]}
              onChange={(value) => onChangeOption(value, key)}
              style={{ width: dropdownWidth ? dropdownWidth : "100%" }}
              placeholder="Select option"
              options={filteredOptions?.map((ele) => ({
                value: ele,
                label: ele,
              }))}
            />
          </Col>
        </Row>
      );
    } else if (type === "radio") {
      return (
        <Row>
          <Col span={24}>
            <Radio.Group
              onChange={(e) => onChangeOption(e.target.value, key)}
              value={FilterValues[key]}
            >
              {filteredOptions?.map((ele, i) => (
                <Radio key={i} value={ele}>
                  {ele}
                </Radio>
              ))}
            </Radio.Group>
          </Col>
        </Row>
      );
    }
  };
  return (
    <>
      {MainVariant?.options?.length > 0 && (
        <Row>
          <Col span={24}>
            <Title
              style={{ fontSize: width > 767 ? "20px" : "16px" }}
              level={4}
            >
              {MainVariant.key}
            </Title>
          </Col>
          <Col span={24}>
            {renderOptions(
              MainVariant.key,
              `${MainVariant.type}`.toLowerCase(),
              MainVariant.options
            )}
          </Col>
        </Row>
      )}
      {Object.keys(VariantsOptions)?.map((key, i) => {
        if (MainVariant.key !== key && VariantsOptions[key]?.length > 0)
          return (
            <Row key={i}>
              {VariantsOptions[key]?.filter(
                (ele) => ele !== null && ele !== undefined && ele?.length > 0
              ).length > 0 && (
                <Col span={24}>
                  <Title
                    style={{ fontSize: width > 767 ? "20px" : "16px" }}
                    level={4}
                  >
                    {key}
                  </Title>
                </Col>
              )}
              <Col span={24}>
                {!FilterValues[MainVariant.key] ? (
                  <Text type="secondary">
                    Please choose {MainVariant.key} first.
                  </Text>
                ) : (
                  renderOptions(
                    key,
                    `${VariantsTypes[key]}`.toLowerCase(),
                    VariantsOptions[key]
                  )
                )}
              </Col>
            </Row>
          );
      })}
    </>
  );
}
export default Variant;
