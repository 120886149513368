import React, { useContext, useEffect, useState } from "react";
import BreadcrumbComp from "../../components/Breadcrumb/Breadcrumb";
import NavBar from "../../components/navBar/navBar";
import { Footer } from "../../components/Footer/Footer";
import { Content } from "antd/es/layout/layout";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
//import { appContext } from "../../Context/appContext";
import MobileNavBar from "../../components/mobileNavBar/mobileNavBar";
function MyCart() {
  //const { userDetails } = useContext(appContext);
  const navigate = useNavigate();
  const [width, setWidth] = useState(window.innerWidth);

  //#region useEffect
  useEffect(() => {
    localStorage.removeItem("promo");
    return () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };
  }, []);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  //#endregion useEffect

  return (
    <>
      {width > 767 ? (
        <>
          <NavBar />
          <Content style={{ width: "80%", margin: "auto" }}>
            <div className="myProfile">
              <BreadcrumbComp />
              <div className="profileComponents">
                <div className="profileinfo">
                  <div className="userVendorCart">
                    <div className="vendorImg">
                      <img src="/Fill 7.svg" alt="" />
                    </div>
                    <div className="vendorTitle">
                      <span>Become a Vendor !</span>
                    </div>
                    <div className="vendorBtnComp">
                      <small className="vendorHint">
                        Read Terms and Conditions
                      </small>
                      <Button
                        //className="vendorBtn"
                        className="vendorBtn red-btn hover-bg-w"
                        onClick={() => navigate("/signup")}
                      >
                        Become a Vendor
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Content>
          <Footer />
        </>
      ) : (
        <>
          <div style={{ width: "100%", overflowX: "hidden" }}>
            <MobileNavBar />
            <Content
              style={{
                width: "100%",
                margin: "auto",
                padding: "0px 25px",
              }}
            >
              <div className="myProfile">
                <BreadcrumbComp />
                <div className="profileComponents">
                  <div className="profileinfo">
                    <div className="userVendorCart">
                      <div className="vendorImg">
                        <img src="/Fill 7.svg" alt="" />
                      </div>
                      <div className="vendorTitle">
                        <span>Become a Vendor !</span>
                      </div>
                      <div className="vendorBtnComp">
                        <small className="vendorHint">
                          Read Terms and Conditions
                        </small>
                        <Button
                          //className="vendorBtn"
                          className="vendorBtn red-btn hover-bg-w"
                          onClick={() => navigate("/signup")}
                        >
                          Become a Vendor
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Content>
            <Footer />
          </div>
        </>
      )}
    </>
  );
}

export default MyCart;
