import Axios from "axios";
Axios.defaults.baseURL = "https://api.beirutmart.com/";
Axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem(
  "token"
)}`;
export const Url = "https://api.beirutmart.com";
export const mapKey = "AIzaSyCCE8oC08n3zVeMu8I6Xb9-IwquwvoeNoo";
export const shippingPrice = 30;
export const phoneNumber = +96178962619;
export const axiosInstance = Axios;

export const loginApi = async (data) => {
  const result = await Axios.post(`/auth/login`, data);
  return result;
};
export const registerApi = async (data) => {
  const result = await Axios.post(`/auth/register`, data);
  return result;
};
export const sendOtpApi = async (data) => {
  const result = await Axios.post(`/auth/verify`, data);
  return result;
};
export const reSendOtpApi = async (data) => {
  const result = await Axios.post(`/auth/code/resend`, data);
  return result;
};
export const forgotPasswordApi = async (data) => {
  const result = await Axios.post(`/auth/password/forgot`, data);
  return result;
};
export const setNewPasswordApi = async (data) => {
  const result = await Axios.post(`/auth/password/set`, data);
  return result;
};
export const verifyForgotPassApi = async (data) => {
  const result = await Axios.post(`/auth/password/forgot/check`, data);
  return result;
};
export const changePasswordApi = async (data) => {
  const result = await Axios.post(`/auth/password/change`, data);
  return result;
};
// automotive apis
export const getAutomotiveApi = async (params) => {
  // let englishName = params?.englishName || "";
  // let arabicName = params?.arabicName || "";
  // let carCategory = "";
  // let location = "";
  // let brand = "";
  // let subBrand = "";
  // let fuelType = "";
  // let paymentOptions = "";
  // let minYear = "";
  // let maxYear = "";
  // let transmissionType = "";
  // let color = "";
  // let bodyType = "";
  // let features = "";
  // let condition = "";
  // let isActive = true;
  // let isPromoted = "";
  // let minPrice = "";
  // let maxPrice = "";
  // let featuredBusinessIds = [];
  // let minKilometers = "";
  // let maxKilometers = "";

  // /automotives?englishName=Honda Civic&arabicName=أودي A4&carCategory=66769c6f9ea21c51485708fe,66769c7f9ea21c51485708ff,66769caf9ea21c5148570902
  // &location=Beirut&brand=6674c3520dbfca5734f6d7a0,6674c3630dbfca5734f6d7a2&subBrand=Corolla&
  // fuelType=6676e84327c74733d33820c0,6676e81e27c74733d33820be&paymentOptions=Credit Card,Cash
  // &minYear=2022&maxYear=2021&transmissionType=6676eab01cab4328d425128d,6676eac01cab4328d425128f
  // &color=Black&bodyType=6676ec7f1cab4328d42512a0,6676ecaf1cab4328d42512a2
  // &features=6676efbcf16c2d8e37e259e6,6676efc6f16c2d8e37e259e8&condition=New
  // &isActive=true&isPromoted=false&minPrice=25000&maxPrice=30000
  // &minKilometers=10000&maxKilometers=30000&featuredBusinessIds=6674eb50aa7fd54239969844,6674eb40aa7fd54239969842

  // let _params = `?englishName=${englishName}&arabicName=${arabicName}&carCategory=${carCategory}
  // &location=${location}&brand=${brand}&subBrand=${subBrand}&fuelType=${fuelType}
  // &paymentOptions=${paymentOptions}&minYear=${minYear}&maxYear=${maxYear}&transmissionType=${transmissionType}
  // &color=${color}&bodyType=${bodyType}&features=${features}&condition=${condition}&isActive=${isActive}&isPromoted=${isPromoted}
  // &minPrice=${minPrice}&maxPrice=${maxPrice}
  // &minKilometers=${minKilometers}&maxKilometers=${maxKilometers}&featuredBusinessIds=${featuredBusinessIds}`;
  const result = await Axios.get(`/automotives`, {
    params: params || {},
  });
  return result;
};
// export const AutomotiveFiltersApi = async () => {
//   const result = await Axios.get(`/property/get/filters`);
//   return result;
// };
export const featuresBusinessApi = async () => {
  const result = await Axios.get(`/featuredBusiness`);
  return result;
};
export const automotiveBrandsApi = async () => {
  const result = await Axios.get(`/brands`);
  return result;
};
// end automotive apis
export const propertyListingApi = async (params) => {
  let type = params?.type ? params?.type?.map((val) => `${val}`) : [];
  let amenities = params?.amenities
    ? params?.amenities?.map((val) => `${val}`)
    : [];
  let status = params?.status ? params?.status?.map((val) => `${val}`) : [];
  let city = params?.city ? params?.city?.map((val) => `${val}`) : [];
  let priceMin = params?.priceMin ? params?.priceMin : "";
  let priceMax = params?.priceMax ? params?.priceMax : "";
  let areaMin = params?.areaMin ? params?.areaMin : "";
  let areaMax = params?.areaMax ? params?.areaMax : "";
  let search = params?.search || "";
  let sort = params?.sort;
  let numberOfBedRoomsMin = params?.numberOfBedRoomsMin || "";
  let numberOfBedRoomsMax = params?.numberOfBedRoomsMax || "";
  let numberOfBathRoomsMin = params?.numberOfBathRoomsMin || "";
  let numberOfBathRoomsMax = params?.numberOfBathRoomsMax || "";
  let _params = `?numberOfBedRoomsMin=${numberOfBedRoomsMin}&numberOfBedRoomsMax=${numberOfBedRoomsMax}&numberOfBathRoomsMin=${numberOfBathRoomsMin}&numberOfBathRoomsMax=${numberOfBathRoomsMax}&city=${city}&type=${type}&amenities=${amenities}&priceMin=${priceMin}&priceMax=${priceMax}&areaMin=${areaMin}&areaMax=${areaMax}&search=${search}&status=${status}&sort=${sort}`;
  const result = await Axios.get(`/propertyListing/${_params}`, {
    //  params: params || {},
  });
  return result;
};
export const getCategoryByNameApi = async (name) => {
  const result = await Axios.get(`/category/name/${name}`);
  return result;
};
export const getCategoryDynamicSectionsApi = async () => {
  const result = await Axios.get("/category/dynamicSections/get/client");
  return result;
};
export const getTagDynamicSectionsApi = async () => {
  const result = await Axios.get("/tag/dynamicSections/get/client");
  return result;
};
export const propertyListingFiltersApi = async () => {
  const result = await Axios.get(`/property/get/filters`);
  return result;
};
export const categoryWithProductApi = async () => {
  const result = await Axios.get(`/category/withproduct`);
  return result;
};
export const categoriesApi = async (id, query) => {
  let queries = "";
  if (id) {
    if (typeof query === "string" && query) {
      queries += `&${query}`;
    }
  } else if (typeof query === "string" && query) {
    queries += `?${query}`;
  }
  const result = await Axios.get(`/category/?${queries}`, {
    params: { cat: id, ...(typeof query === "object" ? query : {}) },
  });
  return result;
};
export const productsApi = async (id, uid) => {
  const result = await Axios.get(`/product/?category=${id}&user=${uid}`);
  return result;
};
export const allProductsApi = async (catId, query) => {
  const result = await Axios.get(`/product/`, {
    params: { category: catId || undefined, ...query },
  });
  return result;
};
export const allProductsCategoryApi = async (catId, query) => {
  const result = await Axios.get(`/category/`, {
    params: { cat: catId || undefined, ...query },
  });
  return result;
};
export const addAddressApi = async (data) => {
  const result = await Axios.post(`/auth/address`, data);
  return result;
};
export const getProfileApi = async () => {
  const result = await Axios.get(`/auth/profile`);
  return result;
};
export const updateProfileApi = async (data) => {
  const result = await Axios.patch(`/auth/profile`, data);
  return result;
};
export const editAddressApi = async (id, data) => {
  const result = await Axios.patch(`/auth/address/${id}`, data);
  return result;
};

export const deleteAddressApi = async (id) => {
  const result = await Axios.delete(`/auth/address/${id}`);
  return result;
};

export const changePassApi = async (data) => {
  const result = await Axios.post(`/auth/password/change`, data);
  return result;
};

export const getAddressApi = async () => {
  const result = await Axios.get(`/auth/address/`);
  return result;
};
export const getVariantsByCategoryApi = async (catId) => {
  const result = catId
    ? await Axios.get(`/category/filter/variants/clint/get/${catId}`)
    : {};
  return result;
};
export const getFiltersApi = async (id) => {
  const result = await Axios.get(`/category/all/filters?cat=${id}`);
  return result;
};

export const getLandingApi = async (cat, user, filter) => {
  const result = await Axios.get(
    `/product/home/?category=${cat}&user=${user}&type=some&filter=${filter}`
  );
  return result;
};
export const getTopRatedApi = async (cat, user) => {
  const result = await Axios.get(
    `/product/home/?category=${cat}&user=${user}&type=some&filter=Top Rated Products`
  );
  return result;
};
export const getCategoryHomeApi = async (cat, user, filter) => {
  const result = await Axios.get(
    `/product/home/?category=${cat}&user=${user}&type=all&filter=${filter}`
  );
  return result;
};
export const addToCartApi = async (data) => {
  const result = await Axios.post(`/cart/`, data);
  return result;
};
export const updateToCartApi = async (data, id) => {
  const result = await Axios.patch(`/cart/${id}`, data);
  return result;
};
export const updateCartApi = async (id, data) => {
  const result = await Axios.patch(`/cart/${id}`, data);
  return result;
};

export const getCartApi = async () => {
  const result = await Axios.get(`/cart/`);
  return result;
};

export const addFavoriteApi = async (id) => {
  const result = await Axios.patch(`/product/user/favorite/add/${id}`);
  return result;
};
export const deleteFavoriteApi = async (id) => {
  const result = await Axios.patch(`/product/user/favorite/remove/${id}`);
  return result;
};

export const getFavouritesApi = async () => {
  const result = await Axios.get(`/product/user/favorite`);
  return result;
};

export const deleteFavouriteApi = (id) => {
  let result = Axios.delete(`/product/user/favorite/remove/${id}`);
  return result;
};

export const addRateApi = (id, data) => {
  let result = Axios.post(`/rate/${id}`, data);
  return result;
};

export const getBannersApi = () => {
  let result = Axios.get(`/banner/`);
  return result;
};

export const addPromoApi = (data) => {
  let result = Axios.post(`/promo/`, data);
  return result;
};

export const createOrderApi = (data) => {
  let result = Axios.post(`/order/`, data);
  return result;
};
export const cardPaymentApi = (referenceNumber, salePrice) => {
  let result = Axios.get(
    `/order/get/payment_form?referenceNumber=${referenceNumber}&amount=${salePrice}`
  );
  return result;
};
export const deleteOrderApi = (id) => {
  let result = Axios.delete(`/order/${id}`);
  return result;
};
export const getOrderByIdApi = (id) => {
  let result = Axios.get(`/order/${id}`);
  return result;
};

export const getOrdersCountApi = () => {
  let result = Axios.get(`/order/all/count`);
  return result;
};
export const getAllOrdersApi = () => {
  let result = Axios.get(`/order/`);
  return result;
};
export const salePriceApi = (data) => {
  //console.log("salePriceApi ", data);
  let result = Axios.post(`/order/total`, data);
  return result;
};

export const AllFavoriteProductsApi = () => {
  let result = Axios.get(`/category/user/favorite`);
  return result;
};

export const deleteFromCartApi = (id) => {
  let result = Axios.delete(`/cart/${id}`);
  return result;
};

export const getOneProductApi = (id) => {
  let result = Axios.get(`/product/one/${id}`);
  return result;
};

export const getOneProductApiByName = (name) => {
  let result = Axios.get(`/product/one/byName/${name}`);
  return result;
};
export const getOneProductApiByUrl = (url) => {
  let result = Axios.get(`/product/getProductByUrl/${url}`);
  return result;
};

export const getBrandsApi = () => {
  let result = Axios.get(`/brand/`);
  return result;
};
export const getTagsApi = () => {
  let result = Axios.get(`/tag/`);
  return result;
};

export const createVendorProductApi = (data) => {
  let result = Axios.post(`/vendor/request`, data);
  return result;
};

export const editVendorProductApi = (id, data) => {
  let result = Axios.patch(`/vendor/request/${id}`, data);
  return result;
};

export const getVendorProductApi = (type) => {
  let result = Axios.get(`/vendor/products?type=${type}`);
  return result;
};
export const getVendorSalesGraphApi = (interval) => {
  let result = Axios.get(`/vendor/sales?interval=${interval}`);
  return result;
};
export const getVendorSoldOrdersApi = () => {
  let result = Axios.get("/vendor/sales/get");
  return result;
};
export const updateVendorOrderStatusApi = (data) => {
  let result = Axios.put("/order/updateStatus", data);
  return result;
};
export const updateProductStatusApi = (data) => {
  let result = Axios.put("/product/updateStatus", data);
  return result;
};
export const requestQuantityApi = (data) => {
  let result = Axios.post(`/quantity/`, data);
  return result;
};

export const editProductVendorApi = (id, data) => {
  let result = Axios.patch(`/vendor/request/${id}`, data);
  return result;
};

export const deleteProductVendorApi = (id) => {
  let result = Axios.delete(`/vendor/request/${id}`);
  return result;
};

export const searchApi = (search) => {
  let result = Axios.get(`/category/all/search?search=${search}`);
  return result;
};
export const searchProductsApi = (search, data) => {
  let result = Axios.get(`/product/category/search?search=${search}&${data}`);
  return result;
};
