import React, { useState, useEffect } from "react";
import { Col, Modal, Row, Skeleton, message, Select, ColorPicker } from "antd";
import { getImgVidUrl, getPath, getStatusColor } from "../../lib/lib";
import { useNavigate } from "react-router-dom";
import { BiEditAlt } from "react-icons/bi";
import StarRating from "../../components/StarRateComp/StarRateComp";
import {
  Url,
  getVendorSalesGraphApi,
  getVendorSoldOrdersApi,
  updateVendorOrderStatusApi,
} from "../../Apis";
import ReactECharts from "echarts-for-react";
import ButtonsFooter from "../../components/ButtonsFooter";

function Sales({ width, IntervalOption, AllProducts }) {
  const navigate = useNavigate();
  const [LoadingGraph, setLoadingGraph] = useState(true);
  const [LoadingOrders, setLoadingOrders] = useState(true);
  const [OpenModal, setOpenModal] = useState(false);
  const [SelectedOrder, setSelectedOrder] = useState({});
  const [ShowOrderInfo, setShowOrderInfo] = useState(false);
  const [Data, setData] = useState({
    salesGraph: {
      xAxisData: [],
      series: [],
    },
    orders: [],
  });
  useEffect(() => {
    getVendorSalesGraph(IntervalOption);
  }, [IntervalOption]);
  useEffect(() => {
    getVendorSalesOrders();
    setSelectedOrder({});
  }, []);
  const getVendorSalesGraph = async (IntervalOption) => {
    try {
      setLoadingGraph(true);
      const response = await getVendorSalesGraphApi(IntervalOption);
      setData((prev) => ({
        ...prev,
        salesGraph: {
          xAxisData: response?.data?.data?.salesGraph?.map(
            (val) => val?.interval
          ),
          series: response?.data?.data?.salesGraph?.map(
            (val) => val?.numberOfUsers
          ),
        },
      }));
      setLoadingGraph(false);
    } catch (error) {
      setLoadingGraph(false);
      message.error(error.message);
    }
  };
  const getVendorSalesOrders = async () => {
    try {
      setLoadingOrders(true);
      const response = await getVendorSoldOrdersApi();
      setData((prev) => ({
        ...prev,
        orders: response?.data?.data,
      }));
      setLoadingOrders(false);
    } catch (error) {
      setLoadingOrders(false);
      message.error(error.message);
    }
  };
  const handleSelectOrder = (order) => {
    setSelectedOrder(order);
  };
  const handleOpen = (order) => {
    setShowOrderInfo(false);
    handleSelectOrder({ _id: order?.order?._id, status: order?.order?.status });
    setOpenModal(true);
  };
  const handleCancel = () => {
    setOpenModal(false);
  };
  const handleSubmit = async () => {
    console.log(SelectedOrder);
    let data = {
      id: SelectedOrder?._id,
      status: SelectedOrder?.status,
    };
    try {
      const response = await updateVendorOrderStatusApi(data);
      getVendorSalesOrders(IntervalOption);
      handleCancel();
    } catch (error) {
      handleCancel();
      message.error(error.message);
    }
  };
  const getProductUrl = (productId, URL) => {
    let product = AllProducts?.find((ele) => ele?._id === productId);
    let category = product?.category?.englishName;
    let url = `${category ? "/" + getPath(category) : ""}/${URL}`;
    console.log(product);
    return {
      url,
    };
  };
  const renderOrderBigView = () => {
    let order = SelectedOrder;
    let url = getProductUrl(
      SelectedOrder?.product?._id,
      SelectedOrder?.product?.URL
    )?.url;
    let detail = order?.product?.details?.find(
      (ele) => Number(ele?.price) === Number(order?.price)
    );
    return (
      <>
        <Row cols={24} gutter={[10, 10]}>
          <Col span={12} style={{ borderRight: "1px solid #EC2025" }}>
            <Row cols={24} gutter={[10, 10]}>
              <Col
                span={24}
                style={{
                  borderBottom: "1px solid #EC2025",
                  marginTop: "10px",
                  padding: "0px 0px 0px 35px",
                }}
              >
                <Row cols={24} gutter={[10, 10]}>
                  <Col span={24}>
                    <Row cols={24} gutter={[10, 10]}>
                      <Col span={6}>
                        <img
                          style={{
                            height: "100%",
                            width: "100%",
                            borderRadius: "10px",
                            cursor: "pointer",
                          }}
                          src={getImgVidUrl(
                            order?.product?.featuredImage ||
                              order?.product?.productImage[0]
                          )}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = "/img_placeholder.png";
                          }}
                          onClick={() => navigate(url)}
                        />
                      </Col>
                      <Col span={12}>
                        <Row cols={24} gutter={[10, 10]}>
                          <Col span={24}>
                            <small>
                              Customer: {order?.user?.fullName || ""}
                            </small>
                          </Col>
                          <Col span={24}>
                            <h2
                              onClick={() => navigate(url)}
                              style={{
                                color: "#EC2025",
                                cursor: "pointer",
                              }}
                            >
                              {order?.product?.englishName}
                            </h2>
                          </Col>
                          <Col span={24}>
                            <Row gutter={[5, 5]}>
                              <Col span={12}>
                                <StarRating
                                  totalStars={5}
                                  initialRating={order?.product?.avgRate}
                                  productId={order?.product?._id}
                                  size={width > 768 ? 20 : 15}
                                />
                              </Col>
                              <Col span={12}>
                                <small>
                                  {order?.product?.avgRate}{" "}
                                  {`(${order?.product?.ratesCount})`}
                                </small>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={6}>
                        <small
                          style={{
                            fontStyle: "italic",
                            color: "#EC2025",
                            fontWeight: "bold",
                          }}
                        >
                          {/* Product Info */}
                          ID {SelectedOrder?.order?.orderNumber}
                        </small>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Row gutter={[10, 10]}>
                      <Col span={14}></Col>
                      <Col span={5}>
                        <small>Product Price:</small>
                      </Col>
                      <Col span={5}>
                        <small style={{ fontWeight: "bold" }}>
                          ${order?.price}
                        </small>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <small>Your Specifications:</small>
                  </Col>
                  <Col span={24}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {Object.keys(detail || {})?.map((key, i) => {
                        if (key !== "quantity" && key !== "price")
                          if (key?.toLowerCase() === "color") {
                            return (
                              <span
                                className="specifications"
                                key={i}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  width: "65px",
                                }}
                              >
                                {key}:{" "}
                                <ColorPicker
                                  style={{ marginLeft: "3px" }}
                                  size="small"
                                  defaultValue={"#" + detail[key]}
                                />
                              </span>
                            );
                          } else {
                            return (
                              <span className="specifications" key={i}>
                                {key}: {detail[key]}
                              </span>
                            );
                          }
                      })}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={12}>Installment Info:</Col>
        </Row>
      </>
    );
  };
  const renderOrderSmallView = () => {
    let order = SelectedOrder;
    let url = getProductUrl(
      SelectedOrder?.product?._id,
      SelectedOrder?.product?.URL
    )?.url;
    let detail = order?.product?.details?.find(
      (ele) => Number(ele?.price) === Number(order?.price)
    );
    return (
      <>
        <Row gutter={[5, 5]}>
          <Col span={24}>
            <h3 style={{ color: "#EC2025" }}>
              ID {SelectedOrder?.order?.orderNumber}
            </h3>
          </Col>
          <Col span={24}>
            <small>
              {new Date(SelectedOrder?.createdAt).toLocaleDateString()}
            </small>
          </Col>
        </Row>
        <Row cols={24}>
          <Col
            span={24}
            style={{
              borderBottom: "1px solid #ACAAB6",
              background: "#EBEBEB",
            }}
          >
            <Row cols={24} gutter={[10, 10]} style={{ padding: "10px 0px" }}>
              <Col span={6}>
                <img
                  style={{
                    height: "80px",
                    width: "100%",
                    borderRadius: "10px",
                    cursor: "pointer",
                  }}
                  src={getImgVidUrl(
                    order?.product?.featuredImage ||
                      order?.product?.productImage[0]
                  )}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = "/img_placeholder.png";
                  }}
                  onClick={() => navigate(url)}
                />
              </Col>
              <Col span={12}>
                <Row cols={24} gutter={[10, 10]}>
                  <Col span={24}>
                    <h2
                      onClick={() => navigate(url)}
                      style={{
                        color: "#EC2025",
                        cursor: "pointer",
                      }}
                    >
                      {order?.product?.englishName}
                    </h2>
                  </Col>
                  <Col span={24}>
                    <small>Your Specifications:</small>
                  </Col>
                  <Col span={24}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {Object.keys(detail || {})?.map((key, i) => {
                        if (key !== "quantity" && key !== "price")
                          if (key?.toLowerCase() === "color") {
                            return (
                              <span
                                className="specifications"
                                key={i}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  width: "65px",
                                }}
                              >
                                {key}:{" "}
                                <ColorPicker
                                  style={{ marginLeft: "3px" }}
                                  size="small"
                                  defaultValue={"#" + detail[key]}
                                />
                              </span>
                            );
                          } else {
                            return (
                              <span className="specifications" key={i}>
                                {key}: {detail[key]}
                              </span>
                            );
                          }
                      })}
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col span={6}>
                <h3>${order?.price}</h3>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={[10, 10]}>
          <Col span={24}>Installment Info:</Col>
        </Row>
      </>
    );
  };
  const onClickInfo = (order) => {
    setSelectedOrder(order);
    setShowOrderInfo(true);
  };
  console.log("SelectedOrder ", SelectedOrder);
  return (
    <>
      <Row cols={24}>
        <Col span={24}>
          {LoadingGraph ? (
            <Row gutter={[15, 15]}>
              <Col span={24}>
                <Skeleton.Button active size={"large"} block />
              </Col>
              <Col span={24}>
                <Skeleton.Button active size={"large"} block />
              </Col>
              <Col span={24}></Col>
            </Row>
          ) : (
            <ReactECharts
              option={{
                title: {
                  text: "Sales Graph:",
                  show: width <= 767,
                },
                tooltip: {
                  trigger: "axis",

                  axisPointer: {
                    type: "cross",
                    label: {
                      backgroundColor: "#6a7985",
                    },
                  },
                },
                xAxis: {
                  type: "category",
                  boundaryGap: false,
                  data: Data?.salesGraph?.xAxisData || [],
                },
                yAxis: {
                  type: "value",
                },
                grid: {
                  left: 37,
                  right: 30,
                },
                axisLine: { show: true, onZero: true },
                series: [
                  {
                    data: Data?.salesGraph?.series || [],
                    type: "line",
                    color: "#ec2025",
                    areaStyle: {
                      color: "rgba(236,32, 37, 0.5)",
                    },
                    smooth: true,
                  },
                ],
              }}
              notMerge={true}
              lazyUpdate={true}
              // theme={"theme_name"}
              // onChartReady={this.onChartReadyCallback}
              // onEvents={EventsDict}
              opts={{}}
              style={{ width: "100%" }}
            />
          )}
          {LoadingOrders ? (
            <Row gutter={[15, 15]}>
              <Col span={24}>
                <Skeleton.Button active size={"large"} block />
              </Col>
              <Col span={24}>
                <Skeleton.Button active size={"large"} block />
              </Col>
            </Row>
          ) : (
            <Col span={24}>
              <h3
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setSelectedOrder({});
                  setShowOrderInfo(false);
                }}
              >
                My Sold Product:
              </h3>

              {width > 767 ? (
                <>
                  {!SelectedOrder?._id || !ShowOrderInfo ? (
                    <Row cols={24} style={{ marginTop: "15px" }}>
                      <Col span={24}>
                        <Row
                          cols={24}
                          style={{
                            borderBottom: "1px solid #EC2025",
                          }}
                        >
                          {[
                            { label: "Item Name and ID", span: 8 },
                            //{ label: "Customer", span: 4 },
                            { label: "Date", span: 4 },
                            { label: "Status", span: 4 },
                            { label: "Price", span: 4 },
                            { label: "", span: 4 },
                            // { label: "More Info", span: 4 },
                          ]?.map((val, i) => (
                            <Col key={i} span={val?.span}>
                              <label
                                style={{
                                  color: "#ACAAB6",
                                  fontSize: "16px",
                                  fontWeight: "bold",
                                  textAlign: "left",
                                }}
                              >
                                {val?.label}
                              </label>
                            </Col>
                          ))}
                        </Row>
                      </Col>
                      {Data?.orders?.length === 0 && (
                        <Col span={24}>
                          <h4 style={{ marginTop: "5px" }}>
                            No sold products yet.
                          </h4>{" "}
                        </Col>
                      )}
                      <Col span={24}>
                        {Data?.orders?.map((order, i) => {
                          let product = order?.product;
                          let url = getProductUrl(
                            order?.product?._id,
                            order?.product?.URL
                          )?.url;
                          return (
                            <Row
                              key={i}
                              cols={24}
                              style={{
                                borderBottom: "1px solid #EC2025",
                                padding: "10px",
                              }}
                            >
                              {[
                                {
                                  span: 8,
                                  customComp: (
                                    <Row>
                                      <Col span={6}>
                                        <img
                                          style={{
                                            height: "50px",
                                            width: "50px",
                                            borderRadius: "10px",
                                            cursor: "pointer",
                                          }}
                                          src={getImgVidUrl(
                                            product?.featuredImage ||
                                              product?.productImage[0]
                                          )}
                                          onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src =
                                              "/img_placeholder.png";
                                          }}
                                          onClick={() => navigate(url)}
                                        />
                                      </Col>
                                      <Col span={18}>
                                        <h3
                                          style={{ cursor: "pointer" }}
                                          onClick={() => navigate(url)}
                                        >
                                          {product?.englishName}
                                        </h3>
                                        <small>
                                          <b>ID: </b>
                                          {order?.order?.orderNumber}
                                        </small>
                                      </Col>
                                    </Row>
                                  ),
                                  isCustom: true,
                                },
                                {
                                  value: new Date(
                                    order.createdAt
                                  ).toLocaleDateString(),
                                  span: 4,
                                  isCustom: false,
                                },
                                {
                                  value: order?.order?.status,
                                  span: 4,
                                  isCustom: false,
                                  style: {
                                    color: getStatusColor(order?.order?.status),
                                  },
                                },
                                {
                                  value: order?.order?.salePrice + " $",
                                  span: 4,
                                },
                                {
                                  span: 4,
                                  customComp: (
                                    <Row>
                                      <Col span={8}>
                                        <BiEditAlt
                                          style={{
                                            fontSize: 24,
                                            color: "#ACAAB6",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => handleOpen(order)}
                                        />
                                      </Col>
                                      <Col span={16}>
                                        <small
                                          style={{
                                            color: "#ec2025",
                                            fontWeight: "bold",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => onClickInfo(order)}
                                        >
                                          More Info
                                        </small>
                                      </Col>
                                    </Row>
                                  ),
                                  isCustom: true,
                                  //   onClick: () => handleSelectOrder(order),
                                },
                              ]?.map((item, j) => (
                                <Col key={j} span={item?.span}>
                                  {item?.isCustom ? (
                                    <>{item?.customComp}</>
                                  ) : (
                                    <label
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: "bold",
                                        ...(item?.style || {}),
                                      }}
                                      onClick={
                                        item?.onClick ? item?.onClick : () => {}
                                      }
                                    >
                                      {item?.value}
                                    </label>
                                  )}
                                </Col>
                              ))}
                            </Row>
                          );
                        })}
                      </Col>
                    </Row>
                  ) : (
                    <>{renderOrderBigView()}</>
                  )}
                </>
              ) : (
                <>
                  {!SelectedOrder?._id || !ShowOrderInfo ? (
                    <Row cols={24}>
                      {Data?.orders?.length === 0 && (
                        <Col span={24}>
                          <h4 style={{ marginTop: "5px" }}>
                            No sold products yet.
                          </h4>{" "}
                        </Col>
                      )}
                      <Col span={24}>
                        {Data?.orders?.map((order, i) => {
                          let product = order?.product;
                          let url = getProductUrl(
                            order?.product?._id,
                            order?.product?.URL
                          )?.url;
                          return (
                            <Row
                              key={i}
                              cols={24}
                              style={{
                                borderBottom: "1px solid #ACAAB6",
                                padding: "10px",
                              }}
                            >
                              <Col span={6}>
                                <img
                                  style={{
                                    height: "50px",
                                    width: "50px",
                                    borderRadius: "10px",
                                    cursor: "pointer",
                                  }}
                                  src={getImgVidUrl(
                                    product?.featuredImage ||
                                      product?.productImage[0]
                                  )}
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = "/img_placeholder.png";
                                  }}
                                  onClick={() => navigate(url)}
                                />
                              </Col>
                              <Col span={8}>
                                <h4
                                  style={{
                                    cursor: "pointer",
                                    color: "#ec2025",
                                  }}
                                  onClick={() => navigate(url)}
                                >
                                  {product?.englishName}
                                </h4>
                                <small>
                                  {new Date(
                                    order.createdAt
                                  ).toLocaleDateString()}
                                </small>
                              </Col>
                              <Col span={6}>
                                <h4
                                  style={{ cursor: "pointer" }}
                                  onClick={() => navigate(url)}
                                >
                                  {order?.order?.salePrice + " $"}
                                </h4>
                                <small
                                  style={{
                                    color: getStatusColor(order?.order?.status),
                                  }}
                                >
                                  {order?.order?.status}
                                </small>
                              </Col>
                              <Col span={2}>
                                <BiEditAlt
                                  style={{
                                    fontSize: 24,
                                    color: "#ACAAB6",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleOpen(order)}
                                />
                              </Col>
                              <Col span={2}>
                                <small
                                  style={{
                                    color: "#ec2025",
                                    fontWeight: "bold",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => onClickInfo(order)}
                                >
                                  Info
                                </small>
                              </Col>
                            </Row>
                          );
                        })}
                      </Col>
                    </Row>
                  ) : (
                    <>{renderOrderSmallView()}</>
                  )}
                </>
              )}
            </Col>
          )}
        </Col>
      </Row>

      {/*******update order status Modal***** */}
      <Modal
        width={width > 767 ? "50vw" : "90vw"}
        bodyStyle={{
          padding: 0,
          border: "none",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        closable={false}
        open={OpenModal}
        onCancel={handleCancel}
        footer={null}
      >
        <div
          style={{
            padding: "1em",
            backgroundColor: "rgba(236, 32, 37, 1)",
            borderRadius: "8px",
            color: "white",
            fontWeight: "bold",
            width: "100%",
            boxSizing: "border-box",
            fontSize: "18px",
          }}
        >
          Update Order Status
        </div>
        <div
          className="paymentForm"
          style={{
            padding: "1em",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Row>
            <Col span={24}>
              <Select
                allowClear
                options={[
                  //["ACCEPTED", "REJECTED", "DELIEVERED", "PROCESSING", "CANCELLED"]
                  { value: "ACCEPTED", label: "ACCEPTED" },
                  { value: "REJECTED", label: "REJECTED" },
                  { value: "DELIEVERED", label: "DELIEVERED" },
                  { value: "PROCESSING", label: "PROCESSING" },
                  { value: "CANCELLED", label: "CANCELLED" },
                ]}
                value={SelectedOrder?.status}
                style={{
                  borderColor: "transparent",
                  marginTop: 10,
                  width: "100%",
                }}
                onChange={(val) => {
                  console.log(val);
                  setSelectedOrder((prev) => ({ ...prev, status: val }));
                }}
              />
            </Col>
            <Col span={24}>
              <ButtonsFooter
                submitLabel="Edit"
                onCancel={handleCancel}
                onSubmit={handleSubmit}
              />
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
}

export default Sales;
