import React, { useEffect, useState, useContext } from "react";
import NavBar from "../../components/navBar/navBar";
import MobileNavBar from "../../components/mobileNavBar/mobileNavBar";
import { Content } from "antd/es/layout/layout";
import { Dropdown, Skeleton, Drawer, Col, Row, Pagination } from "antd";
import { DownOutlined } from "@ant-design/icons";
import BreadcrumbComp from "../../components/Breadcrumb/Breadcrumb";
import "./index.css";
import Card from "./Card";
import { appContext } from "../../Context/appContext";
import Filter from "./Filter";
//import Carousel from "better-react-carousel";
import { Footer } from "../../components/Footer/Footer";
import { chunk } from "../../lib/lib";
import { CiSearch } from "react-icons/ci";
import { AiOutlineClose } from "react-icons/ai";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import ImageComponent from "../../components/Image";

function RealEstate() {
  const {
    getAllPropertyListing,
    LoadingPropertyListing,
    PropertyListing,
    setSort,
    AllCategories,
  } = useContext(appContext);
  let realEstateCatImage =
    AllCategories?.find((ele) => ele?.isRealEstate)?.image || undefined;
  const [width, setWidth] = useState(window.innerWidth);
  const [open, setOpen] = useState(false);
  const [CurrentPage, setCurrentPage] = useState(1);
  const [PageSize, setPageSize] = useState(9);
  // const [pagesData, setPagesData] = useState([]);
  const [pagesAdsData, setPagesAdsData] = useState([]);
  const [pagesNonAdsData, setPagesNonAdsData] = useState([]);
  const [NonAds, setNonAds] = useState([]);
  const [Ads, setAds] = useState([]);
  const [sortName, setSortName] = useState("name asc");
  const [searchInput, setSearchInput] = useState("");
  const items = [
    {
      label: (
        <span>
          Name <AiOutlineArrowDown />
        </span>
      ),
      key: "name-asc",
    },
    {
      label: (
        <span>
          Name <AiOutlineArrowUp />
        </span>
      ),
      key: "name-desc",
    },
    {
      label: (
        <span>
          Price <AiOutlineArrowDown />
        </span>
      ),
      key: "price-asc",
    },
    {
      label: (
        <span>
          Price <AiOutlineArrowUp />
        </span>
      ),
      key: "price-desc",
    },
  ];
  const realEstateFilterLocalStorage = localStorage.getItem("realEstateFilter");
  //#region useEffect
  useEffect(() => {
    return () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };
  }, [CurrentPage]);
  useEffect(() => {
    let Ads = PropertyListing?.filter((ele) => ele?.isPromoted === true);
    let Non_Ads = PropertyListing?.filter((ele) => !ele?.isPromoted);
    setAds(Ads);
    setNonAds(Non_Ads);
    setPagesAdsData(chunk(Ads, PageSize));
    setPagesNonAdsData(chunk(Non_Ads, PageSize));
    //setPagesData(chunk(PropertyListing, PageSize));
  }, [PropertyListing, PageSize]);
  useEffect(() => {
    if (width < 1000) {
      setPageSize(10);
    } else {
      setPageSize(9);
    }
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  useEffect(() => {
    getAllPropertyListing({
      search: searchInput,
      ...JSON.parse(realEstateFilterLocalStorage),
    });
  }, [searchInput]);
  //#endregion useEffect
  const handleSort = (value) => {
    setSort(value?.key);
    //  setSortedVal(value?.key);
    setSortName(value?.key?.trim().replace("-", " "));
  };
  let cardSpan = width < 1000 ? 12 : 8;
  let cardFilterSpan = width < 1000 ? 8 : 7;
  const renderProductsPages = (label, pagesData, data, hidePagination) => {
    return (
      <>
        {data?.length > 0 && pagesData[CurrentPage - 1]?.length > 0 && (
          <Row gutter={[10, 10]}>
            <Col span={24}>
              <h2 style={{ color: "#ec2025" }}>{label}</h2>
            </Col>
            {data?.length === 0 && (
              <Col span={24}>
                <small>No {label} found.</small>
              </Col>
            )}
            {pagesData[CurrentPage - 1]?.map((val) => (
              <Col span={cardSpan}>
                <Card
                  data={val}
                  LoadingPropertyListing={LoadingPropertyListing}
                />
              </Col>
            ))}
          </Row>
        )}
        {!hidePagination && (
          <Pagination
            current={CurrentPage}
            pageSize={PageSize}
            onChange={(page) => {
              setCurrentPage(page);
            }}
            //total={data?.length}
            total={Ads?.length > NonAds?.length ? Ads?.length : NonAds?.length}
          />
        )}
      </>
    );
  };
  return (
    <>
      {width > 767 ? (
        <>
          <NavBar />
          <Content
            style={{
              // width: width > 1024 ? "1440px" : width <= 1024 ? "92%" : "70%",
              width: `calc(${width}px - ${width < 1200 ? 5 : 22}%)`,
              margin: "auto",
            }}
          >
            <Row gutter={[25, 25]}>
              {realEstateCatImage && (
                <Col span={24}>
                  <ImageComponent
                    image={realEstateCatImage}
                    style={{
                      width: "100%",
                      height: width > 767 ? "400px" : "130px",
                    }}
                  />
                </Col>
              )}
              <Col span={24}>
                <BreadcrumbComp />
              </Col>
              <Col span={cardFilterSpan}>
                <Filter setCurrentPage={setCurrentPage} />
              </Col>
              <Col span={24 - cardFilterSpan}>
                <div className="categoriesListTitle">
                  <Row
                    justify="space-between"
                    align="middle"
                    style={{ width: "100%" }}
                  >
                    <Col span={8}>
                      <div className="catTitle1">
                        <span style={{ textTransform: "capitalize" }}>
                          Real Estate
                        </span>
                      </div>
                    </Col>
                    <Col span={4} offset={12}>
                      <div className="catTitle2">
                        <span className="storeByTitle">Sort By</span>
                      </div>
                    </Col>
                    <Col span={4}>
                      <div className="catTitle1">
                        {LoadingPropertyListing ? (
                          <Skeleton.Button
                            active
                            block
                            size={20}
                            shape={"round"}
                          />
                        ) : (
                          <p>{PropertyListing?.length || 0} Results</p>
                        )}
                      </div>
                    </Col>
                    <Col span={4} offset={16}>
                      <div className="catTitle2">
                        <Dropdown
                          menu={{
                            items,
                            style: { marginTop: 20 },
                            onClick: handleSort,
                          }}
                          trigger={["click"]}
                        >
                          <p className="dropDownCat">
                            {sortName}{" "}
                            <DownOutlined
                              color="#231F20"
                              style={{ cursor: "pointer" }}
                            />
                          </p>
                        </Dropdown>
                      </div>
                    </Col>
                    <Col
                      span={24}
                      className="real-state-div"
                      style={{ marginTop: "20px" }}
                    >
                      <Row gutter={[10, 10]}>
                        <Col span={24}>
                          {renderProductsPages(
                            "Featured Ads",
                            pagesAdsData,
                            Ads,
                            true
                          )}
                        </Col>
                        <Col span={24}>
                          {renderProductsPages(
                            "Other",
                            pagesNonAdsData,
                            NonAds
                          )}
                        </Col>
                      </Row>
                      {/* <Carousel
                        containerClassName="carouselContainer"
                        mobileBreakpoint={500}
                        responsiveLayout={responsiveCategories}
                        showDots={PropertyListing?.length > 9}
                        dotColorActive="#231F20"
                        cols={1}
                        rows={1}
                        gap={10}
                        hideArrow={PropertyListing?.length === 0}
                      >
                        {chunk(PropertyListing, 9)?.map((arr, i) => {
                          return (
                            <Carousel.Item key={i}>
                              <Row gutter={[10, 10]}>
                                {arr?.map((val) => (
                                  <Col span={cardSpan}>
                                    <Card
                                      data={val}
                                      LoadingPropertyListing={
                                        LoadingPropertyListing
                                      }
                                    />
                                  </Col>
                                ))}
                              </Row>
                            </Carousel.Item>
                          );
                        })}
                      </Carousel> */}
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Content>
          <Footer />
        </>
      ) : (
        <>
          <MobileNavBar />
          <Content className="mobile-real-estate">
            <Row gutter={[5, 5]}>
              {realEstateCatImage && (
                <Col span={24}>
                  <ImageComponent
                    image={realEstateCatImage}
                    style={{
                      width: "100%",
                      height: width > 767 ? "400px" : "130px",
                    }}
                  />
                </Col>
              )}
              <Col span={24}>
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <div className="search-div-mobile">
                    <input
                      type="search"
                      className="search-mobile"
                      placeholder="Ex: house"
                      value={searchInput}
                      onChange={(e) => {
                        setSearchInput(e.target.value);
                      }}
                    />
                    <CiSearch
                      style={{ position: "absolute", top: "21%", left: "10px" }}
                      size={27}
                      color="#949398"
                    />
                    <img
                      alt=""
                      style={{
                        marginTop: 0,
                        height: "25px",
                        position: "absolute",
                        top: "25%",
                        right: "10px",
                        cursor: "pointer",
                      }}
                      src="/filter.png"
                      onClick={() => setOpen(true)}
                    />
                  </div>
                </div>
              </Col>
              <Col span={24}>
                {renderProductsPages("Featured Ads", pagesAdsData, Ads, true)}
              </Col>
              <Col span={24}>
                {renderProductsPages("Other", pagesNonAdsData, NonAds)}
              </Col>
              {/* {PropertyListing?.map((val, i) => {
                return (
                  <Col span={12}>
                    <Card
                      data={val}
                      LoadingPropertyListing={LoadingPropertyListing}
                    />
                  </Col>
                );
              })} */}
            </Row>
          </Content>
          <Footer />
        </>
      )}

      <Drawer
        title={<span style={{ fontSize: "24px", fontWeight: "bold" }}></span>}
        placement="bottom"
        closable={false}
        height={"95%"}
        style={{
          padding: "0",
          borderTopLeftRadius: 15,
          borderTopRightRadius: 15,
          boxSizing: "border-box",
        }}
        extra={
          <AiOutlineClose
            style={{ fontSize: 25, cursor: "pointer" }}
            onClick={() => setOpen(false)}
          />
        }
        onClose={() => setOpen(false)}
        open={open}
      >
        <div className="paymentForm" style={{ width: "100%", margin: "15px" }}>
          <Filter setOpen={setOpen} setCurrentPage={setCurrentPage} />
        </div>
      </Drawer>
    </>
  );
}

export default RealEstate;
