import React, { useEffect, useState } from "react";
import { Input, Row, Col, Button, message } from "antd";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import ProfileComp from "../../components/Profile/ProfileComp";
import { HiOutlineMail, HiOutlineUser } from "react-icons/hi";
import { BsTelephone } from "react-icons/bs";
import { getProfileApi, updateProfileApi } from "../../Apis";
import { BiEditAlt } from "react-icons/bi";
import { LoadingOutlined } from "@ant-design/icons";
import { FaRegBuilding } from "react-icons/fa";

function BasicInfo({
  ProfileInfo,
  width,
  setIsChangePassOpen,
  IsProfileDataUpdated,
  setIsProfileDataUpdated,
}) {
  const navigate = useNavigate();
  const userData = localStorage.getItem("userData");
  let roleName = JSON.parse(userData)?.role?.name;
  const [SubmitLoading, setSubmitLoading] = useState(false);
  const getProfileData = async () => {
    try {
      const response = await getProfileApi();
      // console.log(response);
      if (response?.data?.data) {
        setValue("myemail", response?.data?.data.email);
        setValue("myname", response?.data?.data.fullName);
        setValue("myphoneNumber", response?.data?.data.mobileNumber);
        setValue("company", response?.data?.data?.company);
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (ProfileInfo) {
      setValue("myemail", ProfileInfo?.email);
      setValue("myname", ProfileInfo?.fullName);
      setValue("myphoneNumber", ProfileInfo?.mobileNumber);
      setValue("company", ProfileInfo?.company);
    } else {
      getProfileData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [IsProfileDataUpdated, ProfileInfo]);
  const profileSchema = yup.object().shape({
    myname: yup.string("Invalid Full Name!").required("Full Name is required!"),
    profileImg: yup.mixed(),
    // email: yup.string("Invalid Email!").email("Invalid Email!"),

    myphoneNumber: yup
      .string()
      .required("Phone number is required")
      .min(10, "phone number  should be at least 10 numbers!")
      .max(16, "phone number  should be at most 16 numbers!"),

    // password: yup.string("Invalid password!"),
  });
  const {
    // register: register,
    handleSubmit,
    formState: { errors },
    setValue,
    // reset,
    control,
  } = useForm({
    resolver: yupResolver(profileSchema),
  });

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userData");
    navigate("/login");
    window.location.reload();
  };
  const onCancel = () => {
    getProfileData();
  };
  const onsubmit = async (data) => {
    const { myphoneNumber, myname, company, profileImg } = data;
    try {
      setSubmitLoading(true);
      const response = await updateProfileApi({
        mobileNumber: myphoneNumber,
        fullName: myname,
        company: company,
        profileImg,
      });
      // console.log(response);
      if (response.data.data) {
        message.success(response.data.messageEn);
        setIsProfileDataUpdated(!IsProfileDataUpdated);
        getProfileData();
        setSubmitLoading(false);
      }
    } catch (error) {
      if (error.response) {
        message.error(error.response.data.messageEn);
      } else {
        message.error(error.message);
      }
      setSubmitLoading(false);
    }
  };
  const userInfo = (
    <div className="userInfo">
      <ProfileComp />
      <small
        className="note"
        style={{ margin: "0px 25px", cursor: "pointer" }}
        onClick={() => setIsChangePassOpen(true)}
      >
        Change Password
      </small>
      <div className="userInfoBtn">
        <Button
          className="infoBtn white-btn hover-bg-red btn-height"
          onClick={logout}
          style={{ width: "100%" }}
        >
          Log Out
        </Button>
      </div>
    </div>
  );
  return (
    <>
      <Row cols={24} style={{ width: "99%" }}>
        {width > 767 && <Col span={8}>{userInfo}</Col>}
        <Col span={width > 767 ? 16 : 24}>
          <form onSubmit={handleSubmit(onsubmit)}>
            <Row gutter={[10, 10]}>
              <Col span={24}>
                <div className="profileInputComp">
                  <p className="profileLable">Your Name :</p>
                  <Controller
                    name="myname"
                    control={control}
                    render={({ field }) => (
                      <Input
                        prefix={
                          <HiOutlineUser
                            style={{ fontSize: "25px", color: "#c9c9c9" }}
                          />
                        }
                        {...field}
                        suffix={
                          <>
                            <BiEditAlt
                              style={{
                                fontSize: 25,
                                cursor: "pointer",
                                color: "#ACAAB6",
                              }}
                              onClick={() => {}}
                            />
                          </>
                        }
                        className="profileInput"
                        // readOnly
                        placeholder=" ex: John Michael Doe"
                      />
                    )}
                  />
                  <p style={{ color: "red" }}>{errors.myname?.message}</p>
                </div>
              </Col>
              <Col span={24}>
                <div className="profileInputComp">
                  <p className="profileLable">Email :</p>
                  <Controller
                    name="myemail"
                    control={control}
                    render={({ field }) => (
                      <Input
                        placeholder="ex: Johndoe@gmail.com"
                        prefix={
                          <HiOutlineMail
                            style={{ fontSize: "25px", color: "#c9c9c9" }}
                          />
                        }
                        readOnly
                        className="profileInput"
                        type="email"
                        {...field}
                      />
                    )}
                  />
                  <p style={{ color: "red" }}>{errors.email?.message}</p>
                </div>
              </Col>
              <Col span={24}>
                <div className="profileInputComp">
                  <small className="note">Confirm Phone Number</small>
                  <p className="profileLable">Phone Number :</p>
                  <Controller
                    name="myphoneNumber"
                    control={control}
                    render={({ field }) => (
                      <Input
                        placeholder=""
                        prefix={
                          <BsTelephone
                            style={{ fontSize: "25px", color: "#c9c9c9" }}
                          />
                        }
                        suffix={
                          <>
                            <BiEditAlt
                              style={{
                                fontSize: 25,
                                cursor: "pointer",
                                color: "#ACAAB6",
                              }}
                              onClick={() => {}}
                            />
                          </>
                        }
                        // readOnly
                        className="profileInput"
                        {...field}
                      />
                    )}
                  />
                  <p style={{ color: "red" }}>
                    {errors.myphoneNumber?.message}
                  </p>
                </div>
              </Col>
              {roleName === "vendor" && (
                <Col span={24}>
                  <div className="profileInputComp">
                    {/* <small className="note">Confirm Phone Number</small> */}
                    <p className="profileLable">Company Name:</p>
                    <Controller
                      name="company"
                      control={control}
                      render={({ field }) => (
                        <Input
                          placeholder=""
                          prefix={
                            <FaRegBuilding
                              style={{ fontSize: "25px", color: "#c9c9c9" }}
                            />
                          }
                          className="profileInput"
                          suffix={
                            <>
                              <BiEditAlt
                                style={{
                                  fontSize: 25,
                                  cursor: "pointer",
                                  color: "#ACAAB6",
                                }}
                                onClick={() => {}}
                              />
                            </>
                          }
                          {...field}
                        />
                      )}
                    />
                    {/* <p style={{ color: "red" }}>{errors.phoneNumber?.message}</p> */}
                  </div>
                </Col>
              )}
              <Col span={24}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    marginTop: "10px",
                  }}
                >
                  <button
                    onClick={onCancel}
                    type="button"
                    className="dialog-cancel-btn"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    //onClick={onSubmit}
                    disabled={SubmitLoading}
                    className="dialog-submit-btn"
                  >
                    {SubmitLoading ? <LoadingOutlined /> : "Save"}
                  </button>
                </div>
              </Col>
            </Row>
          </form>
        </Col>
      </Row>
    </>
  );
}

export default BasicInfo;
