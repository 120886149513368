import React, { useContext, useEffect, useState } from "react";
import { appContext } from "../../Context/appContext";
//import { useParams } from "react-router-dom";
import {
  Collapse,
  Divider,
  Skeleton,
  Slider,
  Row,
  Col,
  Select,
  Radio,
  Button,
  Tooltip,
} from "antd";
import { BsFunnel } from "react-icons/bs";
import _ from "lodash";
import { sortArray, sortArrayAsc } from "../../lib/lib";
import "./index.css";
import { HiMinusSm, HiPlusSm } from "react-icons/hi";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";

//import Slider, { Range } from "rc-slider";
//import "rc-slider/assets/index.css";

function FilterComp(props) {
  const {
    filters,
    categoryId,
    loadingFilters,
    getFilteredProductsRelatedToCategory,
    set_FilterValues,
    setSort,
    sort,
  } = useContext(appContext);
  const categoryFilterPathLocalStorage =
    localStorage.getItem("categoryFilterPath");
  let isNamePath =
    window.location.pathname.split("/")[1] === categoryFilterPathLocalStorage;

  //console.log("isNamePath ", isNamePath);
  const categoryFilterLocalStorage = localStorage.getItem("categoryFilter");
  const [FilterValues, setFilterValues] = useState(
    isNamePath
      ? {
          ...JSON.parse(categoryFilterLocalStorage),
          brandId: undefined,
          tagId: undefined,
        }
      : {
          min: undefined,
          max: undefined,
          tag: undefined,
          brand: undefined,
          brandId: undefined,
          tagId: undefined,
          prices: [],
        }
  );
  // console.log(
  //   "categoryFilterLocalStorage",
  //   JSON.parse(categoryFilterLocalStorage)
  // );
  // console.log("filters ", filters);
  //console.log("FilterValues ", FilterValues);
  const { CategoryVariants, defaultKeys = [], onClose } = props;
  const [width, setWidth] = useState(window.innerWidth);
  const [minMax, setMinMax] = useState({
    min: 0,
    max: 1000,
  });
  const [Prices, setPrices] = useState(
    isNamePath ? JSON.parse(categoryFilterLocalStorage)?.prices : []
  );
  const [sortedVal, setSortedVal] = useState("");
  const items = [
    {
      label: (
        <span>
          Name <AiOutlineArrowDown />
        </span>
      ),
      key: "name-asc",
    },
    {
      label: (
        <span>
          Name <AiOutlineArrowUp />
        </span>
      ),
      key: "name-desc",
    },
    {
      label: (
        <span>
          Price <AiOutlineArrowDown />
        </span>
      ),
      key: "price-asc",
    },
    {
      label: (
        <span>
          Price <AiOutlineArrowUp />
        </span>
      ),
      key: "price-desc",
    },
  ];
  //#region useEffect
  useEffect(() => {
    //onClearFilter();
    if (filters) {
      setFilterValues((prev) => ({
        ...prev,
        prices: sortArrayAsc(filters?.prices || []),
      }));
      let min = Math.min(...(filters?.prices || []));
      let max = Math.max(...(filters?.prices || []));
      setPrices([min, max]);
      setMinMax({
        min: min,
        max: max,
      });
    }
  }, [filters]);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  useEffect(() => {
    let _FilterValues = { ...FilterValues, brand: "", tag: "", sort: sort };
    _FilterValues["brand"] = FilterValues["brandId"] || "";
    _FilterValues["tag"] = FilterValues["tagId"] || "";
    if ("brandId" in _FilterValues) delete _FilterValues["brandId"];
    if ("tagId" in _FilterValues) delete _FilterValues["tagId"];
    if ("prices" in _FilterValues) delete _FilterValues["prices"];
    set_FilterValues(_FilterValues);
    if (categoryId) {
      getFilteredProductsRelatedToCategory(categoryId, _FilterValues);
      window.localStorage.setItem(
        "categoryFilter",
        JSON.stringify({ ...FilterValues, sort: sort })
      );
    }
  }, [categoryId, FilterValues, sort]);
  useEffect(() => {
    let dataFilter = JSON.parse(categoryFilterLocalStorage);
    if (CategoryVariants?.length > 0) {
      CategoryVariants?.forEach((element) => {
        setFilterValues((prev) => ({
          ...prev,
          tag: isNamePath ? dataFilter["tag"] : undefined,
          brand: isNamePath ? dataFilter["brand"] : undefined,
          prices: isNamePath
            ? dataFilter?.prices
            : sortArrayAsc(filters?.prices || []),
          [element?.key]: isNamePath ? dataFilter[element?.key] : undefined,
        }));
      });
    } else {
      setFilterValues((prev) => ({
        ...prev,
        tag: isNamePath ? dataFilter["tag"] : undefined,
        brand: isNamePath ? dataFilter["brand"] : undefined,
        prices: isNamePath
          ? dataFilter?.prices
          : sortArrayAsc(filters?.prices || []),
      }));
    }
  }, [CategoryVariants, categoryId]);
  useEffect(() => {
    let brandId = filters?.brands?.find(
      (ele) => ele.englishName === FilterValues?.brand
    )?._id;
    let tagId = filters?.tags?.find(
      (ele) => ele.englishName === FilterValues?.tag
    )?._id;
    setFilterValues((prev) => ({
      ...prev,
      brandId: brandId,
      tagId: tagId,
    }));
  }, [FilterValues?.brand, FilterValues?.tag, filters?.tags, filters?.brands]);
  //#endregion useEffect
  //#region func
  const onClearFilter = () => {
    let min = Math.min(...(filters?.prices || []));
    let max = Math.max(...(filters?.prices || []));
    setPrices([min, max]);
    if (CategoryVariants?.length > 0) {
      CategoryVariants?.forEach((element) => {
        setFilterValues((prev) => ({
          ...prev,
          [element?.key]: undefined,
          min: undefined,
          max: undefined,
          tag: undefined,
          brand: undefined,
          brandId: undefined,
          tagId: undefined,
          prices: sortArrayAsc(filters?.prices || []),
        }));
      });
    } else {
      setFilterValues((prev) => ({
        min: undefined,
        max: undefined,
        tag: undefined,
        brand: undefined,
        brandId: undefined,
        tagId: undefined,
        prices: sortArrayAsc(filters?.prices || []),
      }));
    }
  };
  const renderOptions = (key, type, options) => {
    let filteredOptions = sortArray(
      [...options]?.filter(
        (ele) => ele !== null && ele !== undefined && ele?.length > 0
      )
    );
    if (filteredOptions?.length === 0) return;
    if (type === "color") {
      return (
        <Row gutter={[35, 35]}>
          {filteredOptions?.map((val, i) => (
            <Col span={4} key={i}>
              <div
                className="divsize color-divsize"
                style={{
                  background: "#" + val,
                  cursor: "pointer",
                  border:
                    FilterValues[key] === val
                      ? "3px solid rgb(236, 32, 37)"
                      : "1px solid #ddd7d7",
                }}
                onClick={() => onChangeOption(val, key)}
              />
            </Col>
          ))}
        </Row>
      );
    } else if (type === "selection circles") {
      return (
        <Row gutter={[35, 15]}>
          {filteredOptions?.sort()?.map((val, i) => (
            <Col span={4} key={i}>
              <div
                className="divsize capitalize-text"
                style={{
                  cursor: "pointer",
                  border:
                    FilterValues[key] === val && "3px solid rgb(236, 32, 37)",
                }}
                onClick={() => onChangeOption(val, key)}
              >
                {val}
              </div>
            </Col>
          ))}
        </Row>
      );
    } else if (type === "dropdown") {
      return (
        <Row>
          <Col span={24}>
            <Select
              defaultValue={FilterValues[key]}
              value={FilterValues[key]}
              onChange={(value) => onChangeOption(value, key)}
              style={{ width: "100%" }}
              placeholder="Select option"
              options={filteredOptions?.map((ele) => ({
                value: ele,
                label: ele,
              }))}
              allowClear
            />
          </Col>
        </Row>
      );
    } else if (type === "radio") {
      return (
        <Row>
          <Col span={24}>
            <Radio.Group
              onChange={(e) => onChangeOption(e.target.value, key)}
              value={FilterValues[key]}
            >
              {filteredOptions?.map((ele, i) => (
                <Radio key={i} value={ele}>
                  {ele}
                </Radio>
              ))}
            </Radio.Group>
          </Col>
        </Row>
      );
    }
  };
  const circleLoadingFilter = (
    <>
      <Row>
        {Array(3)
          .fill(null)
          .map((_, i) => (
            <Col span={4} key={i}>
              <Skeleton.Avatar shape="circle" size={50} />
            </Col>
          ))}
      </Row>
    </>
  );
  const onChangeOption = (value, key) => {
    if (FilterValues[key] === value) {
      setFilterValues((prev) => ({
        ...prev,
        [key]: undefined,
      }));
    } else {
      setFilterValues((prev) => ({
        ...prev,
        [key]: value,
      }));
    }
  };
  const renderItems = () => {
    return [
      {
        key: "Offers",
        label: <span className="categoriesItemCollapse">Offers</span>,
        children: (
          <>
            {loadingFilters
              ? circleLoadingFilter
              : renderOptions(
                  "tag",
                  "selection circles",
                  (filters?.tags || [])?.map((ele) => ele?.englishName)
                )}
          </>
        ),
      },
      {
        key: "Brands",
        label: <span className="categoriesItemCollapse">Brands</span>,
        children: (
          <>
            {loadingFilters
              ? circleLoadingFilter
              : renderOptions(
                  "brand",
                  "selection circles",
                  (filters?.brands || [])?.map((ele) => ele?.englishName)
                )}
          </>
        ),
      },
      ...(CategoryVariants || [])
        ?.filter((ele) => filters[ele?.key]?.length > 0)
        ?.map((val) => ({
          key: val?.key,
          label: <span className="categoriesItemCollapse">{val?.key}</span>,
          children: (
            <>
              {loadingFilters
                ? circleLoadingFilter
                : renderOptions(val?.key, `${val?.type}`.toLowerCase(), [
                    ...new Set(filters[val?.key] || []),
                  ])}
            </>
          ),
        })),
      {
        key: "Price",
        label: <span className="categoriesItemCollapse">Price</span>,
        children: (
          <>
            <Slider
              range={{ draggableTrack: true }}
              defaultValue={[minMax.min, minMax.max]}
              value={Prices}
              min={minMax.min}
              max={minMax.max}
              style={{ maxWidth: "100%" }}
              //step={null}
              marks={{
                [Prices?.length > 0 ? Prices[0] : 0]: `${
                  Prices?.length > 0 ? Prices[0] : 0
                }`,
                [Prices?.length > 1 ? Prices[1] : 0]: `${
                  Prices?.length > 1 ? Prices[1] : 0
                }`,
              }}
              onChange={(newValue) => {
                setPrices(newValue);
                setFilterValues((prev) => ({
                  ...prev,
                  prices: newValue,
                  min: newValue[0],
                  max: newValue[1],
                }));
              }}
            />
          </>
        ),
      },
    ];
  };
  const sortFunc = (value) => {
    if (sortedVal != value) {
      setSortedVal(value);
    } else {
      setSortedVal("");
    }
    setSort(value);
  };
  const sortCollapseArr = [
    {
      // key: "1",
      label: (
        <span
          style={{
            fontSize: "16px",
            textAlign: "end",
            display: "inline-block",
            width: "100%",
          }}
          className="categoriesItemCollapse"
        >
          Sort By Name/Price
        </span>
      ),
      children: (
        <div className="categoriesOffer">
          <>
            {items?.map((off, i) => (
              <div className="offerTitle1" key={i}>
                <span style={{ color: sortedVal == off?.key && "#EC2025" }}>
                  {off?.label}
                </span>
                {sortedVal == off.key ? (
                  <HiMinusSm
                    className="selectCat"
                    style={{ color: "#231F20" }}
                    onClick={() => sortFunc(off?.key)}
                  />
                ) : (
                  <HiPlusSm
                    className="selectCat"
                    style={{ color: "#231F20" }}
                    onClick={() => sortFunc(off?.key)}
                  />
                )}
              </div>
            ))}
          </>
        </div>
      ),
    },
  ];
  //#endregion func
  return (
    <>
      <div className="categoriesFilter">
        {width > 767 ? (
          <>
            <div className="categoryFilterTitle">
              <div>
                <span className="title1" style={{ marginRight: "5px" }}>
                  Filter
                </span>
                <Tooltip title="Clear filter">
                  <Button
                    onClick={onClearFilter}
                    type="text"
                    shape="circle"
                    icon={<BsFunnel size={18} />}
                  />
                </Tooltip>
              </div>
              <img src="/Group 10.svg" alt="" />
            </div>
            <div className="dividerPading">
              <Divider className="categoriesDivider" />
            </div>
          </>
        ) : (
          <>
            <div
              className="categoryFilterTitle"
              style={{
                marginLeft: "15px",
                marginRight: "30px",
              }}
            >
              <div>
                <span className="title1" style={{ marginRight: "5px" }}>
                  Filter
                </span>
                <Tooltip title="Clear filter">
                  <Button
                    onClick={onClearFilter}
                    type="text"
                    shape="circle"
                    icon={<BsFunnel size={18} />}
                  />
                </Tooltip>
              </div>
              <img src="/Group 10.svg" alt="" />
            </div>
            <div className="dividerPading">
              <Divider className="categoriesDivider" />
            </div>
            <div
              className="categorisFilterCollapse"
              style={{ margin: width > 767 ? "0px" : "0 30px 0 10px" }}
            >
              <Collapse
                defaultActiveKey={[...sortCollapseArr.map((data) => data.key)]}
                ghost
                items={sortCollapseArr}
                expandIconPosition="end"
              />
            </div>
          </>
        )}
        <div
          style={{ margin: width > 767 ? "0px" : "0 30px 0 10px" }}
          className="categorisFilterCollapse"
        >
          {(defaultKeys?.length > 0 || CategoryVariants?.length === 0) &&
          !props?.CategoryLoading ? (
            <Collapse
              defaultActiveKey={["Offers", ...defaultKeys, "Brands", "Price"]}
              ghost
              items={renderItems(filters)}
              expandIconPosition="end"
            />
          ) : (
            <>{circleLoadingFilter}</>
          )}
          {width <= 767 && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Button
                style={{
                  padding: "20px auto",
                  height: "50px",
                  width: "150px",
                }}
                className="red-btn hover-bg-w"
                onClick={onClose}
              >
                Apply
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default FilterComp;
