/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import "./Footer.css";
import { useState, useEffect } from "react";
import MobileFooter from "../mobileFooter/mobileFooter";

export const Footer = () => {
	const [width, setWidth] = useState(window.innerWidth);
	useEffect(() => {
		function handleResize() {
			setWidth(window.innerWidth);
		}
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, [width]);
	return (
		<>
			{
				width > 767 ? (
					<div className="footer-main-div">
						<div className="top-div">
							<div className="logo-parent">
								<img src="/logo-footer.svg" className="footer-logo" />
								<div className="scoial-para">
									<p style={{ color: "white", fontSize: "12px" }}>Find Us On</p>
									<div className="social-parent">
										<img src="/whatsapp.svg" className="social-icons" />{" "}
										<img src="/instagram.svg" className="social-icons" />
										<img src="/facebook.svg" className="social-icons" />
										<img src="/twitter.svg" className="social-icons" />
										<img src="/youtube.svg" className="social-icons" />
									</div>
								</div>
							</div>
							<div className="data-parent">
								<div>
									<p className="data-head">Customer Service</p>
									<p className="data-para">Service and Warranty</p>
									<p className="data-para">Return and Exchange</p>
									<p className="data-para">Secured online Payment</p>
									<p className="data-para">Shipping & Delivery</p>
									<p className="data-para">Cash On Delivery</p>
									<p className="data-para">Home Delivery</p>
								</div>

								<div>
									<p className="data-head">About Us</p>
									<p className="data-para">About Beirut Mart</p>
									<p className="data-para">Careers</p>
									<p className="data-para">Newsletter</p>
									<p className="data-para">News And Updates</p>
								</div>
								<div>
									<p className="data-head">Helping You Save</p>
									<p className="data-para">Promo Code</p>
									<p className="data-para">Payment Gateway</p>
									<p className="data-para">Become a Vendor</p>
								</div>
								<div>
									<p className="data-head">Help And Support</p>
									<p className="data-para">Contact Us</p>
									<p className="data-para">Site Map</p>
									<p className="data-para">Terms and Conditions</p>
									<p className="data-para">Privacy Policy</p>
									<p className="data-para">Need Help?</p>
									<p className="data-para">Find a Store</p>
								</div>
							</div>
						</div>
						<div className="bottom-para-parent">
							<p className="bottom-para">
								Download Our App Via{" "}
								<img
									src="/apple.svg"
									style={{ marginLeft: "10px", width: "25px", height: "25px" }}
								/>{" "}
								<img
									src="/google-play.svg"
									style={{ marginLeft: "10px", width: "25px", height: "25px" }}
								/>
							</p>
							<p className="bottom-para">
								{new Date().getFullYear()} Beirut Mart. All Rights Reserved.{" "}
							</p>
							<p className="bottom-para download">
								<a
									target="_blank"
									href="https://dcentrify.com/"
                  rel="noreferrer"
									style={{ margin: "0px 3px", color: "white" }}
								>
									Designed by Dcentrify
								</a>{" "}
							</p>
						</div>
					</div>
				) : (
					<MobileFooter />
				)
				//       (<div
				//       style={{
				//         padding:"24px 62px 36px 62px",
				//         backgroundColor:"white",
				//         position:"sticky",
				//         bottom:"0px",
				//         display:"flex",
				//         justifyContent:"space-around",
				//         alignItems:"center",
				//         width:"100%"
				//       }}
				//       >

				// <TbTicket style={{fontSize:"30px"}}/>
				// <FiSearch style={{fontSize:"30px"}}/>
				// <GrHomeRounded style={{fontSize:"30px"}}/>
				// <AiOutlineHeart style={{fontSize:"30px"}}/>
				// <BsCart2 style={{fontSize:"30px"}}/>
				//       </div>)
			}
		</>
	);
};
