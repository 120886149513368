import React, { useContext, useEffect, useState } from "react";
import BreadcrumbComp from "../../components/Breadcrumb/Breadcrumb";
import NavBar from "../../components/navBar/navBar";
import { Footer } from "../../components/Footer/Footer";
import { Content } from "antd/es/layout/layout";
import {
  Url,
  getProfileApi,
  updateProfileApi,
  changePassApi,
  getAddressApi,
} from "../../Apis";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { IoLogOutOutline } from "react-icons/io5";
import {
  Button,
  Input,
  Modal,
  Row,
  Col,
  message,
  Upload,
  Avatar,
  Select,
} from "antd";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import { RiLockPasswordLine } from "react-icons/ri";
import PhoneInput, { isValidPhoneNumber } from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import { appContext } from "../../Context/appContext";
import ProfileComp from "../../components/Profile/ProfileComp";
import { RxDashboard } from "react-icons/rx";
import { IoLocationOutline } from "react-icons/io5";
import { MdPayment } from "react-icons/md";
import { SlBasket } from "react-icons/sl";
import { PiExportLight } from "react-icons/pi";
import { CiViewList } from "react-icons/ci";
import "./index.css";
import Orders from "./Orders";
import BasicInfo from "./BasicInfo";
import Address from "./Address";
import MyProducts from "./MyProducts";
import Sales from "./Sales";
import { getImgVidUrl } from "../../lib/lib";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};
const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
};
function MyProfile() {
  //#region state
  const {
    setFullName,
    setImgUrl,
    getVendorProducts,
    getAllOrders,
    IsProfileDataUpdated,
    setIsProfileDataUpdated,
    AllProducts,
  } = useContext(appContext);
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [width, setWidth] = useState(window.innerWidth);
  const [show, setShow] = useState(false);
  const [isChangePassOpen, setIsChangePassOpen] = useState(false);
  const [isUpdateInfo, setIsUpdateInfo] = useState(false);
  const [IntervalOption, setIntervalOption] = useState("monthly");
  const [addresses, setAddresses] = useState([]);
  const [ProfileInfo, setProfileInfo] = useState({});

  const [TabInfo, setTabInfo] = useState({
    label: "Basic Info",
    infoLabel: "Info:",
    comp: (
      <>
        <BasicInfo
          width={width}
          setIsChangePassOpen={setIsChangePassOpen}
          IsProfileDataUpdated={IsProfileDataUpdated}
          setIsProfileDataUpdated={setIsProfileDataUpdated}
        />
      </>
    ),
  });
  //#endregion state

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  const navigate = useNavigate();
  const userData = localStorage.getItem("userData");
  let roleName = JSON.parse(userData)?.role?.name;
  // console.log(roleName);
  //#region useEffect
  useEffect(() => {
    getAllOrders();
    getAddress();
    getVendorProducts("some");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    return () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };
  }, []);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  useEffect(() => {
    getProfileData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [IsProfileDataUpdated]);
  useEffect(() => {
    let path = window.location.pathname;
    if (path === "/my-profile/my-addresses") {
      setTabInfo({
        label: "My Addresses",
        infoLabel: "Info:",
        comp: MyAddressesComp,
      });
    } else if (path === "/my-profile/payment-info") {
      setTabInfo({
        label: "Payment Info",
        infoLabel: "Info:",
        comp: PaymentInfoComp,
      });
    } else if (path === "/my-profile/sales") {
      setTabInfo({
        label: "Sales",
        infoLabel: "Sales Graph:",
        comp: SalesComp,
        topRightItem: SalesTopRightItem,
      });
    } else if (path === "/my-profile/orders" || path === "/my-profile/order") {
      setTabInfo({
        label: "Orders",
        infoLabel: "My Orders Info:",
        comp: OrdersComp,
      });
    } else if (path === "/my-profile/my-products") {
      setTabInfo({
        label: "My Products",
        infoLabel: "My Products List:",
        comp: MyProductsComp,
      });
    } else {
      setTabInfo({
        label: "Basic Info",
        infoLabel: "Info:",
        comp: (
          <>
            <BasicInfo
              width={width}
              ProfileInfo={ProfileInfo}
              setIsChangePassOpen={setIsChangePassOpen}
              IsProfileDataUpdated={IsProfileDataUpdated}
              setIsProfileDataUpdated={setIsProfileDataUpdated}
            />
          </>
        ),
      });
    }
  }, [window.location.pathname, ProfileInfo, IntervalOption]);
  //#endregion useEffect
  //#region functions
  const handleChangeInfoCancel = () => {
    setIsUpdateInfo(false);
  };
  const handleChangePassCancel = () => {
    setIsChangePassOpen(false);
  };
  //change password schema
  const changePassSchema = yup.object().shape({
    oldPassword: yup
      .string("Invalid password!")
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
        "Password length must be start with capital letter , 3 characters long , has a number and special character at the end"
      )
      .required("Password is required"),
    newPassword: yup
      .string("Invalid password!")
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
        "Password length must be start with capital letter , 3 characters long , has a number and special character at the end"
      )
      .required("Password is required"),
  });
  const {
    // register: registerPass,
    handleSubmit: handleChangePassSubmit,
    formState: { errors: changePassErrors },
    control: changePassControl,
    // reset: changePassReset,
  } = useForm({
    resolver: yupResolver(changePassSchema),
  });
  //end of change password schema
  const getAddress = async () => {
    try {
      let response = await getAddressApi();
      setAddresses([...response.data.data]);
    } catch (error) {
      // console.log(error.message);
    }
  };
  const profileSchema = yup.object().shape({
    fullName: yup
      .string("Invalid Full Name!")
      .required("Full Name is required!"),
    profileImg: yup.mixed(),
    // email: yup.string("Invalid Email!").email("Invalid Email!"),

    mobileNumber: yup
      .string()
      .required("Phone number is required")
      .min(10, "phone number  should be at least 10 numbers!")
      .max(16, "phone number  should be at most 16 numbers!"),

    // password: yup.string("Invalid password!"),
  });

  const {
    // register: register,
    handleSubmit,
    formState: { errors },
    setValue,
    // reset,
    control,
  } = useForm({
    resolver: yupResolver(profileSchema),
  });

  const handleChange = (info) => {
    // console.log(info);
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      // console.log(info.file.response.data.file.fileName);
      setValue("profileImg", info.file.response.data.file.path);
      setImgUrl(info.file.response.data.file.path);
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };
  const onsubmit = async (data) => {
    // console.log(data);
    const { mobileNumber, fullName, profileImg } = data;
    try {
      const response = await updateProfileApi({
        mobileNumber,
        fullName,
        profileImg,
      });
      // console.log(response);
      if (response.data.data) {
        message.success(response.data.messageEn);
        getProfileData();
        setIsUpdateInfo(false);
      }
    } catch (error) {
      if (error.response) {
        message.error(error.response.data.messageEn);
      } else {
        message.error(error.message);
      }
    }
  };
  const getProfileData = async () => {
    try {
      const response = await getProfileApi();
      // console.log(response);
      if (response?.data?.data) {
        setProfileInfo(response?.data?.data);
        setValue("myemail", response?.data?.data.email);
        setValue("myname", response?.data?.data.fullName);
        setValue("myphoneNumber", response?.data?.data.mobileNumber);
        setValue("company", response?.data?.data?.company);
        setFullName(response?.data?.data.fullName);
        setImgUrl(response?.data?.data.profileImg);
        // console.log(response?.data?.data.profileImg);
      }
    } catch (error) {
      if (error.response) {
        // message.error(error.response.data.messageEn);
      } else {
        // message.error(error.message);
      }
    }
  };
  const changePassOnSubmit = async (data) => {
    // console.log("changePass", data);
    try {
      let response = await changePassApi(data);
      if (response) {
        message.success(response?.data?.messageEn);
        handleChangePassCancel();
      }
    } catch (e) {
      message.error(e?.response?.data?.message);
    }
  };
  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userData");
    navigate("/login");
    window.location.reload();
  };
  //#endregion functions
  //#region tabItems
  const userInfo = (
    <div className="userInfo">
      <ProfileComp />
      <small
        className="note"
        style={{ margin: "0px 25px", cursor: "pointer" }}
        onClick={() => setIsChangePassOpen(true)}
      >
        Change Password
      </small>
      <div className="userInfoBtn">
        <Button
          className="infoBtn white-btn hover-bg-red"
          onClick={() => logout()}
        >
          Log Out
        </Button>
      </div>
    </div>
  );
  const BasicInfoComp = (
    <div style={{ margin: width > 767 ? "0px 30px" : "0px" }}>
      <BasicInfo
        ProfileInfo={ProfileInfo}
        width={width}
        setIsChangePassOpen={setIsChangePassOpen}
        IsProfileDataUpdated={IsProfileDataUpdated}
        setIsProfileDataUpdated={setIsProfileDataUpdated}
      />
    </div>
  );
  const MyAddressesComp = (
    <div style={{ margin: width > 767 ? "0px 30px" : "0px" }}>
      <Address
        width={width}
        Address={addresses}
        setAddressesTab={setAddresses}
      />
    </div>
  );
  const PaymentInfoComp = (
    <div style={{ margin: width > 767 ? "0px 30px" : "0px" }}>payment</div>
  );
  const SalesComp = (
    <div style={{ margin: width > 767 ? "0px 30px" : "0px" }}>
      <Sales
        width={width}
        IntervalOption={IntervalOption}
        AllProducts={AllProducts}
      />
    </div>
  );
  const OPTIONS = ["monthly", "yearly", "weekly"];
  const SalesTopRightItem = (
    <>
      <Select
        value={IntervalOption}
        style={{ width: 120 }}
        onChange={(value) => {
          console.log(value);
          setIntervalOption(value);
        }}
        options={OPTIONS?.map((val) => ({
          value: val,
          label: val,
        }))}
      />
    </>
  );
  const OrdersComp = <Orders width={width} setTabInfo={setTabInfo} />;
  const MyProductsComp = <MyProducts width={width} setTabInfo={setTabInfo} />;
  let tabItems = [
    {
      label: "Basic Info",
      icon: <RxDashboard size={24} />,
      infoLabel: "Info:",
      comp: BasicInfoComp,
      url: "/my-profile/basic-info",
    },
    {
      label: "My Addresses",
      icon: <IoLocationOutline size={24} />,
      infoLabel: "Info:",
      comp: MyAddressesComp,
      url: "/my-profile/my-addresses",
    },
    {
      label: "Payment Info",
      icon: <MdPayment size={24} />,
      infoLabel: "Info:",
      comp: PaymentInfoComp,
      url: "/my-profile/payment-info",
    },
    {
      label: "Sales",
      icon: <PiExportLight size={24} />,
      infoLabel: "Sales Graph:",
      comp: SalesComp,
      topRightItem: SalesTopRightItem,
      hide: roleName !== "vendor",
      url: "/my-profile/sales",
    },
    {
      label: "Orders",
      icon: <SlBasket size={24} />,
      infoLabel: "My Orders Info:",
      comp: OrdersComp,
      url: "/my-profile/orders",
    },
    {
      label: "My Products",
      icon: <CiViewList size={24} />,
      infoLabel: "My Products List:",
      comp: MyProductsComp,
      hide: roleName !== "vendor",
      url: "/my-profile/my-products",
    },
  ];
  const onClickTabItems = (val) => {
    setTabInfo({
      label: val?.label,
      infoLabel: val?.infoLabel,
      comp: val?.comp,
      topRightItem: val?.topRightItem || <></>,
    });
    navigate(val?.url);
  };
  //#endregion tabItems
  //console.log("ProfileInfo ", ProfileInfo);
  return (
    <>
      {width > 767 ? (
        <div style={{ width: "100%", maxWidth: "100%" }}>
          <NavBar />
          <Content style={{ width: "100%" }}>
            <Row cols={24}>
              <Col span={7} style={{ borderRight: "1px solid #EC2025" }}>
                <Row cols={24}>
                  <Col span={24}>
                    <div className="container-breadcrumb">
                      <BreadcrumbComp />
                    </div>
                  </Col>
                  {tabItems?.map((val, i) => {
                    if (!val?.hide)
                      return (
                        <Col key={i} span={24}>
                          <div
                            onClick={() => onClickTabItems(val)}
                            className={`tab-item-style ${
                              TabInfo?.label === val?.label ? "active" : ""
                            }`}
                          >
                            {val?.icon}
                            <p style={{ marginLeft: "10px" }}>{val?.label}</p>
                          </div>
                        </Col>
                      );
                  })}
                  <Col
                    span={24}
                    style={{
                      //  marginTop: "auto",
                      borderTop: "1px solid #EC2025",
                      //  padding: "5px",
                    }}
                  >
                    <div className={"profile-box"}>
                      <div className="image-container">
                        <Avatar
                          size={50}
                          src={getImgVidUrl(ProfileInfo?.profileImg)}
                        />
                      </div>
                      <Row>
                        <Col span={17}>
                          <Row>
                            <Col span={24}>
                              <h3>{ProfileInfo?.fullName}</h3>
                            </Col>
                            <Col span={24}>
                              <p>{ProfileInfo?.email}</p>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={2}>
                          <IoLogOutOutline
                            size={25}
                            style={{ cursor: "pointer", color: "#EC2025" }}
                            onClick={() => logout()}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col span={15}>
                <Row cols={24}>
                  <Col span={24} style={{ padding: "0px 30px" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <h3>{TabInfo?.infoLabel}</h3>
                      {TabInfo?.topRightItem ? TabInfo?.topRightItem : <></>}
                    </div>
                  </Col>
                  <Col span={24} style={{ marginTop: "15px" }}>
                    {TabInfo?.comp}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Content>
        </div>
      ) : (
        <>
          <NavBar />
          <Content
            style={{
              width: "100%",
              padding: "10px 25px",
              margin: "0px auto 110px",
            }}
          >
            <Row cols={24}>
              <Col span={24}>{userInfo}</Col>
              <Col span={24}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    overflow: "auto",
                    maxWidth: "99%",
                    margin: "15px auto",
                  }}
                >
                  {tabItems?.map((val, i) => {
                    if (!val?.hide)
                      return (
                        <div
                          key={i}
                          onClick={() => onClickTabItems(val)}
                          className={`mobile-item-style ${
                            TabInfo?.label === val?.label
                              ? "active"
                              : "inactive"
                          } `}
                        >
                          <p>{val?.label}</p>
                        </div>
                      );
                  })}
                </div>
              </Col>
              <Col span={24}>{TabInfo?.comp}</Col>
            </Row>
          </Content>
        </>
      )}
      <Footer />
      {/*******change Info Modal***** */}
      <Modal
        // title="Basic Modal"
        className="add-address-modal"
        bodyStyle={{
          padding: 0,
          border: "none",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        // style={{ border: "1px solid #707070", padding: 0 }}
        // okText="Add"
        closable={false}
        open={isUpdateInfo}
        // onOk={handleAddAddressOk}
        onCancel={handleChangeInfoCancel}
        footer={null}
      >
        <div
          style={{
            padding: "1em",
            backgroundColor: "rgba(236, 32, 37, 1)",
            borderRadius: "8px",
            color: "white",
            fontWeight: "bold",
            width: "100%",
            boxSizing: "border-box",
            fontSize: "18px",
          }}
        >
          Update Profile
        </div>
        <div
          className="paymentForm"
          style={{
            padding: "1em",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <form onSubmit={handleSubmit(onsubmit)}>
            <Row gutter={[24, 10]}>
              <Col xs={24} md={24}>
                <div className="uploadProfile">
                  <p className="becomeVendorLable"> Profile Image :</p>
                  <Controller
                    name="file"
                    control={control}
                    render={({ field }) => (
                      <Upload
                        style={{
                          width: 140,
                          height: 140,
                          margin: "20px auto",
                          display: "flex",
                          justifyContent: "center",
                        }}
                        name="file"
                        {...field}
                        listType="picture-circle"
                        className="avatar-uploader"
                        showUploadList={false}
                        action={Url + "/uploadImage"}
                        beforeUpload={beforeUpload}
                        onChange={handleChange}
                      >
                        {imageUrl ? (
                          <img
                            src={getImgVidUrl(imageUrl)}
                            alt="avatar"
                            style={{
                              width: "100%",
                              borderRadius: "50%",
                            }}
                          />
                        ) : (
                          uploadButton
                        )}
                      </Upload>
                    )}
                  />
                </div>
              </Col>
              <Col xs={24} md={24}>
                <p className="becomeVendorLable">Your Name :</p>
                <Controller
                  name="fullName"
                  control={control}
                  render={({ field }) => (
                    <Input
                      style={{ height: "50px" }}
                      {...field}
                      className="becomeVendorInput"
                      placeholder=" ex: John Michael Doe"
                    />
                  )}
                />
                <p style={{ color: "red" }}>{errors.fullName?.message}</p>
              </Col>
              <Col xs={24} md={24}>
                <p className="becomeVendorLable">Phone Number :</p>
                <Controller
                  name="mobileNumber"
                  control={control}
                  rules={{
                    validate: (value) => isValidPhoneNumber(value),
                  }}
                  render={({ field: { onChange, value } }) => (
                    <PhoneInput
                      onChange={onChange}
                      value={value}
                      id="phoneNumber"
                      containerStyle={{
                        margin: "10px auto",
                      }}
                      enableSearch
                      enableClickOutside
                      enableTerritories
                      country={"lb"}
                      inputClass="profileInput"
                      inputStyle={{
                        width: "100%",
                        height: "50px",
                        position: "relative",
                        // margin: "10px 0",
                        boxSizing: "border-box",
                        // paddingTop: "1.8em ",
                        // paddingBottom: "1.8em ",
                        // paddingLeft:"2.5em",
                        // textIndent: "1em",
                        border: "1px solid #ccc",
                        outline: "none",
                        borderRadius: "7px",
                        backgroundColor: "#EBEBEB",
                        boxShadow: "0 0 4px lightgray",
                      }}

                      // onChange={phone =>addPhoneNumber(phone)}
                      // {...register("mobileNumber")}
                    />
                  )}
                />

                <p style={{ color: "red" }}>{errors.phoneNumber?.message}</p>
              </Col>
            </Row>
            <Row gutter={[24, 24]} justify={"end"}>
              <Col
                xs={24}
                style={{
                  display: "flex",
                  margin: "20px 0",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <button
                  type="button"
                  // style={{ width: "100%" }}
                  onClick={handleChangeInfoCancel}
                  className="dialog-cancel-btn"
                >
                  Cancel
                </button>
                <button
                  // style={{ width: "100%" }}
                  type="submit"
                  className="dialog-submit-btn"
                >
                  Edit
                </button>
              </Col>
            </Row>
          </form>
        </div>
      </Modal>
      {/*******change password Modal***** */}
      <Modal
        // title="Basic Modal"
        className="add-address-modal"
        bodyStyle={{
          padding: 0,
          border: "none",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        // style={{ border: "1px solid #707070", padding: 0 }}
        // okText="Confirm"
        footer={null}
        closable={false}
        open={isChangePassOpen}
        // onOk={handleChangePassOk}
        onCancel={handleChangePassCancel}
      >
        <div
          style={{
            padding: "1em",
            backgroundColor: "rgba(236, 32, 37, 1)",
            borderRadius: "8px",
            color: "white",
            fontWeight: "bold",
            width: "100%",
            boxSizing: "border-box",
            fontSize: "18px",
          }}
        >
          Change Password
        </div>
        <div
          className="paymentForm"
          style={{
            padding: "1em",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <form onSubmit={handleChangePassSubmit(changePassOnSubmit)}>
            <Row
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "0.5em",
              }}
              gutter={[24, 24]}
            >
              <Col xs={24}>
                <p
                  className="becomeVendorLable"
                  style={{ marginBottom: "0.25em" }}
                >
                  Old Password*
                </p>
                <Controller
                  name="oldPassword"
                  control={changePassControl}
                  render={({ field }) => (
                    <Input
                      prefix={
                        <RiLockPasswordLine
                          style={{
                            fontSize: "25px",
                            color: "#c9c9c9",
                            padding: "0.25em",
                          }}
                        />
                      }
                      suffix={
                        !show ? (
                          <AiOutlineEye
                            style={{ fontSize: "25px", color: "#000" }}
                            onClick={() => setShow(true)}
                          />
                        ) : (
                          <AiOutlineEyeInvisible
                            style={{ fontSize: "25px", color: "#000" }}
                            onClick={() => setShow(false)}
                          />
                        )
                      }
                      className="becomeVendorInput"
                      style={{ height: 50 }}
                      type={!show ? "password" : "text"}
                      {...field}
                    />
                  )}
                />

                <p style={{ color: "red" }}>
                  {changePassErrors.oldPassword?.message}
                </p>
              </Col>
              <Col xs={24}>
                <p
                  className="becomeVendorLable"
                  style={{ marginBottom: "0.5em" }}
                >
                  New Password*
                </p>
                <Controller
                  name="newPassword"
                  control={changePassControl}
                  render={({ field }) => (
                    <Input
                      prefix={
                        <RiLockPasswordLine
                          style={{
                            fontSize: "25px",
                            color: "#c9c9c9",
                            padding: "0.25em",
                          }}
                        />
                      }
                      suffix={
                        !show ? (
                          <AiOutlineEye
                            style={{ fontSize: "25px", color: "#000" }}
                            onClick={() => setShow(true)}
                          />
                        ) : (
                          <AiOutlineEyeInvisible
                            style={{ fontSize: "25px", color: "#000" }}
                            onClick={() => setShow(false)}
                          />
                        )
                      }
                      className="becomeVendorInput"
                      type={!show ? "password" : "text"}
                      style={{ height: 50 }}
                      {...field}
                    />
                  )}
                />
                <p style={{ color: "red" }}>
                  {changePassErrors.newPassword?.message}
                </p>
              </Col>
              <Col
                xs={24}
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <button
                  onClick={handleChangePassCancel}
                  type="button"
                  className="dialog-cancel-btn"
                >
                  {" "}
                  Cancel{" "}
                </button>
                <button type="submit" className="dialog-submit-btn">
                  {" "}
                  Edit
                </button>
              </Col>
            </Row>
          </form>
        </div>
      </Modal>
    </>
  );
}

export default MyProfile;
