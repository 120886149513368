import React from "react";
import DividerComp from "../../components/Divider/divider";
import CarouselProducts from "../../components/Carousel/carouselProducts";
import { Content } from "antd/es/layout/layout";

const ProductSection = ({
  products,
  loadingProducts,
  title,
  route,
  width,
  isCategory = true,
}) => {
  return (
    <Content
      style={{
        padding: width > 550 && "0 80px",
      }}
    >
      <div style={{ margin: width > 550 ? "auto" : "0 15px", padding: "0px" }}>
        <DividerComp title={title} route={route} isCategory={isCategory} />
      </div>
      <div>
        <CarouselProducts
          row={1}
          products={products}
          loading={loadingProducts}
        />
      </div>
    </Content>
  );
};

export default ProductSection;
