import React, { useState, useEffect } from "react";
import { Skeleton, Col, Row } from "antd";
import { useNavigate } from "react-router-dom";
//import Carousel from "better-react-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import "./Landing.css";
import { getImgVidUrl } from "../../lib/lib";

function Banners({
  UpBanners,
  MiddleBanners,
  BottomBanners,
  Banners,
  bannersLoading,
  showArrows = true,
}) {
  const navigate = useNavigate();
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  //const CarouselBannerHeight = width > 767 ? "1000px" : "300px";
  const CarouselBannerHeightPxNumber = width > 767 ? 1000 : 300;
  const bannerHeight = width > 767 ? "490px" : "145px";
  const subBannerHeight = width > 767 ? "240px" : "70px";
  const subBannerHeightPxNumber = width > 767 ? 240 : 70;
  const marginBottomBanner = width > 767 ? "10px" : "5px";

  const renderResponsiveCarousel = (images = [], style) => {
    return (
      <div style={{ width: "100%", height: "100%" }}>
        {bannersLoading ? (
          <Skeleton.Image
            block
            active
            style={{ width: "100%", height: "100%" }}
          />
        ) : (
          <>
            <Carousel // react-responsive-carousel
              autoPlay
              infiniteLoop
              swipeScrollTolerance={10}
              showArrows={showArrows && images.length > 1}
              onChange={(e) => {
                //console.log("onclick ", e);
              }}
              onClickItem={(e) => {
                // console.log("onclick item ", e);
              }}
              onClickThumb={(e) => {
                // console.log("onClickThumb ", e);
              }}
            >
              {images?.map((item, i) => {
                return (
                  <div
                    key={i}
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "8px",
                      cursor: "pointer",
                      ...(style || {}),
                    }}
                    onClick={() => navigate(item?.link)}
                  >
                    <img
                      style={{
                        borderRadius: "8px",
                        cursor: "pointer",
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                      }}
                      className="w-full h-full"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = "/img_placeholder.png";
                      }}
                      src={getImgVidUrl(item.url)}
                      //alt={alt}
                    />
                  </div>
                );
              })}
            </Carousel>
          </>
        )}
      </div>
    );
  };
  const getBannerImages = (position) => {
    return (
      Banners[Banners.findIndex((item) => item.position === position)]
        ?.images || []
    );
  };
  const getMiddleSectionHeight = () => {
    if (
      (getBannerImages("middle-1")?.length === 0 &&
        getBannerImages("middle-2")?.length === 0 &&
        getBannerImages("middle-3")?.length === 0) ||
      (getBannerImages("middle-4")?.length === 0 &&
        getBannerImages("middle-5")?.length === 0 &&
        getBannerImages("middle-6")?.length === 0)
    ) {
      return `${CarouselBannerHeightPxNumber / 2}px`;
    } else if (
      getBannerImages("middle-6")?.length === 0 &&
      (getBannerImages("middle-4")?.length === 0 ||
        getBannerImages("middle-5")?.length === 0)
    ) {
      return `${CarouselBannerHeightPxNumber - subBannerHeightPxNumber}px`;
    }
  };
  return (
    <>
      {!bannersLoading && (
        <div>
          {UpBanners && getBannerImages("up")?.length > 0 && (
            <Row gutter={5} style={{ overflow: "hidden", borderRadius: "8px" }}>
              <Col
                style={{
                  height: width > 767 ? "500px" : "130px",
                  overflow: "hidden",
                  borderRadius: "8px",
                }}
                span={24}
              >
                {renderResponsiveCarousel(getBannerImages("up"), {
                  height: width > 767 ? "500px" : "130px",
                })}
              </Col>
            </Row>
          )}
          {MiddleBanners && (
            <Row
              gutter={width > 767 ? 10 : 6}
              style={{
                height: getMiddleSectionHeight(), //CarouselBannerHeight,
                //overflow: "hidden",
                borderRadius: "8px",
              }}
            >
              {getBannerImages("middle-1")?.length > 0 && ( // middle-1
                <Col
                  style={{
                    height: bannerHeight,
                    //overflow: "hidden",
                    borderRadius: "8px",
                  }}
                  span={12}
                >
                  {renderResponsiveCarousel(getBannerImages("middle-1"), {
                    height: bannerHeight,
                  })}
                </Col>
              )}
              {(getBannerImages("middle-2")?.length > 0 || //  middle-2 middle-3
                getBannerImages("middle-3")?.length > 0) && (
                <Col
                  style={{
                    height:
                      getBannerImages("middle-2")?.length === 0 ||
                      getBannerImages("middle-3")?.length === 0
                        ? `calc(${subBannerHeight} + 10px)`
                        : bannerHeight,
                    // overflow: "hidden",
                    borderRadius: "8px",
                  }}
                  span={12}
                >
                  <Row style={{ height: "100%", borderRadius: "8px" }}>
                    {getBannerImages("middle-2")?.length > 0 && (
                      <Col
                        span={24}
                        style={{
                          height: subBannerHeight,
                          marginBottom: marginBottomBanner,
                          borderRadius: "8px",
                        }}
                      >
                        {renderResponsiveCarousel(getBannerImages("middle-2"), {
                          height: subBannerHeight,
                        })}
                      </Col>
                    )}
                    {getBannerImages("middle-3")?.length > 0 && (
                      <Col
                        span={24}
                        style={{ height: subBannerHeight, borderRadius: "8px" }}
                      >
                        {renderResponsiveCarousel(getBannerImages("middle-3"), {
                          height: subBannerHeight,
                        })}
                      </Col>
                    )}
                  </Row>
                </Col>
              )}
              {(getBannerImages("middle-4")?.length > 0 || //  middle-4 middle-5
                getBannerImages("middle-5")?.length > 0) && (
                <Col
                  style={{
                    height:
                      getBannerImages("middle-4")?.length === 0 ||
                      getBannerImages("middle-5")?.length === 0
                        ? `calc(${subBannerHeight} + 10px)`
                        : bannerHeight,
                    //overflow: "hidden",
                    borderRadius: "8px",
                  }}
                  span={12}
                >
                  <Row style={{ height: "100%", borderRadius: "8px" }}>
                    {getBannerImages("middle-4")?.length > 0 && (
                      <Col
                        span={24}
                        style={{
                          height: subBannerHeight,
                          overflow: "hidden",
                          marginBottom: marginBottomBanner,
                          borderRadius: "8px",
                        }}
                      >
                        {renderResponsiveCarousel(getBannerImages("middle-4"), {
                          height: subBannerHeight,
                        })}
                      </Col>
                    )}
                    {getBannerImages("middle-5")?.length > 0 && (
                      <Col
                        span={24}
                        style={{
                          height: subBannerHeight,
                          //  overflow: "hidden"
                        }}
                      >
                        {renderResponsiveCarousel(getBannerImages("middle-5"), {
                          height: subBannerHeight,
                        })}
                      </Col>
                    )}
                  </Row>
                </Col>
              )}
              {getBannerImages("middle-6")?.length > 0 && ( // middle-6
                <Col
                  style={{
                    height: bannerHeight,
                    //overflow: "hidden",
                    borderRadius: "8px",
                  }}
                  span={12}
                >
                  {renderResponsiveCarousel(getBannerImages("middle-6"), {
                    height: bannerHeight,
                  })}
                </Col>
              )}
            </Row>
          )}
          {BottomBanners && getBannerImages("down")?.length > 0 && (
            <Row gutter={5} style={{ overflow: "hidden", borderRadius: "8px" }}>
              <Col
                style={{
                  height: width > 767 ? "500px" : "250px",
                  overflow: "hidden",
                  borderRadius: "8px",
                }}
                span={24}
              >
                {renderResponsiveCarousel(getBannerImages("down"), {
                  height: width > 767 ? "500px" : "250px",
                })}
              </Col>
            </Row>
          )}
        </div>
      )}
    </>
  );
}

export default Banners;
