import React, { useState, useEffect, useContext } from "react";
import { Button, Col, Row, message, Tooltip } from "antd";
import { getImgVidUrl, getPath, getStatusColor } from "../../lib/lib";
import { useNavigate } from "react-router-dom";
import { BiEditAlt } from "react-icons/bi";
import { PiTrashSimpleLight } from "react-icons/pi";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import { appContext } from "../../Context/appContext";
import {
  Url,
  deleteProductVendorApi,
  updateProductStatusApi,
} from "../../Apis";
import Confirm from "../../components/ConfirmDialog";

function MyProducts({ width, setTabInfo }) {
  const navigate = useNavigate();
  const { getVendorProducts, vendorProducts } = useContext(appContext);
  const [SelectedProduct, setSelectedProduct] = useState({});
  const [SelectedRow, setSelectedRow] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [OpenStatusModal, setOpenStatusModal] = useState(false);
  const [productId, setProductId] = useState("");
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [Refresh, setRefresh] = useState(false);
  const antIcon = (
    <Loading3QuartersOutlined
      style={{
        fontSize: 15,
        color: "#fff",
      }}
      spin
    />
  );
  useEffect(() => {
    getVendorProducts();
  }, [Refresh]);

  const onClickMoreInfo = (order) => {
    // setSelectedOrder(order);
    // navigate("/my-profile/order");
  };
  const handleOpen = (id) => {
    setOpenModal(true);
    setProductId(id);
  };
  const handleClose = () => {
    setOpenModal(false);
    setDeleteLoading(false);
  };

  const handleDelete = async () => {
    try {
      setDeleteLoading(true);
      const response = await deleteProductVendorApi(productId);
      if (response) {
        message.success(response?.data?.messageEn);
        getVendorProducts("all");
        setDeleteLoading(false);
        handleClose();
      } else {
        setDeleteLoading(false);
      }
    } catch (error) {
      setDeleteLoading(false);
      message.error(error?.response?.data?.message);
    }
  };
  const renderProductBigView = () => {
    return <></>;
  };
  const renderProductSmallView = () => {
    return <></>;
  };
  const onClickEditStatus = (order) => {
    setSelectedRow(order);
    setOpenStatusModal(true);
  };
  const onClickAddNewProduct = () => {
    navigate("/my-profile/add-product");
  };
  const onClickEditProduct = (order) => {
    navigate(`/my-profile/edit-product`, {
      state: { order },
    });
  };
  const handleUpdateStatus = async () => {
    let data = {
      id: SelectedRow?.product?._id,
      status:
        SelectedRow?.product?.status === "NOTAVAILABLE"
          ? "AVAILABLE"
          : "NOTAVAILABLE",
    };
    try {
      const response = await updateProductStatusApi(data);
      setRefresh(!Refresh);
      setOpenStatusModal(false);
      setSelectedRow({});
    } catch (error) {
      message.error(error.message);
    }
  };
  return (
    <>
      {width > 767 ? (
        <>
          <>
            {!SelectedProduct?._id ? (
              <Row cols={24} style={{ margin: "0px 30px" }}>
                <Col span={24}>
                  <Row
                    cols={24}
                    style={{
                      borderBottom: "1px solid #EC2025",
                      padding: "10px",
                    }}
                  >
                    {[
                      { label: "Item Name and ID", span: 8 },
                      { label: "Date", span: 4 },
                      { label: "Status", span: 4 },
                      { label: "Price", span: 4 },
                      { label: "", span: 4 },
                      // { label: "More Info", span: 4 },
                    ]?.map((val, i) => (
                      <Col key={i} span={val?.span}>
                        <label
                          style={{
                            color: "#ACAAB6",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          {val?.label}
                        </label>
                      </Col>
                    ))}
                  </Row>
                </Col>
                <Col span={24}>
                  {vendorProducts?.length === 0 && (
                    <h4 style={{ marginTop: "5px" }}>No products yet.</h4>
                  )}
                  {vendorProducts?.map((order, i) => {
                    let category = order?.product?.category?.englishName;
                    // let url = `${
                    //   category ? "/" + getPath(category) : ""
                    // }/${getPath(order?.product?.englishName)}`;
                    let url = `${category ? "/" + getPath(category) : ""}/${
                      order?.product?.URL
                    }`;
                    //console.log("order ", order);
                    return (
                      <Row
                        key={i}
                        cols={24}
                        style={{
                          borderBottom: "1px solid #EC2025",
                          padding: "10px",
                        }}
                      >
                        {[
                          {
                            span: 8,
                            customComp: (
                              <Row>
                                <Col span={6}>
                                  <img
                                    style={{
                                      height: "50px",
                                      width: "50px",
                                      borderRadius: "10px",
                                      cursor: "pointer",
                                    }}
                                    src={getImgVidUrl(
                                      order?.product?.featuredImage ||
                                        order?.product?.productImage[0]
                                    )}
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null; // prevents looping
                                      currentTarget.src =
                                        "/img_placeholder.png";
                                    }}
                                    onClick={() => navigate(url)}
                                  />
                                </Col>
                                <Col span={18}>
                                  <h3
                                    style={{ cursor: "pointer" }}
                                    onClick={() => navigate(url)}
                                  >
                                    {order?.product?.englishName}
                                  </h3>
                                  <small>
                                    <b>ID: </b>
                                    {order?.product?.orderNumber}
                                  </small>
                                </Col>
                              </Row>
                            ),
                            isCustom: true,
                          },
                          {
                            value: new Date(
                              order.createdAt
                            ).toLocaleDateString(),
                            span: 4,
                            isCustom: false,
                          },
                          {
                            value: order?.product?.status,
                            span: 4,
                            isCustom: false,
                            style: {
                              color: getStatusColor(order?.product?.status),
                            },
                          },
                          { value: order?.product?.price + " $", span: 4 },
                          {
                            span: 4,
                            customComp: (
                              <Row>
                                {/* <Col span={8}>
                                  <Tooltip
                                    placement="bottom"
                                    title={"Edit Status"}
                                  >
                                    <GrStatusGood
                                      style={{
                                        fontSize: 24,
                                        color: getStatusColor(
                                          order?.product?.status
                                        ),
                                        cursor: "pointer",
                                      }}
                                      onClick={() => onClickEditStatus(order)}
                                    />
                                  </Tooltip>
                                </Col> */}
                                <Col span={12}>
                                  <Tooltip
                                    placement="bottom"
                                    title={"Edit Product"}
                                  >
                                    <BiEditAlt
                                      style={{
                                        fontSize: 24,
                                        color: order.isDelete
                                          ? "#231F20"
                                          : "#ACAAB6",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => onClickEditProduct(order)}
                                    />
                                  </Tooltip>
                                </Col>
                                <Col span={12}>
                                  <PiTrashSimpleLight
                                    onClick={() => handleOpen(order?._id)}
                                    className="trushIcon"
                                    style={{
                                      color: !order?.isDeleted
                                        ? "#ec2025"
                                        : "#ACAAB6",
                                      cursor: "pointer",
                                    }}
                                  />
                                </Col>
                              </Row>
                            ),
                            isCustom: true,
                          },
                          //   {
                          //     value: "More Info",
                          //     span: 4,
                          //     isCustom: false,
                          //     style: { color: "#EC2025", cursor: "pointer" },
                          //     onClick: () => onClickMoreInfo(order),
                          //   },
                        ]?.map((item, j) => (
                          <Col key={j} span={item?.span}>
                            {item?.isCustom ? (
                              <>{item?.customComp}</>
                            ) : (
                              <label
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "bold",
                                  ...(item?.style || {}),
                                }}
                                onClick={
                                  item?.onClick ? item?.onClick : () => {}
                                }
                              >
                                {item?.value}
                              </label>
                            )}
                          </Col>
                        ))}
                      </Row>
                    );
                  })}
                </Col>
                <Col span={24} style={{ marginTop: "20px" }}>
                  <Button
                    className="infoBtn white-btn hover-bg-red btn-height"
                    style={{ width: "100%" }}
                    onClick={onClickAddNewProduct}
                  >
                    Add New Product
                  </Button>
                </Col>
              </Row>
            ) : (
              <>{renderProductBigView()}</>
            )}
          </>
        </>
      ) : (
        <>
          {!SelectedProduct?._id ? (
            <Row cols={24}>
              <Col span={24}>
                {vendorProducts?.length === 0 && (
                  <h4 style={{ marginTop: "5px" }}>No products yet.</h4>
                )}
                {vendorProducts?.map((order, i) => {
                  let category = order?.product?.category?.englishName;
                  // let url = `${
                  //   category ? "/" + getPath(category) : ""
                  // }/${getPath(order?.product?.englishName)}`;
                  let url = `${category ? "/" + getPath(category) : ""}/${
                    order?.product?.URL
                  }`;
                  return (
                    <Row
                      key={i}
                      cols={24}
                      style={{
                        borderBottom: "1px solid #ACAAB6",
                        padding: "10px",
                      }}
                    >
                      <Col span={6}>
                        <img
                          style={{
                            height: "50px",
                            width: "50px",
                            borderRadius: "10px",
                            cursor: "pointer",
                          }}
                          src={getImgVidUrl(
                            order?.product?.featuredImage ||
                              order?.product?.productImage[0]
                          )}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = "/img_placeholder.png";
                          }}
                          onClick={() => navigate(url)}
                        />
                      </Col>
                      <Col span={8}>
                        <h4
                          style={{ cursor: "pointer", color: "#ec2025" }}
                          onClick={() => navigate(url)}
                        >
                          {order?.product?.englishName}
                        </h4>
                        <small>
                          {new Date(order.createdAt).toLocaleDateString()}
                        </small>
                      </Col>
                      <Col span={6}>
                        <h4
                          style={{ cursor: "pointer" }}
                          onClick={() => navigate(url)}
                        >
                          {order?.product?.price + " $"}
                        </h4>
                        <small
                          style={{
                            color: getStatusColor(order?.product?.status),
                          }}
                          //onClick={() => onClickEditStatus(order)}
                        >
                          {order?.product?.status}
                        </small>
                      </Col>
                      <Col span={2}>
                        <BiEditAlt
                          style={{
                            fontSize: 24,
                            color: order.isDelete ? "#231F20" : "#ACAAB6",
                            cursor: "pointer",
                          }}
                          onClick={() => onClickEditProduct(order)}
                        />
                      </Col>
                      <Col span={2}>
                        <PiTrashSimpleLight
                          onClick={() => handleOpen(order?._id)}
                          className="trushIcon"
                          style={{
                            color: !order?.isDeleted ? "#ec2025" : "#ACAAB6",
                            cursor: "pointer",
                          }}
                        />
                      </Col>
                    </Row>
                  );
                })}
              </Col>
              <Col span={24} style={{ marginTop: "20px" }}>
                <Button
                  className="infoBtn white-btn hover-bg-red btn-height"
                  style={{ width: "100%" }}
                  onClick={onClickAddNewProduct}
                >
                  Add New Product
                </Button>
              </Col>
            </Row>
          ) : (
            <>{renderProductSmallView()}</>
          )}
        </>
      )}

      {/** Delete */}
      <Confirm
        open={openModal}
        title={"Confirm Delete Product"}
        message={"Are you sure to delete this product ?"}
        loading={deleteLoading}
        confirmLabel={"Delete"}
        confirmFunc={handleDelete}
        cancelFunc={handleClose}
      />
      {/** Update Status */}
      <Confirm
        open={OpenStatusModal}
        title={"Confirm Edit Status"}
        message={`Are you sure to update status to ${
          SelectedRow?.product?.status === "NOTAVAILABLE"
            ? "AVAILABLE"
            : "NOTAVAILABLE"
        } ?`}
        // loading={deleteLoading}
        confirmLabel={"Submit"}
        confirmFunc={handleUpdateStatus}
        cancelFunc={() => setOpenStatusModal(false)}
      />
    </>
  );
}

export default MyProducts;
