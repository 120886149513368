import React, { useContext, useEffect, useState } from "react";
import "./AddProduct.css";
import BreadcrumbComp from "../../components/Breadcrumb/Breadcrumb";
import NavBar from "../../components/navBar/navBar";
import { Footer } from "../../components/Footer/Footer";
import "pure-react-carousel/dist/react-carousel.es.css";
import { Content } from "antd/es/layout/layout";
import {
  Button,
  Input,
  Row,
  Col,
  Select,
  InputNumber,
  message,
  Upload,
  DatePicker,
} from "antd";
import { yupResolver } from "@hookform/resolvers/yup";
import { BsCamera, BsCameraVideo } from "react-icons/bs";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import "react-phone-input-2/lib/style.css";
import { useNavigate, useLocation } from "react-router-dom";
// upload image
import {
  LoadingOutlined,
  PlusOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import {
  Url,
  createVendorProductApi,
  editVendorProductApi,
  getVariantsByCategoryApi,
} from "../../Apis";
import { appContext } from "../../Context/appContext";
import { deleteItemFromList, getImgVidUrl } from "../../lib/lib";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};
const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
};
const beforeUploadVideo = (file) => {
  //console.log("file ", file);
  // const isVideo = file.type === "video/*";
  // if (!isVideo) {
  //   message.error("You can only upload video file!");
  // }
  // Note file.size in bytes unit
  //console.log("size in MB ", file.size / 8 / 1000000);
  const isLt5M = file.size / 8 / 1000000 <= 5;
  if (!isLt5M) {
    message.error("Max size 5MB");
  }
  return isLt5M;
};
function AddProduct() {
  const [width, setWidth] = useState(window.innerWidth);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [LoadingVideo, setLoadingVideo] = useState(false);
  const [VideoUrl, setVideoUrl] = useState("");
  // const [VideoFileList, setVideoFileList] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [imageList, setImageList] = useState([]);
  const [catLabel, setCatLabel] = useState("");
  const [brandLabel, setBrandLabel] = useState("");
  const [tagLabel, setTagLabel] = useState("");
  const [formValues, setFormValues] = useState({});
  const [options, setOptions] = useState([]);
  const [optionsBrand, setOptionsBrand] = useState([]);
  const [optionsTags, setOptionsTags] = useState([]);
  const [loadingAddproduct, setLoadingAddproduct] = useState(false);
  const [SelectedCategoriesVariants, setSelectedCategoriesVariants] = useState(
    {}
  );
  const [SelectedCategoriesDetails, setSelectedCategoriesDetails] = useState(
    {}
  );
  const [CategoryVariants, setCategoryVariants] = useState([]);
  const [Detail, setDetail] = useState({
    price: undefined,
    quantity: undefined,
  });
  const { tags, brands, getTags, getBrands, AllCategories } =
    useContext(appContext);

  const location = useLocation();
  const pathname = location.pathname;
  const editValuesFunc = () => {
    if (pathname == "/my-profile/edit-product") {
      let order = location?.state?.order;
      let product = order?.product;
      setFormValues({
        englishName: product?.englishName,
        arabicName: product?.arabicName,
        category: product?.category?._id,
        brand: product?.brand?._id,
        tag: product?.tag?._id,
        price: product?.price,
        quantity: product?.quantity,
        englishDescription: product?.englishDescription,
        arabicDescription: product?.arabicDescription,
        featuredImage: product?.featuredImage,
      });
      setValue1("englishName", product?.englishName);
      setValue1("arabicName", product?.arabicName);
      setValue1("englishDescription", product?.englishDescription);
      setValue1("arabicDescription", product?.arabicDescription);
      setValue1("email", order?.email);
      setValue1("phoneNumber", order?.phoneNumber);
      setValue1("brand", product?.brand?._id);
      setBrandLabel(product?.brand?.englishName);
      setValue1("category", product?.category?._id);
      setCatLabel(product?.category?.englishName);
      setValue1("tag", product?.tag?._id);
      setTagLabel(product?.tag?.englishName);
      setValue1("price", product?.price);
      setValue1("quantity", product?.quantity);
      setValue1("afterDiscountPrice", product?.afterDiscountPrice);
      setValue1(
        "discountEndDate",
        product?.discountEndDate ? dayjs(product?.discountEndDate) : undefined
      );
      setImageList([...(product?.productImage || [])]);
      setValue1("featuredImage", product?.featuredImage);
      setVideoUrl(product?.video);
      getVariantsByCategory(product?.category?._id, product?.details || []);
    }
  };
  useEffect(() => {
    editValuesFunc();
  }, []);
  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setImageUrl(url);
        // console.log(info.file.response.data?.map(data =>{ return  data.fileName}));
        setImageList((prev) => [
          ...prev,
          ...info.file.response.data?.map((data) => {
            return data.path;
          }),
        ]);
      });
    }
    if (info.file.status === "error") {
      setLoading(false);
    }
  };
  const handleChangeVideo = (info) => {
    console.log("info video ", info);
    if (info.file.status === "uploading") {
      setLoadingVideo(true);
    } else if (info.file.status === "done") {
      // Get this url from response in real world.
      setVideoUrl(info.file?.response?.data[0]?.path || "");
      // getBase64(info.file.originFileObj, (url) => {
      //   console.log(url);
      //   setVideoUrl(url);
      // });
      //setVideoFileList(info.fileList);
      setLoadingVideo(false);
    } else if (info.file.status === "error") {
      setLoadingVideo(false);
    } else {
      setLoadingVideo(false);
    }
  };
  useEffect(() => {
    getBrands();
    getTags();
  }, []);
  const uploadButton = (
    <div>
      {loading ? (
        <LoadingOutlined />
      ) : (
        <>
          <BsCamera style={{ fontSize: "36px", color: "#ACAAB6" }} />
          <div
            style={{
              marginTop: 8,
            }}
          >
            <p style={{ color: "#ACAAB6", fontStyle: "italic" }}>
              Click Here To Upload Images
            </p>
          </div>
        </>
      )}
    </div>
  );
  const uploadVideoButton = (
    <div>
      {LoadingVideo ? (
        <LoadingOutlined />
      ) : (
        <>
          <BsCameraVideo style={{ fontSize: "36px", color: "#ACAAB6" }} />
          <div style={{ marginTop: 8 }}>
            <p style={{ color: "#ACAAB6", fontStyle: "italic" }}>
              Click Here To Upload Video
            </p>
          </div>
        </>
      )}
    </div>
  );
  const { TextArea } = Input;
  const billingSchema = yup.object().shape({
    englishName: yup
      .string("Invalid english Name!")
      .required("english Name is required!"),
    arabicName: yup
      .string("Invalid arabic Name!")
      .required("arabic Name is required!"),
    englishDescription: yup
      .string("Invalid english Description!")
      .required("english Description is required!"),
    arabicDescription: yup
      .string("Invalid arabic Description!")
      .required("arabic Description is required!"),
    category: yup
      .string("Invalid category !")
      .required("category is required !"),
    brand: yup.string("Invalid brand !"),
    tag: yup.string("Invalid tag !"),
    featuredImage: yup
      .string("Invalid FeaturedImage !")
      .required("FeaturedImage is required !"),
    price: yup.number("Invalid price !").required("price is required !"),
    quantity: yup.number("Invalid quantity !"),
    afterDiscountPrice: yup.number("Invalid Discount Price !"),
    discountEndDate: yup.date("Invalid date !"),
  });
  const {
    register: register,
    handleSubmit: handleSubmit,
    formState: { errors: errors },
    control,
    setValue: setValue1,
    getValues,
    watch,
  } = useForm({
    resolver: yupResolver(billingSchema),
  });
  const watchFeaturedImage = watch(["featuredImage"]); // you can also target specific fields by their names
  // console.log("watchFeaturedImage ", watchFeaturedImage);
  const onsubmit = async (data) => {
    console.log(data);
    if (pathname == "/my-profile/edit-product") {
      try {
        setLoadingAddproduct(true);
        const {
          // phoneNumber,
          // email,
          englishName,
          arabicName,
          englishDescription,
          arabicDescription,
          price,
          quantity,
          category,
          featuredImage,
          brand,
          tag,
          afterDiscountPrice,
          discountEndDate,
        } = data;
        //const catId=category?.length==2?category[1]:category[0]
        let obj = {
          // phoneNumber,
          // email,
          product: {
            englishName,
            arabicName,
            englishDescription,
            arabicDescription,
            productImage: imageList,
            category,
            details: SelectedCategoriesDetails[category],
            featuredImage,
            brand,
            tag,
            price,
            quantity,
            afterDiscountPrice,
            discountEndDate,
            video: VideoUrl || "",
          },
        };

        const response = await editVendorProductApi(
          location?.state?.order?._id,
          obj
        );
        if (response) {
          message.success(response.data.messageEn);
          setLoadingAddproduct(false);
          setValue1("englishName", "");
          setValue1("arabicName", "");
          setValue1("englishDescription", "");
          setValue1("arabicDescription", "");
          // setValue1('email','')
          // setValue1('phoneNumber','')
          setValue1("brand", "");
          setValue1("category", "");
          setValue1("featuredImage", "");
          setValue1("tag", "");
          setValue1("price", "");
          setValue1("quantity", "");
          setValue1("afterDiscountPrice", "");
          setValue1("discountEndDate", "");
          setImageList([]);
          navigate("/my-profile/my-products");
        }
      } catch (error) {
        message.error(error.response.data.messageEn);
        setLoadingAddproduct(false);
      }
    } else {
      try {
        setLoadingAddproduct(true);
        const {
          phoneNumber,
          email,
          englishName,
          arabicName,
          englishDescription,
          arabicDescription,
          category,
          featuredImage,
          brand,
          tag,
          price,
          quantity,
          afterDiscountPrice,
          discountEndDate,
        } = data;
        let obj = {
          phoneNumber,
          email,
          product: {
            englishName,
            arabicName,
            englishDescription,
            arabicDescription,
            productImage: imageList,
            category,
            details: SelectedCategoriesDetails[category],
            featuredImage,
            brand,
            tag,
            price,
            quantity,
            afterDiscountPrice,
            discountEndDate,
            video: VideoUrl || undefined,
          },
        };

        const response = await createVendorProductApi(obj);
        if (response) {
          message.success(response.data.messageEn);
          setLoadingAddproduct(false);
          setValue1("englishName", "");
          setValue1("arabicName", "");
          setValue1("englishDescription", "");
          setValue1("arabicDescription", "");
          setValue1("email", "");
          setValue1("phoneNumber", "");
          setValue1("brand", "");
          setValue1("category", "");
          setValue1("tag", "");
          setValue1("featuredImage", "");
          setValue1("price", "");
          setValue1("quantity", "");
          setValue1("afterDiscountPrice", "");
          setValue1("discountEndDate", "");
          setImageList([]);
          setImageUrl("");
          setVideoUrl("");
          setSelectedCategoriesDetails({});
        }
      } catch (error) {
        message.error(error.response.data.messageEn);
        setLoadingAddproduct(false);
      }
    }
  };
  //#region useEffect
  useEffect(() => {
    setOptions(
      AllCategories?.filter((c) => !c?.isRealEstate && !c?.isAutomotive)?.map(
        (data) => {
          return {
            label: data?.englishName,
            value: data?._id,
            children: data?.subCategory?.map((sub) => {
              return { label: sub?.englishName, value: sub?._id, children: [] };
            }),
          };
        }
      )
    );
  }, [AllCategories]);
  useEffect(() => {
    setOptionsBrand(
      brands?.map((data) => {
        return { label: data?.englishName, value: data?._id };
      })
    );
  }, [brands]);
  useEffect(() => {
    setOptionsTags(
      tags?.map((data) => {
        return { label: data?.englishName, value: data?._id };
      })
    );
  }, [tags]);
  useEffect(() => {
    return () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };
  }, []);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      // console.log(value, name, type);
      setFormValues(value);
      if (SelectedCategoriesVariants[value?.category]?.length > 0) {
        setCategoryVariants(SelectedCategoriesVariants[value?.category]);
      } else {
        getVariantsByCategory(value?.category);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, SelectedCategoriesVariants]);
  //#endregion useEffect
  const getVariantsByCategory = async (id, details) => {
    try {
      const response = await getVariantsByCategoryApi(id);
      if (response.data) {
        // console.log(response.data?.data);
        setCategoryVariants(response.data?.data?.variants || []);
        setSelectedCategoriesVariants((prev) => ({
          ...prev,
          [id]: response.data?.data?.variants || [],
        }));
        setSelectedCategoriesDetails((prev) => ({
          ...prev,
          [id]: details ? details : [],
        }));
        resetDetail(response.data?.data?.variants);
      }
    } catch (error) {}
  };
  const resetDetail = (variants) => {
    variants?.forEach((ele) => {
      setDetail((prev) => ({
        ...prev,
        [ele?.key]: "",
        price: undefined,
        quantity: undefined,
      }));
    });
  };
  const renderInput = ({ key, value, onChange, hideLabel }) => {
    return (
      <Row gutter={[10, 10]} style={{ width: "100%", marginRight: "10px" }}>
        {!hideLabel && (
          <Col span={24}>
            {width > 767 ? <h3>{key}</h3> : <small>{key}</small>}
          </Col>
        )}
        <Col span={24}>
          <Input size="small" value={value} onChange={onChange} />
        </Col>
      </Row>
    );
  };
  const renderColor = ({ key, value, onChange, hideLabel }) => {
    return (
      <Row gutter={[10, 10]} style={{ width: "100%", marginRight: "10px" }}>
        {!hideLabel && (
          <Col span={24}>
            {width > 767 ? <h3>{key}</h3> : <small>{key}</small>}
          </Col>
        )}
        <Col span={24} style={{ display: "flex", alignItems: "center" }}>
          <input
            type="color"
            style={{ width: "25%" }}
            value={"#" + value}
            onChange={(e) => {
              setDetail((prev) => ({
                ...prev,
                [key]: e.target.value.slice(1),
              }));
            }}
          />
          <Input
            size="small"
            value={value}
            style={{ width: "70%" }}
            onChange={onChange}
            placeholder="Ex: b03030"
          />
        </Col>
      </Row>
    );
  };
  const renderNumber = ({ key, value, onChange, hideLabel }) => {
    return (
      <Row gutter={[10, 10]} style={{ width: "100%", marginRight: "10px" }}>
        {!hideLabel && (
          <Col span={24}>
            {width > 767 ? (
              <h3 style={{ textTransform: "capitalize" }}>{key}</h3>
            ) : (
              <small style={{ textTransform: "capitalize" }}>{key}</small>
            )}
          </Col>
        )}
        <Col span={24}>
          <InputNumber
            style={{ width: "100%" }}
            size="small"
            value={value}
            onChange={onChange}
            min={0}
          />
        </Col>
      </Row>
    );
  };
  let numberFieldWidth = width > 767 ? "80px" : "50px";
  const renderDetails = (
    <Row gutter={[10, 10]}>
      <Col span={24}>
        <div style={{ display: "flex", alignItems: "center" }}>
          {CategoryVariants?.map((ele, i) => {
            if (ele?.type === "color") {
              return renderColor({
                key: ele.key,
                value: Detail[ele.key],
                onChange: (e) => {
                  setDetail((prev) => ({
                    ...prev,
                    [ele.key]: e.target.value,
                  }));
                },
              });
            } else {
              return renderInput({
                key: ele.key,
                value: Detail[ele.key],
                onChange: (e) => {
                  setDetail((prev) => ({
                    ...prev,
                    [ele.key]: e.target.value,
                  }));
                },
              });
            }
          })}
          <div
            style={{
              minWidth: numberFieldWidth,
              maxWidth: numberFieldWidth,
              width: numberFieldWidth,
            }}
          >
            {renderNumber({
              key: "quantity",
              value: Detail?.quantity,
              onChange: (value) => {
                setDetail((prev) => ({
                  ...prev,
                  quantity: value,
                }));
              },
            })}
          </div>
          <div
            style={{
              minWidth: numberFieldWidth,
              maxWidth: numberFieldWidth,
              width: numberFieldWidth,
            }}
          >
            {renderNumber({
              key: "price",
              value: Detail?.price,
              onChange: (value) => {
                setDetail((prev) => ({
                  ...prev,
                  price: value,
                }));
              },
            })}
          </div>
          <div>
            <Row gutter={[10, 10]}>
              <Col span={24}>
                <Button
                  size="small"
                  icon={<PlusOutlined />}
                  disabled={!Detail?.price || !Detail?.quantity}
                  className="white-btn hover-bg-red"
                  onClick={() => {
                    setSelectedCategoriesDetails((prev) => ({
                      ...prev,
                      [formValues.category]: [
                        ...prev[formValues.category],
                        Detail,
                      ],
                    }));
                    resetDetail(CategoryVariants);
                  }}
                  style={{ marginTop: "30px" }}
                />
              </Col>
            </Row>
          </div>
        </div>
      </Col>
      {SelectedCategoriesDetails[formValues.category]?.length > 0 &&
        SelectedCategoriesDetails[formValues.category]?.map((val, i) => (
          <Col span={24} key={i}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                borderTop: "1px solid #ec2025",
                borderBottom: "1px solid #ec2025",
                padding: "5px 0px",
              }}
            >
              {CategoryVariants?.map((ele, j) => {
                if (ele?.type === "color") {
                  return renderColor({
                    key: ele.key,
                    value: val[ele.key],
                    onChange: (e) => {
                      let data = SelectedCategoriesDetails[formValues.category];
                      data[i][ele.key] = e.target.value;
                      setSelectedCategoriesDetails((prev) => ({
                        ...prev,
                        [formValues.category]: data,
                      }));
                    },
                    hideLabel: true,
                  });
                } else {
                  return renderInput({
                    key: ele.key,
                    value: val[ele.key],
                    onChange: (e) => {
                      let data = SelectedCategoriesDetails[formValues.category];
                      data[i][ele.key] = e.target.value;
                      setSelectedCategoriesDetails((prev) => ({
                        ...prev,
                        [formValues.category]: data,
                      }));
                    },
                    hideLabel: true,
                  });
                }
              })}
              <div
                style={{
                  minWidth: numberFieldWidth,
                  maxWidth: numberFieldWidth,
                  width: numberFieldWidth,
                }}
              >
                {renderNumber({
                  key: "quantity",
                  value: val?.quantity,
                  onChange: (value) => {
                    let data = SelectedCategoriesDetails[formValues.category];
                    data[i]["quantity"] = value;
                    setSelectedCategoriesDetails((prev) => ({
                      ...prev,
                      [formValues.category]: data,
                    }));
                  },
                  hideLabel: true,
                })}
              </div>
              <div
                style={{
                  minWidth: numberFieldWidth,
                  maxWidth: numberFieldWidth,
                  width: numberFieldWidth,
                }}
              >
                {renderNumber({
                  key: "price",
                  value: val?.price,
                  onChange: (value) => {
                    let data = SelectedCategoriesDetails[formValues.category];
                    data[i]["price"] = value;
                    setSelectedCategoriesDetails((prev) => ({
                      ...prev,
                      [formValues.category]: data,
                    }));
                  },
                  hideLabel: true,
                })}
              </div>
              <div>
                <Row gutter={[10, 10]}>
                  <Col span={24}>
                    <Button
                      size="small"
                      icon={<DeleteOutlined />}
                      className="white-btn hover-bg-red"
                      onClick={() => {
                        let data = deleteItemFromList(
                          i,
                          SelectedCategoriesDetails[formValues.category]
                        );
                        setSelectedCategoriesDetails((prev) => ({
                          ...prev,
                          [formValues.category]: data,
                        }));
                      }}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        ))}
    </Row>
  );
  // console.log("SelectedCategoriesVariants ", SelectedCategoriesVariants);
  //console.log("SelectedCategoriesDetails ", SelectedCategoriesDetails);
  // console.log("detail ", Detail);
  // console.log("values ", getValues());
  // const buddhistLocale = {
  //   ...en,
  //   lang: {
  //     ...en.lang,
  //     fieldDateFormat: "BBBB-MM-DD",
  //     fieldDateTimeFormat: "BBBB-MM-DD HH:mm:ss",
  //     yearFormat: "BBBB",
  //     cellYearFormat: "BBBB",
  //   },
  // };
  return (
    <>
      <NavBar />
      <Content
        style={{
          width: "90%",
          margin: "auto",
        }}
      >
        <div className="myProfile">
          <BreadcrumbComp />

          <p style={{ fontSize: "24px", fontWeight: "bold" }}>
            {pathname == "/my-profile/edit-product"
              ? "Edit Product:"
              : "Add Product:"}
          </p>
          <Row cols={24} gutter={[30, 30]}>
            <Col span={24}>
              <div
                className="paymentForm"
                style={{ width: "100%", marginBottom: "150px" }}
              >
                <form onSubmit={handleSubmit(onsubmit)}>
                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    gutter={[30, 30]}
                  >
                    <Col xs={24} md={12} lg={16}>
                      <Row gutter={[30, 30]}>
                        <Col xs={24} md={12} lg={12}>
                          <p className="becomeVendorLable">
                            Product English Name*
                          </p>
                          <Controller
                            name="englishName"
                            control={control}
                            render={({ field }) => (
                              <Input
                                {...field}
                                className="becomeVendorInput"
                                placeholder=""
                                size="small"
                              />
                            )}
                          />
                          <p style={{ color: "red" }}>
                            {errors.englishName?.message}
                          </p>
                        </Col>
                        <Col xs={24} md={12} lg={12}>
                          <p className="becomeVendorLable">
                            Product Arabic Name*
                          </p>
                          <Controller
                            name="arabicName"
                            control={control}
                            render={({ field }) => (
                              <Input
                                {...field}
                                className="becomeVendorInput"
                                placeholder=""
                                size="small"
                              />
                            )}
                          />
                          <p style={{ color: "red" }}>
                            {errors.arabicName?.message}
                          </p>
                        </Col>
                        <Col xs={24} md={12} lg={12}>
                          <p className="becomeVendorLable">Product Category*</p>
                          <Controller
                            name="category"
                            control={control}
                            render={({ field }) => (
                              <Select
                                allowClear
                                options={options}
                                value={catLabel}
                                style={{
                                  borderColor: "transparent",
                                  marginTop: 10,
                                  width: "100%",
                                }}
                                onChange={(e) => {
                                  console.log("e ", e);
                                  e !== null ? setCatLabel(e) : setCatLabel("");
                                }}
                                placeholder="Please select"
                                {...field}
                              />
                            )}
                          />
                          <p style={{ color: "red" }}>
                            {errors.category?.message}
                          </p>
                        </Col>
                        <Col xs={24} md={12} lg={12}>
                          <p className="becomeVendorLable">Product Brand</p>
                          <Controller
                            name="brand"
                            control={control}
                            render={({ field }) => (
                              <Select
                                allowClear
                                options={optionsBrand}
                                value={brandLabel}
                                style={{
                                  borderColor: "transparent",
                                  marginTop: 10,
                                  width: "100%",
                                }}
                                onChange={(e) => {
                                  e !== null
                                    ? setBrandLabel(e)
                                    : setBrandLabel("");
                                }}
                                {...field}
                              />
                            )}
                          />
                          <p style={{ color: "red" }}>
                            {errors.brand?.message}
                          </p>
                        </Col>
                        <Col span={24}>
                          {CategoryVariants?.length > 0 && (
                            <Row gutter={[10, 10]} style={{ width: "100%" }}>
                              <Col span={24}>
                                <p className="becomeVendorLable">
                                  Product Details
                                </p>
                              </Col>
                              <Col span={24}>{renderDetails}</Col>
                            </Row>
                          )}
                        </Col>
                        <Col xs={24} md={12} lg={12}>
                          <p className="becomeVendorLable">Product Tag</p>
                          <Controller
                            name="tag"
                            control={control}
                            render={({ field }) => (
                              <Select
                                allowClear
                                options={optionsTags}
                                // disabled={value == "Credit Card"}
                                value={tagLabel}
                                style={{
                                  borderColor: "transparent",
                                  marginTop: 10,
                                  width: "100%",
                                }}
                                onChange={(e) => {
                                  // console.log("catLAbel",e)
                                  e !== null ? setTagLabel(e) : setTagLabel("");
                                }}
                                {...field}
                              />
                            )}
                          />
                          <p style={{ color: "red" }}>{errors.tag?.message}</p>
                        </Col>
                        <Col xs={24} md={12} lg={12}>
                          <p className="becomeVendorLable">Price*</p>
                          <Controller
                            name="price"
                            control={control}
                            render={({ field }) => (
                              <Input
                                style={{
                                  borderColor: "transparent",
                                  marginTop: 10,
                                  width: "100%",
                                }}
                                {...field}
                              />
                            )}
                          />
                          <p style={{ color: "red" }}>
                            {errors.price?.message}
                          </p>
                        </Col>
                        <Col xs={24} md={12} lg={12}>
                          <p className="becomeVendorLable">Discount Price</p>
                          <Controller
                            name="afterDiscountPrice"
                            control={control}
                            render={({ field }) => (
                              <Input
                                style={{
                                  borderColor: "transparent",
                                  marginTop: 10,
                                  width: "100%",
                                }}
                                {...field}
                              />
                            )}
                          />
                          <p style={{ color: "red" }}>
                            {errors.afterDiscountPrice?.message}
                          </p>
                        </Col>
                        <Col xs={24} md={12} lg={12}>
                          <p className="becomeVendorLable">Discount EndDate</p>
                          <Controller
                            name="discountEndDate"
                            control={control}
                            render={({ field }) => (
                              <DatePicker
                                showTime
                                style={{
                                  marginTop: 10,
                                  width: "100%",
                                }}
                                //locale={buddhistLocale}
                                // onChange={onChange}
                                {...field}
                              />
                            )}
                          />
                          <p style={{ color: "red" }}>
                            {errors.discountEndDate?.message}
                          </p>
                        </Col>
                        <Col xs={24} md={12} lg={12}>
                          <p className="becomeVendorLable">Quantity</p>
                          <Controller
                            name="quantity"
                            control={control}
                            render={({ field }) => (
                              <Input
                                style={{
                                  borderColor: "transparent",
                                  marginTop: 10,
                                  width: "100%",
                                }}
                                {...field}
                              />
                            )}
                          />
                          <p style={{ color: "red" }}>
                            {errors.quantity?.message}
                          </p>
                        </Col>
                        <Col xs={24} md={12} lg={12} />
                        <Col xs={24} md={12} lg={12}>
                          <p
                            className="becomeVendorLable"
                            style={{ marginBottom: "0.5em" }}
                          >
                            Product English Description*
                          </p>
                          <Controller
                            name="englishDescription"
                            control={control}
                            render={({ field }) => (
                              <TextArea
                                {...field}
                                rows={4}
                                style={{ backgroundColor: "#EBEBEB" }}
                              />
                            )}
                          />
                          <p style={{ color: "red" }}>
                            {errors.englishDescription?.message}
                          </p>
                        </Col>
                        <Col xs={24} md={12} lg={12}>
                          <p
                            className="becomeVendorLable"
                            style={{ marginBottom: "0.5em" }}
                          >
                            Product Arabic Description*
                          </p>
                          <Controller
                            name="arabicDescription"
                            control={control}
                            render={({ field }) => (
                              <TextArea
                                {...field}
                                rows={4}
                                style={{ backgroundColor: "#EBEBEB" }}
                              />
                            )}
                          />
                          <p style={{ color: "red" }}>
                            {errors.arabicDescription?.message}
                          </p>
                        </Col>
                        {/* <Col span={24}>
                              {CategoryVariants?.length > 0 && (
                                <Row
                                  gutter={[10, 10]}
                                  style={{ width: "100%" }}
                                >
                                  <Col span={24}>
                                    <p className="becomeVendorLable">
                                      Product Details
                                    </p>
                                  </Col>
                                  <Col span={24}>{renderDetails}</Col>
                                </Row>
                              )}
                            </Col> */}
                      </Row>
                    </Col>
                    <Col xs={24} md={12} lg={8}>
                      <Row>
                        <Col span={24}>
                          <div
                            className="addProductImage"
                            style={{ width: "100%" }}
                          >
                            <p
                              style={{
                                fontSize: "16px",
                                fontWeight: "500",
                                marginBottom: "5px",
                              }}
                            >
                              Product Video{" "}
                              {VideoUrl && (
                                <DeleteOutlined
                                  style={{ color: "#ec2025", float: "right" }}
                                  onClick={() => {
                                    setVideoUrl("");
                                  }}
                                />
                              )}
                            </p>
                            <Upload
                              name="file"
                              //fileList={VideoFileList}
                              disabled={LoadingVideo}
                              listType="picture-card"
                              className="avatar-uploader"
                              showUploadList={false}
                              action={Url + "/uploadImage"}
                              onSubmit={(e) => console.log("onsubmit ", e)}
                              // previewFile={(file) => {
                              //   console.log("Your upload file:", file);
                              //   // Your process logic. Here we just mock to the same file
                              //   return fetch(Url + "/uploadImage", {
                              //     method: "POST",
                              //     body: file,
                              //   })
                              //     .then((res) => {
                              //       return res.json();
                              //     })
                              //     .then(({ thumbnail }) => {
                              //       console.log(" thumbnail ", thumbnail);
                              //       return thumbnail;
                              //     });
                              // }}
                              beforeUpload={beforeUploadVideo}
                              onChange={handleChangeVideo}
                              style={{
                                width: "100%",
                                backgroundColor: "#EBEBEB",
                                overflow: "hidden",
                              }}
                              accept="video/mp4,video/x-m4v,video/*"
                              //maxFileSize={5000000}
                            >
                              {VideoUrl ? (
                                <video
                                  controls
                                  src={getImgVidUrl(VideoUrl)}
                                  width={"100%"}
                                  height={"100%"}
                                  style={{
                                    border: "1px solid #fff",
                                    borderRadius: "7px",
                                  }}
                                />
                              ) : (
                                uploadVideoButton
                              )}
                            </Upload>
                          </div>
                        </Col>
                        <Col span={24}>
                          <div
                            className="addProductImage"
                            style={{ width: "100%" }}
                          >
                            <p
                              style={{
                                fontSize: "16px",
                                fontWeight: "500",
                                marginBottom: "5px",
                              }}
                            >
                              Product Images*{" "}
                              <small
                                className="note"
                                style={{ float: "initial" }}
                              >
                                (at Least 3 Images)
                              </small>
                            </p>
                            <Upload
                              name="file"
                              //  multiple
                              disabled={loading}
                              listType="picture-card"
                              className="avatar-uploader"
                              showUploadList={false}
                              accept="image/*"
                              action={Url + "/uploadImage"}
                              beforeUpload={beforeUpload}
                              onChange={handleChange}
                              style={{
                                width: "100%",
                                backgroundColor: "#EBEBEB",
                                overflow: "hidden",
                              }}
                            >
                              {imageUrl ? (
                                <img
                                  src={getImgVidUrl(imageUrl)}
                                  alt="avatar"
                                  style={{
                                    width: "100%",
                                  }}
                                />
                              ) : (
                                uploadButton
                              )}
                            </Upload>
                            <div className="carouselProducts addProductCaresoul">
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "500",
                                  marginBottom: "5px",
                                }}
                              >
                                Select Featured Image*
                              </p>
                              <div className="addProductImage">
                                {[1, 2, 3, 4].map((_, i) => (
                                  <>
                                    <div
                                      className="Image"
                                      style={{
                                        cursor: "pointer",
                                        border:
                                          watchFeaturedImage[0] === undefined
                                            ? "1px solid #eee"
                                            : watchFeaturedImage[0] ===
                                              imageList[i]
                                            ? "2px solid #ec2025"
                                            : "1px solid #eee",
                                        backgroundImage: `url(${getImgVidUrl(
                                          imageList[i]
                                        )})`,
                                      }}
                                      onClick={() =>
                                        setValue1("featuredImage", imageList[i])
                                      }
                                    >
                                      {imageList[i] ? (
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            padding: "5px",
                                            color: "#ec2025",
                                          }}
                                        >
                                          <DeleteOutlined
                                            onClick={() => {
                                              if (
                                                imageList[i] ===
                                                watchFeaturedImage[0]
                                              ) {
                                                setValue1("featuredImage", "");
                                              }
                                              let data = deleteItemFromList(
                                                i,
                                                imageList
                                              );
                                              setImageList(data);
                                            }}
                                          />
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </>
                                ))}
                              </div>
                              <p style={{ color: "red" }}>
                                {errors.featuredImage?.message}
                              </p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={24} md={12} lg={16}>
                      <Button
                        loading={loadingAddproduct}
                        htmlType="submit"
                        className="red-btn hover-bg-w"
                        style={{
                          padding: "20px auto",
                          height: "54px",
                          width: "100%",
                          backgroundColor: "white",
                          color: "black",
                        }}
                      >
                        {pathname == "/my-profile/edit-product"
                          ? "Edit Product"
                          : "Upload Product"}
                      </Button>
                    </Col>
                    <Col xs={24} md={12} lg={8}>
                      <Button
                        className="white-btn hover-bg-red"
                        onClick={() => window.history.back()}
                        style={{
                          padding: "20px auto",
                          height: "54px",
                          width: "100%",
                          backgroundColor: "white",
                          color: "black",
                        }}
                      >
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </form>
              </div>
            </Col>
          </Row>
        </div>
      </Content>
      <Footer />
    </>
  );
}

export default AddProduct;
