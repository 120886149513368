/* eslint-disable no-unused-vars */
import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import "./Checkout.css";
import BreadcrumbComp from "../../components/Breadcrumb/Breadcrumb";
import NavBar from "../../components/navBar/navBar";
import { Footer } from "../../components/Footer/Footer";
import { Content } from "antd/es/layout/layout";
import {
  Button,
  Input,
  Row,
  Col,
  Divider,
  Radio,
  Select,
  AutoComplete,
  message,
  Skeleton,
  Empty,
  Modal,
} from "antd";
import { yupResolver } from "@hookform/resolvers/yup";

import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { HiOutlineLocationMarker } from "react-icons/hi";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import {
  getAddressApi,
  addAddressApi,
  shippingPrice,
  mapKey,
  cardPaymentApi,
} from "../../Apis";
import { appContext } from "../../Context/appContext";
import MobileNavBar from "../../components/mobileNavBar/mobileNavBar";
import ButtonsFooter from "../../components/ButtonsFooter";
import {
  StandaloneSearchBox,
  LoadScript,
  GoogleMap,
  Marker,
} from "@react-google-maps/api";
import { setDefaults, geocode, RequestType } from "react-geocode";

function Checkout() {
  const mapRef = useRef(null);
  const inputRef = useRef();
  const [map, setMap] = useState(null);
  const [status, setStatus] = useState(null); // Optional for handling status messages
  const containerStyle = {
    width: "100%",
    height: "300px",
  };
  let center = {
    lat: 33.894,
    lng: 35.502,
  };
  const [width, setWidth] = useState(window.innerWidth);
  const [PaymentMethod, setPaymentMethod] = useState("CASH"); // CASH, CARD
  const [addresses, setAddresses] = useState([]);
  const [isAddAddressOpen, setIsAddAddressOpen] = useState(false);
  const [shippingAddress, setShippingAddress] = useState("");
  const [options, setOptions] = useState([]);
  const [addressLabel, setAddressLabel] = useState("");
  const [SelectedAddress, setSelectedAddress] = useState({
    formatted_address: "",
    full_address: "",
    lat: center?.lat,
    lng: center?.lng,
    city: "",
    district: "",
  });
  const navigate = useNavigate();
  const { addOrder, cartList, isCheckedOut, loadingCart, priceDetails } =
    useContext(appContext);

  const orderCashSchema = yup.object().shape({
    delivery: yup
      .string("Invalid delivery  !")
      .required("delivery is required!"),
  });
  // const paymentSchema = yup.object().shape({
  //   cardNumber: yup
  //     .number("Invalid card Number!")
  //     .required("card Number is required!"),
  //   cardName: yup
  //     .string("Invalid card Name!")
  //     .required("card Name is required!"),
  //   expirationDate: yup
  //     .string("Invalid expiration Date!")
  //     .required("expiration Date is required !"),
  //   securityCode: yup
  //     .string()
  //     .required("securityCode is required !")
  //     .min(10, "securityCode  should be at least 10 numbers!")
  //     .max(16, "securityCode  should be at most 16 numbers!"),
  // });

  // const {
  //   register: register1,
  //   handleSubmit: handleSubmit1,
  //   formState: { errors: errors1 },
  //   control: control1,
  // } = useForm({
  //   resolver: yupResolver(paymentSchema),
  // });
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    control: control2,
    setValue: setValue2,
  } = useForm({
    resolver: yupResolver(orderCashSchema),
  });
  function generateSixDigitNumber() {
    // Minimum and maximum values for a 6-digit number (100000 to 999999)
    const min = 100000;
    const max = 999999;
    // Generate random number within the range
    const randomInteger = Math.floor(Math.random() * (max - min + 1)) + min;
    return randomInteger;
  }

  const onsubmit2 = async (data) => {
    const randomSixDigitNumber = generateSixDigitNumber();
    console.log(randomSixDigitNumber);
    console.log("data ", data);
    if (PaymentMethod === "CARD") {
      try {
        let response = await cardPaymentApi(
          randomSixDigitNumber,
          priceDetails.totalCart
        );
        console.log("response ", response);
        if (response?.status === 200) {
          addOrder(data?.delivery, PaymentMethod, randomSixDigitNumber);
        }
      } catch (error) {}
    } else if (data.delivery) {
      addOrder(data?.delivery, PaymentMethod);
    } else {
      if (!errors2) {
        addOrder(shippingAddress, PaymentMethod);
      }
    }
  };

  const onChange = (e) => {
    // console.log("radio checked", e.target.value);
    setPaymentMethod(e.target.value);
  };
  const onChangeAddress = (e) => {
    setShippingAddress(e);
  };
  const selectAddress = (address) => {
    // console.log(address);
    let oneAddress = addresses.filter((data) => data?._id === address)[0];
    setAddressLabel(
      oneAddress?.city +
        " , " +
        oneAddress?.district +
        " , " +
        oneAddress?.fullAddress
    );
    setShippingAddress(address);
    // setValue2(
    //   "delivery",
    //   address
    // );
  };

  const onSearchAddress = (addressFilter) => {
    let addressArr = addresses?.filter((add) =>
      (add?.city + " , " + add?.district + " , " + add?.fullAddress).includes(
        addressFilter
      )
    );

    // console.log(addressArr);
    let allAddress = [];
    addressArr?.forEach((address) => {
      let oneAddress = {
        label:
          address?.city +
          " , " +
          address?.district +
          " , " +
          address?.fullAddress,
        value: address?._id,
      };
      allAddress.push(oneAddress);
      // setOptions(prev => [...prev,oneAddress])
    });
    setOptions(allAddress);
  };
  const getAddress = async () => {
    try {
      let response = await getAddressApi();
      // console.log("addresses", response);
      setAddresses([...response.data.data]);
      let allAddress = [];
      response?.data?.data?.forEach((address) => {
        let oneAddress = {
          label:
            address?.city +
            " , " +
            address?.district +
            " , " +
            address?.fullAddress,
          value: address?._id,
        };
        allAddress.push(oneAddress);
        // setOptions(prev => [...prev,oneAddress])
      });
      setOptions(allAddress);
    } catch (error) {
      // console.log(error.message);
    }
  };
  useEffect(() => {
    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };
    if (navigator.geolocation) {
      setStatus("Locating...");
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setStatus(null);
          console.log("position ", position);
          setSelectedAddress((prev) => ({
            ...prev,
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          }));
        },
        (error) => {
          setStatus("Unable to retrieve your location");
          console.error(error);
        },
        options
      );
    } else {
      setStatus("Geolocation is not supported by your browser");
    }
  }, []);
  useEffect(() => {
    setDefaults({
      key: mapKey, // Your API key here.
      language: "en", // Default language for responses.
      region: "es",
    });
  }, []);
  useEffect(() => {
    getAddress();
  }, []);
  useEffect(() => {
    return () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };
  }, []);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  //#region  add address functions
  const handleAddAddressCancel = () => {
    setIsAddAddressOpen(false);
    setSelectedAddress({
      formatted_address: "",
      lat: center?.lat,
      lng: center?.lng,
      city: "",
      district: "",
    });
    resetAdd({
      city: "",
      district: "",
      fullAddress: "",
    });
  };
  const addAddressSubmit = async () => {
    let data = {
      city: SelectedAddress?.city,
      district: SelectedAddress?.district,
      fullAddress: SelectedAddress?.full_address,
      lat: `${SelectedAddress?.lat}`,
      long: `${SelectedAddress?.lng}`,
    };
    try {
      let response = await addAddressApi(data);
      message.success(response.data.messageEn);
      handleAddAddressCancel();
      getAddress();
    } catch (error) {
      message.error(error?.response.data?.messageEn);
    }
  };
  const addressSchema = yup.object().shape({
    city: yup
      .string("invalid value")
      .min(3, "Must be string and at least 3 letters")
      .matches(/^[a-zA-Z\s]+$/, "Only letters are allowed")
      .required("Required"),
    district: yup
      .string()
      .min(3, "Must be string and at least 3 letters")
      .matches(/^[a-zA-Z\s]+$/, "Only letters are allowed")
      .required("district is required !"),
    fullAddress: yup
      .string()
      .min(3, "Must be string and at least 3 letters")
      .required("Full Address is required !"),
  });
  const {
    handleSubmit: handleSubmitAdd,
    formState: { errors: errorsAdd },

    control: controlAdd,
    reset: resetAdd,
    // eslint-disable-next-line no-unused-vars
    setValue: setAddressValue,
  } = useForm({
    resolver: yupResolver(addressSchema),
  });
  const orderDetails = [
    { name: "Sub Total Price:", value: `$${priceDetails.totalCart}` },
    { name: "Delivery:", value: `$${priceDetails.delivery}` },
    { name: "Discount:", value: `$${priceDetails.discount || 0}` },
  ];
  //#endregion add address functions
  //#region map
  const onLoad = useCallback(function callback(map) {
    setMap(map);
    mapRef.current = map;
  }, []);

  const handlePlaceChanged = () => {
    const Places = inputRef.current?.getPlaces() || [];
    console.log("Places", Places);
    // if (Places?.length > 0) {
    setSelectedAddress((prev) => ({
      ...prev,
      formatted_address: Places[0]?.formatted_address || "",
      lat: Places[0]?.geometry?.location?.lat() || center.lat,
      lng: Places[0]?.geometry?.location?.lng() || center.lng,
    }));
    map?.panTo({
      lat: Places[0]?.geometry?.location?.lat() || center.lat,
      lng: Places[0]?.geometry?.location?.lng() || center.lng,
    });

    //console.log(inputRef.current?.getPlaces());
  };
  function getLocationAddress({ updateAddress }) {
    geocode(RequestType.LATLNG, `${SelectedAddress.lat},${SelectedAddress.lng}`)
      .then(({ results }) => {
        console.log("results ", results);
        const address = results[0].formatted_address;
        console.log("address ", address);
        const { city, state, country } = results[0].address_components.reduce(
          (acc, component) => {
            if (component.types.includes("locality"))
              acc.city = component.long_name;
            else if (component.types.includes("administrative_area_level_1"))
              acc.state = component.long_name;
            else if (component.types.includes("country"))
              acc.country = component.long_name;
            return acc;
          },
          {}
        );
        setSelectedAddress((prev) => ({
          ...prev,
          formatted_address: updateAddress ? address : prev.formatted_address,
          city: city,
          // district: country,
        }));
      })
      .catch(console.error);
  }
  const renderMap = (
    <>
      <LoadScript googleMapsApiKey={mapKey} libraries={["places"]}>
        <StandaloneSearchBox
          onLoad={(ref) => (inputRef.current = ref)}
          onPlacesChanged={handlePlaceChanged}
        >
          <Row gutter={[10, 10]} style={{ marginBottom: "10px" }}>
            <Col span={width > 767 ? 6 : 12}>
              <Input
                id="city"
                contentEditable={false}
                aria-describedby="city-help"
                placeholder="City"
                name="city"
                className="profileInput"
                style={{ width: "100%" }}
                value={SelectedAddress?.city}
                onChange={(e) => {
                  setSelectedAddress((prev) => ({
                    ...prev,
                    city: e.target.value,
                    formatted_address: e.target.value,
                  }));
                  //getLocationAddress();
                }}
              />
            </Col>
            <Col span={width > 767 ? 6 : 12}>
              <Input
                id="district"
                contentEditable={false}
                aria-describedby="district-help"
                placeholder="District"
                name="district"
                className="profileInput"
                style={{ width: "100%" }}
                value={SelectedAddress?.district}
                onChange={(e) => {
                  setSelectedAddress((prev) => ({
                    ...prev,
                    district: e.target.value,
                  }));
                }}
              />
            </Col>
            <Col span={width > 767 ? 12 : 24}>
              <Input
                prefix={
                  <HiOutlineLocationMarker
                    style={{ fontSize: "25px", color: "#c9c9c9" }}
                  />
                }
                className="profileInput"
                placeholder="Full Address"
                size="small"
                style={{ width: "100%" }}
                value={SelectedAddress.full_address}
                onChange={(e) => {
                  setSelectedAddress((prev) => ({
                    ...prev,
                    full_address: e.target.value,
                  }));
                }}
              />
            </Col>
          </Row>
        </StandaloneSearchBox>
        <GoogleMap
          mapContainerStyle={containerStyle}
          onLoad={onLoad}
          zoom={10}
          center={{
            lat: parseFloat(SelectedAddress?.lat),
            lng: parseFloat(SelectedAddress?.lng),
          }}
          onClick={(e) => {
            setSelectedAddress((prev) => ({
              ...prev,
              lat: e.latLng.lat(),
              lng: e.latLng.lng(),
            }));
          }}
          //onUnmount={onUnmount}
        >
          <Marker
            draggable={true}
            onDrag={(e) => {
              setSelectedAddress((prev) => ({
                ...prev,
                lat: e.latLng.lat(),
                lng: e.latLng.lng(),
              }));
              getLocationAddress({ updateAddress: true });
            }}
            position={{
              lat: +(SelectedAddress?.lat || center?.lat),
              lng: +(SelectedAddress?.lng || center?.lng),
            }}
          />
          <></>
        </GoogleMap>
      </LoadScript>
    </>
  );
  //#endregion map
  console.log("addressLabel ", addressLabel);
  console.log("allAddress ", addresses);
  console.log("shippingAddress ", shippingAddress);
  return (
    <>
      {width > 767 ? (
        <>
          <NavBar />
          <Content
            style={{
              width: "80%",
              margin: "auto",
            }}
          >
            {isCheckedOut ? (
              <>
                <div className="myProfile">
                  <BreadcrumbComp />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      gap: 20,
                      alignItems: "center",
                      alignContent: "center",
                    }}
                  >
                    <img width={"30%"} src="/Approved.svg" alt="" />
                    <div className="shoppingBtn" style={{ width: "30%" }}>
                      <Button
                        className="vendorBtn"
                        style={{ width: "100%" }}
                        onClick={() => navigate("/")}
                      >
                        Continue Shopping
                      </Button>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="myProfile">
                  <BreadcrumbComp />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "2em",
                      flexWrap: "wrap",
                    }}
                  >
                    <div style={{ width: width > 1121 ? "100%" : "100%" }}>
                      <p style={{ fontSize: "24px", fontWeight: "bold" }}>
                        Order Summary:{" "}
                      </p>

                      <div className="orderSummary">
                        <div className="flex flex-col gap-2">
                          <Row justify={"end"} align={"middle"} gutter={10}>
                            <Col xs={9} className="orderSummaryTitle">
                              Products
                            </Col>
                            <Col xs={4} className="orderSummaryTitle">
                              quantity
                            </Col>
                            <Col xs={9} className="orderSummaryTitle">
                              Items Prices
                            </Col>
                          </Row>
                          <div>
                            {loadingCart
                              ? [1, 2].map((_) => (
                                  <Row
                                    style={{ marginBottom: 10 }}
                                    justify={"end"}
                                    align={"middle"}
                                    gutter={[10, 10]}
                                  >
                                    <Col xs={9}>
                                      <Skeleton.Button size={20} active block />
                                    </Col>
                                    <Col xs={4}>
                                      <Skeleton.Button size={20} active block />
                                    </Col>
                                    <Col xs={9}>
                                      <Skeleton.Button size={20} active block />
                                    </Col>
                                  </Row>
                                ))
                              : cartList?.map((data, i) => {
                                  let price = data?.price
                                    ? data?.price
                                    : data?.product?.price;
                                  return (
                                    <Row
                                      justify={"end"}
                                      align={"middle"}
                                      gutter={10}
                                      key={i}
                                    >
                                      <Col xs={9}>
                                        <span className="productTitle">
                                          {data?.product?.englishName}
                                        </span>
                                      </Col>
                                      <Col xs={4}>
                                        <span className="priceTitle">
                                          {data?.quantity}
                                        </span>
                                      </Col>
                                      <Col xs={9}>
                                        <span className="priceTitle">
                                          {price} $
                                        </span>
                                      </Col>
                                    </Row>
                                  );
                                })}
                          </div>
                          <div className="dividerPading">
                            <Divider className="categoriesDivider" />
                          </div>
                          <div className="">
                            <div
                              style={{
                                display: "flex",
                                gap: 15,
                                justifyContent: "center",
                                flexWrap: "wrap",
                                alignItems: "center",
                                flexDirection: "column",
                              }}
                            >
                              {orderDetails.map((order, i) => (
                                <div key={i} className="calculationsTitle">
                                  <div
                                    className="note"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {order.name}
                                  </div>
                                  <div className="title1">{order.value}</div>
                                </div>
                              ))}
                            </div>
                            <span
                              style={{
                                display: "flex",
                                gap: 15,
                                justifyContent: "center",
                                flexWrap: "wrap",
                                alignItems: "center",
                              }}
                            >
                              <span className="totalTitle">Grand Total:</span>
                              <span className="totalValue">
                                {priceDetails.salePrice}$
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ marginTop: "2em" }}>
                    <p style={{ fontSize: "24px", fontWeight: "bold" }}>
                      Payment Method:{" "}
                    </p>
                  </div>

                  <div className="radioPaymentMethod">
                    <Radio
                      style={{
                        color: PaymentMethod === "CARD" && "#ACAAB6",
                      }}
                      checked={PaymentMethod === "CASH"}
                      onChange={onChange}
                      value={"CASH"}
                    >
                      <span
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 1,
                        }}
                      >
                        <span
                          style={{
                            color: PaymentMethod === "CARD" && "#ACAAB6",
                          }}
                          className="payTitle"
                        >
                          Cash on Delivery
                        </span>
                        <span
                          style={{
                            color: PaymentMethod === "CARD" && "#ACAAB6",
                          }}
                          className="paySubTitle"
                        >
                          Pay with Cash upon Delivery.
                        </span>
                      </span>
                    </Radio>
                    <Radio
                      style={{
                        color: PaymentMethod === "CARD" && "#ACAAB6",
                      }}
                      checked={PaymentMethod === "CARD"}
                      onChange={onChange}
                      value={"CARD"}
                    >
                      <span
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 1,
                        }}
                      >
                        <span
                          style={{
                            color: PaymentMethod === "CASH" && "#ACAAB6",
                          }}
                          className="payTitle"
                        >
                          Credit Card
                        </span>
                        <span
                          style={{
                            color: PaymentMethod === "CASH" && "#ACAAB6",
                          }}
                          className="paySubTitle"
                        >
                          Pay with credit card.
                        </span>
                      </span>
                    </Radio>
                  </div>
                  <div className="paymentForm">
                    <p
                      style={{
                        color: " #EC2025",
                        fontSize: "16px",
                        margin: "10px 0px 20px",
                        textDecoration: "underline",
                        fontWeight: "500",
                        cursor: "pointer",
                        width: "fit-content",
                      }}
                      onClick={() => {
                        setIsAddAddressOpen(true);
                      }}
                    >
                      Add Address
                    </p>
                    <form
                      // onSubmit={
                      //   PaymentMethod === "CASH"
                      //     ? handleSubmit2(onsubmit2)
                      //     : handleSubmit1(onsubmit)
                      // }
                      onSubmit={handleSubmit2(onsubmit2)}
                    >
                      <Row
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        gutter={[24, 24]}
                        align={"middle"}
                      >
                        <Col xs={24} md={8} lg={8}>
                          <p
                            className="becomeVendorLable"
                            // style={{
                            //   color:
                            //     PaymentMethod === "CARD" && "#ACAAB6",
                            // }}
                          >
                            Shipping Address
                          </p>
                          <Controller
                            name="delivery"
                            control={control2}
                            render={({ field }) => (
                              <Select
                                allowClear
                                options={options}
                                //disabled={PaymentMethod === "CARD"}
                                value={addressLabel}
                                style={{
                                  borderColor: "transparent",
                                  marginTop: 10,
                                  width: "100%",
                                }}
                                onChange={(e) => {
                                  console.log("eee ", e);
                                  e !== null
                                    ? setAddressLabel(e)
                                    : setAddressLabel("");
                                  onChangeAddress(e);
                                }}
                                prefix={
                                  <HiOutlineLocationMarker
                                    style={{
                                      fontSize: "25px",
                                      color: "#c9c9c9",
                                    }}
                                  />
                                }
                                {...field}
                                // className="becomeVendorInput"
                                placeholder="Deliver to
                              123st, Location.
                              via
                              Same Day delivery"
                              />
                              //   }
                              // />
                            )}
                          />
                          <p style={{ color: "red" }}>
                            {errors2.delivery?.message}
                          </p>
                        </Col>
                        <Col xs={24} md={8} lg={8}>
                          <p className="becomeVendorLable">{""}</p>
                          <Button
                            htmlType="submit"
                            className="becomeVendorBtn red-btn hover-bg-w"
                          >
                            Check Out
                          </Button>
                        </Col>
                      </Row>
                    </form>
                  </div>
                </div>
              </>
            )}
          </Content>
          <Footer />
        </>
      ) : (
        <>
          <MobileNavBar />
          <Content
            style={{
              width: "80%",
              margin: "15px auto 0px",
            }}
          >
            <div className="radioPaymentMethod">
              <Radio
                style={{
                  color: PaymentMethod === "CARD" && "#ACAAB6",
                }}
                checked={PaymentMethod === "CASH"}
                onChange={onChange}
                value={"CASH"}
              >
                <span
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                  }}
                >
                  <span
                    style={{
                      color: PaymentMethod === "CARD" && "#ACAAB6",
                      fontSize: "14px",
                    }}
                    className="payTitle"
                  >
                    Cash on Delivery
                  </span>
                  <span
                    style={{
                      color: PaymentMethod === "CARD" && "#ACAAB6",
                    }}
                    className="paySubTitle"
                  >
                    Pay with Cash upon Delivery.
                  </span>
                </span>
              </Radio>
              <Radio
                style={{
                  color: PaymentMethod === "CARD" && "#ACAAB6",
                }}
                checked={PaymentMethod === "CARD"}
                onChange={onChange}
                value={"CARD"}
              >
                <span
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                  }}
                >
                  <span
                    style={{
                      color: PaymentMethod === "CASH" && "#ACAAB6",
                      fontSize: "14px",
                    }}
                    className="payTitle"
                  >
                    Credit Card
                  </span>
                  <span
                    style={{
                      color: PaymentMethod === "CASH" && "#ACAAB6",
                    }}
                    className="paySubTitle"
                  >
                    Pay with credit card.
                  </span>
                </span>
              </Radio>
            </div>
            <p
              style={{
                color: " #EC2025",
                fontSize: "16px",
                margin: "10px 0px 20px",
                textDecoration: "underline",
                fontWeight: "500",
                cursor: "pointer",
                width: "fit-content",
              }}
              onClick={() => {
                setIsAddAddressOpen(true);
              }}
            >
              Add Address
            </p>
            <form
              // onSubmit={
              //   PaymentMethod === "CASH"
              //     ? handleSubmit2(onsubmit2)
              //     : handleSubmit1(onsubmit)
              // }
              onSubmit={handleSubmit2(onsubmit2)}
            >
              <p style={{ fontSize: "16px", fontWeight: "700" }}>
                shipping Address
              </p>

              <Controller
                name="delivery"
                control={control2}
                render={({ field }) => (
                  <AutoComplete
                    onSearch={onSearchAddress}
                    notFoundContent={
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    }
                    // disabled={value == "CARD"}
                    style={{ width: "100%", marginTop: "1em" }}
                    // autoFocus={value == "CASH"}
                    options={options}
                    {...field}
                    value={addressLabel}
                    onSelect={selectAddress}
                    children={
                      <Input
                        allowClear
                        // disabled={value == "CARD"}
                        {...field}
                        value={addressLabel}
                        onChange={(e) => {
                          e.target.value !== null
                            ? setAddressLabel(e.target.value)
                            : setAddressLabel("");
                          e.target.value == null && setShippingAddress("");
                        }}
                        prefix={
                          <HiOutlineLocationMarker
                            style={{
                              fontSize: "25px",
                              color: "rgba(236, 32, 37, 1)",
                            }}
                          />
                        }
                        className="becomeVendorInput"
                        placeholder="Deliver to
                              123st, Location.
                              via
                              Same Day delivery"
                      />
                    }
                  />
                )}
              />
              <p style={{ color: "red" }}>{errors2.delivery?.message}</p>
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "700",
                  marginTop: "2em",
                }}
              >
                Payment Details
              </p>

              <Row style={{ marginTop: "1em" }}>
                <Col xs={16} style={{ fontStyle: "italic" }}>
                  Sub Total Price:
                </Col>
                <Col xs={8} style={{ textAlign: "right" }}>
                  {priceDetails.totalCart} $
                </Col>
              </Row>
              <Row>
                <Col xs={16} style={{ fontStyle: "italic" }}>
                  Delivery:
                </Col>
                <Col xs={8} style={{ textAlign: "right" }}>
                  {priceDetails?.delivery} $
                </Col>
              </Row>
              <Row>
                <Col xs={16} style={{ fontStyle: "italic" }}>
                  Discount:
                </Col>
                <Col xs={8} style={{ textAlign: "right" }}>
                  00.00 $
                </Col>
              </Row>
              <Row>
                <Col
                  xs={16}
                  style={{
                    fontSize: "16px",
                    fontWeight: "700",
                    color: "rgba(236, 32, 37, 1)",
                  }}
                >
                  Grand Total:
                </Col>
                <Col
                  xs={8}
                  style={{
                    textAlign: "right",
                    fontSize: "16px",
                    fontWeight: "700",
                    color: "rgba(236, 32, 37, 1)",
                  }}
                >
                  {priceDetails.totalCart} $
                </Col>
              </Row>
              <div
                className="shoppingBtn2"
                style={{
                  position: "fixed",
                  bottom: "110px",
                  width: "80%",
                  margin: "auto",
                  boxSizing: "border-box",
                }}
              >
                <Button
                  className="CheckOut"
                  style={{
                    width: "100%",
                    height: "unset",
                  }}
                  // onClick={onsubmit2}
                  htmlType="submit"
                >
                  Proceed to Check Out
                </Button>
              </div>
            </form>
          </Content>
          <Footer />
        </>
      )}
      {/*******add address Modal***** */}
      <Modal
        width={width > 767 ? "50vw" : "90vw"}
        bodyStyle={{
          padding: 0,
          border: "none",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        closable={false}
        open={isAddAddressOpen}
        onCancel={handleAddAddressCancel}
        footer={null}
      >
        <div
          style={{
            padding: "1em",
            backgroundColor: "rgba(236, 32, 37, 1)",
            borderRadius: "8px",
            color: "white",
            fontWeight: "bold",
            width: "100%",
            boxSizing: "border-box",
            fontSize: "18px",
          }}
        >
          Add New Address
        </div>
        <div
          className="paymentForm"
          style={{
            padding: "1em",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Row>
            <Col span={24}>{renderMap}</Col>
            <Col span={24}>
              <ButtonsFooter
                submitLabel="Add"
                onCancel={handleAddAddressCancel}
                onSubmit={addAddressSubmit}
              />
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
}

export default Checkout;
