/* eslint-disable react-hooks/exhaustive-deps */
import { message } from "antd";
import { createContext, useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import {
  categoryWithProductApi,
  categoriesApi,
  productsApi,
  getFiltersApi,
  getLandingApi,
  getProfileApi,
  getCartApi,
  salePriceApi,
  createOrderApi,
  getAllOrdersApi,
  getOrderByIdApi,
  deleteOrderApi,
  AllFavoriteProductsApi,
  getCategoryHomeApi,
  getTopRatedApi,
  getOneProductApi,
  addToCartApi,
  updateToCartApi,
  deleteFromCartApi,
  addFavoriteApi,
  deleteFavoriteApi,
  getBrandsApi,
  getTagsApi,
  getVendorProductApi,
  getOneProductApiByName,
  getOneProductApiByUrl,
  allProductsApi,
  allProductsCategoryApi,
  propertyListingApi,
  propertyListingFiltersApi,
  getAutomotiveApi,
  automotiveBrandsApi,
} from "../Apis";
import { useNavigate, useParams } from "react-router-dom";
import { getCategoryByCatName } from "../lib/lib";
import moment from "moment";

const appContext = createContext();

const AppContextProvider = ({ children }) => {
  //#region state
  const [fullName, setFullName] = useState("");
  const [imgUrl, setImgUrl] = useState("");
  const [categories, setCategories] = useState([]);
  const [landingProducts, setLandingProducts] = useState({});
  const [filters, setFilters] = useState({});
  const [topRatedProducts, setTopRatedProducts] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const { categoryName } = useParams();
  const [loadingTopRatedProducts, setLoadingTopRatedProducts] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [loadingCategoryProducts, setLoadingCategoryProducts] = useState(false);
  const [categoryProducts, setCategoryProducts] = useState([]);
  const [products, setProducts] = useState([]);
  const [loadingProfile, setLoadingProfile] = useState(false);
  const [loadingFilters, setLoadingFilters] = useState(false);
  const [loadingCart, setLoadingCart] = useState(false);
  const [loadingFavorit, setLoadingFavorit] = useState(false);
  const [loadingFavoritProduct, setLoadingFavoritProduct] = useState(false);
  const [favoritList, setFavoritList] = useState([]);
  const [favoritProductList, setFavoritProductList] = useState([]);
  const [cartList, setCartList] = useState([]);
  // const [totalCart, setTotalCart] = useState("0.00");
  const [shippingPrice, setShippingPrice] = useState(30);
  const [priceDetails, setPriceDetails] = useState({
    salePrice: 0,
    totalCart: 0,
    delivery: shippingPrice,
    discount: 0,
    vat: 0,
  });

  const [allOrders, setAllOrders] = useState([]);
  const [brands, setBrands] = useState([]);
  const [brandsLoading, setBrandsLoading] = useState(false);
  const [tagsLoading, setTagsLoading] = useState(false);
  const [tags, setTags] = useState([]);
  const [allOrdersLoading, setAllOrdersLoading] = useState(false);
  const [oneOrder, setOneOrder] = useState({});
  const [oneOrderLoading, setOneOrderLoading] = useState(false);
  const [oneProduct, setOneProduct] = useState({});
  const [oneProductLoading, setOneProductLoading] = useState(false);
  const token = localStorage.getItem("token");
  const [isCheckedOut, setIsCheckedOut] = useState(false);
  const user = localStorage.getItem("userData");
  const userDetails = JSON.parse(user);
  const [loadingQuantity, setLoadingQuantity] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [quantity, setQuantity] = useState(1);
  const [loading, setLoading] = useState(false);
  const [LoadingBtnInSearch, setLoadingBtnInSearch] = useState(false);
  const [loadingFavorite, setLoadingFavorite] = useState(false);
  const [itemId, setItemId] = useState("");
  const [vendorProducts, setVendorProducts] = useState([]);
  // new
  const [AllCategories, setAllCategories] = useState([]);
  const [AllCategoriesLoading, setAllCategoriesLoading] = useState(true);
  ///
  const [loadingVendorProducts, setLoadingVendorProducts] = useState(false);
  const [sort, setSort] = useState("");
  const promo = localStorage.getItem("promo");
  const [bestSellingData, setbestSellingData] = useState([]);
  const [topSellingData, settopSellingData] = useState([]);
  const [frequentlyData, setFrequentlyData] = useState([]);
  const [bestSellingLoading, setbestSellingLoading] = useState(false);
  const [topSellingLoading, settopSellingLoading] = useState(false);
  const [frequentlyLoading, setFrequentlyLoading] = useState(false);
  const [productColor, setProductColor] = useState("");
  const [productSize, setProductSize] = useState("");
  const [width, setWidth] = useState(window.innerWidth);
  const [open, setOpen] = useState(false);
  const [AllProducts, setAllProducts] = useState([]);
  const [IsProductDeleteSuccefully, setIsProductDeleteSuccefully] =
    useState(false);
  const [FilteredProducts, setFilteredProducts] = useState([]);
  const [LoadingFilteredProducts, setLoadingFilteredProducts] = useState(false);
  const [_FilterValues, set_FilterValues] = useState({
    min: undefined,
    max: undefined,
    tag: undefined,
    brand: undefined,
    brandId: undefined,
    tagId: undefined,
  });
  const [PropertyListing, setPropertyListing] = useState([]);
  const [AllPropertyListing, setAllPropertyListing] = useState([]);
  const [LoadingPropertyListing, setLoadingPropertyListing] = useState(false);
  const [PropertyListingFilter, setPropertyListingFilter] = useState({});
  const [LoadingPropertyListingFilter, setLoadingPropertyListingFilter] =
    useState(false);
  //#region automotive
  const [AllAutomotive, setAllAutomotive] = useState([]);
  const [AutomotiveWithFilter, setAutomotiveWithFilter] = useState([]);
  const [LoadingAutomotive, setLoadingAutomotive] = useState(false);
  const [AutomotiveFilter, setAutomotiveFilter] = useState({});
  const [LoadingAutomotiveFilter, setLoadingAutomotiveFilter] = useState(false);
  const [AutomotiveBrands, setAutomotiveBrands] = useState({});
  //#endregion automotive
  const [IsProfileDataUpdated, setIsProfileDataUpdated] = useState(false);
  //#endregion state
  const navigate = useNavigate();
  const root = document.documentElement;

  const catId = getCategoryByCatName(categoryName, AllCategories)?._id; //location.state?.id;
  if (
    window.location.pathname !== "/login" &&
    window.location.pathname !== "/signup" &&
    window.location.pathname !== "/reset-password" &&
    window.location.pathname !== "/become-a-vendor" &&
    width < 768
  ) {
    localStorage.setItem("prevRoute", window.location.pathname);
  }
  //#region useEffect
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  useEffect(() => {
    // console.log(catId);
    if (catId) {
      setCategoryId(catId);
    }
  }, [catId]);
  useEffect(() => {
    if (cartList?.length > 0) {
      setPriceDetails((prev) => ({
        ...prev,
        delivery: shippingPrice,
      }));
    } else {
      setPriceDetails((prev) => ({
        ...prev,
        delivery: 0,
      }));
    }
  }, [cartList]);
  useEffect(() => {
    setLoadingFavoritProduct(true);
    getFavoritProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);
  useEffect(() => {
    getAllCategories();
    getProfileData();
    getCartData();
    getAllOrders();
    getALLProducts();
    // realestate
    getAllPropertyListing();
    getAllPropertyListingWithoutFilters();
    getAllPropertyListingFilters();
    // end realestate
    // automotive
    getAllAutomotive();
    getAutomotiveWithFilter();
    getAutomotiveBrands();
    // getAutomotiveFilters();
    // end automotive
    return () => {
      setCategoryId(undefined);
    };
  }, []);
  //#endregion useEffect
  //#region functions
  //#region realestate
  const getAllPropertyListing = async (params) => {
    try {
      setLoadingPropertyListing(true);
      const response = await propertyListingApi(params);
      // console.log(response);
      setPropertyListing(response?.data?.data || []);
      setLoadingPropertyListing(false);
    } catch (error) {
      setLoadingPropertyListing(false);
      message.error(error.message);
    }
  };
  const getAllPropertyListingWithoutFilters = async () => {
    try {
      setLoadingPropertyListing(true);
      const response = await propertyListingApi();
      //console.log("response ", response?.data);
      setAllPropertyListing(response?.data?.data || []);
      setLoadingPropertyListing(false);
    } catch (error) {
      setLoadingPropertyListing(false);
      message.error(error.message);
    }
  };
  const getAllPropertyListingFilters = async () => {
    try {
      setLoadingPropertyListingFilter(true);
      const response = await propertyListingFiltersApi();
      // console.log(response);
      setPropertyListingFilter(response?.data?.data || {});
      setLoadingPropertyListingFilter(false);
    } catch (error) {
      setLoadingPropertyListingFilter(false);
      message.error(error.message);
    }
  };
  //#endregion  realestate
  //#region automotive functions
  const getAllAutomotive = async () => {
    try {
      setLoadingAutomotive(true);
      setLoadingAutomotiveFilter(true);
      const response = await getAutomotiveApi();
      setAllAutomotive(response?.data?.data || []);
      //setAllAutomotive(automotives);
      let carCategory = [];
      let location = [];
      let brand = [];
      let subBrand = [];
      let fuelType = [];
      let paymentOptions = [];
      let Year = [];
      let transmissionType = [];
      let color = [];
      let bodyType = [];
      let features = [];
      let condition = [];
      let featuredBusiness = [];
      let price = [];
      let kilometers = [];
      response?.data?.data?.forEach((ele) => {
        if (ele?.carCategory?._id)
          carCategory = [...carCategory, ele?.carCategory];
        if (ele?.location)
          location = [
            ...location,
            { _id: ele?.location, englishName: ele?.location },
          ];
        if (ele?.brand?._id)
          brand = [
            ...brand,
            { _id: ele?.brand?._id, englishName: ele?.brand?.brandEnglishName },
          ];
        if (ele?.subBrand)
          subBrand = [
            ...subBrand,
            { _id: ele?.subBrand, englishName: ele?.subBrand },
          ];
        if (ele?.fuelType?._id) fuelType = [...fuelType, ele?.fuelType];
        if (ele?.paymentOptions)
          paymentOptions = [
            ...paymentOptions,
            { _id: ele?.paymentOptions, englishName: ele?.paymentOptions },
          ];
        if (ele?.year) Year = [...Year, ele?.year]; // dropdown
        if (ele?.transmissionType?._id)
          transmissionType = [...transmissionType, ele?.transmissionType];
        if (ele?.color)
          color = [...color, { _id: ele?.color, englishName: ele?.color }];
        if (ele?.bodyType?._id) bodyType = [...bodyType, ele?.bodyType];
        if (ele?.features?.length > 0)
          features = [...features, ...(ele?.features || [])];
        if (ele?.condition) condition = [...condition, ele?.condition];
        if (ele?.price) price = [...price, ele?.price];
        if (ele?.featuredBusiness?._id)
          featuredBusiness = [...featuredBusiness, ele?.featuredBusiness];
        if (ele?.kilometers)
          kilometers = [
            ...kilometers,
            { _id: ele?.kilometers, englishName: ele?.kilometers },
          ];
      });
      setAutomotiveFilter({
        carCategory,
        location,
        brand,
        subBrand,
        fuelType,
        paymentOptions,
        Year,
        transmissionType,
        color,
        bodyType,
        features,
        condition: [...new Set(condition)],
        price,
        featuredBusiness,
        kilometers,
      });
      setLoadingAutomotive(false);
      setLoadingAutomotiveFilter(false);
    } catch (error) {
      setLoadingAutomotive(false);
      setLoadingAutomotiveFilter(false);
      message.error(error.message);
    }
  };
  const getAutomotiveWithFilter = async (params) => {
    try {
      setLoadingAutomotive(true);
      const response = await getAutomotiveApi(params);
      setAutomotiveWithFilter(response?.data?.data || []);
      setLoadingAutomotive(false);
    } catch (error) {
      setLoadingAutomotive(false);
      message.error(error.message);
    }
  };
  const getAutomotiveBrands = async () => {
    try {
      const response = await automotiveBrandsApi();
      let brands = {};
      response?.data?.data?.forEach((ele) => {
        brands[ele?._id] = ele?.subBrand?.map((ele) => ({
          _id: ele,
          englishName: ele,
        }));
      });
      setAutomotiveBrands(brands);
    } catch (error) {
      message.error(error.message);
    }
  };
  //#endregion  automotive functions

  //#region category
  const getAllCategories = async () => {
    try {
      setAllCategoriesLoading(true);
      const response = await categoryWithProductApi(); //categoriesApi();
      let allCats = response?.data?.messageAr?.categories?.map((cat) => {
        let products = cat?.products?.filter((p) => p?.category === cat?._id);
        return { ...cat, products };
      });
      setAllCategories(allCats);
      // setAllCategories(categoriesdummy)
      setAllCategoriesLoading(false);
    } catch (error) {
      setAllCategoriesLoading(false);
      message.error(error.message);
    }
  };
  //#endregion category
  const getFilteredProductsRelatedToCategory = async (catId, query) => {
    try {
      setLoadingFilteredProducts(true);
      const response = await allProductsCategoryApi(catId, query);
      let findCat = response.data?.data?.categories?.find(
        (ele) => ele?._id === catId
      );
      let products = [];
      if (findCat) {
        // setFilteredCategory(findCat);
        products = [...products, ...findCat?.products];
        if (findCat?.subCategory?.length > 0) {
          findCat?.subCategory?.forEach((ele) => {
            products = [...products, ...ele?.products];
          });
        }
        setFilteredProducts(products || []);
      }
      setLoadingFilteredProducts(false);
    } catch (error) {
      setLoadingFilteredProducts(false);
      message.error(error.message);
    }
  };
  const resetTotalPrice = () => {
    setCartList([]);
    getTotalPrice("", [], 0)
      .then((data) => {
        setPriceDetails({
          salePrice: 0,
          totalCart: 0,
          delivery: 0,
          discount: 0,
          vat: 0,
        });
      })
      .catch((error) => {})
      .finally(() => {});
  };
  const getCartData = async (shippingPrice = 0) => {
    try {
      setLoadingCart(true);
      const response = await getCartApi();
      // console.log(response.data.data);
      if (response.data) {
        setCartList(response.data.data?.products);
      } else {
        resetTotalPrice();
      }
      let total = response.data.data?.total;
      if (total !== undefined) {
        const promo = localStorage.getItem("promo");
        const products = response.data.data?.products || [];

        const cart = products.map((item) => ({
          product: item._id,
          price: item.price,
          quantity: item.quantity,
          note: item.note,
        }));
        if (cart?.length > 0) {
          getTotalPrice(promo, cart, shippingPrice)
            .then((data) => {
              //console.log("saleprice ", data.data.data);
              setPriceDetails((prev) => ({
                ...prev,
                salePrice: data.data.data.salePrice,
                discount: data.data.data.discount,
                vat: data.data.data.vat,
              }));
            })
            .catch((error) => {
              //console.log("error?.message ", error?.message);
              message.error(error?.message?.messageEn);
              localStorage.removeItem("promo");
              setPriceDetails((prev) => ({
                ...prev,
                salePrice: prev.totalCart,
                totalCart: prev.totalCart,
              }));
            })
            .finally(() => {});
          setPriceDetails((prev) => ({
            ...prev,
            totalCart: total,
          }));
        } else {
          resetTotalPrice();
        }
      } else {
        resetTotalPrice();
      }
      setLoadingCart(false);
    } catch (error) {
      // console.log(error.message);
      setLoadingCart(false);
    }
  };
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
    //  resetData();
  };
  const getBestSelling = async () => {
    try {
      setbestSellingLoading(true);
      if (userDetails?._id) {
        const response = await getLandingApi(
          "",
          userDetails?._id,
          "Best Sellers in beauty Care"
        );
        setbestSellingData(response?.data?.data);
        setbestSellingLoading(false);
      } else {
        const response = await getLandingApi(
          "",
          "",
          "Best Sellers in beauty Care"
        );
        setbestSellingData(response?.data?.data);
        setbestSellingLoading(false);
      }
    } catch (error) {
      // setbestSellingLoading(false);
    }
  };
  const getFrequently = async () => {
    try {
      setFrequentlyLoading(true);
      if (userDetails?._id) {
        const response = await getLandingApi(
          "",
          userDetails?._id,
          "Frequently bought by customers"
        );
        setFrequentlyData(response?.data?.data);
        setFrequentlyLoading(false);
      } else {
        const response = await getLandingApi(
          "",
          "",
          "Frequently bought by customers"
        );
        setFrequentlyData(response?.data?.data);
        setFrequentlyLoading(false);
      }
    } catch (error) {
      setFrequentlyLoading(false);
    }
  };
  const getTopSelling = async () => {
    try {
      settopSellingLoading(true);
      if (userDetails?._id) {
        const response = await getLandingApi(
          "",
          userDetails?._id,
          "Top Selling Smart Phones and Accessories"
        );
        settopSellingData(response?.data?.data);
        settopSellingLoading(false);
      } else {
        const response = await getLandingApi(
          "",
          "",
          "Top Selling Smart Phones and Accessories"
        );
        settopSellingData(response?.data?.data);
        settopSellingLoading(false);
      }
    } catch (error) {
      // setbestSellingLoading(false);
    }
  };
  const getHomeCategoryProducts = async (id, filter) => {
    try {
      setLoadingCategoryProducts(true);
      if (id && !userDetails?._id) {
        const response = await getCategoryHomeApi(id, "", filter);
        setCategoryProducts(response?.data?.data);
        setLoadingCategoryProducts(false);
      } else if (userDetails?._id && id) {
        const response = await getCategoryHomeApi(id, userDetails?._id, filter);
        setCategoryProducts(response?.data?.data);
        setLoadingCategoryProducts(false);
      } else if (userDetails?._id) {
        const response = await getCategoryHomeApi("", userDetails?._id, filter);

        setCategoryProducts(response?.data?.data);
        setLoadingCategoryProducts(false);
      } else {
        const response = await getCategoryHomeApi("", "", filter);

        setCategoryProducts(response?.data?.data);
        setLoadingCategoryProducts(false);
      }
    } catch (error) {
      // message.error(error.message);
    }
  };
  const getProfileData = async () => {
    try {
      setLoadingProfile(true);
      const response = await getProfileApi();
      // console.log(response);
      if (response.data.data) {
        if (response.data.data.fullName) {
          setFullName(response.data.data.fullName);
        }
        // setEmail(response.data.data.email)
        if (response.data.data.profileImg) {
          setImgUrl(response.data.data.profileImg);
        }
        // console.log(response.data.data.profileImg);
        setLoadingProfile(false);
      }
    } catch (error) {
      setLoadingProfile(false);
      if (error.response) {
        // message.error(error.response.data.messageEn);
      } else {
        // message.error(error.message);
      }
    }
  };
  const getTopRatedProducts = async (Id) => {
    //console.log("id ", Id);
    try {
      setLoadingTopRatedProducts(true);
      if (Id !== undefined && userDetails?._id) {
        const response = await getTopRatedApi(Id, userDetails?._id);
        // console.log(response.data);
        let top = response.data?.data;
        // console.log(top);
        setTopRatedProducts([...top]);
        setLoadingTopRatedProducts(false);
      } else {
        if (Id !== undefined) {
          // setLoadingTopRatedProducts(true);
          const response = await getTopRatedApi(Id, "");
          // console.log(response.data);
          let top = response.data?.data;
          // console.log(top);
          setTopRatedProducts([...top]);
          setLoadingTopRatedProducts(false);
        }
      }
    } catch (error) {
      setLoadingTopRatedProducts(false);
      // console.log(error.message);
    }
  };
  const increaseQuantity = async (details, productId, callback) => {
    let prodPrice = AllProducts?.find((ele) => ele?._id === productId)?.price;

    try {
      if (productId) {
        setItemId(productId);
        let quantity =
          (await cartList?.filter((data) => data?.product?._id === productId)[0]
            ?.quantity) + 1;
        setLoadingQuantity(true);
        let _details = { ...details };
        if ("quantity" in _details) delete _details["quantity"];
        let obj = {
          note: "",
          details: {
            ...(_details || {}),
          },
          price: _details?.price ? _details?.price : prodPrice,
          quantity,
          product: productId,
        };
        const response = await addToCartApi({ product: obj });
        getCartData();
        message.success(response.data.messageEn);
        if (typeof callback === "function") callback();
        getCartData().then(() => {
          setLoadingQuantity(false);
        });
      }
    } catch (error) {
      message.error(error.response.data.messageEn);
      setLoadingQuantity(false);
    }
  };
  const decreaseQuantity = async (details, productId, orderId, callback) => {
    let prodPrice = AllProducts?.find((ele) => ele?._id === productId)?.price;
    try {
      if (productId) {
        setItemId(productId);

        let quantity =
          (await cartList?.filter((data) => data?.product?._id === productId)[0]
            ?.quantity) - 1;
        setLoadingQuantity(true);
        let _details = { ...details };
        if ("quantity" in _details) delete _details["quantity"];
        if (quantity > 1) {
          let obj = {
            note: "",
            details: {
              ...(_details || {}),
            },
            price: _details?.price ? _details?.price : prodPrice,
            quantity,
            product: productId,
          };
          const response = await addToCartApi({ product: obj });
          getCartData();
          message.success(response.data.messageEn);
          if (typeof callback === "function") callback();
          setLoadingQuantity(false);
        } else {
          const response = await deleteFromCartApi(orderId);
          message.success(response.data?.messageEn);
          if (typeof callback === "function") callback();
          setLoadingQuantity(false);
        }
        getCartData().then(() => {
          setLoadingQuantity(false);
        });
      }
    } catch (error) {
      message.error(error.response.data.message);
      setLoadingQuantity(false);
    }
  };
  // console.log("AllProducts ", AllProducts);
  const addToCart = async (details, productId, shippingPrice) => {
    let product = AllProducts?.find((ele) => ele?._id === productId);
    let isExpire = moment(Date.now()).isAfter(
      product?.discountEndDate || "1970-01-01"
    );
    let prodPrice = isExpire ? product?.price : product?.afterDiscountPrice;

    if (!token) {
      message.warning("Please Login first");
      navigate("/login");
      return;
    }
    try {
      setItemId(productId);
      setLoading(true);
      let _details = { ...details };
      if ("quantity" in _details) delete _details["quantity"];
      if ("plusQuantity" in _details) delete _details["plusQuantity"];
      let obj = {
        details: {
          ...(_details || {}),
        },
        price: _details?.price ? _details?.price : prodPrice,
        product: productId,
        quantity: 1 + (details?.plusQuantity || 0),
        note: "",
      };

      const response = await addToCartApi({ product: obj });
      getCartData(shippingPrice).then(() => {
        setLoading(false);
        setLoadingBtnInSearch(false);
        message.success(response.data.messageEn);
      });
    } catch (error) {
      message.error(error.response.data.message);
      setLoading(false);
      setLoadingBtnInSearch(false);
    }
  };
  const handleFavoret = async (id, favorite) => {
    if (!token) {
      message.warning("Please Login first");
      navigate("/login");
      return;
    }
    setItemId(id);
    // console.log(favorite, favoritList.includes(id));
    try {
      setLoadingFavorite(true);
      if (!favoritList.includes(id)) {
        let response = await addFavoriteApi(id);
        message.success(response.data.messageEn);
        if (response) {
          setFavoritList((prev) => [...prev, id]);
          setLoadingFavorite(false);
          getFavoritProducts();
        }
      } else {
        let response = await deleteFavoriteApi(id);
        if (response) {
          setFavoritList((prev) => [...prev?.filter((data) => data !== id)]);
          setLoadingFavorite(false);
          getFavoritProducts();
        }
      }
    } catch (error) {
      message.error(error.response.data.message);
      setLoadingFavorite(false);
    }
  };
  const getBrands = async () => {
    try {
      setBrandsLoading(true);
      const response = await getBrandsApi();
      if (response) {
        setBrands(response.data?.data);
        setBrandsLoading(false);
      }
    } catch (error) {
      setBrandsLoading(false);

      // console.log(error.message);
    }
  };
  const getTags = async () => {
    try {
      setTagsLoading(true);
      const response = await getTagsApi();
      if (response) {
        setTags(response.data?.data);
        setTagsLoading(false);
      }
    } catch (error) {
      setTagsLoading(false);

      // console.log(error.message);
    }
  };
  const getALLProducts = async () => {
    try {
      setTagsLoading(true);
      const response = await allProductsApi();
      if (response) {
        setAllProducts(response.data?.data);
      }
    } catch (error) {
      // console.log(error.message);
    }
  };
  const getFilters = async (id) => {
    setFilters({});
    setLoadingFilters(true);
    try {
      const response = await getFiltersApi(id);
      if (response?.data?.data) setFilters(response.data.data);
      else setFilters({});
      setLoadingFilters(false);
    } catch (error) {
      setFilters({});
      setLoadingFilters(false);
    }
  };
  const getAllOrders = async () => {
    try {
      setAllOrdersLoading(true);
      const response = await getAllOrdersApi();
      // console.log(response);
      setAllOrders(response?.data?.data);
      setAllOrdersLoading(false);
    } catch (error) {
      // console.log(error.message);
      setAllOrdersLoading(false);
    }
  };
  const getOneOrder = async (id) => {
    try {
      setOneOrderLoading(true);
      const response = await getOrderByIdApi(id);
      // console.log(response);
      setOneOrder(response.data.data);
      setOneOrderLoading(false);
    } catch (error) {
      // console.log(error.message);
      setOneOrderLoading(false);
    }
  };
  const deleteOrder = async (id) => {
    try {
      const response = await deleteOrderApi(id);
      // console.log(response);
      message.success(response.data?.messageEn);
    } catch (error) {
      // console.log(error.message);
      message.error(error.response.data?.messageEn);
    }
  };
  const getTotalPrice = (
    inPromo,
    cart,
    shippingPrice = priceDetails.delivery
  ) => {
    // localStorage.setItem("promo", inPromo);
    return salePriceApi({
      shippingPrice: shippingPrice,
      orderDetails: cart,
      promo: inPromo || "",
    });
  };
  const getProductsOfCategory = async (catId) => {
    try {
      setLandingProducts(true);
      if (userDetails?._id) {
        const response = await productsApi(catId, userDetails?._id);
        // console.log(response.data.data);
        setProducts(response.data.data);
        setLandingProducts(false);
      } else {
        const response = await productsApi(catId, "");
        // console.log(response.data.data);
        setProducts(response.data.data);
        setLandingProducts(false);
      }
    } catch (error) {
      setLandingProducts(false);
    }
  };
  const getFavoritProducts = async () => {
    try {
      if (token) {
        setLoadingFavoritProduct(true);
        const response = await AllFavoriteProductsApi();
        // console.log(response.data);
        setFavoritProductList(response.data?.data);
        setFavoritList(response.data?.data);
        setLoadingFavoritProduct(false);
      }
    } catch (error) {
      setLoadingFavoritProduct(false);
      // message.error(error.response.data.messageEn)
    }
  };
  //console.log("priceDetails.totalCart ", priceDetails.totalCart);
  const addOrder = async (addressId, paymentMethod, referenceNumber) => {
    console.log("add order");
    try {
      console.log("cartList", cartList);
      let obj = {
        currency: "USD",
        paymentMethod: paymentMethod || "CASH", // CASH, CARD
        paymentToken: "",
        referenceNumber: referenceNumber || undefined,
        orderType: "DELIVERY",
        chosenDate: new Date().toLocaleDateString("en-ca"),
        chosenTime: new Date().toTimeString().split(" ")[0],
        shippingPrice: `${shippingPrice}`,
        shippingPriceId: addressId,
        status: "PROCESSING",
        notes: "",
        shippingAddress: addressId,
        billingAddress: null,
        promo: priceDetails.discount ? `${priceDetails.discount}` : undefined, //promo, // to do
        // orderDetails: cartList
        salePrice: priceDetails.totalCart,
        orderDetails: cartList?.map((pro) => ({
          ...(pro?.details || {}),
          product: pro?.product?._id,
          quantity: pro?.quantity,
          price: pro?.price,
          note: "",
        })),
      };
      const response = await createOrderApi(obj);
      // console.log(response);
      message.success(response.data?.messageEn);
      getCartData();
      setIsCheckedOut(true);
    } catch (error) {
      message.error(error?.response.data?.messageEn);
      setIsCheckedOut(false);
    }
  };
  const getProduct = async (id) => {
    try {
      setOneProduct({});
      setOneProductLoading(true);
      const response = await getOneProductApi(id);
      // console.log(response);
      setOneProduct(response?.data?.data);
      setOneProductLoading(false);
    } catch (error) {
      // message.error(error.message);
      setOneProductLoading(false);
      setOneProductLoading(false);
    }
  };
  const getProductByName = async (name) => {
    try {
      setOneProductLoading(true);
      const response = await getOneProductApiByName(name);
      // console.log(response);
      setOneProduct(response?.data?.data);
      setOneProductLoading(false);
    } catch (error) {
      // message.error(error.message);
      setOneProductLoading(false);
    }
  };
  const getProductByUrl = async (url) => {
    try {
      setOneProductLoading(true);
      const response = await getOneProductApiByUrl(url);
      // console.log(response);
      setOneProduct(response?.data?.data);
      setOneProductLoading(false);
    } catch (error) {
      // message.error(error.message);
      setOneProductLoading(false);
    }
  };
  const getVendorProducts = async (type) => {
    try {
      setLoadingVendorProducts(true);
      const response = await getVendorProductApi(type);
      if (response.data) {
        setVendorProducts(response.data?.data);
        setLoadingVendorProducts(false);
      }
    } catch (error) {
      // console.log(error.message);
      setLoadingVendorProducts(false);
    }
  };
  const getCartValue = () => {
    const [beforePoint, afterPoint] = (
      `${priceDetails?.salePrice?.toString()}` || ""
    ).split(".");

    if (!afterPoint) {
      return beforePoint;
    } else {
      return `${beforePoint}.${afterPoint[0]}`;
    }
  };
  //#endregion functions
  const contextValue = {
    // automotive
    AllAutomotive,
    getAutomotiveWithFilter,
    AutomotiveWithFilter,
    LoadingAutomotive,
    AutomotiveFilter,
    LoadingAutomotiveFilter,
    AutomotiveBrands,
    // end automotive
    // realestate
    getAllPropertyListing,
    LoadingPropertyListing,
    PropertyListing,
    AllPropertyListing,
    PropertyListingFilter,
    LoadingPropertyListingFilter,
    // end realestate
    AllCategories,
    AllCategoriesLoading,
    AllProducts,
    productColor,
    setProductColor,
    productSize,
    setProductSize,
    getFilters,
    _FilterValues,
    set_FilterValues,
    getAllOrders,
    showDrawer,
    onClose,
    open,
    setOpen,
    fullName,
    setFullName,
    imgUrl,
    setImgUrl,
    categories,
    filters,
    setFilters,
    FilteredProducts,
    getFilteredProductsRelatedToCategory,
    LoadingFilteredProducts,
    topRatedProducts,
    categoryId,
    loadingProfile,
    setLoadingProfile,
    setCategoryId,
    landingProducts,
    setSearchQuery,
    loadingTopRatedProducts,
    setLoadingFavoritProduct,
    loadingProducts,
    favoritList,
    setFavoritList,
    cartList,
    getCartData,
    IsProductDeleteSuccefully,
    setIsProductDeleteSuccefully,
    userDetails,
    loadingCart,
    setLoadingFavorit,
    loadingFavorit,
    // totalCart,
    addOrder,
    allOrders,
    allOrdersLoading,
    deleteOrder,
    getOneOrder,
    oneOrderLoading,
    oneOrder,
    setOneOrder,
    oneProductLoading,
    setOneProduct,
    oneProduct,
    getProduct,
    setOneProductLoading,
    isCheckedOut,
    favoritProductList,
    loadingFavoritProduct,
    getFavoritProducts,
    categoryProducts,
    loadingCategoryProducts,
    getHomeCategoryProducts,
    quantity,
    loadingQuantity,
    setLoadingQuantity,
    handleFavoret,
    increaseQuantity,
    decreaseQuantity,
    addToCart,
    loadingFavorite,
    loading,
    LoadingBtnInSearch,
    itemId,
    getProductsOfCategory,
    products,
    tags,
    brands,
    getTags,
    getBrands,
    brandsLoading,
    tagsLoading,
    token,
    getVendorProducts,
    loadingVendorProducts,
    vendorProducts,
    setSort,
    sort,
    searchQuery,
    getTopRatedProducts,
    getTopSelling,
    getFrequently,
    getBestSelling,
    bestSellingData,
    bestSellingLoading,
    frequentlyData,
    frequentlyLoading,
    topSellingData,
    topSellingLoading,
    root,
    loadingFilters,
    setLoadingFilters,
    CarouselComp: Carousel,
    promo,
    getTotalPrice,
    priceDetails,
    setPriceDetails,
    getCartValue,
    getProductByName,
    getProductByUrl,
    shippingPrice,
    IsProfileDataUpdated,
    setIsProfileDataUpdated,
  };
  return (
    <appContext.Provider value={contextValue}>{children}</appContext.Provider>
  );
};
export { appContext, AppContextProvider };
