import React, { useEffect, useState } from "react";
import "./Login.css";
import { Footer } from "../../components/Footer/Footer";
import NavBar from "../../components/navBar/navBar";
import { Button, Input, message } from "antd";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { HiOutlineMail } from "react-icons/hi";
import { RiLockPasswordLine } from "react-icons/ri";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { loginApi, reSendOtpApi, sendOtpApi } from "../../Apis";
function Login() {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const [showCode, setShowCode] = useState(false);
  const [showConfirmCode, setShowConfirmCode] = useState(false);
  const [email, setEmail] = useState("");
  const [roleName, setRoleName] = useState("user");
  const confirmCodeSchema = yup.object().shape({
    code: yup.string().required("Code is required!"),
  });

  const {
    register: register1,
    handleSubmit: handleSubmit1,
    formState: { errors: errors1 },
    control: control1,
    setValue: resetCode,
  } = useForm({
    resolver: yupResolver(confirmCodeSchema),
  });
  const loginSchema = yup.object().shape({
    email: yup
      .string("Invalid Email!")
      .email("Invalid Email!")
      .required("Email is required!"),
    password: yup.string("Invalid password!").required("Password is required"),
  });

  const {
    register: register,
    handleSubmit: handleSubmit,
    formState: { errors: errors },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(loginSchema),
  });
  // console.log(errors);
  const onsubmit = async (data) => {
    // console.log(data);
    const { email } = data;
    setEmail(email);
    let obj = {
      ...data,
      //  roleName
    };
    try {
      const response = await loginApi(obj);
      // console.log(response);
      message.success(response.data.messageEn);
      if (response.data.data instanceof Object) {
        localStorage.setItem("userData", JSON.stringify(response.data.data));
        localStorage.setItem("token", response.data.data.token);
        navigate(localStorage.getItem("prevRoute"));
        window.location.reload();
        return;
      }
      setShowConfirmCode(true);
    } catch (error) {
      if (error.response) {
        message.error(error.response.data.messageEn);
      } else {
        message.error(error.message);
      }
    }
  };

  const resetCodeForm = () => {
    resetCode("code", "");
  };
  const handleResendCode = async () => {
    try {
      const response = await reSendOtpApi({ email });
      // console.log(response);
      message.success(response.data.messageEn);
    } catch (error) {
      if (error.response) {
        message.error(error.response.data.messageEn);
      } else {
        message.error(error.message);
      }
    }
  };
  const onsubmit1 = async (data) => {
    // console.log(data);
    const { code } = data;
    let obj = { code: parseInt(code), email, roleName };
    try {
      const response = await sendOtpApi(obj);
      // console.log(response.data.data);
      localStorage.setItem("userData", JSON.stringify(response.data.data));
      localStorage.setItem("token", response.data.data.token);
      message.success(response.data.messageEn);
      setShowConfirmCode(false);
      resetCodeForm();
      navigate(localStorage.getItem("prevRoute"));
      window.location.reload();
      reset({
        email: "",
        password: "",
      });
      // resetSignUpForm()
    } catch (error) {
      if (error.response) {
        message.error(error.response.data.messageEn);
      } else {
        message.error(error.message);
      }
    }
  };

  useEffect(() => {
    return () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };
  }, []);
  return (
    <>
      <NavBar />
      <div className="loginContainer">
        {/* <div className="loginLogo" style={{marginTop:showConfirmCode?"50%":"auto"}}>
             <img src="/Logo Icon.svg" alt="logo" />
             <div className="logoTitle">
                <h1 className="logoTitle1" >Beirut Mart</h1>
                <p className="logoTitle2" >It is all about your needs</p>
             </div>
           </div> */}
        {!showConfirmCode && (
          <>
            <div className="loginTitle">
              <h1 className="title1">Log in</h1>
              {/* <p className="title2">Lorem ipsum dolor sit amet consectetur.</p> */}
            </div>
            <div className="loginForm">
              <form onSubmit={handleSubmit(onsubmit)}>
                <p className="loginLable">Email :</p>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="ex: Johndoe@gmail.com"
                      prefix={
                        <HiOutlineMail
                          style={{
                            fontSize: "25px",
                            color: "#c9c9c9",
                            borderColor: "#d9d9d9",
                          }}
                        />
                      }
                      className="loginInput"
                      type="email"
                      {...field}
                    />
                  )}
                />

                <p style={{ color: "red" }}>{errors.email?.message}</p>
                <p className="loginLable">Password :</p>
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <Input
                      prefix={
                        <RiLockPasswordLine
                          style={{ fontSize: "25px", color: "#c9c9c9" }}
                        />
                      }
                      suffix={
                        !show ? (
                          <AiOutlineEye
                            style={{ fontSize: "25px", color: "#000" }}
                            onClick={() => setShow(true)}
                          />
                        ) : (
                          <AiOutlineEyeInvisible
                            style={{ fontSize: "25px", color: "#000" }}
                            onClick={() => setShow(false)}
                          />
                        )
                      }
                      className="loginInput"
                      type={!show ? "password" : "text"}
                      {...field}
                    />
                  )}
                />

                <small
                  className="forgotPass"
                  onClick={() => navigate("/reset-password")}
                >
                  Forgot Password ?
                </small>
                <p style={{ color: "red" }}>{errors.password?.message}</p>

                <Button
                  htmlType="submit"
                  className="loginBtn red-btn hover-bg-w"
                  // onClick={() => setRoleName("user")}
                >
                  Log in
                </Button>
                {/* <Button
                  htmlType="submit"
                  className="loginAuthBtn white-btn hover-bg-red"
                  onClick={() => setRoleName("vendor")}
                >
                  Log in As Vendor
                </Button> */}
              </form>
              {/* <Divider style={{borderColor:"#231F20"}}>or</Divider>
                      <button   className="loginAuthBtn" >
                      <img style={{marginRight:10}} src='/Google.svg' /> Log In with Google</button> */}
              <p className="hint1">You Don’t have an Account ?</p>
              <p className="hint2" onClick={() => navigate("/signup")}>
                Sign Up Now
              </p>
              {/* <p
                className="hint2"
                onClick={() => navigate("/become-a-vendor")}
                style={{ margin: "10px 0px" }}
              >
                Sign Up As a Vendor
              </p> */}
            </div>
          </>
        )}

        {showConfirmCode && (
          <div className="loginForm">
            <form onSubmit={handleSubmit1(onsubmit1)}>
              <span className="resetPasswordLable">Confirmation Code :</span>
              <Controller
                name="code"
                control={control1}
                render={({ field }) => (
                  <Input
                    prefix={
                      <RiLockPasswordLine
                        style={{ fontSize: "25px", color: "#c9c9c9" }}
                      />
                    }
                    suffix={
                      !showCode ? (
                        <AiOutlineEye
                          style={{ fontSize: "25px", color: "#000" }}
                          onClick={() => setShowCode(true)}
                        />
                      ) : (
                        <AiOutlineEyeInvisible
                          style={{ fontSize: "25px", color: "#000" }}
                          onClick={() => setShowCode(false)}
                        />
                      )
                    }
                    className="resetPasswordInput"
                    type={!showCode ? "password" : "text"}
                    {...field}
                  />
                )}
              />

              <p style={{ color: "red" }}>{errors1.code?.message}</p>
              <small className="note">Enter the Code Sent to your Email</small>

              <Button
                htmlType="submit"
                className="resetPasswordBtn red-btn hover-bg-w"
              >
                Enter Code
              </Button>
              <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                <button
                  type="button"
                  className="resetPasswordAuthBtn"
                  onClick={() => handleResendCode()}
                >
                  Resend Code
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
}

export default Login;
